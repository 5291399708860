import React from "react";
import { Platform, TouchableOpacity } from "react-native";
import { Badge, Box, Circle, Icon, Pressable, VStack } from "native-base";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import colors from "config/colors";
import {
	FontAwesome5,
	HomeIcon,
	HospitalIcon,
	Ionicons,
	PackageIcon,
	UserIcon,
} from "assets/icons";

import UserAvatar from "widgets/user-avatar";
import ZemidiLogo from "widgets/zemidi-logo";

import AppStore from "AppStore";

import Home from "screens/home";
import Packages from "screens/packages";
import Hospitals from "screens/hospitals";
import Account from "screens/auth/account";
import SignIn from "screens/auth/sign-in";
import { headerStyle } from "config/header-style";
import WebHeader from "./web-header";
import { LinkToPage } from "widgets";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import WebHeaderRight from "./web-header-right";

const BottomTabs = createBottomTabNavigator();
export const BottomTabsScreen = ({ navigation }: any) => {
	const [isBigScreen, setIsBigScreen] = AppStore("isBigScreen");
	const [user, setUser] = AppStore("user");
	const [showNotificationBadge] = AppStore("showNotificationBadge");
	const insets = useSafeAreaInsets();

	// const headerStyle = {
	//   height: 80 + insets.bottom,
	//   elevation: 3,
	//   shadowColor: "#000000",
	//   backgroundColor: colors.white,
	// };

	return (
		<BottomTabs.Navigator
			screenOptions={{
				tabBarActiveTintColor: colors.primary,
				tabBarHideOnKeyboard: true,
				tabBarShowLabel: false,
				tabBarStyle: isBigScreen
					? { display: "none" }
					: {
							height: 65 + insets.bottom,
							backgroundColor: colors.primary,
							borderTopWidth: 0,
					  },
				headerTitle: "",
				headerStyle: headerStyle(insets),
				headerShadowVisible: true,
				headerLeft: () =>
					isBigScreen ? <WebHeader navigation={navigation} /> : <ZemidiLogo />,
				headerRight: () =>
					isBigScreen ? (
						<WebHeaderRight />
					) : Platform.OS == "web" && !isBigScreen ? null : (
						<LinkToPage route="Notifications">
							<Box alignItems="center">
								<VStack>
									<Circle bg="gray.200" size="sm" mr={3}>
										{showNotificationBadge ? (
											<Circle
												mb={-3}
												size="4"
												zIndex={1}
												alignSelf="flex-end"
												bg={colors.primary}
											/>
										) : null}
										<Icon
											size="8"
											color={colors.black}
											as={<Ionicons name="notifications" />}
										/>
									</Circle>
								</VStack>
							</Box>
						</LinkToPage>
					),
			}}
		>
			<BottomTabs.Screen
				name="Home"
				component={Home}
				options={{
					tabBarIcon: ({ focused }) =>
						focused ? (
							<TouchableOpacity>
								<Circle bg={colors.tabCircle} size={60}>
									<HomeIcon color={colors.white} />
								</Circle>
							</TouchableOpacity>
						) : (
							<HomeIcon color={colors.accent} />
						),
					title: "Zemidi",
				}}
			/>
			<BottomTabs.Screen
				name="Hospitals"
				component={Hospitals}
				options={{
					tabBarIcon: ({ focused }) =>
						focused ? (
							<TouchableOpacity>
								<Circle bg={colors.tabCircle} size={60}>
									<HospitalIcon color={colors.white} />
								</Circle>
							</TouchableOpacity>
						) : (
							<HospitalIcon color={colors.accent} />
						),
					headerStyle: headerStyle(insets),
					title: "Zemidi | Hospitals",
					headerTitle: !isBigScreen && Object.keys(user).length ? user.name : "",
					headerLeft: () =>
						isBigScreen ? (
							<WebHeader navigation={navigation} />
						) : Object.keys(user).length ? (
							<UserAvatar />
						) : (
							<ZemidiLogo />
						),
				}}
			/>
			<BottomTabs.Screen
				name="Services"
				component={Packages}
				options={{
					tabBarIcon: ({ focused }) =>
						focused ? (
							<TouchableOpacity>
								<Circle bg={colors.tabCircle} size={60}>
									<PackageIcon color={colors.white} />
								</Circle>
							</TouchableOpacity>
						) : (
							<PackageIcon color={colors.accent} />
						),
					title: "Zemidi | Services",
					headerStyle: headerStyle(insets),
					headerTitle: !isBigScreen && Object.keys(user).length ? user.name : "",
					headerLeft: () =>
						isBigScreen ? (
							<WebHeader navigation={navigation} />
						) : Object.keys(user).length ? (
							<UserAvatar />
						) : (
							<ZemidiLogo />
						),
				}}
			/>

			{Object.keys(user).length ? (
				<BottomTabs.Screen
					name="Account"
					component={Account}
					options={{
						tabBarIcon: ({ focused }) =>
							focused ? (
								<TouchableOpacity>
									<Circle bg={colors.tabCircle} size={60}>
										<UserIcon color={colors.white} />
									</Circle>
								</TouchableOpacity>
							) : (
								<UserIcon color={colors.accent} />
							),
						headerShown: isBigScreen ? true : false,
						title: "Zemidi | Account",
						headerLeft: () =>
							isBigScreen ? <WebHeader navigation={navigation} /> : null,
					}}
				/>
			) : (
				<BottomTabs.Screen
					name="SignIn"
					component={SignIn}
					options={{
						title: "Zemidi | Sign In",
						tabBarIcon: ({ focused }) =>
							focused ? (
								<TouchableOpacity>
									<Circle bg={colors.tabCircle} size={60}>
										<UserIcon color={colors.white} />
									</Circle>
								</TouchableOpacity>
							) : (
								<UserIcon color={colors.accent} />
							),
						headerShown: isBigScreen ? true : false,

						headerLeft: () =>
							isBigScreen ? <WebHeader navigation={navigation} /> : null,
					}}
				/>
			)}
		</BottomTabs.Navigator>
	);
};
