import _APP_CONFIG from "config/app";
import React, { useEffect, useState } from "react";
import { Box, Center, Image, Text, VStack, FlatList } from "native-base";
import { ActivityIndicator } from "react-native";
import colors from "config/colors";

import AppStore from "AppStore";
import { getServices } from "./actions";
import LoadingAnimation from "widgets/loading-animation";
import { webStyle } from "config/web-style";
import { LinkToPage } from "widgets";
import { getScreen } from "AppActions";

const Services = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isLoading, setIsLoading] = AppStore("isLoading");
  const [services, setServices] = useState<any>([]);
  const [totalServices, setTotalServices] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [reloadScreen, setReloadScreen] = useState<any>(false);

  useEffect(() => {
    getScreen("Services");
  }, []);

  useEffect(() => {
    getServices(page, (res: any, err: any) => {
      if (!err) {
        setServices([...services, ...res.data]);
        setTotalServices(res.totalData);
        setLoading(false);
        setIsLoadingMore(false);
      } else {
        setLoading(false);
        setIsLoadingMore(false);
      }
    });
  }, [page, reloadScreen]);

  const reload = () => {
    setLoading(true);
    setServices([]);
    setTotalServices(0);
    setPage(1);
    setReloadScreen(!reloadScreen);
  };

  useEffect(() => {
    setCurrentPageInit(reload);
    const unsubscribe = navigation.addListener("focus", () => {
      setCurrentPageInit(reload);
    });
    return unsubscribe;
  }, []);

  //to reset isLoading global state used in doctor, donor, mitra.
  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      setIsLoading(true);
    });
    return unsubscribe;
  }, []);
  //

  const handlePagination = () => {
    if (services.length < totalServices && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage((page) => page + 1);
    }
  };

  const listFooterComponent = () => {
    return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
  };

  return (
    <Box
      flex={1}
      // pt="4"
      bgColor={isBigScreen ? colors.webOuterBg : colors.appInnerBg}
      justifyContent="center"
      px={isBigScreen ? webStyle.xNum : 0}
    >
      <Box
        bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
        mx={isBigScreen ? webStyle.xNum : 0}
        flex="1"
        w="full"
        alignItems={
          isBigScreen ? (services.length > 5 ? "center" : "flex-start") : services.length > 1 ? "center" : "flex-start"
        }
      >
        <FlatList
          data={services}
          showsVerticalScrollIndicator={false}
          numColumns={isBigScreen ? webStyle.numCol : 2}
          onEndReached={handlePagination}
          onEndReachedThreshold={0.5}
          ListFooterComponent={listFooterComponent}
          contentContainerStyle={{
            //   flex: 1,
            alignItems: "flex-start",
            justifyContent: "center",
          }}
          style={{
            //   flexGrow: 0,
            paddingHorizontal: 4,
          }}
          renderItem={({ item }) => (
            <Box
              borderWidth="1"
              borderRadius="lg"
              // w={{ base: 150, sm: "40" }}
              w={150}
              rounded="3xl"
              overflow="hidden"
              borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
              p="2"
              m="1"
              bg={colors.white}
            >
              <LinkToPage
                route={
                  item.type == "doctor-appointment"
                    ? "Doctors"
                    : item.type == "blood-donation"
                    ? "BloodDonors"
                    : item.type == "upload-prescription"
                    ? "UploadPrescription"
                    : item.type == "products"
                    ? "Products"
                    : "ServiceDetails"
                }
                params={{
                  id: item.id,
                  name: item.name,
                  image: item.image,
                }}
              >
                <VStack alignItems="center" space="1">
                  <Center size="32">
                    {item.image ? (
                      <Image
                        size={115}
                        borderRadius={115}
                        source={{
                          uri: `${_APP_CONFIG._IMAGE_URL}/${item.image}`,
                        }}
                        resizeMode="contain"
                        alt="loading"
                      />
                    ) : (
                      <Image
                        source={require("assets/images/no-image.png")}
                        size={115}
                        resizeMode="contain"
                        alt="loading"
                      />
                    )}
                  </Center>
                  <Text
                    fontSize="sm"
                    fontWeight="medium"
                    color={colors.primary}
                    textAlign="center"
                    noOfLines={3}
                    lineHeight="sm"
                  >
                    {item.name}
                  </Text>
                </VStack>
              </LinkToPage>
            </Box>
          )}
          keyExtractor={(item: any) => item.id}
        />
      </Box>
      <LoadingAnimation loading={loading} />
    </Box>
  );
};

export default Services;
