import _APP_CONFIG from "config/app";
import {
  Box,
  Checkbox,
  CheckIcon,
  Circle,
  HStack,
  Icon,
  Image,
  Input,
  InputLeftAddon,
  ScrollView,
  Select,
  Stack,
  Text,
  TextArea,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import AppStore from "AppStore";
import { TouchableOpacity } from "react-native";
import { webStyle } from "config/web-style";
import colors from "config/colors";
import { districts, getCoords, saveMitra } from "./actions";
import { Button, LinkToPage, LoadingAnimation, showToast } from "widgets";
import { Entypo, FontAwesome, Ionicons, MaterialCommunityIcons, MaterialIcons } from "assets/icons";
import { sendOtp, verifyOtp } from "AppActions";

const HealthCareMitra = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [name, setName] = useState<any>(null);
  const [phone, setPhone] = useState<any>(null);
  const [location, setLocation] = useState<any>({});
  const [fields, setFields] = useState<any>({});
  const [otp, setOtp] = useState<any>();
  const [acknowledgement, setAcknowledgement] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otpDetails, setOtpDetails] = useState();

  useEffect(() => {
    getCoords(location, setLocation);
  }, []);

  const getOtp = () => {
    if (phone) {
      if (phone.match(/^[0-9]*$/) && phone.length == 10) {
        setOtpRequested(true);
        sendOtp(phone, "mitra-sign-up", (res: any, err: any) => {
          if (!err) {
            setOtpDetails(res.data);
          }
        });
      } else {
        showToast("Please enter valid phone no");
      }
    } else {
      showToast("Please enter phone no");
    }
  };

  const updateUser = () => {
    if (name && phone && fields.gender && fields.dist && acknowledgement && Object.keys(location).length) {
      if (phone.match(/^[0-9]*$/) && phone.length == 10) {
        setIsLoading(true);
        verifyOtp(otp, otpDetails, (res: any, err: any) => {
          if (!err && res.success) {
            saveMitra(name, phone, location, fields, (error: any, message: any) => {
              if (!error) {
                if (message == "Already Applied") {
                  setIsLoading(false);
                  showToast("You have already applied once with this phone no");
                } else {
                  setIsLoading(false);
                  showToast(message);
                  navigation.replace("Zemidi", { screen: "Home" });
                }
              } else {
                setIsLoading(false);
                showToast(message);
              }
            });
          } else {
            setIsLoading(false);
            showToast("OTP verification failed!");
          }
        });
      }
    } else {
      showToast("Please fill required fields & Accept Declaration");
    }
  };

  const reload = () => {
    navigation.replace("Zemidi", { screen: "Home" });
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box>
          <Box h="56" w="full" bg="white" roundedBottom="3xl" pt="12" overflow="hidden">
            <Image
              source={{
                uri: `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/healthcare-mitra.png`,
              }}
              alt="loading"
              size="full"
              resizeMode="contain"
            />
          </Box>
          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px="10"
            space="6"
            mx={isBigScreen ? webStyle.xNum : 0}
          >
            <HStack w="full" alignItems="center" justifyContent="space-between">
              <Box w="75%">
                <Text color="white" fontSize="lg" fontWeight="bold">
                  Register as Healthcare Mitra
                </Text>
              </Box>

              <TouchableOpacity onPress={() => navigation.replace("Zemidi", { screen: "Home" })}>
                <Circle bgColor={colors.white} size={10}>
                  <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                </Circle>
              </TouchableOpacity>
            </HStack>

            <Input
              w="full"
              h="12"
              type="text"
              placeholder="Name"
              variant="unstyled"
              rounded="3xl"
              size="xl"
              bgColor="white"
              isRequired
              onChangeText={(e: any) => setName(e)}
              value={name || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<FontAwesome name="user" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />

            <Input
              h="12"
              w="full"
              keyboardType="phone-pad"
              placeholder="Mobile Number"
              maxLength={10}
              variant="unstyled"
              isRequired
              rounded="3xl"
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => setPhone(e)}
              value={phone || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" bgColor="gray.100">
                  <Text fontSize="lg" fontWeight="semibold" color={colors.primary}>
                    +91
                  </Text>
                </InputLeftAddon>
              }
            />
            <Input
              h="12"
              w="full"
              keyboardType="phone-pad"
              placeholder="Whatsapp Number"
              maxLength={10}
              variant="unstyled"
              rounded="3xl"
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => {
                fields.whatsapp_no = e;
                setFields({ ...fields });
              }}
              value={fields.whatsapp_no || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<Ionicons name="logo-whatsapp" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />
            <Input
              w="full"
              h="12"
              type="text"
              keyboardType="email-address"
              placeholder="Email"
              variant="unstyled"
              rounded="3xl"
              size="xl"
              bgColor="white"
              isRequired
              onChangeText={(e: any) => {
                fields.mail = e;
                setFields({ ...fields });
              }}
              value={fields.mail || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<Ionicons name="mail" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />

            <VStack w="full" space="1">
              <Select
                minWidth="200"
                rounded="2xl"
                size="xl"
                bgColor={colors.white}
                accessibilityLabel="Select Gender"
                placeholder="Select Gender"
                _selectedItem={{
                  bg: colors.lightGrayish,
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={fields.gender}
                onValueChange={(itemValue) => {
                  fields.gender = itemValue;
                  setFields({ ...fields });
                }}
              >
                <Select.Item label="Male" value="Male" />
                <Select.Item label="Female" value="Female" />
                <Select.Item label="Other" value="Other" />
              </Select>
            </VStack>

            <Stack w="full" alignItems="flex-end" space="1">
              <Input
                w="full"
                h="12"
                keyboardType="default"
                type="text"
                placeholder="Address"
                variant="unstyled"
                rounded="3xl"
                size="lg"
                bgColor="white"
                isRequired
                isReadOnly
                value={location.address || "Detecting Location.."}
                InputLeftElement={
                  <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                    <Icon as={<Ionicons name="ios-location-sharp" />} size={5} color={colors.primary} />
                  </InputLeftAddon>
                }
              />
              <TouchableOpacity onPress={() => getCoords(location, setLocation)}>
                <Text color={colors.white} fontSize="md" fontWeight="semibold">
                  Detect Location Again
                </Text>
              </TouchableOpacity>
            </Stack>

            <Input
              w="full"
              h="12"
              keyboardType="default"
              type="text"
              placeholder="Place"
              variant="unstyled"
              rounded="3xl"
              size="lg"
              bgColor="white"
              isRequired
              value={fields.place || ""}
              onChangeText={(e: any) => {
                fields.place = e;
                setFields({ ...fields });
              }}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon
                    as={<MaterialIcons name="location-city" size={24} color="black" />}
                    size={5}
                    color={colors.primary}
                  />
                </InputLeftAddon>
              }
            />

            {/* <Input
              w="full"
              h="12"
              type="text"
              placeholder="District"
              isRequired
              variant="unstyled"
              rounded="3xl"
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => {
                fields.dist = e;
                setFields({ ...fields });
              }}
              value={fields.dist || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<MaterialCommunityIcons name="city-variant" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            /> */}
            <VStack w="full" space="1">
              <Select
                minWidth="200"
                rounded="2xl"
                size="xl"
                bgColor={colors.white}
                accessibilityLabel="Choose Any District"
                placeholder="Choose Any District"
                _selectedItem={{
                  bg: colors.lightGrayish,
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={fields.dist}
                onValueChange={(itemValue) => {
                  fields.dist = itemValue;
                  setFields({ ...fields });
                }}
              >
                {districts.length
                  ? districts.map((e: any) => <Select.Item label={e.value} value={e.value} key={e.id} />)
                  : null}
              </Select>
            </VStack>

            <Input
              w="full"
              h="12"
              type="text"
              placeholder="Block"
              isRequired
              variant="unstyled"
              rounded="3xl"
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => {
                fields.block = e;
                setFields({ ...fields });
              }}
              value={fields.block || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<MaterialCommunityIcons name="city-variant" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />

            <Input
              w="full"
              h="12"
              type="text"
              placeholder="Panchayat"
              variant="unstyled"
              rounded="3xl"
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => {
                fields.panchayat = e;
                setFields({ ...fields });
              }}
              value={fields.panchayat || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon
                    as={<MaterialIcons name="location-city" size={24} color="black" />}
                    size={5}
                    color={colors.primary}
                  />
                </InputLeftAddon>
              }
            />

            <Input
              w="full"
              h="12"
              type="text"
              maxLength={6}
              placeholder="Pin Code"
              variant="unstyled"
              rounded="3xl"
              isRequired
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => {
                fields.pin = e;
                setFields({ ...fields });
              }}
              value={fields.pin || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<Ionicons name="ios-location-sharp" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />

            <Input
              w="full"
              h="12"
              type="text"
              placeholder="Date of Birth"
              variant="unstyled"
              rounded="3xl"
              isRequired
              size="lg"
              bgColor="white"
              onChangeText={(e: any) => {
                fields.dob = e;
                setFields({ ...fields });
              }}
              value={fields.dob || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<Entypo name="calendar" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />
            <Input
              w="full"
              h="12"
              type="text"
              placeholder="Educational Qualification"
              variant="unstyled"
              rounded="3xl"
              isRequired
              size="lg"
              bgColor="white"
              onChangeText={(e: any) => {
                fields.edu = e;
                setFields({ ...fields });
              }}
              value={fields.edu || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<Entypo name="graduation-cap" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />

            <VStack w="full" space="1">
              <Text fontSize="md" color={colors.white} fontWeight="semibold">
                Are you part of any Govt project? If yes, mention the project.
              </Text>
              <Input
                w="full"
                h="12"
                type="text"
                placeholder="Govt. Project"
                variant="unstyled"
                rounded="3xl"
                isRequired
                size="lg"
                bgColor="white"
                onChangeText={(e: any) => {
                  fields.govtProject = e;
                  setFields({ ...fields });
                }}
                value={fields.govtProject || ""}
                InputLeftElement={
                  <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                    <Icon as={<Entypo name="briefcase" />} size={5} color={colors.primary} />
                  </InputLeftAddon>
                }
              />
            </VStack>

            <VStack w="full" space="1">
              <Text fontSize="md" color={colors.white} fontWeight="semibold">
                Do you have a Smart Phone?
              </Text>
              <Select
                minWidth="200"
                rounded="2xl"
                size="xl"
                bgColor={colors.white}
                accessibilityLabel="Select"
                placeholder="Select"
                _selectedItem={{
                  bg: colors.lightGrayish,
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={fields.have_smartPhone}
                onValueChange={(itemValue) => {
                  fields.have_smartPhone = itemValue;
                  setFields({ ...fields });
                }}
              >
                <Select.Item label="Yes" value="Yes" />
                <Select.Item label="No" value="No" />
              </Select>
            </VStack>

            <VStack w="full" space="1">
              <Text fontSize="md" color={colors.white} fontWeight="semibold">
                Do you have a Two Wheeler?
              </Text>

              <Select
                minWidth="200"
                rounded="2xl"
                size="xl"
                bgColor={colors.white}
                accessibilityLabel="Select"
                placeholder="Select"
                _selectedItem={{
                  bg: colors.lightGrayish,
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={fields.have_bike}
                onValueChange={(itemValue) => {
                  fields.have_bike = itemValue;
                  setFields({ ...fields });
                }}
              >
                <Select.Item label="Yes" value="Yes" />
                <Select.Item label="No" value="No" />
              </Select>
            </VStack>

            <VStack w="full" space="1">
              <Text fontSize="md" color={colors.white} fontWeight="semibold">
                Do you have any Medical Conditions?
              </Text>
              <Select
                minWidth="200"
                rounded="2xl"
                size="xl"
                bgColor={colors.white}
                accessibilityLabel="Select"
                placeholder="Select"
                _selectedItem={{
                  bg: colors.lightGrayish,
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={fields.have_MedCon}
                onValueChange={(itemValue) => {
                  fields.have_MedCon = itemValue;
                  setFields({ ...fields });
                }}
              >
                <Select.Item label="Yes" value="Yes" />
                <Select.Item label="No" value="No" />
              </Select>
            </VStack>

            <TextArea
              autoCompleteType={undefined}
              w="full"
              h="20"
              type="text"
              placeholder="Bank Account Details"
              variant="unstyled"
              rounded="3xl"
              isRequired
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => {
                fields.bank_details = e;
                setFields({ ...fields });
              }}
              value={fields.bank_details || ""}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<FontAwesome name="bank" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />

            <VStack w="full" space="1">
              <Text fontSize="md" color={colors.white} fontWeight="semibold">
                Referred By
              </Text>
              <Input
                w="full"
                h="12"
                type="text"
                placeholder="Referred By"
                variant="unstyled"
                rounded="3xl"
                isRequired
                size="lg"
                bgColor="white"
                onChangeText={(e: any) => {
                  fields.referredBy = e;
                  setFields({ ...fields });
                }}
                value={fields.referredBy || ""}
                InputLeftElement={
                  <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                    <Icon as={<FontAwesome name="qrcode" />} size={5} color={colors.primary} />
                  </InputLeftAddon>
                }
              />
            </VStack>

            <Stack w="full">
              <Checkbox
                value=""
                isChecked={acknowledgement}
                colorScheme="green"
                size="lg"
                onChange={(value) => {
                  setAcknowledgement(!acknowledgement);
                }}
              >
                <Text color={colors.white} fontSize="sm" fontWeight="normal">
                  I hereby declare that the above information is true & correct to the best of my knowledge and belief.
                </Text>
              </Checkbox>
            </Stack>

            <Stack w="full" alignItems="flex-end" space="1">
              {otpRequested ? (
                <>
                  <Input
                    w="full"
                    h="12"
                    keyboardType="phone-pad"
                    placeholder="OTP"
                    variant="unstyled"
                    rounded="3xl"
                    size="xl"
                    bgColor="white"
                    onChangeText={(e: any) => setOtp(e)}
                    value={otp || ""}
                    InputLeftElement={
                      <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                        <Icon as={<MaterialIcons name="verified-user" />} size={5} color={colors.primary} />
                      </InputLeftAddon>
                    }
                  />
                  <TouchableOpacity onPress={() => getOtp()}>
                    <Text color={colors.white} fontSize="lg" fontWeight="semibold">
                      Resend OTP
                    </Text>
                  </TouchableOpacity>
                  <Button title="SUBMIT" onPress={updateUser} />
                </>
              ) : (
                <>
                  <Button title="REQUEST OTP" onPress={getOtp} />
                  <HStack w="full" justifyContent="center" alignItems="center" space="1" mt="2">
                    <Text color={colors.white} fontSize="lg" fontWeight="normal">
                      Already a Mitra?
                    </Text>

                    <LinkToPage route="MitraLocation">
                      <Text color={colors.white} fontSize="lg" fontWeight="semibold">
                        Click Here
                      </Text>
                    </LinkToPage>
                  </HStack>
                </>
              )}
            </Stack>
          </VStack>
        </Box>
      </ScrollView>
      <LoadingAnimation loading={isLoading} />
    </Box>
  );
};

export default HealthCareMitra;
