import React from "react";
import AppStore from "AppStore";
import colors from "config/colors";
import { Box, HStack, Text, VStack } from "native-base";

const HealthcareText = ({ e }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  return (
    <VStack flex={isBigScreen ? "3" : "2"} alignItems="center" justifyContent="center">
      <Box w="full">
        <HStack>
          <Text fontWeight="bold" fontSize={isBigScreen ? "5xl" : "3xl"} color={colors.black}>
            {e.title_1} <Text color={colors.primary}>{e.title_2}</Text>
          </Text>
        </HStack>
        <Box mt="4">
          <Text fontWeight="medium" fontSize="xl">
            {e.text}
          </Text>
        </Box>
      </Box>
    </VStack>
  );
};

export default HealthcareText;
