import _APP_CONFIG from "config/app";
import React, { useEffect, useState } from "react";
import { Box, HStack, Icon, Image, Text, VStack, FlatList, Divider, Circle } from "native-base";
import { Ionicons, MaterialIcons } from "assets/icons";
import { ActivityIndicator, Platform, TouchableOpacity } from "react-native";
import colors from "config/colors";
import * as Linking from "expo-linking";

import { getProvidersByServiceId } from "./actions";

import LoadingAnimation from "widgets/loading-animation";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import MapImage from "widgets/map-image";
import { providersCalled } from "AppActions";

const ServiceDetails = ({ route, navigation }: any) => {
  let serviceId = route.params.id;
  let serviceName = route.params.name;
  let serviceImage = route.params.image;

  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [providers, setProviders] = useState<any>([]);
  const [totalProviders, setTotalProviders] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!providers.length || providers.length < totalProviders) {
      setIsLoading(true);
      getProvidersByServiceId(page, serviceId, (res: any, err: any) => {
        if (!err) {
          setProviders([...providers, ...res.data]);
          setTotalProviders(res.totalData);
          setIsLoading(false);
          setIsLoadingMore(false);
        }
        setIsLoading(false);
        setIsLoadingMore(false);
      });
    }
  }, [page, serviceId]);

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  const handleCall = (item: any, mode: any) => {
    setIsLoading(true);
    providersCalled({ providerId: item.provider_id.id, contactMode: mode }, (res: any, err: any) => {
      if (!err && res) {
        setIsLoading(false);
        if (mode == "phone") {
          Linking.openURL(`tel:"+91" ${item.provider_id.phone_no}`);
        } else {
          Linking.openURL(
            Platform.OS == "web"
              ? `https://wa.me/+91${item.provider_id.whatsapp_no}`
              : `whatsapp://send?phone=+91${item.provider_id.whatsapp_no}`
          );
        }
      } else {
        setIsLoading(false);
      }
    });
  };

  const handlePagination = () => {
    if (providers.length < totalProviders && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage((page) => page + 1);
    }
  };

  const listFooterComponent = () => {
    return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
  };

  const listHeaderComponent = () => {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        h="64"
        w="full"
        overflow="hidden"
        bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
      >
        {serviceName == "Ambulance" && Platform.OS !== "web" ? (
          <MapImage providers={providers} />
        ) : serviceImage ? (
          <Image
            mb="3"
            size={isBigScreen ? 250 : 180}
            borderRadius="full"
            source={{
              uri: `${_APP_CONFIG._IMAGE_URL}/${serviceImage}`,
            }}
            resizeMode="contain"
            alt="loading"
          />
        ) : (
          <Image mb="3" source={require("assets/images/no-image.png")} size={180} resizeMode="contain" alt="loading" />
        )}
      </Box>
    );
  };

  return (
    <Box flex="1" px={isBigScreen ? webStyle.xNum : "4"} bgColor={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      {providers.length ? (
        <Box
          flex="1"
          // px={isBigScreen ? webStyle.xNum : "4"}
          bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          w="full"
          alignItems={isBigScreen ? "center" : null}
        >
          <FlatList
            data={providers}
            showsVerticalScrollIndicator={false}
            numColumns={isBigScreen ? 2 : 1}
            onEndReached={handlePagination}
            onEndReachedThreshold={0.5}
            ListFooterComponent={listFooterComponent}
            ListHeaderComponent={listHeaderComponent}
            renderItem={({ item }: any) => {
              let phone_no = item.provider_id.phone_no ? item.provider_id.phone_no : null;
              let whatsapp_no = item.provider_id.whatsapp_no ? item.provider_id.whatsapp_no : null;
              phone_no = phone_no && phone_no.toString().replace(/(\d{5})(\d{5})/, "$1 $2");
              whatsapp_no = whatsapp_no && whatsapp_no.toString().replace(/(\d{5})(\d{5})/, "$1 $2");
              return (
                <Box
                  w={isBigScreen ? 600 : "full"}
                  borderRadius="2xl"
                  borderWidth="1"
                  borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
                  backgroundColor="white"
                  py="4"
                  my="2"
                  mx={isBigScreen ? 1 : 0}
                  flex={!isBigScreen ? "1" : isBigScreen ? (item.length % 2 == 0 ? "1" : "1/2") : null}
                  justifyContent="center"
                >
                  <HStack alignItems="center">
                    <Box flex="1" overflow="hidden" alignItems="center" justifyContent="center" p="1">
                      {item.provider_id && item.provider_id.image ? (
                        <Image
                          size="65"
                          source={{
                            uri: `${_APP_CONFIG._IMAGE_URL}/${item.provider_id.image}`,
                          }}
                          alt="loading"
                          resizeMode="contain"
                        />
                      ) : (
                        <Image
                          size={65}
                          source={require("assets/images/no-image.png")}
                          alt="loading"
                          resizeMode="contain"
                        />
                      )}
                    </Box>
                    <VStack space="1" flex="3">
                      <Text fontSize="md" fontWeight="medium" noOfLines={2} lineHeight="sm">
                        {item.provider_id.name}
                      </Text>
                      {phone_no ? (
                        <HStack alignItems="center" space="1">
                          <Circle bg="gray.200" size="5">
                            <Icon size="3" color={colors.darkGrayish} as={<MaterialIcons name="call" />} />
                          </Circle>
                          <Text>{phone_no}</Text>
                        </HStack>
                      ) : null}
                      {whatsapp_no ? (
                        <HStack alignItems="center" space="1">
                          <Circle bg="gray.200" size="5">
                            <Icon size="3" color={colors.darkGrayish} as={<Ionicons name="chatbubble-ellipses" />} />
                          </Circle>
                          <Text>{whatsapp_no}</Text>
                        </HStack>
                      ) : null}
                    </VStack>
                    <Divider orientation="vertical" />
                    <VStack alignItems="center" space="3" flex="1">
                      {phone_no ? (
                        <TouchableOpacity
                          onPress={() =>
                            // Linking.openURL(`tel:${item.provider_id.phone_no}`)
                            handleCall(item, "phone")
                          }
                        >
                          <Circle bg="gray.200" size="sm">
                            <Icon size="8" color="green.700" as={<MaterialIcons name="call" />} />
                          </Circle>
                        </TouchableOpacity>
                      ) : null}
                      {whatsapp_no ? (
                        <TouchableOpacity
                          onPress={() =>
                            // Linking.openURL(
                            //   Platform.OS == "web"
                            //     ? `https://wa.me/+91${item.provider_id.whatsapp_no}`
                            //     : `whatsapp://send?phone=+91${item.provider_id.whatsapp_no}`
                            // )
                            handleCall(item, "whatsapp")
                          }
                        >
                          <Circle bg="gray.200" size="sm">
                            <Icon size="8" color="green.700" as={<Ionicons name="logo-whatsapp" />} />
                          </Circle>
                        </TouchableOpacity>
                      ) : null}
                    </VStack>
                  </HStack>
                </Box>
              );
            }}
            keyExtractor={(item: any) => item.id}
          />
        </Box>
      ) : !isLoading && !providers.length ? (
        <Box h="96" w="full" bg="white" overflow="hidden">
          <Image source={require("assets/images/no-data.png")} alt="loading" size="full" resizeMode="contain" />
        </Box>
      ) : null}
      <LoadingAnimation loading={isLoading} />
    </Box>
  );
};

export default ServiceDetails;
