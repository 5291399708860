import React, { useEffect, useState } from "react";
import { Box } from "native-base";
import { ActivityIndicator } from "react-native";
import colors from "config/colors";
import { getPackagesByHospital, getCategoriesByHospital } from "./actions";

import LoadingAnimation from "widgets/loading-animation";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import { LinkToPage, NoData } from "widgets";
import PackagesList from "screens/packages/packages-list";
import { FilterIcon } from "widgets";

const PackageDetails = ({ route, navigation }: any) => {
  let hospitalId = route.params.id;
  let filter = {};
  if (route.params.filter) {
    filter = route.params.filter;
  }
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [hospitalCategories, setHospitalCategories] = useState([]);
  const [packages, setPackages] = useState<any>([]);
  const [totalPackages, setTotalPackages] = useState(0);
  const [selected, setSelected] = useState<any>(filter);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <LinkToPage
          route="Filter"
          params={{
            header: "Specialisations",
            filterData: hospitalCategories,
            selected: selected,
            handleFilterData: handleFilterData,
          }}
        >
          <FilterIcon />
        </LinkToPage>
      ),
    });
  }, [hospitalCategories, selected]);

  const resetStates = () => {
    setIsLoading(true);
    setPage(1);
    setPackages([]);
    setTotalPackages(0);
  };

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
    const unsubscribe = navigation.addListener("focus", () => {
      setCurrentPageInit(reload);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (hospitalId) {
      getCategoriesByHospital(setHospitalCategories, hospitalId, setIsLoading);
    }
  }, []);

  useEffect(() => {
    if (!packages.length || packages.length < totalPackages) {
      setIsLoading(true);
      getPackagesByHospital(
        page,
        hospitalId,
        selected,
        (res: any, err: any) => {
          if (!err) {
            setPackages([...packages, ...res.data]);
            setTotalPackages(res.totalData);
            setIsLoading(false);
            setIsLoadingMore(false);
          }
          setIsLoading(false);
          setIsLoadingMore(false);
        }
      );
    }
  }, [page, hospitalId, selected]);

  const handleFilterData = (data: any) => {
    resetStates();
    if (selected.id == data.id) {
      setSelected({});
    } else {
      setSelected(() => ({ ...selected, ...data }));
    }
  };

  const handlePagination = () => {
    if (packages.length < totalPackages && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage(page + 1);
    }
  };

  const listFooterComponent = () => {
    return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
  };

  return (
    <Box
      flex={1}
      px={!isBigScreen ? (packages.length == 1 ? "4" : "0") : webStyle.xNum}
      justifyContent="center"
      alignItems={packages.length > 1 ? "center" : "flex-start"}
      bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}
    >
      {packages.length ? (
        <Box
          flex="1"
          bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          w="full"
          pt={isBigScreen ? "4" : "2"}
          alignItems={
            isBigScreen
              ? packages.length > 5
                ? "center"
                : "flex-start"
              : packages.length > 1
              ? "center"
              : "flex-start"
          }
        >
          <PackagesList
            packages={packages}
            handlePagination={handlePagination}
            listFooterComponent={listFooterComponent}
            navigation={navigation}
            showDetails={true}
          />
        </Box>
      ) : !isLoading && !packages.length ? (
        <NoData />
      ) : (
        <LoadingAnimation loading={isLoading} />
      )}
    </Box>
  );
};

export default PackageDetails;
