import React from "react";
import { Circle, Icon } from "native-base";
import { CardStyleInterpolators, createStackNavigator, TransitionPresets } from "@react-navigation/stack";

import colors from "config/colors";
import { FontAwesome5, Ionicons } from "assets/icons";
import AppStore from "AppStore";

import Discover from "screens/on-boarding/discover";
import Trust from "screens/on-boarding/trust";
import Support from "screens/on-boarding/support";

import PackageDetails from "screens/package-details";
import HospitalDetails from "screens/hospital-details";
import ServiceDetails from "screens/service-details";
import Services from "screens/services";
import PushNotifications from "screens/notifications";

import Booking from "screens/booking/index";
import BookingDetails from "screens/booking/partials/booking-details";
import BookingSuccess from "screens/booking/partials/booking-success";
import ViewBookings from "screens/booking/view-bookings";

// import Account from "screens/auth/account";
import Profile from "screens/auth/account/profile";
import SignUp from "screens/auth/sign-up";
import ForgotPassword from "screens/auth/forgot-password";
import SignIn from "screens/auth/sign-in"; //for navigating to & from booking

import SearchResults from "screens/search-results";
import { BottomTabsScreen } from "./bottom-tabs";
import { Filter, UserAvatar, ZemidiLogo } from "widgets";
import { headerStyle } from "config/header-style";
import HeaderTitle from "widgets/header-title";
import { webStyle } from "config/web-style";
import { TouchableOpacity } from "react-native";
import AppUpdate from "widgets/updates/app-update";
import Map from "widgets/map";
import Doctors from "screens/doctors";
import DoctorSearch from "screens/doctors/doctor-search";
import BloodDonors from "screens/blood-donors";
import DonorSearch from "screens/blood-donors/donor-search";
import AddDonor from "screens/blood-donors/add-donor";
import HealthCareMitra from "screens/healthcare-mitra";
import ViewMitras from "screens/healthcare-mitra/view-mitras";
import MitraMap from "screens/healthcare-mitra/mitra-map";
import MitraSearch from "screens/healthcare-mitra/mitra-search";
import HealthCard from "screens/health-card";
import MitraLocation from "screens/healthcare-mitra/mitra-location";
import About from "screens/company/about";
import Gallery from "screens/company/gallery";
import WebHeader from "./web-header";
import { useNavigation } from "@react-navigation/native";
import WebHeaderRight from "./web-header-right";
import HospitalCenters from "screens/hospitals/hospital-centers";
import UploadPrescription from "screens/upload-prescription";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Videos from "screens/company/videos";
import Benefits from "screens/health-card/benefits";
import Consultancy from "screens/consultancy/consultancy";
import Products from "screens/products";
import ProductsSearch from "screens/products/products-search";
import Privacy from "screens/privacy";
import Enquire from "screens/enquire";
import EnquiringSuccess from "screens/enquire/partials/enquiring-success";
import Insurance from "screens/insurance";
import HealthServices from "screens/health-services";

const RootStack = createStackNavigator();
export const RootStackScreens = () => {
  const [pushNotification, setPushNotification] = AppStore("pushNotification");
  const [isBigScreen] = AppStore("isBigScreen");
  const [showWelcome] = AppStore("showWelcome");
  const [updateAvailable] = AppStore("updateAvailable");
  const [initialRoute] = AppStore("initialRoute");
  const [user] = AppStore("user");
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();

  return (
    <RootStack.Navigator initialRouteName={initialRoute}>
      <RootStack.Group
        screenOptions={{
          // gestureEnabled: true,
          // cardShadowEnabled: true,
          headerStyle: headerStyle(insets),
          headerShadowVisible: true,
          headerBackImage: () => (
            <Icon
              size={12}
              color={colors.primary}
              as={<Ionicons name="ios-caret-back-circle" />}
              ml={isBigScreen ? webStyle.xNum : 0}
            />
          ),
          ...TransitionPresets.SlideFromRightIOS,
          cardStyleInterpolator: isBigScreen
            ? CardStyleInterpolators.forNoAnimation
            : CardStyleInterpolators.forHorizontalIOS,
        }}
      >
        <RootStack.Screen
          name="Zemidi"
          component={
            isBigScreen
              ? BottomTabsScreen
              : updateAvailable
              ? AppUpdate
              : !updateAvailable && showWelcome
              ? Discover
              : BottomTabsScreen
          }
          options={{ headerShown: false }}
        />
        <RootStack.Screen name="Discover" component={Discover} options={{ headerShown: false }} />
        <RootStack.Screen name="Trust" component={Trust} options={{ headerShown: false }} />
        <RootStack.Screen name="Support" component={Support} options={{ headerShown: false }} />
        <RootStack.Screen
          name="ForgotPassword"
          component={ForgotPassword}
          options={({ route }: any) => ({
            title: isBigScreen ? "Zemidi | Forgot Password" : "",
            headerTitle: () => <HeaderTitle title="Forgot Password" />,
            headerShown: isBigScreen ? true : false,
          })}
        />
        <RootStack.Screen
          name="SignUp"
          component={SignUp}
          options={({ route }: any) => ({
            title: isBigScreen ? "Zemidi | Sign Up" : "",
            headerTitle: () => <HeaderTitle title="Sign Up" />,
            headerShown: isBigScreen ? true : false,
          })}
        />
        <RootStack.Screen
          name="SignUp2"
          component={SignUp}
          options={({ route }: any) => ({
            title: isBigScreen ? "Zemidi | Sign Up" : "",
            headerTitle: () => <HeaderTitle title="Sign Up" />,
            headerShown: isBigScreen ? true : false,
          })}
        />
        {/* <RootStack.Screen
          name="Account"
          component={Account}
          options={{ headerShown: false }}
        /> */}
        <RootStack.Screen name="Profile" component={Profile} options={{ headerShown: isBigScreen ? true : false }} />
        <RootStack.Screen
          name="Notifications"
          component={PushNotifications}
          options={() => ({
            headerTitle: () => <HeaderTitle title="Notifications" />,
            headerRight: () =>
              pushNotification.length ? (
                <TouchableOpacity onPress={() => setPushNotification([])}>
                  <Circle bg="gray.200" size="sm" mr={3}>
                    <Icon size="6" color={colors.primary} as={<FontAwesome5 name="trash" />} />
                  </Circle>
                </TouchableOpacity>
              ) : null,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="HospitalDetails"
          component={HospitalDetails}
          options={({ route }: any) => ({
            title: isBigScreen ? "Zemidi | Hospitals" : "",
            headerTitle: () => <HeaderTitle title={route.params.name} />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="HospitalCenters"
          component={HospitalCenters}
          options={({ route }: any) => ({
            title: isBigScreen ? "Zemidi | Hospitals" : "",
            headerTitle: () => <HeaderTitle title={route.params.name} />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="PackageDetails"
          component={PackageDetails}
          options={({ route }: any) => ({
            title: isBigScreen ? "Zemidi | Packages" : "",
            headerTitle: () => <HeaderTitle title={route.params.name} />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="Services"
          component={Services}
          options={() => ({
            title: isBigScreen ? "Zemidi | Services" : "",
            headerTitle: () => <HeaderTitle title="Services" />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="ServiceDetails"
          component={ServiceDetails}
          options={({ route }: any) => ({
            title: isBigScreen ? "Zemidi | Services" : "",
            headerTitle: () => <HeaderTitle title={route.params.name} />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="Booking"
          component={Booking}
          options={({ route }: any) => ({
            title: isBigScreen ? "Zemidi | Booking" : "",
            headerTitle: () => <HeaderTitle title={route.params.name} />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="Enquire"
          component={Enquire}
          options={({ route }: any) => ({
            title: "Hospital Details",
            // headerTitle: () => <HeaderTitle title={route.params.name} />,
            headerShown: true,
          })}
        />

        <RootStack.Screen
          name="BookingSuccess"
          component={BookingSuccess}
          options={{
            title: isBigScreen ? "Booking Success" : "",
            headerShown: false,
          }}
        />

        <RootStack.Screen
          name="EnquiringSuccess"
          component={EnquiringSuccess}
          options={{
            title: isBigScreen ? "Booking Success" : "",
            headerShown: false,
          }}
        />
        <RootStack.Screen
          name="ViewBookings"
          component={ViewBookings}
          options={() => ({
            title: isBigScreen ? "My Bookings" : "",
            headerTitle: () => <HeaderTitle title="My Bookings" />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="BookingDetails"
          component={BookingDetails}
          options={() => ({
            title: isBigScreen ? "Booking Details" : "",
            headerTitle: () => <HeaderTitle title="Booking Details" />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="SearchResults"
          component={SearchResults}
          options={{
            headerShown: false,
            presentation: "modal",
          }}
        />
        <RootStack.Screen
          name="SignIn2"
          component={SignIn}
          options={() => ({
            title: isBigScreen ? "Zemidi | Sign In" : "",
            headerTitle: () => <HeaderTitle title="Sign In" />,
            headerShown: isBigScreen ? true : false,
          })}
        />
        <RootStack.Screen
          name="Map"
          component={Map}
          options={() => ({
            // title: isBigScreen ? "Zemidi | Ambulance Location" : "",
            headerTitle: () => <HeaderTitle title="" />,
            headerShown: true,
            headerTransparent: true,
          })}
        />
        <RootStack.Screen
          name="Doctors"
          component={Doctors}
          options={() => ({
            title: isBigScreen ? "Zemidi | Doctors" : "",
            headerTitle: () => <HeaderTitle title="Doctors" />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="DoctorSearch"
          component={DoctorSearch}
          options={{
            headerShown: false,
            presentation: "modal",
          }}
        />
        <RootStack.Screen
          name="Products"
          component={Products}
          options={() => ({
            title: isBigScreen ? "Zemidi | Products" : "",
            headerTitle: () => <HeaderTitle title="Products" />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="ProductSearch"
          component={ProductsSearch}
          options={{
            headerShown: false,
            presentation: "modal",
          }}
        />
        <RootStack.Screen
          name="BloodDonors"
          component={BloodDonors}
          options={() => ({
            title: isBigScreen ? "Zemidi | Blood Donors" : "",
            headerTitle: () => <HeaderTitle title="Blood Donors" />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="AddDonor"
          component={AddDonor}
          options={() => ({
            title: isBigScreen ? "Zemidi | Register as Blood Donor" : "",
            headerTitle: () => <HeaderTitle title="Register as Blood Donor" />,
            headerShown: isBigScreen ? true : false,
          })}
        />
        <RootStack.Screen
          name="DonorSearch"
          component={DonorSearch}
          options={{
            headerShown: false,
            presentation: "modal",
          }}
        />
        <RootStack.Screen
          name="HealthCareMitra"
          component={HealthCareMitra}
          options={() => ({
            title: isBigScreen ? "Zemidi | Healthcare Mitra" : "",
            headerTitle: () => <HeaderTitle title="Healthcare Mitra" />,
            headerShown: false,
          })}
        />
        <RootStack.Screen
          name="MitraLocation"
          component={MitraLocation}
          options={() => ({
            title: isBigScreen ? "Zemidi | Healthcare Mitra" : "",
            headerTitle: () => <HeaderTitle title="Healthcare Mitra" />,
            headerShown: false,
          })}
        />
        <RootStack.Screen
          name="ViewMitras"
          component={ViewMitras}
          options={() => ({
            title: isBigScreen ? "Zemidi | Healthcare Mitras" : "",
            headerTitle: () => <HeaderTitle title="Healthcare Mitras" />,
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="MitraSearch"
          component={MitraSearch}
          options={{
            headerShown: false,
            presentation: "modal",
          }}
        />
        <RootStack.Screen
          name="MitraMap"
          component={MitraMap}
          options={() => ({
            // title: isBigScreen ? "Zemidi | Mitra Location" : "",
            headerTitle: () => <HeaderTitle title="" />,
            headerShown: true,
            headerTransparent: true,
          })}
        />
        <RootStack.Screen
          name="HealthCard"
          component={HealthCard}
          options={{
            headerShown: isBigScreen ? true : false,
            title: isBigScreen ? "Zemidi | Health Card" : "",
            headerTitle: () => <HeaderTitle title="Health Card" />,
          }}
        />
        <RootStack.Screen
          name="UploadPrescription"
          component={UploadPrescription}
          options={{
            headerShown: isBigScreen ? true : false,
            title: isBigScreen ? "Zemidi | Upload Prescription" : "",
            headerTitle: () => <HeaderTitle title="Upload Prescription" />,
          }}
        />
        <RootStack.Screen
          name="Benefits"
          component={Benefits}
          options={{
            headerShown: isBigScreen ? true : false,
            title: isBigScreen ? "Zemidi | Benefits & Specifications" : "",
            headerTitle: () => <HeaderTitle title="Benefits & Specifications" />,
          }}
        />
        {/* <RootStack.Screen
          name="Benefits"
          component={Benefits}
          options={{
            headerShown: isBigScreen ? true : false,
            title: isBigScreen ? "Zemidi | Health Card Benefits" : "",
            headerTitle: () => <HeaderTitle title="Health Card Benefits" />,
          }}
        /> */}
        <RootStack.Screen
          name="About"
          component={About}
          options={{
            headerStyle: headerStyle(insets),
            title: "Zemidi | About Us",
            headerTitle: !isBigScreen && Object.keys(user).length ? user.name : "",
            headerLeft: () =>
              isBigScreen ? (
                <WebHeader navigation={navigation} />
              ) : Object.keys(user).length ? (
                <UserAvatar />
              ) : (
                <ZemidiLogo />
              ),
            headerRight: () => (isBigScreen ? <WebHeaderRight /> : null),
          }}
        />
        <RootStack.Screen
          name="Consultancy"
          component={Consultancy}
          options={{
            headerStyle: headerStyle(insets),
            title: "Zemidi | Consultancy",
            headerTitle: !isBigScreen && Object.keys(user).length ? user.name : "",
            headerLeft: () =>
              isBigScreen ? (
                <WebHeader navigation={navigation} />
              ) : Object.keys(user).length ? (
                <UserAvatar />
              ) : (
                <ZemidiLogo />
              ),
            headerRight: () => (isBigScreen ? <WebHeaderRight /> : null),
          }}
        />

        <RootStack.Screen
          name="Gallery"
          component={Gallery}
          options={{
            headerStyle: headerStyle(insets),
            title: "Zemidi | Gallery",
            headerTitle: !isBigScreen && Object.keys(user).length ? user.name : "",
            headerLeft: () =>
              isBigScreen ? (
                <WebHeader navigation={navigation} />
              ) : Object.keys(user).length ? (
                <UserAvatar />
              ) : (
                <ZemidiLogo />
              ),
            headerRight: () => (isBigScreen ? <WebHeaderRight /> : null),
          }}
        />
        <RootStack.Screen
          name="Videos"
          component={Videos}
          options={{
            headerStyle: headerStyle(insets),
            title: "Zemidi | Videos",
            headerTitle: !isBigScreen && Object.keys(user).length ? user.name : "",
            headerLeft: () =>
              isBigScreen ? (
                <WebHeader navigation={navigation} />
              ) : Object.keys(user).length ? (
                <UserAvatar />
              ) : (
                <ZemidiLogo />
              ),
            headerRight: () => (isBigScreen ? <WebHeaderRight /> : null),
          }}
        />
      </RootStack.Group>
      <RootStack.Group>
        <RootStack.Screen
          name="Filter"
          component={Filter}
          options={{
            headerShown: false,
            presentation: "modal",
          }}
        />
      </RootStack.Group>
      <RootStack.Screen name="Privacy" component={Privacy} options={{ headerShown: isBigScreen ? true : false }} />
      <RootStack.Screen name="Insurance" component={Insurance} options={{ headerShown: false }} />
      <RootStack.Screen
        name="HealthServices"
        component={HealthServices}
        options={{ headerShown: false, title: "Health Services" }}
      />
    </RootStack.Navigator>
  );
};
