import React, { useEffect, useState } from "react";
import {
	Box,
	Circle,
	HStack,
	Icon,
	Image,
	Input,
	InputLeftAddon,
	ScrollView,
	Stack,
	Text,
	VStack,
} from "native-base";
import { Entypo, FontAwesome, MaterialIcons } from "assets/icons";
import { Platform, TouchableOpacity } from "react-native";
import colors from "config/colors";

import AppStore from "AppStore";
import { saveUser } from "./actions";
import { AuthHeader, Button, LinkToPage } from "widgets";
import {
	getAndroidId,
	getDeviceOS,
	getIpAddress,
	getLatLong,
	registerForPushNotificationsAsync,
	saveReferralsInstalls,
	sendOtp,
	subscribeToTopic,
	verifyOtp,
} from "AppActions";
import LoadingAnimation from "widgets/loading-animation";
import { showToast } from "widgets/nb-toast";
import { webStyle } from "config/web-style";

const SignUp = ({ route, navigation }: any) => {
	const [isBigScreen] = AppStore("isBigScreen");
	const [deviceOs] = AppStore("deviceOs");
	const [selectedWebHeader, setSelectedWebHeader] = AppStore("selectedWebHeader");
	const [user, setUser] = AppStore("user");
	const [session, setSession] = AppStore("session");
	const [deviceInfo] = AppStore("deviceInfo");
	const [latLong] = AppStore("latLong");
	const [broadcastSubscribed] = AppStore("broadcastSubscribed");
	const [androidId] = AppStore("androidId");
	const [ipAddress] = AppStore("ipAddress");
	const [referralUserSaved] = AppStore("referralUserSaved");
	const [referralCode, setReferralCode] = AppStore("referralCode");
	const [isLoading, setIsLoading] = useState(false);
	const [fields, setFields] = useState<any>({});
	const [togglePassword, setTogglePassword] = useState(false);
	const [otpRequested, setOtpRequested] = useState(false);
	const [otpDetails, setOtpDetails] = useState();

	let screen: any;
	let id: any;
	let code: any;
	if (route.params) {
		screen = route.params.screen;
		id = route.params.id;
		code = route.params.referral;
	}

	useEffect(() => {
		if (referralCode && !code) {
			return;
		} else {
			setReferralCode(code);
		}
	}, [route]);

	useEffect(() => {
		if (Object.keys(user).length) {
			navigation.replace("Zemidi", { screen: "Home" });
		}
	}, []);

	useEffect(() => {
		registerForPushNotificationsAsync();
		getDeviceOS();
		subscribeToTopic();
		getAndroidId();
		getIpAddress();
		if (referralCode || route.params.referral) {
			return;
		} else {
			getLatLong();
		}
	}, []);

	useEffect(() => {
		if (!referralUserSaved && referralCode && !Object.keys(user).length) {
			if (referralCode.trim().length == 4) {
				saveReferralsInstalls();
			}
		}
	}, [deviceInfo, referralCode, broadcastSubscribed, androidId, ipAddress]);

	const getOtp = () => {
		if (fields.phone) {
			if (fields.phone.match(/^[0-9]*$/) && fields.phone.length == 10) {
				setOtpRequested(true);
				sendOtp(fields.phone, "user-sign-up", (res: any, err: any) => {
					if (!err) {
						setOtpDetails(res.data);
					}
				});
			} else {
				showToast("Please enter valid phone no");
			}
		} else {
			showToast("Please enter phone no");
		}
	};

	const registerUser = () => {
		if (Object.keys(fields).length && fields.name && fields.phone && fields.password) {
			if (fields.phone.match(/^[0-9]*$/) && fields.phone.length == 10) {
				setIsLoading(true);
				verifyOtp(fields.otp, otpDetails, (res: any, err: any) => {
					if (!err && res.success) {
						saveUser(
							fields,
							latLong,
							deviceInfo,
							broadcastSubscribed,
							deviceOs,
							referralCode,
							(res: any, err: any) => {
								if (!err) {
									if (res.taken) {
										showToast("Phone no already registered!");
										setIsLoading(false);
									} else {
										// let { id, data } = res;
										// delete data.password;
										// data.id = id;
										// setUser(data);
										setUser(res.data?.user);
										// delete user?.password;
										// setUser({ ...user });
										let data = { ...res.data?.user };
										let token = res.data?.session;
										setSession({ userSession: data, token: token });
										setIsLoading(false);
										if (screen && screen == "Enquire") {
											navigation.navigate(`${screen}`, { id });
										} else if (screen && screen !== "Enquire") {
											navigation.navigate(`${screen}`);
										} else {
											navigation.replace("Zemidi", { screen: "Home" });
											isBigScreen ? setSelectedWebHeader("Home") : null;
										}
										showToast("Welcome!");
									}
								} else {
									setIsLoading(false);
									showToast("Please try again after sometime!");
								}
							}
						);
					} else {
						setIsLoading(false);
						showToast("OTP verification failed!");
					}
				});
			} else {
				showToast("Please enter valid phone no");
			}
		} else {
			showToast("Please fill required fields");
		}
	};
	return (
		<Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
			<ScrollView
				flex="1"
				bg={colors.primary}
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{ paddingBottom: 10 }}
				mx={isBigScreen ? webStyle.authX : 0}
			>
				<Box>
					<AuthHeader />
					<VStack
						py="10"
						justifyContent="center"
						alignItems="flex-start"
						px="10"
						space="6"
						mx={isBigScreen ? webStyle.xNum : 0}
					>
						<HStack w="full" alignItems="center" justifyContent="space-between">
							<Text color="white" fontSize="4xl" fontWeight="bold">
								Sign Up
							</Text>
							{!isBigScreen && !route.params.referral ? (
								<TouchableOpacity onPress={() => navigation.goBack()}>
									<Circle bgColor={colors.white} size={10}>
										<Icon
											size="6"
											color={colors.primary}
											as={<Entypo name="chevron-left" />}
										/>
									</Circle>
								</TouchableOpacity>
							) : null}
						</HStack>

						<Input
							w="full"
							h="12"
							type="text"
							placeholder="Name"
							isRequired
							variant="unstyled"
							rounded="3xl"
							size="xl"
							bgColor="white"
							onChangeText={(e: any) => {
								fields.name = e;
								setFields({ ...fields });
							}}
							value={fields.name || ""}
							InputLeftElement={
								<InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
									<Icon
										as={<FontAwesome name="user" />}
										size={5}
										color={colors.primary}
									/>
								</InputLeftAddon>
							}
						/>

						<Input
							h="12"
							w="full"
							keyboardType="phone-pad"
							placeholder="Mobile Number"
							maxLength={10}
							variant="unstyled"
							isRequired
							rounded="3xl"
							size="xl"
							bgColor="white"
							onChangeText={(e: any) => {
								fields.phone = e;
								setFields({ ...fields });
							}}
							value={fields.phone || ""}
							InputLeftElement={
								<InputLeftAddon w="20%" h="full" bgColor="gray.100">
									<Text
										fontSize="lg"
										fontWeight="semibold"
										color={colors.primary}
									>
										+91
									</Text>
								</InputLeftAddon>
							}
						/>
						<Input
							w="full"
							h="12"
							keyboardType="email-address"
							type="text"
							placeholder="Email Id (optional)"
							variant="unstyled"
							rounded="3xl"
							size="xl"
							bgColor="white"
							onChangeText={(e: any) => {
								fields.email = e;
								setFields({ ...fields });
							}}
							value={fields.email || ""}
							InputLeftElement={
								<InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
									<Icon
										as={<MaterialIcons name="email" />}
										size={5}
										color={colors.primary}
									/>
								</InputLeftAddon>
							}
						/>
						<Input
							w="full"
							h="12"
							type="text"
							placeholder="Have a Referral Code?"
							variant="unstyled"
							rounded="3xl"
							size="lg"
							bgColor="white"
							onChangeText={(e: any) => {
								setReferralCode(e);
							}}
							autoCapitalize="characters"
							value={referralCode || ""}
							InputLeftElement={
								<InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
									<Icon
										as={<FontAwesome name="qrcode" />}
										size={5}
										color={colors.primary}
									/>
								</InputLeftAddon>
							}
						/>

						<Input
							w="full"
							h="12"
							type={togglePassword ? "text" : "password"}
							variant="unstyled"
							placeholder="Password"
							rounded="3xl"
							size="xl"
							bgColor="white"
							onChangeText={(e: any) => {
								fields.password = e;
								setFields({ ...fields });
							}}
							value={fields.password || ""}
							InputLeftElement={
								<InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
									<Icon
										as={<MaterialIcons name="lock-outline" />}
										size={5}
										color={colors.primary}
									/>
								</InputLeftAddon>
							}
							InputRightElement={
								<InputLeftAddon
									borderColor={colors.white}
									h="full"
									fontSize="2xl"
									bgColor={colors.white}
								>
									<TouchableOpacity
										onPress={() => setTogglePassword(!togglePassword)}
									>
										<Icon
											as={
												<MaterialIcons
													name={
														togglePassword
															? "visibility"
															: "visibility-off"
													}
												/>
											}
											size={5}
											color={colors.black}
										/>
									</TouchableOpacity>
								</InputLeftAddon>
							}
						/>

						<Stack w="full" alignItems="flex-end" space="2">
							{otpRequested ? (
								<>
									<Input
										w="full"
										h="12"
										keyboardType="phone-pad"
										placeholder="OTP"
										variant="unstyled"
										rounded="3xl"
										size="xl"
										bgColor="white"
										onChangeText={(e: any) => {
											fields.otp = e;
											setFields({ ...fields });
										}}
										value={fields.otp || ""}
										InputLeftElement={
											<InputLeftAddon
												w="20%"
												h="full"
												fontSize="2xl"
												bgColor="gray.100"
											>
												<Icon
													as={<MaterialIcons name="verified-user" />}
													size={5}
													color={colors.primary}
												/>
											</InputLeftAddon>
										}
									/>
									<TouchableOpacity onPress={() => getOtp()}>
										<Text
											color={colors.white}
											fontSize="lg"
											fontWeight="semibold"
										>
											Resend OTP
										</Text>
									</TouchableOpacity>
									<Button title="SIGN UP" onPress={registerUser} />
								</>
							) : (
								<Button title="REQUEST OTP" onPress={getOtp} />
							)}
							{!referralCode ? (
								<HStack
									w="full"
									justifyContent="center"
									alignItems="center"
									space="1"
								>
									<Text color={colors.white} fontSize="lg" fontWeight="normal">
										Already registered?
									</Text>
									<LinkToPage
										route={screen ? "SignIn2" : "SignIn"}
										params={{
											screen: screen,
											id: id,
										}}
									>
										<Text
											color={colors.white}
											fontSize="lg"
											fontWeight="semibold"
										>
											Sign In
										</Text>
									</LinkToPage>
								</HStack>
							) : null}
						</Stack>
					</VStack>
				</Box>
				<LoadingAnimation loading={isLoading} />
			</ScrollView>
		</Box>
	);
};

export default SignUp;
