import axios from "axios";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getProvidersByServiceId = async (page, serviceId, callback) => {
  let paramsCount = [serviceId];
  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: query.getServiceProvidersByServiceIdCount.query,
      sqlParams: paramsCount,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.getServiceProvidersByServiceIdCount.token,
      },
    }
  );
  // console.log("resCount", resCount);
  let params = [serviceId, (page - 1) * 20];
  const body = {
    sql: query.getServiceProvidersByServiceId.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getServiceProvidersByServiceId.token,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res", res);
  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      // console.log(" res?.data?.result", res?.data?.result);
      callback(
        {
          // data: [],
          data: res?.data?.result,
          totalData: resCount?.data?.result[0]?.["count(*)"],
        },
        false
      );
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }

  // Api.get(
  //   // `/services_providers?join=providers&join=services&filter=service_id,eq,${serviceId}&order=id&page=${page},30`
  //   `/services_providers?join=providers&join=services&filter=service_id,eq,${serviceId}&filter=provider_priority,gt,0&order=provider_priority,desc&page=${page},30`
  // ).send((res) => {
  //   if (res.type !== "error") {
  //     // console.log("res", res.records);
  //     callback(
  //       {
  //         data: res.records,
  //         totalData: res.results,
  //       },
  //       false
  //     );
  //   } else {
  //     callback(
  //       {
  //         data: [],
  //         totalData: 0,
  //       },
  //       true
  //     );
  //   }
  // });
};
