import AppStore from "AppStore";
import { FontAwesome, Foundation, Ionicons } from "assets/icons";
import colors from "config/colors";
import moment from "moment";
import { Box, Circle, HStack, Icon, Input, InputLeftAddon, Stack, Text, VStack } from "native-base";
import React from "react";

const IssuedCard = ({ card }: any) => {
  const [isBigScreen, setIsBigScreen] = AppStore("isBigScreen");
  let names = card && card.name.split(",");
  return (
    <VStack
      space="2"
      w={isBigScreen ? "96" : "full"}
      // borderColor={isBigScreen ? "white" : "none"}
      // borderWidth={isBigScreen ? "2" : "0"}
      // borderRadius={isBigScreen ? "lg" : "none"}
      // p={isBigScreen ? "3" : "0"}
    >
      <Stack w="full" space="2">
        <HStack space="2">
          <Circle size={`${isBigScreen ? "6" : "4"}`} mt={`${isBigScreen ? "3" : "2"}`} bgColor={colors.white}></Circle>
          <Text color={colors.white} fontSize={`${isBigScreen ? "3xl" : "xl"}`} fontWeight="medium">
            Beneficiary Name(s)
          </Text>
        </HStack>
        <Stack w="full" space="1" ml="3">
          {names.length
            ? names.map((n: any, i: any) => (
                <Text key={i} color={colors.white} fontSize={`${isBigScreen ? "xl" : "md"}`}>
                  {i + 1}
                  {". "}
                  {n.trim()}
                </Text>
              ))
            : null}
        </Stack>
        {/* <Input
          w="full"
          h="12"
          type="text"
          placeholder="Beneficiary Name"
          isReadOnly
          variant="unstyled"
          rounded="3xl"
          size="xl"
          bgColor="white"
          value={card.name || ""}
          InputLeftElement={
            <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
              <Icon as={<FontAwesome name="user" />} size={5} color={colors.primary} />
            </InputLeftAddon>
          }
        /> */}
      </Stack>
      {card.product == "HAI-199" ? null : (
        <Stack w="full" space="2">
          <HStack space="2">
            <Circle
              size={`${isBigScreen ? "6" : "4"}`}
              mt={`${isBigScreen ? "3" : "2"}`}
              bgColor={colors.white}
            ></Circle>
            <Text color={colors.white} fontSize={`${isBigScreen ? "3xl" : "xl"}`} fontWeight="medium">
              Card Type
            </Text>
          </HStack>
          <Stack w="full" space="1" ml="3">
            <Text color={colors.white} fontSize={`${isBigScreen ? "xl" : "md"}`}>
              {card.product}
            </Text>
          </Stack>
        </Stack>
      )}
      <Stack w="full" space="2">
        <HStack space="2">
          <Circle size={`${isBigScreen ? "6" : "4"}`} mt={`${isBigScreen ? "3" : "2"}`} bgColor={colors.white}></Circle>
          <Text color={colors.white} fontSize={`${isBigScreen ? "3xl" : "xl"}`} fontWeight="medium">
            {card.product == "HAI-199" ? "HAI Subscription No." : "Card Number"}
          </Text>
        </HStack>
        <Stack w="full" space="1" ml="3">
          <Text color={colors.white} fontSize={`${isBigScreen ? "xl" : "md"}`} fontWeight="medium">
            {card.healthcard_no}
          </Text>
        </Stack>
      </Stack>

      {/* <Stack w="full" space="1">
        <Text color={colors.white} fontSize="md" fontWeight="medium">
          Expires on
        </Text>
        <Input
          w="full"
          h="12"
          type="text"
          placeholder="Valid Upto"
          isReadOnly
          variant="unstyled"
          rounded="3xl"
          size="xl"
          bgColor="white"
          value={moment.utc(card.expiry).format("DD-MM-YYYY") || ""}
          InputLeftElement={
            <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
              <Icon as={<Foundation name="calendar" />} size={5} color={colors.primary} />
            </InputLeftAddon>
          }
        />
      </Stack> */}
    </VStack>
  );
};

export default IssuedCard;
