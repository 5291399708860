import AppStore from "AppStore";
import { Box, Image } from "native-base";
import React from "react";

const AccfServicesImage = () => {
  const [isBigScreen] = AppStore("isBigScreen");
  return (
    <Box
      h={isBigScreen ? "64" : "48"}
      my="5"
      w={isBigScreen ? "70%" : "full"}
      alignSelf="center"
      rounded="2xl"
      borderRadius="2xl"
    >
      <Image source={require("assets/images/accf.png")} size="full" flex={1} resizeMode="contain" alt="loading" />
    </Box>
  );
};

export default AccfServicesImage;
