import axios from "axios";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getHealthCard = async (user, session, callback) => {
  const body = {
    sql: query.getHealthCardById.query,
    session: session?.userSession,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getHealthCardById.token,
    sessionToken: session.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result.length) {
      callback({ data: res?.data?.result[0] }, false);
    } else {
      callback({ data: {} }, false);
    }
  } else {
    callback({ data: {} }, true);
  }
  // Api.get(`/sales?filter=user_id,eq,${user.id}`).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: res.records }, false);
  //   } else {
  //     callback({ data: {} }, true);
  //   }
  // });
};

export const updateHealthCard = async (user, session, fields, callback) => {
  const checkRes = await axios.post(
    _BASE_URL2,
    {
      sql: query.checkHealthCardByPhone.query,
      sqlParams: [fields.phone_no, fields.card_no],
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.checkHealthCardByPhone.token,
      },
    }
  );

  if (checkRes?.data?.err == undefined) {
    if (checkRes?.data?.result.length) {
      let id = checkRes?.data?.result[0].id;
      let userId = checkRes?.data?.result[0].user_id;

      if (!userId) {
        let params = [id];

        const body = {
          sql: query.updateHealthCard.query,
          sqlParams: params,
          session: session?.userSession,
        };
        const headers = {
          db: _DATABASE,
          sqlToken: query.updateHealthCard.token,
          sessionToken: session?.token,
        };

        const res = await axios.post(_BASE_URL2, body, {
          headers: headers,
        });

        if (res?.data?.err == undefined) {
          if (res?.data?.result?.affectedRows > 0) {
            callback({ data: checkRes?.data?.result }, false);
          } else {
            callback({ data: [] }, true);
          }
        } else {
          callback({ data: [] }, true);
        }
      } else {
        callback({ data: [] }, true);
      }
    } else {
      callback({ data: [] }, true);
    }
  } else {
    callback({ data: [] }, true);
  }
  // Api.get(`/sales?filter=phone_no,eq,${fields.phone_no}&filter=healthcard_no,eq,${fields.card_no}`).send((res) => {
  //   if (res.type !== "error") {
  //     if (res.records.length) {
  //       let id = res.records[0].id;
  //       let userId = res.records[0].user_id;
  //       if (!userId) {
  //         let params = {
  //           user_id: user.id,
  //         };
  //         Api.put(`/sales/${id}`, params).send((response) => {
  //           if (response.type !== "error") {
  //             callback({ data: res.records }, false);
  //           } else {
  //             callback({ data: [] }, true);
  //           }
  //         });
  //       } else {
  //         callback({ data: [] }, false);
  //       }
  //     } else {
  //       callback({ data: [] }, false);
  //     }
  //   } else {
  //     callback({ data: [] }, true);
  //   }
  // });
};
