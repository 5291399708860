import AppStore from "AppStore";
import colors from "config/colors";
import { Box, Image } from "native-base";
import React from "react";
import { Rating } from "react-native-ratings";
import { OverlappingImage } from "widgets";
import _APP_CONFIG from "config/app";

const BookingTopImage = ({ packageImage, hospitalImage, rating }: any) => {
	const [isBigScreen] = AppStore("isBigScreen");
	return (
		<Box
			justifyContent="center"
			alignItems="center"
			h="64"
			w="full"
			bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
			overflow="hidden"
		>
			{/* <Image
				mb="3"
				size="48"
				borderRadius="full"
				source={
					packageImage
						? {
								uri: `${_APP_CONFIG._IMAGE_URL}/${packageImage}`,
						  }
						: require("assets/images/no-image.png")
				}
				resizeMode="contain"
				alt="loading"
			/> */}
			<OverlappingImage packageImage={packageImage} hospitalImage={hospitalImage} />
			{rating ? (
				<Rating
					type="heart"
					// @ts-ignore
					startingValue={rating}
					imageSize={15}
					ratingCount={5}
					readonly
				/>
			) : null}
		</Box>
	);
};

export default BookingTopImage;
