import React, { Fragment, useState } from "react";
import { Box, Text, VStack } from "native-base";
import Accordion from "react-native-collapsible/Accordion";
import { webStyle } from "config/web-style";
import colors from "config/colors";
import AppStore from "AppStore";
import AccordionSections from "./accordion-sections";
import AccordionHeader from "./accordion-header";
import AccordionContent from "./accordion-content";
import { TouchableOpacity } from "react-native";

const BookingBottomAccordion = ({ details }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [activeSections, setActiveSections] = useState([0]);
  const [multipleSelect, setMultipleSelect] = useState(false);

  const CONTENT = [
    {
      title: <Text>Procedure Name</Text>,
      content:
        Object.keys(details).length &&
        details.procedure_name &&
        details.procedure_name !== "" ? (
          <AccordionSections data={details.procedure_name} />
        ) : null,
    },
    {
      title: <Text>Basic Information</Text>,
      content:
        Object.keys(details).length && details.info && details.info !== "" ? (
          <AccordionSections data={details.info} />
        ) : null,
    },
    {
      title: <Text>Inclusion</Text>,
      content:
        Object.keys(details).length && details.inclusion.length
          ? details.inclusion.map((i: any, index: any) =>
              i !== "" ? (
                <Fragment key={index}>
                  <AccordionSections data={i} />
                </Fragment>
              ) : null
            )
          : null,
    },
    {
      title: <Text>Exclusion</Text>,
      content:
        Object.keys(details).length && details.exclusion.length
          ? details.exclusion.map((i: any, index: any) =>
              i !== "" ? (
                <Fragment key={index}>
                  <AccordionSections data={i} />
                </Fragment>
              ) : null
            )
          : null,
    },
    {
      title: <Text>Remarks</Text>,
      content:
        Object.keys(details).length && details.remarks.length
          ? details.remarks.map((i: any, index: any) =>
              i !== "" ? (
                <Fragment key={index}>
                  <AccordionSections data={i} />
                </Fragment>
              ) : null
            )
          : null,
    },
  ];

  const setSections = (sections: any) => {
    setActiveSections(sections.includes(undefined) ? [] : sections);
  };

  const renderHeader = (section: any, _: any, isActive: any) => {
    return <AccordionHeader section={section} _={_} isActive={isActive} />;
  };

  const renderContent = (section: any, _: any, isActive: any) => {
    return <AccordionContent section={section} _={_} isActive={isActive} />;
  };

  return Object.keys(details).length ? (
    <VStack
      flex={1}
      // w={isBigScreen ? "2/3" : "full"}
      w={isBigScreen ? "full" : "full"}
      space="2"
      px={isBigScreen ? webStyle.xNum : 6}
      alignSelf="center"
      borderWidth={isBigScreen ? "1" : "1"}
      borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
      borderRadius="2xl"
      mt={isBigScreen ? "4" : "4"}
      bg={colors.white}
      py="4"
    >
      {(details.procedure_name && details.procedure_name !== "") ||
      (details.info && details.info !== "") ||
      details.inclusion.length ||
      details.exclusion.length ||
      details.remarks.length ? (
        <Text fontSize="xl" fontWeight="semibold" color={colors.primary}>
          Package Details
        </Text>
      ) : (
        <Text
          fontSize="lg"
          fontWeight="semibold"
          color={colors.black}
          textAlign="center"
        >
          No Information Available
        </Text>
      )}
      <Accordion
        containerStyle={{ width: "100%" }}
        align="center"
        activeSections={activeSections}
        sections={CONTENT}
        touchableComponent={TouchableOpacity}
        expandMultiple={multipleSelect}
        renderHeader={renderHeader}
        renderContent={renderContent}
        duration={400}
        onChange={setSections}
        renderAsFlatList={false}
      />
    </VStack>
  ) : (
    <Box
      flex={1}
      w={isBigScreen ? "2/3" : "full"}
      px={isBigScreen ? webStyle.xNum : 6}
      alignSelf="center"
      borderWidth={isBigScreen ? "1" : "1"}
      borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
      borderRadius="2xl"
      mt={isBigScreen ? "4" : "4"}
      bg={colors.white}
      py="4"
    >
      <Text
        fontSize="lg"
        fontWeight="semibold"
        color={colors.black}
        textAlign="center"
      >
        No Information Available
      </Text>
    </Box>
  );
};

export default BookingBottomAccordion;
