import colors from "./colors";

export function headerStyle(insets) {
  return {
    height: 80 + insets.bottom,
    elevation: 3,
    shadowColor: "#000000",
    backgroundColor: colors.white,
  };
}
