import { AntDesign } from "assets/icons";
import colors from "config/colors";
import { Box, Icon, Text, View } from "native-base";
import React from "react";
import * as Animatable from "react-native-animatable";

const AccordionHeader = ({ section, _, isActive }: any) => {
  return section.content && section.content[0] !== null ? (
    <Animatable.View
      duration={400}
      transition="backgroundColor"
      style={{
        backgroundColor: "#f5f3f4",
        margin: 2,
      }}
    >
      <Box
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        p="6"
      >
        <Text
          style={{ color: isActive ? colors.primary : colors.black }}
          fontSize="md"
          fontWeight="semibold"
          // p="2"
        >
          {section.title}
        </Text>
        <Icon
          size="7"
          color={isActive ? colors.primary : colors.darkGrayish}
          as={<AntDesign name={isActive ? "upcircleo" : "downcircleo"} />}
        />
      </Box>
    </Animatable.View>
  ) : (
    <View style={{ opacity: 0, height: 0 }}></View>
  );
};

export default AccordionHeader;
