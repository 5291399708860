import _APP_CONFIG from "config/app";
import { ActivityIndicator, TouchableOpacity } from "react-native";
import React, { useEffect, useState } from "react";
import { Box, HStack, Image, Spacer, VStack, Text, Icon, FlatList } from "native-base";
import colors from "config/colors";

import { getBookings } from "./actions";
import { Entypo } from "assets/icons";
import moment from "moment";
import LoadingAnimation from "widgets/loading-animation";
import AppStore from "AppStore";
import { _BOOKING_PREFIX } from "config/defaults";
import { webStyle } from "config/web-style";
import { LinkToPage, NoData } from "widgets";

const ViewBookings = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [user] = AppStore("user");
  const [session] = AppStore("session");
  const [bookings, setBookings] = useState<any>([]);
  const [totalBookings, setTotalBookings] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [reloadScreen, setReloadScreen] = useState<any>(false);

  useEffect(() => {
    getBookings(page, user, session, (res: any, err: any) => {
      if (!err) {
        setBookings([...bookings, ...res.data]);
        setTotalBookings(res.totalData);
        setIsLoading(false);
        setIsLoadingMore(false);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
    });
  }, [user, page, reloadScreen]);

  const reload = () => {
    setIsLoading(true);
    setPage(1);
    setBookings([]);
    setTotalBookings(0);
    setReloadScreen(!reloadScreen);
  };

  useEffect(() => {
    setCurrentPageInit(reload);
    const unsubscribe = navigation.addListener("focus", () => {
      setCurrentPageInit(reload);
    });
    return unsubscribe;
  }, []);
  const handlePagination = () => {
    if (bookings.length < totalBookings && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage((page) => page + 1);
    }
  };

  const listFooterComponent = () => {
    return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
  };

  return (
    <Box flex="1" px={isBigScreen ? webStyle.xNum : 4} bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      {bookings.length ? (
        <Box bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg} alignItems="center">
          <FlatList
            data={bookings}
            numColumns={isBigScreen ? 2 : 1}
            showsVerticalScrollIndicator={false}
            onEndReached={handlePagination}
            onEndReachedThreshold={0.5}
            ListFooterComponent={listFooterComponent}
            renderItem={({ item }: any) => {
              let hospital_details = item.hospital_details;
              // let hospital_details = JSON.parse(item.hospital_details);
              return (
                <Box
                  w={isBigScreen ? 600 : "full"}
                  borderRadius="2xl"
                  borderWidth="1"
                  borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
                  bgColor={colors.white}
                  py="3"
                  mx={isBigScreen ? 1 : 0}
                  my="2"
                  flex={
                    !isBigScreen
                      ? "1"
                      : isBigScreen
                      ? item.length % 2 == 0
                        ? "1"
                        : // : item.length % 2 !== 0
                          // ? "1/2"
                          // : null
                          // : null
                          "1/2"
                      : null
                  }
                  justifyContent="center"
                >
                  <LinkToPage route="BookingDetails" params={{ name: item.package, item: item }}>
                    <HStack alignItems="center" justifyContent="space-between" space={3} flex="1">
                      <Box size={75} overflow="hidden" alignItems="center" justifyContent="center">
                        <Image
                          size={65}
                          source={{
                            uri: `${_APP_CONFIG._IMAGE_URL}/${hospital_details.image}`,
                          }}
                          alt="loading"
                          resizeMode="contain"
                        />
                      </Box>

                      <VStack w="3/5">
                        <Text fontSize="lg" pb="1" fontWeight="semibold">
                          {_BOOKING_PREFIX.concat(item.id)}
                        </Text>
                        <Text fontSize="md" fontWeight="semibold">
                          {item.hospital}
                        </Text>
                        <Text>{item.package}</Text>
                        <Text color={colors.darkGrayish}>
                          {moment.utc(item.created_at).local().format("DD-MM-YYYY")}
                        </Text>
                      </VStack>
                      <Icon size="8" color="gray.300" as={<Entypo name="chevron-right" />} />
                    </HStack>
                  </LinkToPage>
                </Box>
              );
            }}
            keyExtractor={(item: any) => item.id}
          />
        </Box>
      ) : !bookings.length && !isLoading ? (
        <NoData />
      ) : (
        <LoadingAnimation loading={isLoading} />
      )}
    </Box>
  );
};

export default ViewBookings;
