import React, { useCallback, useEffect, useRef, useState } from "react";
import { TouchableOpacity } from "react-native";
import {
  Box,
  Button,
  Circle,
  HStack,
  Icon,
  ScrollView,
  Stack,
  Text,
  VStack,
} from "native-base";

import * as SplashScreen from "expo-splash-screen";
import { Entypo, DiscoverImage } from "assets/icons";
import colors from "config/colors";
import { LinkToPage } from "widgets";

const Discover = ({ navigation }: any) => {
  const [appIsReady, setAppIsReady] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setAppIsReady(true);
    }
    return () => {
      mounted.current = false;
    };
    // async function prepare() {

    //   try {
    //     await new Promise((resolve) => setTimeout(resolve, 1000));
    //   } catch (e) {
    //     console.warn(e);
    //   } finally {
    //     setAppIsReady(true);
    //   }
    // }
    // prepare();
  }, []);

  // const onLayoutRootView = useCallback(async () => {
  //   if (appIsReady) {
  //     await SplashScreen.hideAsync();
  //   }
  // }, [appIsReady]);

  if (!appIsReady) {
    SplashScreen.preventAutoHideAsync();
  }

  return (
    <ScrollView
      py="7"
      flex={1}
      bg="white"
      // onLayout={onLayoutRootView}
    >
      <Stack w="full" p="4" alignItems="flex-end">
        <Button variant="link" onPress={() => navigation.navigate("Support")}>
          <Text color={colors.darkGrayish} fontSize="md">
            SKIP
          </Text>
        </Button>
      </Stack>
      <Box h="72">
        <DiscoverImage />
      </Box>
      <VStack py="10" justifyContent="center" alignItems="flex-start" space="8">
        <Stack w="full" alignItems="center" space="2">
          <Text color={colors.black} fontSize="3xl" fontWeight="bold">
            DISCOVER
          </Text>
          <Text
            px="2"
            mb="8"
            textAlign="center"
            color={colors.darkGrayish}
            fontSize="md"
          >
            Best health packages from top hospitals in your vicinity
          </Text>
          {/* <TouchableOpacity onPress={() => navigation.navigate("Trust")}> */}
          <LinkToPage route="Trust">
            <Circle bgColor={colors.primary} size={12}>
              <Icon
                size="8"
                color={colors.white}
                as={<Entypo name="chevron-right" />}
              />
            </Circle>
          </LinkToPage>
          <HStack space="4" mt="16">
            <Circle bgColor={colors.primary} size={3}></Circle>
            <Circle bgColor={colors.accent} size={3}></Circle>
            <Circle bgColor={colors.accent} size={3}></Circle>
          </HStack>
        </Stack>
      </VStack>
    </ScrollView>
  );
};

export default Discover;
