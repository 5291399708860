import "react-native-gesture-handler";
import "react-native-reanimated";
import "expo-dev-client";

import { SafeAreaProvider } from "react-native-safe-area-context";
import Navigation from "./src/navigation";
import { NativeBaseProvider, useMediaQuery } from "native-base";
import { useEffect, useState } from "react";
import {
  checkOtaUpdate,
  getAndroidId,
  getAppStoreVersion,
  getDeviceOS,
  getIpAddress,
  getLatLong,
  handleBackgroundNotification,
  handleInitialNotification,
  handleNotificationReceived,
  registerForPushNotificationsAsync,
  subscribeToTopic,
  updateDeviceToken,
} from "AppActions";
import { AppState, Platform, StatusBar } from "react-native";
import NetInfo from "@react-native-community/netinfo";
import AppStore from "AppStore";
import { _APP_URL } from "config/url";
import * as Application from "expo-application";

console.log("VERSION", 64);

export default function App() {
  const [_, setIsBigScreen] = AppStore("isBigScreen");
  const [deviceInfo] = AppStore("deviceInfo");
  const [deviceOs] = AppStore("deviceOs");
  const [latLong] = AppStore("latLong");
  const [androidId] = AppStore("androidId");
  const [ipAddress] = AppStore("ipAddress");
  const [goToHome, setGoToHome] = AppStore("goToHome");
  const [broadcastSubscribed] = AppStore("broadcastSubscribed");
  const [currentPageInit] = AppStore("currentPageInit");
  const [appBackgroundTime, setAppBackgroundTime] = AppStore("appBackgroundTime");
  const [bigScreen] = useMediaQuery({
    minWidth: 992,
  });
  const [isOpen, setIsOpen] = AppStore("isOpen");
  const [updateAvailable, setUpdateAvailable] = AppStore("updateAvailable");
  const [applicationVersion, setApplicationVersion] = useState<any>();
  const [versionCode, setVersionCode] = useState<any>();
  const [storeVersionCode, setStoreVersionCode] = useState<any>();

  useEffect(() => {
    if (Platform.OS !== "web") {
      const subscription = AppState.addEventListener("change", (nextAppState) => {
        let time = new Date().getTime();
        AppState.currentState = nextAppState;
        if (AppState.currentState == "active") {
          let checkTime = time - appBackgroundTime;
          let timeOut = 15 * 60 * 1000;
          let timeOut2 = 30 * 60 * 1000;
          if (checkTime > timeOut && checkTime < timeOut2) {
            if (typeof currentPageInit == "function") {
              currentPageInit();
            }
          }
          if (checkTime > timeOut && checkTime > timeOut2 && appBackgroundTime !== null) {
            setGoToHome(true);
            getDeviceData();
            getAppInfo();
            checkOtaUpdate();
          }
        } else {
          setAppBackgroundTime(time);
        }
      });
      return subscription.remove;
    }
  }, [appBackgroundTime]);

  useEffect(() => {
    getAppInfo();
    checkOtaUpdate();
  }, []);

  useEffect(() => {
    handleUpdate();
  }, [versionCode, storeVersionCode]);

  useEffect(() => {
    getDeviceData();
  }, []);

  useEffect(() => {
    if (deviceOs && Object.keys(latLong).length && ipAddress && (broadcastSubscribed != undefined || broadcastSubscribed != null)) {
      updateDeviceToken();
    }
  }, [deviceInfo, deviceOs, broadcastSubscribed, latLong, androidId, ipAddress]);

  useEffect(() => {
    setIsBigScreen(bigScreen);
  }, [bigScreen]);

  const getAppInfo = () => {
    setApplicationVersion(Application.nativeApplicationVersion);
    setVersionCode(Application.nativeBuildVersion);
    getAppStoreVersion((res: any, err: any) => {
      if (!err) {
        setStoreVersionCode(res.data.store_version_code);
      }
    });
  };

  const handleUpdate = () => {
    if (Platform.OS == "android" && Application.applicationName !== "Expo Go" && !__DEV__) {
      if (versionCode < storeVersionCode) {
        setIsOpen(true);
        setUpdateAvailable(true);
      } else {
        setIsOpen(false);
        setUpdateAvailable(false);
      }
    }
  };

  const getDeviceData = () => {
    getLatLong();
    getDeviceOS();
    getAndroidId();
    getIpAddress();
    registerForPushNotificationsAsync();
    subscribeToTopic();
    handleNotificationReceived();
    handleInitialNotification();
    handleBackgroundNotification();
  };

  return (
    <SafeAreaProvider>
      <NativeBaseProvider>
        <StatusBar barStyle="dark-content" backgroundColor="white" />
        <Navigation />
      </NativeBaseProvider>
    </SafeAreaProvider>
  );
}
