import _APP_CONFIG from "config/app";
import { Entypo, Ionicons } from "assets/icons";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import { Box, Circle, HStack, Icon, Image, ScrollView, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { LoadingAnimation } from "widgets";

const Benefits = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box>
          <Box
            justifyContent="center"
            alignItems="center"
            h="72"
            w="full"
            bg="white"
            roundedBottom="3xl"
            overflow="hidden"
          >
            <Image
              source={{
                uri: `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/health-card-new.png`,
              }}
              alt="loading"
              size="full"
              resizeMode="contain"
            />
          </Box>

          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px={isBigScreen ? webStyle.xNum : "4"}
            space="6"
            mx={isBigScreen ? webStyle.xNum : 1}
          >
            <HStack w="full" alignItems="center" justifyContent="space-between">
              <Text color="white" fontSize="xl" fontWeight="bold">
                Benefits & Specifications
              </Text>
              {!isBigScreen ? (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                  </Circle>
                </TouchableOpacity>
              ) : null}
            </HStack>
            <VStack w="90%" space="2">
              <HStack space="2">
                <Circle size="1" mt="1">
                  {/* <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  /> */}
                </Circle>
                <Text color={colors.white} fontSize="md" fontWeight="semibold">
                  "Every patient's right is to get the right health related information on time with clarity."
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="1" mt="1"></Circle>

                <Text color={colors.white} fontSize="md">
                  Don't stay in a dilemma!
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="1" mt="1"></Circle>

                <Text color={colors.white} fontSize="md">
                  Get our health card now for all the information relating to your health and your family at just 199/-
                  for a year. (T & C applicable)
                </Text>
              </HStack>
              {/* <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Any kind of free assistance regarding applying for an ABHA card.
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Special discounts on Zemidi tied up Hospitals.
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Special discounts on lab tests.
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Hassle free OPD/IPD bookings
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Free therapies at Zemidi PrimaCare with our highly equipped machines.
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Special discounts on Medicine at our Zemidi PrimaCare.
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Free medicine delivery within 5km radius of Zemidi PrimaCare.
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Free 24*7 Mental Health tele-counseling.
                </Text>
              </HStack>
              <HStack space="2">
                <Circle size="5" mt="1">
                  <Icon
                    as={<Ionicons name="md-checkmark-circle-sharp" />}
                    size="5"
                    color={colors.white}
                    alignSelf="center"
                  />
                </Circle>
                <Text color={colors.white} fontSize="md">
                  Access to free health camps.
                </Text>
              </HStack> */}
            </VStack>
          </VStack>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default Benefits;
