import { fetchUpdate } from "AppActions";
import AppStore from "AppStore";
import colors from "config/colors";
import { Box, HStack, Image, Text } from "native-base";
import React from "react";
import { Platform } from "react-native";
import { Button } from "widgets";

const OtaUpdate = () => {
  const [otaAvailable] = AppStore("otaAvailable");
  return otaAvailable && Platform.OS !== "web" ? (
    <Box mx="3" bg={colors.white} flex="1" p="2" rounded="lg">
      <HStack alignItems="center" space="2">
        <Image
          size={30}
          borderRadius="full"
          source={require("assets/images/icon.png")}
          resizeMode="contain"
          alt="loading"
        />
        <Text fontWeight="medium" fontSize="lg">
          Update Available
        </Text>
      </HStack>
      <HStack space="2" w="72" mt="2">
        <Box size={30}></Box>
        <Text fontWeight="medium" color="gray.500">
          Please update the app for proper functioning and latest features!
        </Text>
      </HStack>
      <HStack space="2" w="72" mt="2">
        <Box size={30}></Box>
        <Button
          title="UPDATE"
          btnColor={colors.primary}
          pressedColor={colors.accent}
          titleColor={colors.white}
          h="10"
          w="24"
          fontSize="md"
          onPress={fetchUpdate}
        />
      </HStack>
    </Box>
  ) : null;
};

export default OtaUpdate;
