import _APP_CONFIG from "config/app";
import React from "react";
import { Box, Image } from "native-base";
import { Dimensions, Linking, TouchableOpacity } from "react-native";
import Carousel from "react-native-reanimated-carousel";
import colors from "config/colors";
import AppStore from "AppStore";
import { _ZEMIDI_HELPLINE } from "config/defaults";

const ImageCarousel = () => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [mobileSliderImages] = AppStore("mobileSliderImages");
  const [webSliderImages] = AppStore("webSliderImages");

  const width = Dimensions.get("window").width;
  return webSliderImages.length || mobileSliderImages.length ? (
    <Carousel
      loop
      width={isBigScreen ? width - 160 : width}
      height={isBigScreen ? 450 : 238}
      autoPlay={true}
      data={isBigScreen ? (webSliderImages.length ? webSliderImages : mobileSliderImages) : mobileSliderImages}
      scrollAnimationDuration={2000}
      renderItem={({ item, index }: any) => (
        <TouchableOpacity style={{ flex: 1 }} onPress={() => (isBigScreen ? Linking.openURL(`tel:${_ZEMIDI_HELPLINE}`) : () => {})}>
          <Box flex="1" justifyContent="center" alignItems="center" key={index} bg={isBigScreen ? colors.carouselBg : colors.white}>
            <Image source={{ uri: `${_APP_CONFIG._IMAGE_URL}/${item}` }} alt="loading" size="full" resizeMode="contain" alignSelf="center" />
          </Box>
        </TouchableOpacity>
      )}
    />
  ) : null;
};

export default ImageCarousel;
