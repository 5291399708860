import create from "zustand";
import { persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";

function process(set, state) {
  const _state = {};
  for (const key in state) {
    _state[key] = state[key];
    _state["set" + key] = function (value) {
      set((state) => {
        state[key] = value;
      });
    };
  }
  return _state;
}

function store(state) {
  const keyStore = {};
  const getStore = create(
    persist(
      (set) => {
        return process(set, state);
      },
      {
        name: "local-storage",
        getStorage: () => AsyncStorage,
      }
    )
  );

  const storeFactory = function (key) {
    keyStore[key] = [
      getStore((state) => state[key]),
      getStore((state) => state["set" + key]),
    ];
    return keyStore[key];
  };

  storeFactory.getState = getStore.getState;
  storeFactory.setState = getStore.setState;
  storeFactory.reset = function () {
    for (let key in keyStore) {
      // console.log("keyStore[1]", keyStore[key][1], key);
      if (typeof keyStore[key][1] == "function") {
        keyStore[key][1](state[key]);
      }
    }
  };
  return storeFactory;
}

export default store;
