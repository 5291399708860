import axios from "axios";
import { query } from "config/query";
import { _BASE_URL, _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getDonors = async (page, selectedId, selectedName, callback) => {
  let paramsCount = [];
  let params = [];
  let countQuery = "";
  let countQueryToken = "";
  let dataQuery = "";
  let dataQueryToken = "";

  if (selectedId > 0) {
    paramsCount = [`%${selectedName}%`];
    params = [`%${selectedName}%`, (page - 1) * 20];
    countQuery = query?.getUserDonorsByBloodGroupCount?.query;
    countQueryToken = query?.getUserDonorsByBloodGroupCount?.token;
    dataQuery = query?.getUserDonorsByBloodGroup?.query;
    dataQueryToken = query?.getUserDonorsByBloodGroup?.token;
  } else {
    params = [(page - 1) * 20];
    countQuery = query?.getUserDonorsCount?.query;
    countQueryToken = query?.getUserDonorsCount?.token;
    dataQuery = query?.getUserDonors?.query;
    dataQueryToken = query?.getUserDonors?.token;
  }

  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: countQuery,
      sqlParams: paramsCount,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: countQueryToken,
      },
    }
  );
  // console.log("resCount", resCount);
  const body = {
    sql: dataQuery,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: dataQueryToken,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res", res);

  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      // console.log(" res?.data?.result", res?.data?.result);
      callback(
        {
          // data: [],
          data: res?.data?.result,
          totalData: resCount?.data?.result[0]?.["count(*)"],
        },
        false
      );
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }

  //   let query;
  //   if (selectedId > 0) {
  //     selectedName = encodeURIComponent(selectedName);
  //     query = `/users?filter=blood_group,eq,${selectedName}&filter=is_donor,eq,1&filter=is_available,eq,1&filter=priority,gt,0&order=priority,desc&page=${page},10`;
  //   } else {
  //     query = `/users?filter=is_donor,eq,1&filter=is_available,eq,1&filter=priority,gt,0&order=priority,desc&page=${page},10`;
  //   }
  //   Api.get(query).send((res) => {
  //     if (res.type !== "error") {
  //       callback({ data: res.records, totalData: res.results }, false);
  //     } else {
  //       callback({ data: [], totalData: 0 }, true);
  //     }
  //   });
};
export const getBloodGroups = async (callback) => {
  const body = {
    sql: query.getAllBloodGroups.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllBloodGroups.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    callback({ data: res?.data?.result }, false);
  } else {
    callback({ data: [] }, true);
  }
};

export const searchDonor = async (e, setResults, setIsLoading) => {
  if (!e.length) {
    setResults([]);
  }

  if (e.length > 2) {
    setIsLoading(true);
    let searchTerm = { e, ...searchTerm };

    let params = [`%${searchTerm.e}%`];
    const body = {
      sql: query.getAllUserDonorsByName.query,
      sqlParams: params,
    };
    const headers = {
      db: _DATABASE,
      sqlToken: query.getAllUserDonorsByName.token,
    };

    const res = await axios.post(_BASE_URL2, body, {
      headers: headers,
    });

    if (res?.data?.err == undefined) {
      setResults(res?.data?.result);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  // if (e.length > 2) {
  //   setIsLoading(true);
  //   let searchTerm = { e, ...searchTerm };
  //   let endpoints = [`${_BASE_URL}/users?filter=name,cs,${searchTerm.e}&filter=is_donor,eq,1&filter=is_available,eq,1`];
  //   Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
  //     .then(([{ data: donors }]) => {
  //       if (donors.records.length) {
  //         setResults(donors.records);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((e) => setIsLoading(false));
  // }
};

export const donorCalled = async (user, donorId, callback) => {
  let params = [user.id, donorId];
  const body = {
    sql: query.createUsersDonorsCalls.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.createUsersDonorsCalls.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.insertId > 0) {
      callback({ data: true }, false);
    } else {
      callback({ data: false }, true);
    }
  } else {
    callback({ data: false }, true);
  }

  // let params = {
  //   user_id: user.id,
  //   donor_id: donorId,
  // };
  // Api.post(`/users_donors_calls`, params).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: true }, false);
  //   } else {
  //     callback({ data: false }, true);
  //   }
  // });
};

export const updateDonorUser = async (fields, user, session, callback) => {
  let userParams = {
    is_donor: true,
    is_available: fields.is_available,
    blood_group: fields.blood_group,
    gender: fields.gender,
    date_of_birth: fields.date_of_birth,
    last_donation_date: fields.last_donation_date ? fields.last_donation_date : null,
    last_donation_duration: fields.last_donation_duration,
  };
  let paramsArr = [
    true,
    fields.is_available,
    fields.blood_group,
    fields.gender,
    fields.date_of_birth,
    fields.last_donation_date ? fields.last_donation_date : null,
    fields.last_donation_duration,
  ];

  let params = [...paramsArr];

  const body = {
    sql: query.updateUserDonorInfo.query,
    sqlParams: params,
    session: session?.userSession,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.updateUserDonorInfo.token,
    sessionToken: session?.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.affectedRows > 0) {
      callback({ data: userParams }, false);
    } else {
      callback(null, true);
    }
  } else {
    callback(null, true);
  }

  // Api.put(`/users/${user.id}`, params).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: params }, false);
  //   }
  //   callback(null, true);
  // });
};
