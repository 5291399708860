import { useNavigation } from "@react-navigation/native";
import AppStore from "AppStore";
import React, { useEffect } from "react";
import { TouchableOpacity } from "react-native";
// import * as Analytics from "expo-firebase-analytics";

const LinkToPage = ({ route, params, setLink, children }: any) => {
  const navigation = useNavigation();
  const [isBigScreen] = AppStore("isBigScreen");
  const [routeParams, setRouteParams] = AppStore("routeParams");
  const [showWelcome, setShowWelcome] = AppStore("showWelcome");
  const [selectedWebHeader, setSelectedWebHeader] = AppStore("selectedWebHeader");
  const [selectedFooter, setSelectedFooter] = AppStore("selectedFooter");

  // useEffect(() => {
  //   logEvent();
  // }, []);

  // const logEvent = async () => {
  //   // await Analytics.logEvent("screen_view", route);
  //   await Analytics.logEvent("screen_view", { firebase_screen: route });
  // };

  return (
    <TouchableOpacity
      onPress={() => {
        if (route == "goBack") {
          navigation.goBack();
          setRouteParams({});
        } else {
          //@ts-ignore
          navigation.navigate(route, params);
          setRouteParams({ ...params });
        }
        if (
          route == "Home" ||
          route == "About" ||
          route == "Consultancy" ||
          route == "Packages" ||
          route == "Hospitals" ||
          route == "Account" ||
          route == "Gallery" ||
          route == "Videos"
        ) {
          isBigScreen ? setSelectedWebHeader(route) : null;
          // isBigScreen ? setSelectedFooter(route) : null;
        }
        if (route == "SignIn") {
          isBigScreen ? setSelectedWebHeader("Account") : null;
        }
        if (route == "Zemidi") {
          setShowWelcome(false);
        }
      }}
    >
      {children}
    </TouchableOpacity>
  );
};

export default LinkToPage;
