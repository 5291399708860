import _APP_CONFIG from "config/app";
import { AntDesign, Entypo, Ionicons } from "assets/icons";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import { Box, Circle, HStack, Icon, Image, ScrollView, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { Platform, TouchableOpacity } from "react-native";
import { Button, LoadingAnimation, showToast } from "widgets";
import moment from "moment";
import * as ImagePicker from "expo-image-picker";
import axios from "axios";
import { updateUserPrescription } from "./actions";
import { linkToPage } from "widgets/link-to-page/link-to-page";

const UploadPrescription = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [user, setUser] = AppStore("user");
  const [session] = AppStore("session");
  const [image, setImage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [dataUpload, setDataToUpload] = useState<any>(null);

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  const reload = () => {
    navigation.goBack();
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 0.8,
    });

    if (!result.cancelled) {
      setImage(result.uri);
      prepareImage(result, setImage);
    }
  };

  const openCamera = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 0.8,
    });

    if (!result.cancelled) {
      setImage(result.uri);
      prepareImage(result, setImage);
    }
  };

  const prepareImage = async (file: any, setImage: any) => {
    let extension = file.uri.split(".").pop();
    let now = moment.now();
    const formData = new FormData();

    if (Platform.OS == "android") {
      formData.append("folder", "prescriptions");
      // @ts-ignore
      formData.append("image", {
        name: `${user.id}-${now}.${extension}`,
        type: `image/${extension}`,
        uri: file.uri,
      });
      setDataToUpload(formData);
    } else {
      fetch(file.uri)
        .then((res) => res.blob())
        .then((blob) => {
          let type = blob.type;
          let ext = type.split("/").pop();
          let name = `${user.id}-${now}.${ext}`;
          const file = new File([blob], name);
          formData.append("folder", "prescriptions");
          formData.append("image", file);
          setDataToUpload(formData);
        });
    }
  };

  const handleUpload = () => {
    if (dataUpload !== null && Object.keys(user).length) {
      setIsLoading(true);
      axios
        .post("https://uploads.zemidi.com/api", dataUpload, {
          headers: {
            "Content-Type": "multipart/form-data",
            username: "zemidi",
            password: "zemidi",
          },
        })
        .then((res) => {
          updateUserPrescription(user, setUser, session, setImage, setIsLoading, res.data.files.image);
        })
        .catch((error) => {
          setIsLoading(false);
          showToast("Please try again!");
        });
    } else {
      showToast("Upload a prescription");
    }
  };

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box>
          <VStack h="56" w="full" bg="white" roundedBottom="3xl" pt="12" overflow="hidden">
            <Image source={require("assets/images/prescription.png")} alt="loading" size="full" resizeMode="contain" />
          </VStack>
          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px="10"
            space="6"
            mx={isBigScreen ? webStyle.xNum : 0}
          >
            <HStack w="full" alignItems="center" justifyContent="space-between">
              <Box w="75%">
                <Text color="white" fontSize="lg" fontWeight="bold">
                  ZEMIDI pharmacy services launching soon!!
                </Text>
              </Box>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <Circle bgColor={colors.white} size={10}>
                  <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                </Circle>
              </TouchableOpacity>
            </HStack>
            <Box>
              {user?.prescription ? (
                <Text color={colors.white} fontSize="sm" fontWeight="medium" pt="2">
                  Thank you for your submission!
                </Text>
              ) : (
                <Text color={colors.white} fontSize="sm" fontWeight="medium" pt="2">
                  Please upload your prescription and help us build our database to serve you better.
                </Text>
              )}
            </Box>
            {image || user?.prescription ? (
              <HStack flex="1" overflow="hidden" alignItems="flex-start" justifyContent="center" alignSelf="center">
                <Image
                  source={{ uri: user?.prescription ? `${_APP_CONFIG._IMAGE_URL}/${user.prescription}` : image }}
                  alt="loading"
                  size="200"
                  resizeMode="contain"
                  rounded="2xl"
                />
                {!user?.prescription && !isLoading ? (
                  <TouchableOpacity onPress={() => setImage(null)}>
                    <AntDesign name="closecircle" size={24} color={colors.white} />
                  </TouchableOpacity>
                ) : null}
              </HStack>
            ) : (
              <>
                <Box
                  borderWidth="1"
                  borderRadius="full"
                  borderStyle="dotted"
                  borderColor={colors.white}
                  p="4"
                  alignItems="center"
                  justifyItems="center"
                  w="full"
                >
                  <TouchableOpacity style={{ alignItems: "center" }} onPress={() => pickImage()}>
                    <Ionicons name="cloud-upload-outline" size={24} color={colors.white} />
                    <Text color={colors.white} fontSize="sm">
                      Upload your prescription
                    </Text>
                  </TouchableOpacity>
                </Box>

                {!isBigScreen ? (
                  <Box w="full" alignItems="center">
                    <Text color={colors.white} fontSize="sm" fontWeight="bold">
                      OR
                    </Text>
                  </Box>
                ) : null}
                {!isBigScreen ? (
                  <Box
                    borderWidth="1"
                    borderRadius="full"
                    borderStyle="dotted"
                    borderColor={colors.white}
                    p="4"
                    alignItems="center"
                    justifyItems="center"
                    w="full"
                  >
                    <TouchableOpacity style={{ alignItems: "center" }} onPress={() => openCamera()}>
                      <Ionicons name="ios-camera-outline" size={24} color={colors.white} />
                      <Text color={colors.white} fontSize="sm">
                        Capture your prescription
                      </Text>
                    </TouchableOpacity>
                  </Box>
                ) : null}
              </>
            )}
            {!user?.prescription ? (
              <Button
                title="SAVE"
                onPress={() =>
                  !Object.keys(user).length
                    ? linkToPage({
                        navigation: navigation,
                        route: "SignUp2",
                        params: {
                          screen: !Object.keys(user).length ? "UploadPrescription" : null,
                        },
                      })
                    : handleUpload()
                }
              />
            ) : null}
          </VStack>
        </Box>
      </ScrollView>
      <LoadingAnimation loading={isLoading} />
    </Box>
  );
};

export default UploadPrescription;
