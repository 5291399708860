import { Ionicons } from "assets/icons";
import AppStore from "AppStore";
import colors from "config/colors";
import { Icon, Input, InputLeftAddon, Stack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { Button, showToast } from "widgets";
import { updateHealthCard } from "./actions";

const UpdateCard = ({ setCard, setNoCard, setIsLoading }: any) => {
  const [user] = AppStore("user");
  const [session] = AppStore("session");
  const [fields, setFields] = useState<any>({});

  const linkHealthCard = () => {
    if (Object.keys(fields).length && fields.phone_no && fields.card_no) {
      setIsLoading(true);
      updateHealthCard(user, session, fields, (res: any, err: any) => {
        if (!err) {
          if (res.data.length) {
            res.data[0].user_id = user.id;
            setCard(res.data[0]);
            setNoCard(false);
            showToast("Card successfully linked!");
          } else {
            showToast("Card not found!");
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          showToast("Something went wrong! Please try again later");
        }
      });
    } else {
      showToast("All fields are required");
    }
  };

  return (
    <VStack w="full">
      <VStack space="3">
        <Stack w="full" space="2" p="2" rounded="2xl">
          <Text color="white" fontSize="md" fontWeight="medium">
            Link your Health Assistance and Information Subscription or get one from app home screen
          </Text>
        </Stack>

        <Input
          h="12"
          w="full"
          keyboardType="phone-pad"
          placeholder="Mobile Number"
          maxLength={10}
          variant="unstyled"
          rounded="3xl"
          size="xl"
          bgColor="white"
          focusOutlineColor="white"
          onChangeText={(e: any) => {
            fields.phone_no = e;
            setFields({ ...fields });
          }}
          value={(fields.phone_no && fields.phone_no.toString()) || ""}
          InputLeftElement={
            <InputLeftAddon w="20%" h="full" bgColor="gray.100">
              <Text fontSize="lg" fontWeight="semibold" color={colors.primary}>
                +91
              </Text>
            </InputLeftAddon>
          }
        />

        <Input
          w="full"
          h="12"
          type="text"
          placeholder="HAI Number"
          autoCapitalize="characters"
          isRequired
          variant="unstyled"
          rounded="3xl"
          size="lg"
          bgColor="white"
          value={fields.card_no || ""}
          onChangeText={(e: any) => {
            fields.card_no = e;
            setFields({ ...fields });
          }}
          InputLeftElement={
            <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
              <Icon as={<Ionicons name="ios-card" />} size={5} color={colors.primary} />
            </InputLeftAddon>
          }
        />

        <Button title="SAVE" onPress={linkHealthCard} />
      </VStack>
    </VStack>
  );
};

export default UpdateCard;
