import axios from "axios";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getServices = async (page, callback) => {
  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: query.getServicesCount.query,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.getServicesCount.token,
      },
    }
  );
  // console.log("resCount", resCount);
  let params = [20, (page - 1) * 20];
  const body = {
    sql: query.getServices.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getServices.token,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res", res);
  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      callback({ data: res.data.result, totalData: resCount?.data?.result[0]?.["count(*)"] }, false);
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }

  // Api.get(`/services?filter=priority,gt,0&order=priority,desc&page=${page},10`).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: res.records, totalData: res.results }, false);
  //   } else {
  //     callback({ data: [], totalData: 0 }, true);
  //   }
  // });
};
