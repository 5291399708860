import axios from "axios";
import { query } from "config/query";
import { _BASE_URL, _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getHospitalCategories = async (callback) => {
  const body = {
    sql: query.getAllHospitalsCategories.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllHospitalsCategories.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  if (res?.data?.err == undefined) {
    callback({ data: res.data.result }, false);
  } else {
    callback({ data: [] }, true);
  }
};
// export const getHospitalCategories = (callback) => {
//   Api.get(`/hospitals_categories`).send((res) => {
//     if (res.type !== "error") {
//       callback({ data: res.records }, false);
//     }
//     callback({ data: [] }, true);
//   });
// };

export const getPackages = async (page, categoryId, callback) => {
  let paramsCount = [];
  let params = [];
  let countQuery = "";
  let countQueryToken = "";
  let dataQuery = "";
  let dataQueryToken = "";

  if (categoryId > 0) {
    paramsCount = [categoryId];
    params = [categoryId, (page - 1) * 20];
    countQuery = query?.getPackagesByCategoryIdCount?.query;
    countQueryToken = query?.getPackagesByCategoryIdCount?.token;
    dataQuery = query?.getPackagesByCategoryId?.query;
    dataQueryToken = query?.getPackagesByCategoryId?.token;
  } else {
    params = [10, (page - 1) * 10];
    countQuery = query?.getPackagesCount?.query;
    countQueryToken = query?.getPackagesCount?.token;
    dataQuery = query?.getPackages?.query;
    dataQueryToken = query?.getPackages?.token;
  }

  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: countQuery,
      sqlParams: paramsCount,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: countQueryToken,
      },
    }
  );
  // console.log("resCount", resCount);
  const body = {
    sql: dataQuery,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: dataQueryToken,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res", res);

  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      // console.log(" res?.data?.result", res?.data?.result);
      callback(
        {
          // data: [],
          data: res?.data?.result,
          totalData: resCount?.data?.result[0]?.["count(*)"],
        },
        false
      );
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }

  // let query;
  // if (categoryId > 0) {
  //   // query = `/hospitals_packages?join=packages&join=hospitals&join=packages_categories&filter=hospital_id,eq,${category_id}&order=id&page=${page},10`;
  //   query = `/packages?filter=category_id,gt,0&filter=category_id,eq,${categoryId}&join=packages_categories&filter=priority,gt,0&order=priority,desc&page=${page},10`;
  // } else {
  //   query = `/packages?filter=category_id,gt,0&join=packages_categories&filter=priority,gt,0&order=priority,desc&page=${page},10`;
  // }

  // Api.get(query).send((res) => {
  //   if (res.type !== "error") {
  //     // let data = [];
  //     // if (res.records.length && "package_id" in res.records[0]) {
  //     //   for (let i in res.records) {
  //     //     // data.push(res.records[i].package_id);
  //     //     data.push(res.records[i]);
  //     //   }
  //     // }
  //     callback({ data: res.records, totalData: res.results }, false);
  //   } else {
  //     callback({ data: [], totalData: 0 }, true);
  //   }
  // });
};
