import React, { Fragment, useState } from "react";
import { Box, Text, VStack } from "native-base";
import Accordion from "react-native-collapsible/Accordion";
import { webStyle } from "config/web-style";
import colors from "config/colors";
import AppStore from "AppStore";
import AccordionSections from "./accordion-sections";
import AccordionHeader from "./accordion-header";
import AccordionContent from "./accordion-content";
import { FlatList, TouchableOpacity, View } from "react-native";

const BookingBottomAccordion = ({ details }: any) => {
	const [isBigScreen] = AppStore("isBigScreen");
	const [activeSections, setActiveSections] = useState([0]);
	const [multipleSelect, setMultipleSelect] = useState(false);

	// const CONTENT = Object.keys(details).map(key => ({
	// 	title: <Text key={`title-${key}`}>{key}</Text>,
	// 	content: (
	// 		<AccordionSections
	// 			key={`content-${key}`}
	// 			data={
	// 				<ul>
	// 					{details[key].map((item: string, index: number) => (
	// 						<li key={`item-${index}`}>{item}</li>
	// 					))}
	// 				</ul>
	// 			}
	// 		/>
	// 	),
	// }));

	const sectionOrder = ["Speciality", "Patient Care Facility", "Facility", "Insurance", "PSUs"];

	const CONTENT = sectionOrder
		.filter(key => details[key])
		.map(key => ({
			title: (
				<Text key={`title-${key}`}>
					{key == "Patient Care Facility" ? "Patient Care" : key}
				</Text>
			),
			content: (
				<AccordionSections
					key={`content-${key}`}
					data={
						<Fragment>
							{details[key].map((item: string, index: number) => (
								<Text key={`item-${index}`}>{`\u2022 ${item}\n`}</Text>
							))}
						</Fragment>
					}
				/>
			),
		}));

	const setSections = (sections: any) => {
		setActiveSections(sections.includes(undefined) ? [] : sections);
	};

	const renderHeader = (section: any, _: any, isActive: any) => {
		return <AccordionHeader section={section} _={_} isActive={isActive} />;
	};

	const renderContent = (section: any, _: any, isActive: any) => {
		return <AccordionContent section={section} _={_} isActive={isActive} />;
	};

	return Object.keys(details).length ? (
		<VStack
			flex={1}
			// w={isBigScreen ? "2/3" : "full"}
			w={isBigScreen ? "full" : "full"}
			space="2"
			px={isBigScreen ? webStyle.xNum : 6}
			alignSelf="center"
			borderWidth={isBigScreen ? "1" : "1"}
			borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
			borderRadius="2xl"
			mt={isBigScreen ? "4" : "4"}
			bg={colors.white}
			py="4"
		>
			{/* {(details.procedure_name && details.procedure_name !== "") ||
			(details.info && details.info !== "") ||
			details.inclusion.length ||
			details.exclusion.length ||
			details.remarks.length ? (
				<Text fontSize="xl" fontWeight="semibold" color={colors.primary}>
					Package Details
				</Text>
			) : (
				<Text fontSize="lg" fontWeight="semibold" color={colors.black} textAlign="center">
					No Information Available
				</Text>
			)} */}
			<Accordion
				containerStyle={{ width: "100%" }}
				align="center"
				activeSections={activeSections}
				sections={CONTENT}
				touchableComponent={TouchableOpacity}
				expandMultiple={multipleSelect}
				renderHeader={renderHeader}
				renderContent={renderContent}
				duration={400}
				onChange={setSections}
				renderAsFlatList={false}
			/>
		</VStack>
	) : (
		<Box
			flex={1}
			w={isBigScreen ? "2/3" : "full"}
			px={isBigScreen ? webStyle.xNum : 6}
			alignSelf="center"
			borderWidth={isBigScreen ? "1" : "1"}
			borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
			borderRadius="2xl"
			mt={isBigScreen ? "4" : "4"}
			bg={colors.white}
			py="4"
		>
			<Text fontSize="lg" fontWeight="semibold" color={colors.black} textAlign="center">
				No Information Available
			</Text>
		</Box>
	);
};

export default BookingBottomAccordion;
