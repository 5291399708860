import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import { Box, ScrollView, VStack } from "native-base";
import React from "react";
import { Footer } from "widgets";
import AboutImage from "./about-image";
import AboutText from "./about-text";

const About = () => {
  const [isBigScreen] = AppStore("isBigScreen");
  const content = [
    {
      title_1: "Our",
      title_2: "Story",
      text: "We are a group of passionate individuals who strive to revolutionize the healthcare industry using technology. Aiming to meet the needs of patients even in remote places, Zemidi was launched in 2022 with the aim of delivering an affordable, efficient, and patient-centric system.",
      image: require("assets/images/our-story.png"),
    },
    {
      title_1: "Our",
      title_2: "Vision",
      text: "Zemidi aims to be the largest online aggregator of hospitals, standardizing medical and surgical interventions into packages. The merger of technology & healthcare provides a streamlined process for doctors, healthcare professionals, diagnostic labs & patients. Zemidi further plans to boost this techno-medical merger with the power of AI and IOT.",
      image: require("assets/images/our-vision.png"),
    },
    {
      title_1: "Our",
      title_2: "Mission",
      text: "To create a Universal Healthcare Platform that enables wider reach to the masses and connects all the stakeholders. Zemidi aspires to be the largest virtual hospital in the World - for accessible & affordable  Healthcare",
      image: require("assets/images/our-mission.png"),
    },
  ];

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      <ScrollView
        py={isBigScreen ? "0" : "4"}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={
          isBigScreen ? { paddingBottom: 0 } : { paddingBottom: 50 }
        }
      >
        <VStack
          space={4}
          mx={isBigScreen ? webStyle.xNum : 0}
          bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          p={isBigScreen ? "2" : "4"}
        >
          {content.length &&
            content.map((e, index) => (
              <Box
                mt="2"
                flexDirection={isBigScreen ? "row" : "column"}
                key={index}
              >
                {index % 2 == 0 ? (
                  <>
                    <AboutText e={e} />
                    <AboutImage e={e} />
                  </>
                ) : (
                  <>
                    {isBigScreen ? (
                      <>
                        <AboutImage e={e} />
                        <AboutText e={e} />
                      </>
                    ) : (
                      <>
                        <AboutText e={e} />
                        <AboutImage e={e} />
                      </>
                    )}
                  </>
                )}
              </Box>
            ))}
        </VStack>
        {isBigScreen ? <Box h="6"></Box> : null}
        {isBigScreen ? <Footer /> : null}
      </ScrollView>
    </Box>
  );
};

export default About;
