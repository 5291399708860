import { Box, Image } from "native-base";
import _APP_CONFIG from "config/app";

const Insurance = () => {
  return (
    <Box flex={1} justifyContent={"center"} alignItems={"center"}>
      <Image
        h={["72", "72"]}
        w={["full", "full"]}
        // w={["72", "72"]}
        source={{
          uri: require("assets/images/coming-soon-1.png"),
        }}
        resizeMode="contain"
        alt="loading"
      />
    </Box>
  );
};

export default Insurance;
