import { Box, View } from "native-base";
import React from "react";
import * as Animatable from "react-native-animatable";

const AccordionContent = ({ section, _, isActive }: any) => {
  return section.content ? (
    <Animatable.View duration={400}>
      <Box flex="1" w="full">
        {section.content}
      </Box>
    </Animatable.View>
  ) : (
    //  <View style={{ opacity: 0, height: 0 }}></View>
    <Box h="0" w="0"></Box>
  );
};

export default AccordionContent;
