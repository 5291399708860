import _APP_CONFIG from "config/app";
import React, { useEffect, useState } from "react";
import { Box, Circle, Icon, ScrollView, Text } from "native-base";
import colors from "config/colors";
import BookingTopInfo from "./partials/booking-top-info";
import BookingBottomAccordion from "./partials/booking-bottom-accordion";
import { HeaderTitle, LoadingAnimation } from "widgets";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import { getHospitalPackageDetails } from "./actions";
import HospitalScroll from "./partials/hospital-scroll";
import BookingTopImage from "./partials/booking-top-image";
import { TouchableOpacity } from "react-native";
import { generateDynamicLink } from "AppActions";
import { Entypo, Ionicons } from "assets/icons";
import { getType2HospitalById } from "screens/hospitals/actions";

const Enquire = ({ route, navigation }: any) => {
	const [isBigScreen] = AppStore("isBigScreen");
	const [routeParams] = AppStore("routeParams");
	const [_, setCurrentPageInit] = AppStore("currentPageInit");
	const [isLoading, setIsLoading] = useState(true);
	const [hospital, setHospital] = useState<any>([]);
	const [selected, setSelected] = useState<any>({});
	const [selectedIndex, setSelectedIndex] = useState<any>(null);
	const [reloadScreen, setReloadScreen] = useState<any>(false);

	let hospitalId: any;

	if (route.params) {
		hospitalId = route.params.id;
	}

	useEffect(() => {
		setIsLoading(true);

		if (hospitalId) {
			getType2HospitalById(hospitalId, (res: any, err: any) => {
				if (!err && res.data.length) {
					// console.log("res.data", res.data);
					setHospital(res.data[0]);
					setIsLoading(false);
				} else {
					setIsLoading(false);
				}
			});
		}
	}, [hospitalId]);

	const reload = () => {
		setHospital([]);
		setSelected({});
		setSelectedIndex(null);
		setReloadScreen(!reloadScreen);
	};

	useEffect(() => {
		setCurrentPageInit(reload);
	}, []);

	return (
		<Box
			flex="1"
			px={isBigScreen ? webStyle.xNum : 0}
			bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}
		>
			{isLoading ? (
				<LoadingAnimation loading={isLoading} />
			) : (
				<ScrollView
					flex="1"
					bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
					showsVerticalScrollIndicator={false}
					contentContainerStyle={{
						paddingBottom: 10,
					}}
				>
					{/* {Object.keys(selected).length ? ( */}
					<Box alignItems="center">
						<BookingTopImage
							packageImage={hospital.image}
							// hospitalImage={hospitalImage}
							// rating={selected.rating}
						/>

						<Box px={isBigScreen ? webStyle.xNum : 4} w={isBigScreen ? "2/3" : "full"}>
							<BookingTopInfo
								item={hospital}
								url={hospital.location.url}
								hospitalName={hospital.name}
								navigation={navigation}
								setIsLoading={setIsLoading}
							/>
							<BookingBottomAccordion details={hospital.details} />
						</Box>
					</Box>
					{/* ) : null} */}
				</ScrollView>
			)}
		</Box>
	);
};

export default Enquire;
