import React, { useEffect } from "react";
import { Box, Icon, Stack, Text, VStack } from "native-base";
import { FontAwesome5 } from "assets/icons";
import { Button } from "widgets";

import colors from "config/colors";
import { webStyle } from "config/web-style";
import AppStore from "AppStore";
import { getScreen } from "AppActions";

const BookingSuccess = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [selectedWebHeader, setSelectedWebHeader] =
    AppStore("selectedWebHeader");

  useEffect(() => {
    getScreen("Booking Success");
  }, []);

  useEffect(
    () =>
      navigation.addListener("beforeRemove", (e: any) => {
        e.preventDefault();
        navigation.navigate("Home");
        isBigScreen ? setSelectedWebHeader("Home") : null;
      }),
    [navigation]
  );

  return (
    <Box
      flex={1}
      px={isBigScreen ? webStyle.xNum : 2}
      bg={isBigScreen ? colors.webOuterBg : colors.white}
    >
      <Box flex="1" bg={isBigScreen ? colors.webInnerBg : colors.white} py="7">
        <Box h="80" alignItems="center" justifyContent="center">
          <Icon
            size="32"
            color="green.600"
            as={<FontAwesome5 name="calendar-check" />}
          />
        </Box>
        <VStack
          // py="10"
          justifyContent="center"
          alignItems="flex-start"
          space="8"
        >
          <Stack w="full" alignItems="center" px="2" space="2">
            <Text color="green.700" fontSize="3xl" fontWeight="bold">
              Thank You!
            </Text>
            <Box w="full">
              <Text
                // mb="8"
                textAlign="center"
                color={colors.darkGrayish}
                fontSize="lg"
              >
                Booking Request placed!
              </Text>
              <Text
                mb="8"
                textAlign="center"
                color={colors.darkGrayish}
                fontSize="lg"
              >
                Our representative will contact you shortly.
              </Text>
            </Box>
            <Button
              title="Browse more"
              btnColor={colors.primary}
              pressedColor={colors.accent}
              titleColor={colors.white}
              h="16"
              w="48"
              onPress={() => {
                navigation.navigate("Home");
                isBigScreen ? setSelectedWebHeader("Home") : null;
              }}
            />
          </Stack>
        </VStack>
      </Box>
    </Box>
  );
};

export default BookingSuccess;
