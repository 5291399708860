import _APP_CONFIG from "config/app";
import React from "react";
import { Box, Center, Image, Text, VStack } from "native-base";
import { Rating } from "react-native-ratings";
import colors from "config/colors";
import { FontAwesome } from "assets/icons";
import AppStore from "AppStore";

const HospitalCard = ({
  name,
  image,
  rating,
  price,
  location,
  package_category,
  w = 150,
  h = 48,
  p = 1,
  m = 1,
  imageContainerSize = 32,
  imageSize = 115,
}: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <Box
      borderWidth="1"
      borderRadius="lg"
      w={w}
      // w={{ base: 170, sm: "40" }}
      rounded="3xl"
      overflow="hidden"
      borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
      p={p}
      m={m}
      bg={colors.white}
    >
      <VStack justifyContent="center" alignItems="center" space="1" p={p}>
        <Box h={h} alignItems="center">
          <Center size={imageContainerSize} overflow="hidden">
            {image ? (
              <Image
                size={imageSize}
                borderRadius="full"
                source={{
                  uri: `${_APP_CONFIG._IMAGE_URL}/${image}`,
                }}
                resizeMode="contain"
                alt="loading"
              />
            ) : (
              <Image
                source={require("assets/images/no-image.png")}
                size={imageSize}
                resizeMode="contain"
                alt="loading"
              />
            )}
          </Center>
          <Box flex="1" mx="2">
            <Text
              fontSize="sm"
              fontWeight="medium"
              color={colors.primary}
              textAlign="center"
              noOfLines={3}
              lineHeight="sm"
            >
              {name}
            </Text>
            {package_category ? (
              <Text textAlign="center" fontSize="sm" color={colors.grayText}>
                ({package_category})
              </Text>
            ) : null}
          </Box>
        </Box>
        <Text textAlign="center" fontSize="sm" color={colors.grayText}>
          {"place" in location ? location.place : null}
        </Text>
        {rating ? (
          <Rating
            type="heart"
            // @ts-ignore
            startingValue={rating}
            imageSize={15}
            ratingCount={5}
            readonly
            // onFinishRating={this.ratingCompleted}
          />
        ) : null}

        {price ? (
          <Text fontSize="lg" fontWeight="bold" color="black">
            <FontAwesome name="rupee" size={16} color="black" /> {price}
          </Text>
        ) : null}
      </VStack>
    </Box>
  );
};

export default HospitalCard;
