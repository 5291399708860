import _APP_CONFIG from "config/app";
import { View, Text } from "react-native";
import React from "react";
import { Box, Center, Image } from "native-base";
import colors from "config/colors";

const OverlappingImage = ({ packageImage, hospitalImage }: any) => {
	return (
		<Box w="96" flex="1" alignItems="center" justifyContent="center">
			<Image
				mb="3"
				size="48"
				borderRadius="full"
				source={
					packageImage
						? {
								uri: `${_APP_CONFIG._IMAGE_URL}/${packageImage}`,
						  }
						: require("assets/images/no-image.png")
				}
				resizeMode="contain"
				alt="loading"
				backgroundColor="white"
			/>
			{/* <Image
        bgColor="white"
        size="20"
        borderRadius="full"
        borderWidth="4"
        borderColor={colors.accent}
        source={
          hospitalImage
            ? {
                uri: `${_APP_CONFIG._IMAGE_URL}/${hospitalImage}`,
              }
            : require("assets/images/no-image.png")
        }
        resizeMode="contain"
        alt="loading"
        position="absolute"
        left="64"
        bottom="32"
      /> */}
		</Box>
	);
};

export default OverlappingImage;
