import axios from "axios";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";
import { showToast } from "widgets";

export const updateUserPrescription = async (user, setUser, session, setImage, setIsLoading, image) => {
  let params = [image, user?.id];

  const body = {
    sql: query.updateUserPrescription.query,
    sqlParams: params,
    session: session?.userSession,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.updateUserPrescription.token,
    sessionToken: session?.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.affectedRows > 0) {
      user.prescription = image;
      setImage(image);
      setUser({ ...user });
      setIsLoading(false);
      showToast("Thank you for your submission");
    } else {
      setIsLoading(false);
      showToast("Please try again!");
    }
  } else {
    setIsLoading(false);
    showToast("Please try again!");
  }

  // Api.put(`/users/${user.id}`, params).send((res) => {
  //   if (res.type !== "error") {
  //     user.prescription = image;
  //     setImage(image);
  //     setUser({ ...user });
  //     setIsLoading(false);
  //     showToast("Thank you for your submission");
  //   } else {
  //     setIsLoading(false);
  //     showToast("Please try again!");
  //   }
  // });
};
