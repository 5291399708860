import Api from "services/Api";
import { hashPassword } from "config/password-hash";
import axios from "axios";
import { _BASE_URL2, _DATABASE } from "config/url";
import { query } from "config/query";

export const loginUser = async (fields, latLong, deviceInfo, broadcastSubscribed, deviceOs, callback) => {
  let params = {
    phone: fields.phone,
    password: fields.password,
  };
  let details = { ...deviceInfo };
  details.subscribed = broadcastSubscribed;

  let updateParams = [Object.keys(details).length ? JSON.stringify(details) : null, deviceOs, JSON.stringify(latLong)];
  let hash = await hashPassword(params.password);

  const checkRes = await axios.post(
    _BASE_URL2,
    {
      sql: query.checkUserByPhone.query,
      sqlParams: [params.phone],
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.checkUserByPhone.token,
      },
    }
  );

  if (checkRes?.data?.err == undefined) {
    if (checkRes?.data?.result.length && checkRes?.data?.result[0].phone == params.phone) {
      if (hash == checkRes?.data?.result[0].password) {
        let user = checkRes?.data?.result[0];
        let userSession = checkRes?.data?.resultToken;

        if (Object.keys(deviceInfo).length) {
          let params = [...updateParams];

          const body = {
            sql: query.updateUserDevInfo.query,
            sqlParams: params,
            session: user,
          };
          const headers = {
            db: _DATABASE,
            sqlToken: query.updateUserDevInfo.token,
            sessionToken: userSession,
          };

          const res = await axios.post(_BASE_URL2, body, {
            headers: headers,
          });
        }
        callback({ data: { user: checkRes?.data?.result[0], session: checkRes?.data?.resultToken } }, false);
      } else {
        callback({ invalid: true }, false);
      }
    } else {
      callback({ invalid: true }, false);
    }
  } else {
    callback(null, true);
  }

  // Api.get(`/users?filter=phone,eq,${params.phone}`).send((res) => {
  //   if (res.type !== "error") {
  //     if (res.records.length && res.records[0].phone == params.phone) {
  //       if (hash == res.records[0].password) {
  //         let userId = res.records[0].id;
  //         if (Object.keys(deviceInfo).length) {
  //           Api.put(`/users/${userId}`, updateParams).send((resp) => {});
  //         }
  //         callback({ data: res.records[0] }, false);
  //       } else {
  //         callback({ invalid: true }, false);
  //       }
  //     } else {
  //       callback({ invalid: true }, false);
  //     }
  //   } else {
  //     callback(null, true);
  //   }
  // });
};
