import axios from "axios";
import { query } from "config/query";
import { _BASE_URL, _BASE_URL2, _DATABASE } from "config/url";

export const searchData = async (e, setResults, setIsLoading, setResultsCategory) => {
  if (!e.length) {
    setResults([]);
  }
  if (e.length > 2) {
    setIsLoading(true);
    let searchTerm = { e, ...searchTerm };

    let params1 = [`%${searchTerm.e}%`, `%${searchTerm.e}%`];
    const body1 = {
      sql: query.getAllPackagesByName.query,
      sqlParams: params1,
    };
    const headers1 = {
      db: _DATABASE,
      sqlToken: query.getAllPackagesByName.token,
    };

    const packageRes = await axios.post(_BASE_URL2, body1, {
      headers: headers1,
    });

    let params2 = [`%${searchTerm.e}%`];
    const body2 = {
      sql: query.getAllHospitalsByName.query,
      sqlParams: params2,
    };
    const headers2 = {
      db: _DATABASE,
      sqlToken: query.getAllHospitalsByName.token,
    };

    const hospitalRes = await axios.post(_BASE_URL2, body2, {
      headers: headers2,
    });

    if (packageRes?.data?.err == undefined || hospitalRes?.data?.err == undefined) {
      if (packageRes?.data?.result.length) {
        setResultsCategory("packages");
        setResults(packageRes?.data?.result);
      } else if (hospitalRes?.data?.result.length) {
        setResultsCategory("hospitals");
        setResults(hospitalRes?.data?.result);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }
};
// export const searchData = async (e, setResults, setIsLoading, setResultsCategory) => {
//   if (!e.length) {
//     setResults([]);
//   }
//   if (e.length > 2) {
//     setIsLoading(true);
//     let searchTerm = { e, ...searchTerm };
//     let endpoints = [
//       `${_BASE_URL}/packages?filter1=name,cs,${searchTerm.e}&filter2=keywords,cs,${searchTerm.e}&filter=priority,gt,0`,
//       `${_BASE_URL}/hospitals?filter=name,cs,${searchTerm.e}&filter=priority,gt,0`,
//     ];
//     Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
//       .then(([{ data: packages }, { data: hospitals }]) => {
//         if (packages.records.length) {
//           setResultsCategory("packages");
//           setResults(packages.records);
//         }
//         if (hospitals.records.length) {
//           setResultsCategory("hospitals");
//           setResults(hospitals.records);
//         }
//         setIsLoading(false);
//       })
//       .catch((e) => setIsLoading(false));
//   }
// };
