export const filterMenu = [
  {
    id: "rating",
    name: "Popularity",
  },
  {
    id: "desc",
    name: "Price - High to Low",
  },
  {
    id: "asc",
    name: "Price - Low to High",
  },
];
