import axios from "axios";
import { query } from "config/query";
import { _BASE_URL, _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getDoctors = async (page, selectedId, callback) => {
  let paramsCount = [];
  let params = [];
  let countQuery = "";
  let countQueryToken = "";
  let dataQuery = "";
  let dataQueryToken = "";

  if (selectedId > 0) {
    paramsCount = [selectedId];
    params = [selectedId, (page - 1) * 20];
    countQuery = query?.getDoctorsBySpecializationCount?.query;
    countQueryToken = query?.getDoctorsBySpecializationCount?.token;
    dataQuery = query?.getDoctorsBySpecialization?.query;
    dataQueryToken = query?.getDoctorsBySpecialization?.token;
  } else {
    params = [(page - 1) * 20];
    countQuery = query?.getDoctorsCount?.query;
    countQueryToken = query?.getDoctorsCount?.token;
    dataQuery = query?.getDoctors?.query;
    dataQueryToken = query?.getDoctors?.token;
  }

  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: countQuery,
      sqlParams: paramsCount,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: countQueryToken,
      },
    }
  );
  // console.log("resCount", resCount);
  const body = {
    sql: dataQuery,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: dataQueryToken,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res", res);
  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      // console.log(" res?.data?.result", res?.data?.result);
      callback(
        {
          // data: [],
          data: res?.data?.result,
          totalData: resCount?.data?.result[0]?.["count(*)"],
        },
        false
      );
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }
};
// export const getDoctors = (page, selectedId, callback) => {
//   let query;
//   if (selectedId > 0) {
//     query = `/doctors?join=doctors_specializations&filter=specialization_id,eq,${selectedId}&filter=deleted,eq,0&filter=priority,gt,0&order=priority,desc&page=${page},10`;
//   } else {
//     query = `/doctors?join=doctors_specializations&filter=deleted,eq,0&filter=priority,gt,0&order=priority,desc&page=${page},10`;
//   }
//   Api.get(query).send((res) => {
//     if (res.type !== "error") {
//       callback({ data: res.records, totalData: res.results }, false);
//     } else {
//       callback({ data: [], totalData: 0 }, true);
//     }
//   });
// };

export const getSpecializations = async (callback) => {
  const body = {
    sql: query.getAllDoctorsSpecializations.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllDoctorsSpecializations.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  if (res?.data?.err == undefined) {
    callback({ data: res?.data?.result }, false);
  } else {
    callback({ data: [] }, true);
  }
  // Api.get(`/doctors_specializations`).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: res.records }, false);
  //   }
  //   callback({ data: [] }, true);
  // });
};

export const searchDoc = async (e, setResults, setIsLoading) => {
  if (!e.length) {
    setResults([]);
  }
  if (e.length > 2) {
    setIsLoading(true);
    let searchTerm = { e, ...searchTerm };

    let params = [`%${searchTerm.e}%`, `%${searchTerm.e}%`];
    const body = {
      sql: query.getAllDoctorsByName.query,
      sqlParams: params,
    };
    const headers = {
      db: _DATABASE,
      sqlToken: query.getAllDoctorsByName.token,
    };

    const res = await axios.post(_BASE_URL2, body, {
      headers: headers,
    });

    if (res?.data?.err == undefined) {
      setResults(res?.data?.result);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }
};
// export const searchDoc = async (e, setResults, setIsLoading) => {
//   if (!e.length) {
//     setResults([]);
//   }
//   if (e.length > 2) {
//     setIsLoading(true);
//     let searchTerm = { e, ...searchTerm };
//     let endpoints = [
//       `${_BASE_URL}/doctors?join=doctors_specializations&filter1=name,cs,${searchTerm.e}&filter2=keywords,cs,${searchTerm.e}&filter=deleted,eq,0&filter=priority,gt,0&order=priority,desc`,
//     ];
//     Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
//       .then(([{ data: doctors }]) => {
//         if (doctors.records.length) {
//           setResults(doctors.records);
//         }
//         setIsLoading(false);
//       })
//       .catch((e) => setIsLoading(false));
//   }
// };

export const doctorCalled = async (user, docId, callback) => {
  let params = [user.id, docId];
  const body = {
    sql: query.createUsersDoctorsCalls.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.createUsersDoctorsCalls.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.insertId > 0) {
      callback({ data: true }, false);
    } else {
      callback({ data: false }, true);
    }
  } else {
    callback({ data: false }, true);
  }

  // let params = {
  //   user_id: user.id,
  //   doctor_id: docId,
  // };
  // Api.post(`/users_doctors_calls`, params).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: true }, false);
  //   } else {
  //     callback({ data: false }, true);
  //   }
  // });
};
