import React from "react";

// import { View, Modal } from "react-native";
import { Box, Spinner, View } from "native-base";

import {
  Plane,
  Chase,
  Bounce,
  Wave,
  Pulse,
  Flow,
  Swing,
  Circle,
  CircleFade,
  Grid,
  Fold,
  Wander,
} from "react-native-animated-spinkit";

//style
import styles from "./styles";
import colors from "config/colors";

export const showSwing = (
  <View style={styles.loading}>
    <Swing size={48} color="red" />
  </View>

  //   <Modal animationType="fade" transparent={true} visible={true}>
  //     <View style={styles.centeredView}>
  //       <View style={styles.loading}>
  //         <Swing size={48} color="red" />
  //       </View>
  //     </View>
  //   </Modal>
);

export const showPlane = (
  <View style={styles.loading}>
    <Plane size={48} color="red" />
  </View>
);
export const showChase = (
  <View style={styles.loading}>
    <Chase size={48} color="red" />
  </View>
);
export const showBounce = (
  <View style={styles.loading}>
    <Bounce size={48} color="red" />
  </View>
);
export const showWave = (
  <View style={styles.loading}>
    <Wave size={48} color="red" />
  </View>
);
export const showPulse = (
  <View style={styles.loading}>
    <Pulse size={48} color="red" />
  </View>
);
export const showFlow = (
  // <Box
  //   zIndex="50"
  //   position="absolute"
  //   top="0"
  //   right="0"
  //   h="full"
  //   w="full"
  //   alignItems="center"
  //   justifyContent="center"
  //   bgColor="#00000055"
  //   flex={1}
  // >
  //   <Flow size={48} color="red" />
  // </Box>
  <View style={styles.loading}>
    <Flow size={48} color="red" />
  </View>
);

export const showCircle = (
  <View style={styles.loading}>
    <Circle size={48} color="red" />
  </View>
);
export const showFade = (
  <View style={styles.loading}>
    <CircleFade size={48} color="red" />
  </View>
);
export const showGrid = (
  <View style={styles.loading}>
    <Grid size={48} color="red" />
  </View>
);
export const showFold = (
  <View style={styles.loading}>
    <Fold size={48} color="red" />
  </View>
);
export const showWander = (
  <View style={styles.loading}>
    <Wander size={48} color="red" />
  </View>
);
