import _APP_CONFIG from "config/app";
import React from "react";
import { Box, Image, VStack, ScrollView } from "native-base";
import AppStore from "AppStore";
import colors from "config/colors";

const ImagesList = ({ images }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <ScrollView
      contentContainerStyle={{
        flexDirection: "row",
        flexWrap: "wrap",
        marginHorizontal: 4,
        justifyContent: isBigScreen ? "flex-start" : "center",
      }}
    >
      {images.map((item: any) => (
        <Box
          key={item.id}
          borderWidth="1"
          borderRadius="lg"
          w={["72", "72"]}
          h={["72", "72"]}
          rounded="2xl"
          overflow="hidden"
          borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
          m="2"
          bg={colors.white}
        >
          <VStack justifyContent="center" alignItems="center">
            <Box h={["72", "72"]} alignItems="center" justifyContent="center">
              <Image
                borderRadius="lg"
                h={["72", "72"]}
                w={["72", "72"]}
                source={{
                  uri: `${_APP_CONFIG._IMAGE_URL}/${item.image_url}`,
                }}
                resizeMode="cover"
                alt="loading"
              />
            </Box>
          </VStack>
        </Box>
      ))}
    </ScrollView>
  );

  //   return (
  //     <FlatList
  //       data={images}
  //       showsVerticalScrollIndicator={false}
  //       numColumns={isBigScreen ? webStyle.numCol : 2}
  //       //   onEndReached={handlePagination}
  //       onEndReachedThreshold={0.5}
  //       //   ListFooterComponent={listFooterComponent}
  //       style={{
  //         paddingHorizontal: 4,
  //       }}
  //       renderItem={({ item }) => {
  //         return (
  //           <Box
  //             borderWidth="1"
  //             borderRadius="lg"
  //             w="80"
  //             h="96"
  //             rounded="2xl"
  //             overflow="hidden"
  //             borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
  //             m="2"
  //             bg={colors.white}
  //           >
  //             <VStack justifyContent="center" alignItems="center" space="1">
  //               <Box h="80" alignItems="center">
  //                 <Image
  //                   size="full"
  //                   //   source={{
  //                   //     uri: item.url,
  //                   //   }}
  //                   source={require("assets/images/no-image.png")}
  //                   resizeMode="cover"
  //                   alt="loading"
  //                 />
  //               </Box>
  //               <Box h="16" w="full" p="4" textAlign="center">
  //                 <Text fontSize="md" fontWeight="bold">
  //                   {item.text}
  //                 </Text>
  //               </Box>
  //             </VStack>
  //           </Box>
  //         );
  //       }}
  //       keyExtractor={(item: any) => item.id}
  //     />
  //   );
};

export default ImagesList;
