import { TouchableOpacity } from "react-native";
import React from "react";
import { Icon, Stack } from "native-base";
import colors from "config/colors";
import { Ionicons } from "assets/icons";

const HeaderBack = ({ navigation }: any) => {
  return (
    <Stack w="full" p="4" alignItems="flex-start">
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Icon
          size="10"
          color={colors.primary}
          as={<Ionicons name="ios-caret-back-circle" />}
        />
      </TouchableOpacity>
    </Stack>
  );
};

export default HeaderBack;
