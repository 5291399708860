import AppStore from "AppStore";
import { Box, Text } from "native-base";
import React from "react";

const HeaderTitle = ({ title }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  return (
    <Box w={isBigScreen ? "full" : "48"}>
      <Text fontWeight="medium" fontSize="xl" noOfLines={1}>
        {title}
      </Text>
    </Box>
  );
};

export default HeaderTitle;
