import React from "react";
import { Icon, Text, HStack } from "native-base";
import { Ionicons } from "assets/icons";
import AppStore from "AppStore";
import colors from "config/colors";

const SearchBar = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <HStack
      alignItems="center"
      justifyContent="flex-start"
      w="full"
      h="10"
      rounded="full"
      borderWidth={0}
      bg={isBigScreen ? colors.white : colors.white}
      zIndex={50}
    >
      <Icon
        ml="2"
        size="6"
        color="gray.400"
        as={<Ionicons name="ios-search" />}
      />
      <Text pl="3" color="gray.400">
        Search
      </Text>
    </HStack>
  );
};

export default React.memo(SearchBar);
