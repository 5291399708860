import React from "react";
import { Circle, Icon } from "native-base";
import { webStyle } from "config/web-style";
import colors from "config/colors";
import { MaterialIcons } from "assets/icons";
import AppStore from "AppStore";

const FilterIcon = () => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <Circle
      bg="gray.200"
      size={50}
      // mr={3}
      mr={isBigScreen ? webStyle.xNum : 3}
    >
      <Icon
        size={8}
        color={colors.black}
        as={<MaterialIcons name="filter-alt" />}
      />
    </Circle>
  );
};

export default FilterIcon;
