import axios from "axios";
import { _BOOKING_PREFIX } from "config/defaults";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE, _EMAIL_URL } from "config/url";
import { Platform } from "react-native";
import Api from "services/Api";

export const getHospitalPackageDetails = async (
	packageId,
	hospitalId,
	packageCategoryId,
	item,
	setIsLoading,
	callback
) => {
	// let query;
	// let query2;

	if (packageId && !hospitalId) {
		setIsLoading(true);
		const checkRes = await axios.post(
			_BASE_URL2,
			{
				sql: query.getAllHospitalPackagesByPackageId.query,
				sqlParams: [packageId],
			},
			{
				headers: {
					db: _DATABASE,
					sqlToken: query.getAllHospitalPackagesByPackageId.token,
				},
			}
		);

		if (checkRes?.data?.err == undefined) {
			if (checkRes?.data?.result.length) {
				callback({ data: checkRes.data.result }, false);
			} else {
				callback({ data: [] }, true);
			}
		} else {
			callback({ data: [] }, true);
		}

		// query = `/hospitals_packages?join=hospitals&join=packages&join=hospitals_categories&join=packages_categories&filter=package_id,eq,${packageId}&filter=deleted,eq,0&order=price,asc`;

		// Api.get(query).send((res) => {
		//   if (res.type !== "error") {
		//     callback({ data: res.records }, false);
		//   } else {
		//     callback({ data: [] }, true);
		//   }
		// });
	}
	if (packageId && hospitalId && item) {
		setIsLoading(true);
		const checkRes1 = await axios.post(
			_BASE_URL2,
			{
				sql: query.getAllHospitalPackagesByPiHiPci.query,
				sqlParams: [packageId, hospitalId, packageCategoryId],
			},
			{
				headers: {
					db: _DATABASE,
					sqlToken: query.getAllHospitalPackagesByPiHiPci.token,
				},
			}
		);

		if (checkRes1?.data?.err == undefined) {
			if (checkRes1?.data?.result.length) {
				const checkRes2 = await axios.post(
					_BASE_URL2,
					{
						sql: query.getAllHospitalPackagesByPiHi.query,
						sqlParams: [packageId, hospitalId],
					},
					{
						headers: {
							db: _DATABASE,
							sqlToken: query.getAllHospitalPackagesByPiHi.token,
						},
					}
				);

				if (checkRes2?.data?.err == undefined) {
					if (checkRes2?.data?.result.length) {
						let result = [item, ...checkRes1.data.result, ...checkRes2.data.result];
						callback({ data: result }, false);
					} else {
						let result = [item, ...checkRes1.data.result];
						callback({ data: result }, false);
					}
				} else {
					let result = [item, ...checkRes1.data.result];
					callback({ data: result }, false);
				}
			} else {
				callback({ data: [item] }, false);
			}
		} else {
			callback({ data: [] }, true);
		}

		// query = `/hospitals_packages?join=hospitals&join=packages&join=hospitals_categories&join=packages_categories&filter=package_id,eq,${packageId}&filter=hospital_id,eq,${hospitalId}&filter=package_category_id,neq,${packageCategoryId}&filter=deleted,eq,0&order=price,asc`;

		// query2 = `/hospitals_packages?join=hospitals&join=packages&join=hospitals_categories&join=packages_categories&filter=package_id,eq,${packageId}&filter=hospital_id,neq,${hospitalId}&filter=deleted,eq,0&order=price,asc`;

		// Api.get(query).send((res) => {
		//   if (res.type !== "error") {
		//     Api.get(query2).send((res2) => {
		//       let result = [item, ...res.records, ...res2.records];
		//       callback({ data: result }, false);
		//     });
		//   } else {
		//     callback({ data: res.records }, false);
		//   }
		// });
	}
};

export const booking = async (user, session, item, callback) => {
	let params = [
		item.package_id.name,
		JSON.stringify(item.package_id),
		item.hospital_id.name,
		JSON.stringify(item.hospital_id),
		item.hospital_category_id.name,
		item.package_category_id.name,
		item.price,
		item.price_suffix,
		"pending",
	];
	const body = {
		sql: query.createBooking.query,
		sqlParams: params,
		session: session?.userSession,
	};
	const headers = {
		db: _DATABASE,
		sqlToken: query.createBooking.token,
		sessionToken: session?.token,
	};

	const res = await axios.post(_BASE_URL2, body, {
		headers: headers,
	});

	if (res?.data?.err == undefined) {
		if (res?.data?.result?.insertId > 0) {
			callback({ data: res?.data?.result?.insertId }, false);
		} else {
			callback({ data: null }, true);
		}
	} else {
		callback({ data: null }, true);
	}
};
// export const booking = (user, item, callback) => {
//   let params = {
//     user_id: user.id,
//     package: item.package_id.name,
//     package_details: JSON.stringify(item.package_id),
//     hospital: item.hospital_id.name,
//     hospital_details: JSON.stringify(item.hospital_id),
//     hospital_category: item.hospital_category_id.name,
//     package_category: item.package_category_id.name,
//     price: item.price,
//     price_suffix: item.price_suffix,
//     status: "pending",
//   };
//   Api.post("/bookings", params).send((res) => {
//     console.log("res", res);
//     if (res.type !== "error") {
//       // callback({ data: res }, false);
//     }
//     // callback({ data: null }, true);
//   });
// };

export const getBookings = async (page, user, session, callback) => {
	// let paramsCount = [userId];
	const resCount = await axios.post(
		_BASE_URL2,
		{
			sql: query.getBookingsCount.query,
			session: session?.userSession,
		},
		{
			headers: {
				db: _DATABASE,
				sqlToken: query.getBookingsCount.token,
				sessionToken: session?.token,
			},
		}
	);
	// console.log("resCount", resCount);
	let params = [(page - 1) * 20];
	const body = {
		sql: query.getBookings.query,
		sqlParams: params,
		session: session?.userSession,
	};
	const headers = {
		db: _DATABASE,
		sqlToken: query.getBookings.token,
		sessionToken: session?.token,
	};
	const res = await axios.post(_BASE_URL2, body, {
		headers: headers,
	});
	// console.log("res", res);
	if (resCount?.data?.err == undefined) {
		if (res?.data?.err == undefined) {
			// console.log(" res?.data?.result", res?.data?.result);
			callback(
				{
					// data: [],
					data: res?.data?.result,
					totalData: resCount?.data?.result[0]?.["count(*)"],
				},
				false
			);
		} else {
			callback({ data: [], totalData: 0 }, true);
		}
	} else {
		callback({ data: [], totalData: 0 }, true);
	}

	// let query = `/bookings?filter=user_id,eq,${userId}&order=id,desc&page=${page},10`;
	// Api.get(query).send((res) => {
	//   if (res.type !== "error") {
	//     console.log("first", {
	//       data: res.records,
	//       totalData: res.results,
	//     });
	//     callback(
	//       {
	//         data: res.records,
	//         totalData: res.results,
	//       },
	//       false
	//     );
	//   } else {
	//     callback({ data: [], totalData: 0 }, true);
	//   }
	// });
};

export const cancelBooking = async (bookingId, user, session, callback) => {
	let params = [bookingId];
	const body = {
		sql: query.cancelBooking.query,
		sqlParams: params,
		session: session?.userSession,
	};
	const headers = {
		db: _DATABASE,
		sqlToken: query.cancelBooking.token,
		sessionToken: session?.token,
	};

	const res = await axios.post(_BASE_URL2, body, {
		headers: headers,
	});

	if (res?.data?.err == undefined) {
		if (res?.data?.result?.affectedRows > 0) {
			callback({ data: res?.data?.result?.affectedRows }, false);
		} else {
			callback(null, true);
		}
	} else {
		callback(null, true);
	}

	// let id = bookingId;
	// let params = {
	//   status: "cancelled",
	// };
	// Api.put(`/bookings/${id}`, params).send((res) => {
	//   if (res.type !== "error") {
	//     callback({ data: res.records }, false);
	//   }
	//   callback(null, true);
	// });
};

export const sendMail = (
	bookingId,
	user,
	hospitalName,
	packageName,
	packageCategory,
	setIsLoading,
	type
) => {
	setIsLoading(false);
	bookingId = _BOOKING_PREFIX.concat(bookingId);

	const formData = new FormData();
	formData.append("bookingId", bookingId);
	formData.append("user", user.name);
	formData.append("phone", user.phone);
	formData.append("hospitalName", hospitalName);
	formData.append("packageName", packageName);
	formData.append("packageCategory", packageCategory);
	formData.append("type", type);

	// if (Platform.OS == "android") {
	//   fetch(`${_EMAIL_URL}`, {
	//     method: "POST",
	//     body: formData,
	//   })
	//     .then((res) => () => {})
	//     .catch((e) => () => {});
	// } else {
	//   axios
	//     .post(`${_EMAIL_URL}`, formData)
	//     .then((res) => {
	//       // console.log("res web", res.data);
	//     })
	//     .catch((error) => {
	//       // console.log("error web", error);
	//     });
	// }
};

export const enquiring = async (user, session, hospitalId, callback) => {
	let params = [hospitalId];
	const body = {
		sql: query.createEnquiry.query,
		sqlParams: params,
		session: session?.userSession,
	};
	const headers = {
		db: _DATABASE,
		sqlToken: query.createEnquiry.token,
		sessionToken: session?.token,
	};

	const res = await axios.post(_BASE_URL2, body, {
		headers: headers,
	});

	if (res?.data?.err == undefined) {
		if (res?.data?.result?.insertId > 0) {
			callback({ data: res?.data?.result?.insertId }, false);
		} else {
			callback({ data: null }, true);
		}
	} else {
		callback({ data: null }, true);
	}
};
