export default {
  // primary: "#D72740",
  primary: "#DB4132",
  accent: "#FCBEC1",
  tabCircle: "#ef9799",
  headerShadow: "#000000",
  carouselBg: "#444444",
  // webOuterBg: "#d9d9d9",
  webOuterBg: "#f4f4f4",
  webInnerBg: "#f4f4f4",
  appInnerBg: "#f4f4f4",
  webBorder: "#d9d9d9",
  phoneBorder: "#eaeaea",
  blueColor: "#34c0eb",
  white: "#ffff",
  black: "#000",
  inputHolder: "#f4f4f4",
  divider: "#a5a2a2",
  heading: "#f4f4f4",
  searchBar: "#f4f4f4",
  btnBlue: "#2d77e5",
  activeColorAccepted: "#0090ff",
  // activeColorOutForDelivery: "#ffb83f",
  activeColorDelivered: "#02e837",
  extremeLightGrayish: "#f4f4f4",
  lightGrayish: "#dddddd",
  darkGrayish: "#a5a2a2",
  grayText: "gray",
  richDarkCyan: "#06BCA4",
  rnBlue: "#4388D6",
  transparent: "#00000055",
  whiteTranslucent: "rgba(255, 255, 255, 0.4)",
  // transparent: "#00000080",
  // processing: "#ff8f26",
  btnCloseRed: "red",
  btnGreen: "green",
  rating1: "#D2222D",
  // rating2: "#eb9334",
  // rating3: "#FFBF00",
  rating4: "#32a852",
  rating5: "#007000",
  signOut: "#ff9393",
  wave: "#fccaa9",
  ayushman: "#e57c0b",
};
