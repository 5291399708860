import {
  Box,
  Checkbox,
  CheckIcon,
  Circle,
  HStack,
  Icon,
  Input,
  InputRightAddon,
  ScrollView,
  Select,
  Stack,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useState } from "react";
import AppStore from "AppStore";
import { Platform, TouchableOpacity } from "react-native";
import { webStyle } from "config/web-style";
import colors from "config/colors";
import { getBloodGroups, updateDonorUser } from "./actions";
import { AuthHeader, Button, LoadingAnimation, showToast } from "widgets";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { Entypo, FontAwesome5 } from "assets/icons";
import { checkAge } from "AppActions";
import moment from "moment";
import DatePickerWeb from "./date-picker.web";

const AddDonor = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [user, setUser] = AppStore("user");
  const [session] = AppStore("session");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [fields, setFields] = useState<any>({});
  const [bloodGroups, setBloodGroups] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [birthDate, setBirthDate] = useState<any>({});
  const [isAvailable, setIsAvailable] = useState<any>({});
  const [picker, setPicker] = useState<any>({});
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  useEffect(() => {
    if (Object.keys(user).length) {
      let displayBirthDate;
      if (user.date_of_birth) {
        displayBirthDate = moment(user.date_of_birth).format("DD-MM-YYYY");
        user.displayBirthDate = displayBirthDate;
      }
      setFields({ ...user });
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(birthDate).length) {
      fields.displayBirthDate = birthDate.displayBirthDate;
      fields.date_of_birth = birthDate.date_of_birth;
      setFields({ ...fields });
    }
  }, [birthDate]);

  useEffect(() => {
    if (Object.keys(isAvailable).length) {
      fields.is_available = isAvailable.is_available;
      setFields({ ...fields });
    }
  }, [isAvailable]);

  const showDatePicker = (data: any) => {
    setDatePickerVisibility(true);
    setPicker(data);
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleConfirm = (date: any) => {
    if (picker == "dob") {
      handleBirthDate(date);
    } else {
      handleDonatedDate(date);
    }
    hideDatePicker();
  };

  const handleBirthDate = (date: any) => {
    let displayBirthDate = moment.utc(date).local().format("DD-MM-YYYY");
    birthDate.displayBirthDate = displayBirthDate;
    let saveDate = moment.utc(date).local().format("YYYY-MM-DD");
    birthDate.date_of_birth = saveDate;
    setBirthDate({ ...birthDate });
  };

  const handleDonatedDate = (date: any) => {
    let lastDonatedDate;
    if (date.includes("weeks")) {
      let weeks = date.split("weeks")[0];
      let lastDonated = moment().subtract(weeks, "weeks").format("YYYY-MM-DD");
      lastDonatedDate = moment(lastDonated).subtract(1, "day").format("YYYY-MM-DD");
    }
    if (date.includes("months")) {
      let months = date.split("months")[0];
      let lastDonated = moment().subtract(months, "months").format("YYYY-MM-DD");
      lastDonatedDate = moment(lastDonated).subtract(1, "day").format("YYYY-MM-DD");
    }
    fields.last_donation_date = lastDonatedDate;
    setFields({ ...fields });
  };

  useEffect(() => {
    getBloodGroups((res: any, err: any) => {
      if (!err) {
        setBloodGroups(res.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  const updateUser = () => {
    if (
      Object.keys(fields).length &&
      fields.gender &&
      fields.blood_group &&
      fields.date_of_birth &&
      fields.last_donation_duration
    ) {
      setIsLoading(true);
      let eligible = checkAge(fields.date_of_birth);
      if (eligible) {
        updateDonorUser(fields, user, session, (res: any, err: any) => {
          if (!err) {
            setUser({ ...user, ...res.data });
            setIsLoading(false);
            showToast("Saved!");
          } else {
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
        showToast("Age must be between 18 and 65");
      }
    } else {
      showToast("Gender, Blood Group, DOB & Last Donated is required");
    }
  };

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box>
          <AuthHeader />
          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px="10"
            space="6"
            mx={isBigScreen ? webStyle.xNum : 0}
          >
            <HStack w="full" alignItems="center" justifyContent="space-between">
              <Box w="70%">
                <Text color="white" fontSize="lg" fontWeight="bold">
                  {Object.keys(user).length && user.is_donor ? "Blood Donor profile" : "Register as Blood Donor"}
                </Text>
              </Box>
              {!isBigScreen ? (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                  </Circle>
                </TouchableOpacity>
              ) : null}
            </HStack>
            <VStack w="full" space="4">
              <Select
                minWidth="300"
                rounded="md"
                size="xl"
                bgColor={colors.white}
                accessibilityLabel="Choose Gender"
                placeholder="Choose Gender"
                _selectedItem={{
                  bg: colors.lightGrayish,
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={fields.gender}
                onValueChange={(itemValue) => {
                  fields.gender = itemValue;
                  setFields({ ...fields });
                }}
              >
                <Select.Item label="Male" value="Male" />
                <Select.Item label="Female" value="Female" />
              </Select>

              <Select
                minWidth="300"
                rounded="md"
                size="xl"
                bgColor={colors.white}
                accessibilityLabel="Choose Blood Group"
                placeholder="Choose Blood Group"
                _selectedItem={{
                  bg: colors.lightGrayish,
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={fields.blood_group}
                onValueChange={(itemValue) => {
                  fields.blood_group = itemValue;
                  setFields({ ...fields });
                }}
              >
                {bloodGroups.length
                  ? bloodGroups.map((e: any) => <Select.Item label={e.name} value={e.name} key={e.id} />)
                  : null}
              </Select>
              <Stack w="full" space="1">
                <Text color={colors.white} fontSize="md" fontWeight="medium">
                  Date of Birth
                </Text>
                {Platform.OS == "web" ? (
                  <DatePickerWeb
                    displayDate={fields.displayBirthDate}
                    placeholder="Date of Birth"
                    handleDate={handleBirthDate}
                  />
                ) : (
                  <TouchableOpacity onPress={() => showDatePicker("dob")}>
                    <Input
                      h="12"
                      w="full"
                      isReadOnly
                      placeholder="Date of Birth"
                      variant="outline"
                      isRequired
                      rounded="md"
                      size="xl"
                      bgColor={colors.white}
                      focusOutlineColor="white"
                      value={fields.displayBirthDate || ""}
                      InputRightElement={
                        <InputRightAddon w="20%" h="full" bgColor={colors.lightGrayish}>
                          <Icon size="5" color={colors.primary} as={<FontAwesome5 name="calendar-alt" />} />
                        </InputRightAddon>
                      }
                    />
                  </TouchableOpacity>
                )}
              </Stack>

              <Stack w="full" space="1">
                <Text color={colors.white} fontSize="md" fontWeight="medium">
                  Last Donated more than
                </Text>

                <Select
                  minWidth="300"
                  rounded="md"
                  size="xl"
                  bgColor={colors.white}
                  accessibilityLabel="Choose"
                  placeholder="Choose"
                  _selectedItem={{
                    bg: colors.lightGrayish,
                    endIcon: <CheckIcon size="5" />,
                  }}
                  selectedValue={fields.last_donation_duration}
                  onValueChange={(itemValue) => {
                    fields.last_donation_duration = itemValue;
                    setFields({ ...fields });
                    handleDonatedDate(itemValue);
                  }}
                >
                  <Select.Item label="1 week" value="1 weeks" />
                  <Select.Item label="2 weeks" value="2 weeks" />
                  <Select.Item label="1 month" value="1 months" />
                  <Select.Item label="2 months" value="2 months" />
                  <Select.Item label="3 months" value="3 months" />
                  <Select.Item label="4 months" value="4 months" />
                  <Select.Item label="5 months" value="5 months" />
                  <Select.Item label="Never donated" value="Never donated" />
                </Select>
              </Stack>
              <Checkbox
                value=""
                isChecked={fields.is_available || ""}
                colorScheme="green"
                size="lg"
                onChange={(value) => {
                  isAvailable.is_available = value;
                  setIsAvailable({ ...isAvailable });
                }}
              >
                <Text color={colors.white} fontSize="md" fontWeight="medium">
                  Show yourself publicly?
                </Text>
              </Checkbox>
            </VStack>

            <Button
              title={Object.keys(user).length && user.is_donor ? "UPDATE" : "REGISTER"}
              btnColor={colors.white}
              titleColor={colors.primary}
              onPress={updateUser}
              mt="10"
            />

            <DateTimePickerModal
              isVisible={isDatePickerVisible}
              mode="date"
              onConfirm={handleConfirm}
              onCancel={hideDatePicker}
            />
            <LoadingAnimation loading={isLoading} />
          </VStack>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default AddDonor;
