import _APP_CONFIG from "config/app";
import AppStore from "AppStore";
import axios from "axios";
import Api from "services/Api";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Platform, Share } from "react-native";
import { _APP_URL, _BASE_URL2, _DATABASE, _PACKAGE_NAME, _SMS_LOG_URL, _SMS_URL } from "config/url";
import { showToast } from "widgets";
// import * as Analytics from "expo-firebase-analytics";
import * as Updates from "expo-updates";
import messaging from "@react-native-firebase/messaging";
import * as Application from "expo-application";
import * as Network from "expo-network";
import * as Clipboard from "expo-clipboard";
import * as Location from "expo-location";
import moment from "moment";
import { query } from "config/query";

const { getState, setState } = AppStore;

export const getScreen = async (route) => {
  // await Analytics.logEvent("screen_view", route);
  // await Analytics.logEvent("screen_view", { firebase_screen: route });
};

export const resetToHome = (navigation) => {
  navigation.navigate("Home");
  // navigation.replace("Zemidi", { screen: "Home" });
  setState({ goToHome: false });
};

export const getAppStoreVersion = async (callback) => {
  const body = {
    sql: query.getAllAppVersions.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllAppVersions.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    callback({ data: res?.data?.result[0] }, false);
  } else {
    callback({ data: null }, true);
  }

  // Api.get(`/app_versions`).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: res.records[0] }, false);
  //   } else {
  //     callback({ data: null }, true);
  //   }
  // });
};

export const checkOtaUpdate = async () => {
  if (Platform.OS !== "web") {
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        setState({ otaAvailable: true });
      } else {
        setState({ otaAvailable: false });
      }
    } catch (error) {
      setState({ otaAvailable: false });
    }
  }
};

export const fetchUpdate = async () => {
  try {
    const res = await Updates.fetchUpdateAsync();
    if (res.isNew) {
      setState({ otaAvailable: false });
      reload();
    } else {
      setState({ otaAvailable: false });
    }
  } catch (error) {
    // alert(`Error fetching latest Expo update: ${error}`);
  }
};

export const reload = async () => {
  try {
    await Updates.reloadAsync();
  } catch (error) {
    // alert(`Error reloading: ${error}`);
  }
};

export const getGoogleMapApiKey = async (callback) => {
  const body = {
    sql: query.getAllApiKeys.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllApiKeys.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res :>> ", res);
  if (res?.data?.err == undefined) {
    let key = res.data.result[0].api_key;
    callback({ data: key }, false);
  } else {
    callback({ data: null }, true);
  }

  // Api.get("/api_keys").send(async (res) => {
  //   console.log("res old", res);
  //   if (res.type !== "error") {
  //     let key = res.records[0].api_key;
  //     callback({ data: key }, false);
  //   } else {
  //     callback({ data: null }, true);
  //   }
  // });
};

// export const receiveDynamicLink = () => {
//   //app background
//   dynamicLinks()
//     .getInitialLink()
//     .then((link) => {
//       handleDynamicLink(link);
//     });
//   //app foreground
//   const unsubscribe = dynamicLinks().onLink(handleDynamicLink);
//   return () => unsubscribe();
// };

// export const handleDynamicLink = (link) => {
//   if (link && Object.keys(link).length) {
//     let url = Linking.parse(link.url);
//     let referralCode = url.queryParams.referral;
//   }
// };

// export const getInitialURL = async (callback) => {
//   const url = await Linking.getInitialURL();
//   if (url && url.includes("referral")) {
//     let referralCode = url.split("?referral=");
//     // callback(referralCode[1]);
//     // setState({ referralCode: referralCode[1] });
//   }
// };

export const generateDynamicLink = async (packageId, name, selected, setIsLoading) => {
  const body = {
    sql: query.getAllApiKeys.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllApiKeys.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  // Api.get("/api_keys").send(async (res) => {
  if (res?.data?.err == undefined) {
    let apiKey = res?.data?.result[1].api_key;
    // let apiKey = res.records[1].api_key;
    // let url = `http://localhost:19006/booking?share=${packageId}&selectedId=${selected}`;
    let url = `https://zemidi.com/booking?share=${packageId}&selectedId=${selected}`;
    let fireBaseUrl = "https://zemidi.page.link";
    // let longLink;
    //long link
    // try {
    //   longLink = await dynamicLinks().buildLink({
    //     link: url,
    //     domainUriPrefix: fireBaseUrl,
    //     android: {
    //       packageName: _PACKAGE_NAME,
    //     },
    //     navigation: {
    //       forcedRedirectEnabled: true,
    //     },
    //     social: {
    //       title: name,
    //       descriptionText: "Discover affordable health packages | Zemidi",
    //       imageUrl: `${_APP_CONFIG._IMAGE_URL}/zemidi/icon.jpg`,
    //     },
    //   });
    //   console.log("longLink", longLink);
    // } catch (err) {
    //   console.log("err", err);
    // }

    //short link
    try {
      const res = await axios.post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
        // {
        // longDynamicLink: `${fireBaseUrl}/?link=${url}&apn=${_PACKAGE_NAME}`,
        // // longDynamicLink: longLink,
        // suffix: {
        //   option: "SHORT",
        // },
        // }
        {
          dynamicLinkInfo: {
            domainUriPrefix: fireBaseUrl,
            link: url,
            androidInfo: {
              androidPackageName: _PACKAGE_NAME,
            },
            socialMetaTagInfo: {
              socialTitle: name,
              socialDescription: "Zemidi | Discover health packages and hospitals at affordable prices",
              socialImageLink: `${_APP_CONFIG._IMAGE_URL}/zemidi/icon.jpg`,
            },
          },
          suffix: {
            option: "SHORT",
          },
        }
      );
      // console.log("shortLink", res.data);
      sharePackage(res.data.shortLink);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // console.log("err", err);
    }
  } else {
    setIsLoading(false);
  }
  // });
};

export const checkNetwork = async () => {
  const res = await NetInfo.fetch();
  if (res.isConnected) {
    return true;
  } else {
    return false;
    // showToast("No internet connection!");
  }
};

export const shareApp = async () => {
  try {
    const result = await Share.share({
      message: `${_APP_URL}`,
    });
  } catch (error) {}
};

export const sharePackage = async (url) => {
  if (Platform.OS !== "web") {
    try {
      await Share.share({
        message: url,
      });
    } catch (error) {
      // console.log("error", error);
    }
  } else {
    // const link = await Linking.getInitialURL();
    await Clipboard.setStringAsync(url);
    showToast("Link copied!");
  }
};

export const registerForPushNotificationsAsync = async () => {
  if (Platform.OS !== "web") {
    let expoToken;
    let deviceToken;
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        // alert("Failed to get push token for push notification!");
        return;
      }
      try {
        expoToken = (
          await Notifications.getExpoPushTokenAsync({
            experienceId: "arodos.tech@zemidi",
          })
        ).data;
        deviceToken = (
          await Notifications.getDevicePushTokenAsync({
            experienceId: "arodos.tech@zemidi",
          })
        ).data;
      } catch (e) {
        // console.log("e", e);
      }
    } else {
      // alert("Must use physical device for Push Notifications");
    }

    setState({
      deviceInfo: {
        expoToken: expoToken,
        deviceToken: deviceToken,
        os: Platform.OS,
      },
    });
    // return token;
  } else {
    return;
  }
};

export const schedulePushNotification = async (bookingId, title, body, package_url, hospital_url) => {
  if (Platform.OS == "android" || Platform.OS == "ios") {
    await Notifications.scheduleNotificationAsync({
      content: {
        id: bookingId,
        title: title,
        body: body,
        image: package_url,
        hospital_url: hospital_url,
        // time: "10:00 pm",
      },
      trigger: { seconds: 1 },
    });
    let pushNotification = getState()["pushNotification"];
    let details = {
      id: bookingId,
      title: title,
      body: body,
      image: package_url,
      hospital_url: hospital_url,
    };
    setState({ pushNotification: [details, ...pushNotification] });
    setState({ showNotificationBadge: true });
  }
};

export const subscribeToTopic = () => {
  let broadcastSubscribed = getState()["broadcastSubscribed"];
  if (Platform.OS == "android" && !broadcastSubscribed) {
    const subscribe = messaging()
      .subscribeToTopic("broadcast")
      .then(() => setState({ broadcastSubscribed: true }));
    return () => subscribe;
  }
};

export const getAndroidId = () => {
  if (Platform.OS == "android") {
    const id = Application.androidId;
    setState({ androidId: id });
  }
};

export const getIpAddress = async () => {
  try {
    const ip = await Network.getIpAddressAsync();
    setState({ ipAddress: ip });
  } catch (error) {
    // console.log("error", error);
  }
};

export const getDeviceOS = () => {
  setState({ deviceOs: Platform.OS });
};

export const getLocation = async (setLocation) => {
  getGoogleMapApiKey(async (res, err) => {
    if (!err) {
      let key = res.data;
      Location.setGoogleApiKey(key);
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        return;
      }
      let location = await Location.getCurrentPositionAsync({});
      const place = await Location.reverseGeocodeAsync({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      });
      setLocation(place[0].city + ", " + place[0].region);
    }
  });
};

export const getLatLong = async () => {
  getGoogleMapApiKey(async (res, err) => {
    if (!err) {
      let key = res.data;
      Location.setGoogleApiKey(key);
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        return;
      }
      let location = await Location.getCurrentPositionAsync({});
      setState({
        latLong: {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        },
      });
    }
  });
};

export const handleNotificationReceived = () => {
  if (Platform.OS == "android") {
    const unsubscribe = messaging().onMessage(async (remoteMessage) => {
      // console.log("fg");
      handleReceivedNotification(remoteMessage, "foreground");
    });
    return unsubscribe;
  }
};

export const handleInitialNotification = () => {
  if (Platform.OS == "android") {
    const subscribe = messaging()
      .getInitialNotification()
      .then((remoteMessage) => {
        if (remoteMessage) {
          // console.log("initial");
          handleReceivedNotification(remoteMessage, "initial");
        }
      });
    return subscribe;
  }
};

export const handleNotificationOpened = (navigation) => {
  if (Platform.OS == "android") {
    const subscribe = messaging().onNotificationOpenedApp((remoteMessage) => {
      // console.log("Notification caused app to open from background state:");
      handleReceivedNotification(remoteMessage, null);
      // navigation.navigate(remoteMessage.data.screen);
      navigation.navigate("Notifications");
      setState({ showNotificationBadge: false });
    });
    return subscribe;
  }
};

export const handleBackgroundNotification = () => {
  if (Platform.OS == "android") {
    const subscribe = messaging().setBackgroundMessageHandler(async (remoteMessage) => {
      // console.log("Message handled in the background!");
      handleReceivedNotification(remoteMessage, "background");
    });
    return subscribe;
  }
};

export const handleReceivedNotification = (remoteMessage, param) => {
  let pushNotification = getState()["pushNotification"];
  let msg = {
    msgId: remoteMessage.messageId,
    title: remoteMessage.notification?.title,
    body: remoteMessage.notification?.body,
    image: remoteMessage.notification?.android?.imageUrl,
  };
  let exists = pushNotification.find((e) => e.msgId == msg.msgId);
  if (!exists) {
    setState({ showNotificationBadge: true });
    setState({ pushNotification: [msg, ...pushNotification] });
  }
  if (param == "initial") {
    setState({ initialRoute: "Notifications" });
    setState({ showNotificationBadge: false });
  }
};

export const updateDeviceToken = async () => {
  let deviceInfo = getState()["deviceInfo"];
  let deviceOs = getState()["deviceOs"];
  let broadcastSubscribed = getState()["broadcastSubscribed"];
  let androidId = getState()["androidId"];
  let ipAddress = getState()["ipAddress"];
  let user = getState()["user"];
  let session = getState()["session"];
  let latLong = getState()["latLong"];

  let hasToken = Object.keys(deviceInfo).length;
  let hasLocation = Object.keys(latLong).length;
  let deviceToken = hasToken ? deviceInfo.deviceToken : null;
  let expoToken = hasToken ? deviceInfo.expoToken : null;
  let params = {};
  let postParams = [];
  let userParams = [];
  let details = {};

  if (deviceToken) {
    params.device_token = deviceToken;
    params.expo_token = expoToken;
    details = { ...deviceInfo };
    userParams.push(JSON.stringify(details));
  }
  if (hasLocation) {
    params.lat_long = JSON.stringify(latLong);
    userParams.push(JSON.stringify(latLong));
  }
  if (deviceOs) {
    params.os = deviceOs;
    userParams.push(deviceOs);
  }
  if (broadcastSubscribed) {
    params.subscribed = broadcastSubscribed;
    details.subscribed = broadcastSubscribed;
  }
  if (androidId) {
    params.android_id = androidId;
  }
  if (ipAddress) {
    params.ip_address = ipAddress;
  }
  postParams.push(deviceToken);
  postParams.push(expoToken);
  postParams.push(JSON.stringify(latLong));
  postParams.push(deviceOs);
  postParams.push(broadcastSubscribed);
  postParams.push(androidId);
  postParams.push(ipAddress);

  if (Object.keys(user).length && Object.keys(session).length) {
    const checkRes = await axios.post(
      _BASE_URL2,
      {
        sql: query.getUserById.query,
        session: session?.userSession,
      },
      {
        headers: {
          db: _DATABASE,
          sqlToken: query.getUserById.token,
          sessionToken: session?.token,
        },
      }
    );

    if (checkRes?.data?.err == undefined) {
      if (checkRes?.data?.result.length) {
        let result = checkRes?.data?.result[0].device_info ? checkRes?.data?.result[0].device_info : null;
        // let result = checkRes?.data?.result[0].device_info ? JSON.parse(checkRes?.data?.result[0].device_info) : null;
        let savedOs = checkRes?.data?.result[0].os;
        let savedDeviceToken = result && result.deviceToken;
        let savedExpoToken = result && result.expoToken;
        let subscribed = result && result.subscribed;
        let savedLatLong = checkRes?.data?.result[0].lat_long ? checkRes?.data?.result[0].lat_long : null;
        // let savedLatLong = checkRes?.data?.result[0].lat_long ? JSON.parse(checkRes?.data?.result[0].lat_long) : null;
        let hasSavedLatLong = savedLatLong ? Object.keys(savedLatLong).length : null;

        let params = userParams.length == 3 ? [...userParams] : [null, ...userParams];
        const body = {
          sql: query.updateUserDevInfo.query,
          sqlParams: params,
          session: session?.userSession,
        };
        const headers = {
          db: _DATABASE,
          sqlToken: query.updateUserDevInfo.token,
          sessionToken: session?.token,
        };

        if (!hasSavedLatLong) {
          const userRes = await axios.post(_BASE_URL2, body, {
            headers: headers,
          });

          if (
            (userParams[1] && latLong.latitude != savedLatLong.latitude) ||
            (userParams[1] && latLong.longitude != savedLatLong.longitude)
          ) {
            const addressUserRes = await axios.post(_BASE_URL2, body, {
              headers: headers,
            });
          }
        }
        if (userParams[0]) {
          if (
            (savedDeviceToken !== deviceToken && deviceToken !== null) ||
            (savedExpoToken !== expoToken && expoToken !== null) ||
            subscribed !== true ||
            !savedOs
          ) {
            const deviceUserRes = await axios.post(_BASE_URL2, body, {
              headers: headers,
            });
          }
        }
      }
    }
  } else {
    // console.log("first check");
    const checkUsersInstallRes = await axios.post(
      _BASE_URL2,
      {
        sql: query.checkUserInstallByTokens.query,
        sqlParams: [deviceToken],
      },
      {
        headers: {
          db: _DATABASE,
          sqlToken: query.checkUserInstallByTokens.token,
        },
      }
    );

    if (checkUsersInstallRes?.data?.err == undefined) {
      if (checkUsersInstallRes?.data?.result.length) {
        let params = [...postParams, checkUsersInstallRes.data.result[0].id];
        const body = {
          sql: query.updateUserInstall.query,
          sqlParams: params,
        };
        const headers = {
          db: _DATABASE,
          sqlToken: query.updateUserInstall.token,
        };

        const updateRes = await axios.post(_BASE_URL2, body, {
          headers: headers,
        });
      } else {
        let params = [...postParams];
        const body = {
          sql: query.addUserInstall.query,
          sqlParams: params,
        };
        const headers = {
          db: _DATABASE,
          sqlToken: query.addUserInstall.token,
        };

        const usersInstallRes = await axios.post(_BASE_URL2, body, {
          headers: headers,
        });
      }
    }

    // Api.get(
    //   `/users_installs?filter=device_token,eq,${deviceToken}&filter=expo_token,eq,${expoToken}&filter=subscribed,eq,1`
    // ).send((res) => {
    //   if (res.type !== "error") {
    //     if (!res.records.length) {
    //       Api.post(`/users_installs`, postParams).send((resp) => {});
    //     } else {
    //       if (res.records.length) {
    //         Api.put(`/users_installs/${res.records[0].id}`, params).send((res) => {});
    //       }
    //     }
    //   }
    // });
  }
};

// export const updateDeviceToken = () => {
//   let deviceInfo = getState()["deviceInfo"];
//   let deviceOs = getState()["deviceOs"];
//   let broadcastSubscribed = getState()["broadcastSubscribed"];
//   let androidId = getState()["androidId"];
//   let ipAddress = getState()["ipAddress"];
//   let user = getState()["user"];
//   let latLong = getState()["latLong"];

//   let hasToken = Object.keys(deviceInfo).length;
//   let hasLocation = Object.keys(latLong).length;
//   let deviceToken = hasToken ? deviceInfo.deviceToken : null;
//   let expoToken = hasToken ? deviceInfo.expoToken : null;
//   let params = {};
//   let postParams = {};
//   let userParams = {};
//   let details = {};

//   if (deviceToken) {
//     params.device_token = deviceToken;
//     params.expo_token = expoToken;
//     details = { ...deviceInfo };
//     userParams.device_info = JSON.stringify(details);
//   }
//   if (hasLocation) {
//     params.lat_long = JSON.stringify(latLong);
//     userParams.lat_long = JSON.stringify(latLong);
//   }
//   if (deviceOs) {
//     params.os = deviceOs;
//     userParams.os = deviceOs;
//   }
//   if (broadcastSubscribed) {
//     params.subscribed = broadcastSubscribed;
//     details.subscribed = broadcastSubscribed;
//   }
//   if (androidId) {
//     params.android_id = androidId;
//   }
//   if (ipAddress) {
//     params.ip_address = ipAddress;
//   }
//   postParams.device_token = deviceToken;
//   postParams.expo_token = expoToken;
//   postParams.lat_long = JSON.stringify(latLong);
//   postParams.os = deviceOs;
//   postParams.subscribed = broadcastSubscribed;
//   postParams.android_id = androidId;
//   postParams.ip_address = ipAddress;

//   if (Object.keys(user).length) {
//     Api.get(`/users?filter=id,eq,${user.id}&include=device_info,os,lat_long`).send((res) => {
//       if (res.type !== "error" && res.records.length) {
//         let result = res.records[0].device_info ? JSON.parse(res.records[0].device_info) : null;
//         let savedOs = res.records[0].os;
//         let savedDeviceToken = result && result.deviceToken;
//         let savedExpoToken = result && result.expoToken;
//         let subscribed = result && result.subscribed;
//         let savedLatLong = res.records[0].lat_long ? JSON.parse(res.records[0].lat_long) : null;
//         let hasSavedLatLong = savedLatLong ? Object.keys(savedLatLong).length : null;
//         if (!hasSavedLatLong) {
//           Api.put(`/users/${user.id}`, userParams).send((resp) => {});
//         } else {
//           if (
//             (userParams.lat_long && latLong.latitude != savedLatLong.latitude) ||
//             (userParams.lat_long && latLong.longitude != savedLatLong.longitude)
//           ) {
//             Api.put(`/users/${user.id}`, userParams).send((resp) => {});
//           }
//         }
//         if (userParams.device_info) {
//           if (
//             (savedDeviceToken !== deviceToken && deviceToken !== null) ||
//             (savedExpoToken !== expoToken && expoToken !== null) ||
//             subscribed !== true ||
//             !savedOs
//           ) {
//             Api.put(`/users/${user.id}`, userParams).send((resp) => {});
//           }
//         }
//       }
//     });
//   } else {
//     Api.get(
//       `/users_installs?filter=device_token,eq,${deviceToken}&filter=expo_token,eq,${expoToken}&filter=subscribed,eq,1`
//     ).send((res) => {
//       if (res.type !== "error") {
//         if (!res.records.length) {
//           Api.post(`/users_installs`, postParams).send((resp) => {});
//         } else {
//           if (res.records.length) {
//             Api.put(`/users_installs/${res.records[0].id}`, params).send((res) => {});
//           }
//         }
//       }
//     });
//   }
// };

export const saveReferralsInstalls = async () => {
  let referralCode = getState()["referralCode"];
  let deviceInfo = getState()["deviceInfo"];
  let broadcastSubscribed = getState()["broadcastSubscribed"];
  let androidId = getState()["androidId"];
  let ipAddress = getState()["ipAddress"];
  let deviceOs = getState()["deviceOs"];
  let hasToken = Object.keys(deviceInfo).length;

  let params = [
    hasToken ? deviceInfo.deviceToken : null,
    hasToken ? deviceInfo.expoToken : null,
    deviceOs,
    broadcastSubscribed,
    androidId,
    ipAddress,
    referralCode,
  ];
  const body = {
    sql: query.createReferralsInstalls.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.createReferralsInstalls.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.insertId > 0) {
      setState({ referralUserSaved: true });
    } else {
    }
  } else {
  }

  // Api.post(`/referrals_installs`, params).send((res) => {
  //   if (res.type !== "error") {
  //     setState({ referralUserSaved: true });
  //   }
  // });
};

export const getPackagesCategories = async (setPackagesCategories) => {
  const body = {
    sql: query.getAllPackagesCategories.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllPackagesCategories.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  if (res?.data?.err == undefined) {
    setPackagesCategories(res.data.result);
  } else {
    setPackagesCategories([]);
  }
  // Api.get(`/packages_categories`).send((res) => {
  //   if (res.type !== "error") {
  //     setPackagesCategories(res.records);
  //   }
  // });
};

export const getHospitalCategories = async (setHospitalCategories, setIsLoading) => {
  const body = {
    sql: query.getAllHospitalsCategories.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllHospitalsCategories.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res :>> ", res);
  if (res?.data?.err == undefined) {
    setHospitalCategories(res.data.result);
    setIsLoading(false);
  } else {
    setIsLoading(false);
  }
  // Api.get(`/hospitals_categories`).send((res) => {
  //   if (res.type !== "error") {
  //     setHospitalCategories(res.records);
  //     setIsLoading(false);
  //   }
  //   setIsLoading(false);
  // });
};

export const checkAge = (birthDate) => {
  let today = moment.utc().local().format("YYYY-MM-DD");
  let dob = moment(today).diff(moment(birthDate), "years");
  if (dob >= 18 && dob <= 65) {
    return true;
  } else {
    return false;
  }
};

export const checkEligibility = (donor) => {
  if (donor.last_donation_date) {
    let today = moment.utc().local().format("YYYY-MM-DD");
    let lastDonationDuration = moment(today).diff(moment(donor.last_donation_date), "months");
    if (lastDonationDuration < 3) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const providersCalled = async (data, callback) => {
  let user = getState()["user"];
  let deviceInfo = getState()["deviceInfo"];
  let deviceOs = getState()["deviceOs"];
  let latLong = getState()["latLong"];
  let hasLocation = Object.keys(latLong).length;
  let hasToken = Object.keys(deviceInfo).length;
  let device_token = hasToken ? deviceInfo.deviceToken : null;
  let expo_token = hasToken ? deviceInfo.expoToken : null;
  let os = deviceOs;

  let params = [
    Object.keys(user).length ? user.id : null,
    data?.providerId,
    data?.hospitalId,
    data?.center,
    data?.contactMode,
    device_token,
    expo_token,
    os,
    hasLocation ? JSON.stringify(latLong) : null,
  ];

  const body = {
    sql: query.addUserProviderCall.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.addUserProviderCall.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.insertId > 0) {
      callback(res?.data?.result?.insertId, false);
      // callback(res, false);
    } else {
      callback(null, true);
    }
  } else {
    callback(null, true);
  }

  // Api.post(`/users_providers_calls`, params).send((res) => {
  //   if (res.type !== "error") {
  //     callback(res, false);
  //   } else {
  //     callback(null, true);
  //   }
  // });
};

export const sendOtp = (phone, action, callback) => {
  let params = {
    phone: phone,
    key: "zemidi",
  };

  axios
    .post(`${_SMS_URL}/sendOtp`, params)
    .then((res) => {
      if (res.status == 200) {
        saveSmsLog(action);
        callback({ data: res.data }, false);
      } else {
        callback(null, true);
      }
    })
    .catch((e) => {
      callback(null, true);
    });
};

export const verifyOtp = async (otp, otpDetails, callback) => {
  try {
    let res = await axios.post(`${_SMS_URL}/verifyOtp`, { otpDetails, otp });
    if (res.data == "Success") {
      callback({ success: true }, false);
    } else {
      callback({ success: false }, true);
    }
  } catch (err) {
    callback({ success: false }, true);
  }
};

export const signOut = (setUser, setSession, navigation, setIsLoading) => {
  navigation.navigate("Home");
  setUser({});
  setSession({});
  setState({ referralCode: null });
  setState({ pushNotification: [] });
  setState({ showNotificationBadge: false });
  setState({ selectedWebHeader: "Home" });
  showToast("You have signed out successfully!");

  // setIsLoading(false);

  // AppStore.reset();
  // setState({ showWelcome: false });
  // AsyncStorage.clear();
};

export const saveSmsLog = async (action) => {
  let logParams = {
    project: "zemidi",
    type: "otp",
    action: action,
  };
  try {
    let res = await axios.post(`${_SMS_LOG_URL}`, logParams);
    // console.log("res", res);
  } catch (e) {
    // console.log("e", e);
  }
};
