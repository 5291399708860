import Api from "services/Api";
import { hashPassword } from "config/password-hash";
import axios from "axios";
import { _BASE_URL2, _DATABASE } from "config/url";
import { query } from "config/query";

export const updatePassword = async (phone, password, callback) => {
  let hash = await hashPassword(password);

  const checkRes = await axios.post(
    _BASE_URL2,
    {
      sql: query.checkUserByPhone.query,
      sqlParams: [phone],
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.checkUserByPhone.token,
      },
    }
  );

  if (checkRes?.data?.err == undefined) {
    if (checkRes?.data?.result.length) {
      let params = [hash];
      let user = checkRes?.data?.result[0];
      let userSession = checkRes?.data?.resultToken;

      const body = {
        sql: query.updateUserPassword.query,
        sqlParams: params,
        session: user,
      };
      const headers = {
        db: _DATABASE,
        sqlToken: query.updateUserPassword.token,
        sessionToken: userSession,
      };

      const res = await axios.post(_BASE_URL2, body, {
        headers: headers,
      });

      if (res?.data?.err == undefined) {
        if (res?.data?.result?.affectedRows > 0) {
          callback({ reset: true }, false);
        } else {
          callback(null, true);
        }
      } else {
        callback(null, true);
      }
    } else {
      callback({ invalid: true }, false);
    }
  } else {
    callback({ invalid: true }, false);
  }

  // Api.get(`/users?filter=phone,eq,${phone}`).send((user) => {
  //   if (user.type !== "error") {
  //     if (user.records.length && user.records[0].phone == phone) {
  //       Api.put(`/users/${user.records[0].id}`, params).send((res) => {
  //         if (res.type !== "error") {
  //           callback({ reset: true }, false);
  //         } else {
  //           callback(null, true);
  //         }
  //       });
  //     } else {
  //       callback({ invalid: true }, false);
  //     }
  //   } else {
  //     callback(null, true);
  //   }
  // });
};
