import colors from "config/colors";
import { Icon, Input, InputRightAddon, Pressable } from "native-base";
import React from "react";
import { DatePickerModal } from "react-native-paper-dates";
import { FontAwesome5 } from "assets/icons";

const DatePickerWeb = ({ displayDate, placeholder, handleDate }: any) => {
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      handleDate(params.date);
      setOpen(false);
    },
    [setOpen, setDate]
  );

  return (
    <>
      <Pressable onPress={() => setOpen(true)}>
        <Input
          h="12"
          w="full"
          isReadOnly
          placeholder={placeholder}
          variant="outline"
          isRequired
          rounded="md"
          size="xl"
          bgColor={colors.white}
          focusOutlineColor="white"
          value={displayDate || ""}
          InputRightElement={
            <InputRightAddon w="20%" h="full" bgColor="gray.300">
              <Icon
                size="5"
                color={colors.primary}
                as={<FontAwesome5 name="calendar-alt" />}
              />
            </InputRightAddon>
          }
        />
      </Pressable>
      <DatePickerModal
        locale="en"
        mode="single"
        visible={open}
        onDismiss={onDismissSingle}
        date={date}
        onConfirm={onConfirmSingle}
        onChange={onConfirmSingle}
        saveLabel=" " // optional
        saveLabelDisabled={true} // optional, default is false
        // uppercase={false} // optional, default is true
        label="Select date" // optional
        // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
        // startYear={2000} // optional, default is 1800
        endYear={new Date().getFullYear()} // optional, default is 2200
        // closeIcon="close" // optional, default is "close"
        // editIcon="pencil" // optional, default is "pencil"
        // calendarIcon="calendar" // optional, default is "calendar"
      />
    </>
  );
};

export default DatePickerWeb;
