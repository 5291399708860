import _APP_CONFIG from "config/app";
import React from "react";
import { Box, Circle, Divider, FlatList, HStack, Icon, Image, Text, VStack } from "native-base";
import AppStore from "AppStore";
import colors from "config/colors";
import { Fontisto, MaterialCommunityIcons, MaterialIcons } from "assets/icons";
import { Linking, TouchableOpacity } from "react-native";
import { donorCalled } from "./actions";
import { showToast } from "widgets";
import { checkEligibility } from "AppActions";
import { _ZEMIDI_HELPLINE } from "config/defaults";

const DonorCard = ({ donor }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [user] = AppStore("user");
  const [isOpen, setIsOpen] = AppStore("isOpen");
  const [isLoading, setIsLoading] = AppStore("isLoading");

  const handleCall = (donorId: any) => {
    setIsLoading(true);
    donorCalled(user, donorId, (res: any, err: any) => {
      if (!err && res.data) {
        setIsLoading(false);
        Linking.openURL(`tel:${_ZEMIDI_HELPLINE}`);
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <Box
      w={isBigScreen ? 600 : "full"}
      borderRadius="2xl"
      borderWidth="1"
      borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
      backgroundColor="white"
      py="4"
      my="2"
      mx={isBigScreen ? 1 : 0}
      flex={!isBigScreen ? "1" : isBigScreen ? (donor.length % 2 == 0 ? "1" : "1/2") : null}
      justifyContent="center"
    >
      <HStack alignItems="center">
        <Box flex="1" overflow="hidden" alignItems="center" justifyContent="center" p="1">
          {donor.image ? (
            <Image
              size="65"
              rounded="full"
              source={{
                uri: `${_APP_CONFIG._IMAGE_URL}/${donor.image}`,
              }}
              alt="loading"
              resizeMode="cover"
            />
          ) : (
            <Image size={65} source={require("assets/images/no-profile.png")} alt="loading" resizeMode="contain" />
          )}
        </Box>
        <VStack space="1" flex="3">
          <Text fontSize="md" fontWeight="medium" noOfLines={2} lineHeight="sm">
            {donor.name}
          </Text>
          <HStack p="1" w="16" alignItems="center">
            <Circle bg={colors.lightGrayish} size="5" mr="2">
              <Icon size="3" color={colors.primary} as={<Fontisto name="blood-drop" />} ml="1" />
            </Circle>
            <Text fontSize="sm" fontWeight="medium" color={colors.primary}>
              {donor.blood_group}
            </Text>
          </HStack>
          {donor.last_donation_date ? (
            checkEligibility(donor) ? (
              <HStack p="1" alignItems="center" flexWrap="wrap">
                <Circle bg={colors.lightGrayish} size="5" mr="2">
                  <Icon size="3" color={colors.primary} as={<MaterialCommunityIcons name="cancel" />} />
                </Circle>
                <Text fontSize="xs" fontWeight="medium" color={colors.darkGrayish}>
                  Recently Donated
                </Text>
              </HStack>
            ) : null
          ) : null}
        </VStack>
        <Divider orientation="vertical" />
        <VStack alignItems="center" space="3" flex="1">
          <TouchableOpacity
            onPress={() =>
              Object.keys(user).length
                ? checkEligibility(donor)
                  ? showToast("Unavailable. Recently Donated!")
                  : handleCall(donor.id)
                : setIsOpen(!isOpen)
            }
          >
            <Circle bg="gray.200" size="sm">
              <Icon size="8" color="green.700" as={<MaterialIcons name="call" />} />
            </Circle>
          </TouchableOpacity>
        </VStack>
      </HStack>
    </Box>
  );
};

const DonorsList = ({ donors, handlePagination, listHeaderComponent, listFooterComponent }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <FlatList
      data={donors}
      showsVerticalScrollIndicator={false}
      numColumns={isBigScreen ? 2 : 1}
      onEndReached={handlePagination}
      onEndReachedThreshold={0.5}
      ListHeaderComponent={listHeaderComponent}
      ListFooterComponent={listFooterComponent}
      renderItem={({ item }: any) => {
        let donor = item;
        return <DonorCard donor={donor} />;
      }}
      keyExtractor={(donor: any) => donor.id}
    />
  );
};

export default DonorsList;
