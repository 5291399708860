import React from "react";
import { Box, Image } from "native-base";

const AuthHeader = () => {
  return (
    <Box
      h="56"
      w="full"
      bg="white"
      roundedBottom="3xl"
      pt="12"
      overflow="hidden"
    >
      <Image
        source={require("assets/images/top-image.png")}
        alt="loading"
        size="full"
        resizeMode="contain"
      />
    </Box>
  );
};

export default AuthHeader;
