import _APP_CONFIG from "config/app";
import { Entypo } from "assets/icons";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import { Box, Circle, HStack, Icon, Image, ScrollView, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { LoadingAnimation } from "widgets";
import { getHealthCard } from "./actions";
import IssuedCard from "./issued-card";
import UpdateCard from "./update-card";

const HealthCard = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [user] = AppStore("user");
  const [session] = AppStore("session");
  const [isLoading, setIsLoading] = useState(true);
  const [card, setCard] = useState<any>({});
  const [noCard, setNoCard] = useState<any>(false);

  useEffect(() => {
    getHealthCard(user, session, (res: any, err: any) => {
      // if (!err) {
      if (Object.keys(res?.data).length) {
        setCard(res.data);
        // setNoCard(false);
      } else {
        setNoCard(true);
      }
      setIsLoading(false);
      // } else {
      // setIsLoading(false);
      // }
    });
  }, []);

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box>
          <Box
            justifyContent="center"
            alignItems="center"
            h="72"
            w="full"
            bg="white"
            roundedBottom="3xl"
            overflow="hidden"
          >
            <Image
              source={{
                uri: `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/health-card-terms.png`,
              }}
              alt="loading"
              size="full"
              resizeMode="contain"
            />
          </Box>

          <VStack
            py="10"
            justifyContent="center"
            alignItems={isBigScreen ? "center" : "flex-start"}
            px="10"
            space="6"
            mx={isBigScreen ? webStyle.xNum : 0}
          >
            <HStack w="full" alignItems="center" justifyContent={isBigScreen ? "center" : "space-between"}>
              <Text color="white" fontSize={`${isBigScreen ? "4xl" : "xl"}`} fontWeight="bold">
                {card?.product
                  ? card?.product != "HAI-199"
                    ? "Health Card"
                    : "Health Assistance and Information"
                  : "Health Assistance and Information"}
              </Text>
              {!isBigScreen ? (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                  </Circle>
                </TouchableOpacity>
              ) : null}
            </HStack>
            {Object.keys(card).length && !noCard ? (
              <IssuedCard card={card} />
            ) : noCard ? (
              <UpdateCard setCard={setCard} setNoCard={setNoCard} setIsLoading={setIsLoading} />
            ) : null}
          </VStack>
        </Box>
      </ScrollView>
      <LoadingAnimation loading={isLoading} />
    </Box>
  );
};

export default HealthCard;
