import colors from "config/colors";
import { StatusBar } from "expo-status-bar";
import { Box, Center, Circle, Image, Modal, View } from "native-base";
import React from "react";
import { StyleSheet } from "react-native";

const LoadingAnimation = ({ loading }: any) => {
  return loading ? (
    <View style={styles.loading}>
      <Circle bg={colors.whiteTranslucent} p="6">
        <Image
          source={require("assets/images/loader-hearbeat.gif")}
          alt="loading"
          // alignSelf="center"
          resizeMode="contain"
          size="24"
        />
      </Circle>
    </View>
  ) : null;
};

export default LoadingAnimation;
const styles = StyleSheet.create({
  loading: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    // backgroundColor: colors.transparent,
  },
});
