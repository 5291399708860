import React from "react";
import { TouchableOpacity } from "react-native";
import {
  Box,
  Circle,
  HStack,
  Icon,
  ScrollView,
  Stack,
  Text,
  VStack,
} from "native-base";

import { Entypo, SupportImage } from "assets/icons";
import colors from "config/colors";
import AppStore from "AppStore";
import { LinkToPage } from "widgets";

const Support = ({ navigation }: any) => {
  const [showWelcome, setShowWelcome] = AppStore("showWelcome");

  return (
    <ScrollView py="7" flex={1} bg="white">
      <Stack w="full" p="4" alignItems="flex-start">
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Icon
            size="8"
            color={colors.black}
            as={<Entypo name="chevron-left" />}
          />
        </TouchableOpacity>
      </Stack>
      <Box h="72">
        <SupportImage />
      </Box>
      <VStack py="10" justifyContent="center" alignItems="flex-start" space="8">
        <Stack w="full" alignItems="center" space="2">
          <Text color={colors.black} fontSize="3xl" fontWeight="bold">
            SUPPORT
          </Text>
          <Text
            mb="8"
            textAlign="center"
            color={colors.darkGrayish}
            fontSize="md"
          >
            Personalized 24*7 support
          </Text>
          {/* <TouchableOpacity
            onPress={() => {
              navigation.navigate("Zemidi");
              setShowWelcome(false);
            }}
          > */}
          <LinkToPage route="Zemidi">
            <Box
              alignItems="center"
              justifyContent="center"
              bgColor={colors.primary}
              h={12}
              w="32"
              rounded="md"
            >
              <Text color={colors.white} fontSize="lg">
                Get Started
              </Text>
            </Box>
          </LinkToPage>
          <HStack space="4" mt="16">
            <Circle bgColor={colors.accent} size={3}></Circle>
            <Circle bgColor={colors.accent} size={3}></Circle>
            <Circle bgColor={colors.primary} size={3}></Circle>
          </HStack>
        </Stack>
      </VStack>
    </ScrollView>
  );
};

export default Support;
