import axios from "axios";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";
import { showToast } from "widgets";

export const updateUser = async (setUser, user, session, fields, setLoading) => {
  setLoading(true);
  let params = [fields.name, fields.phone, fields.email];

  const body = {
    sql: query.updateUser.query,
    sqlParams: params,
    session: session?.userSession,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.updateUser.token,
    sessionToken: session?.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.affectedRows > 0) {
      user.name = fields.name;
      user.phone = fields.phone;
      user.email = fields.email ? fields.email : null;
      user.image = fields.image ? fields.image : null;
      setUser({ ...user });
      setLoading(false);
      showToast("Profile Updated!");
    } else {
      setLoading(false);
    }
  } else {
    setLoading(false);
  }

  // Api.put(`/users/${user.id}`, params).send((res) => {
  //   if (res.type !== "error") {
  //     user.name = fields.name;
  //     user.phone = fields.phone;
  //     user.email = fields.email ? fields.email : null;
  //     user.image = fields.image ? fields.image : null;
  //     setUser({ ...user });
  //     setLoading(false);
  //     showToast("Profile Updated!");
  //   }
  //   setLoading(false);
  // });
};

export const updateUserPhoto = async (user, setUser, session, setImage, setIsImageLoading, image) => {
  let params = [image];

  const body = {
    sql: query.updateUserImage.query,
    sqlParams: params,
    session: session?.userSession,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.updateUserImage.token,
    sessionToken: session?.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.affectedRows > 0) {
      user.image = image;
      setImage(image);
      setUser({ ...user });
      setIsImageLoading(false);
    } else {
      setIsImageLoading(false);
    }
  } else {
    setIsImageLoading(false);
  }

  // Api.put(`/users/${user.id}`, params).send((res) => {
  //   if (res.type !== "error") {
  //     user.image = image;
  //     setImage(image);
  //     setUser({ ...user });
  //     setIsImageLoading(false);
  //   }
  //   setIsImageLoading(false);
  // });
};
