import { View, Text } from "react-native";
import React from "react";
import { Box, Image } from "native-base";

const ZemidiLogo = ({ ml = "3" }) => {
  return (
    <Box flex={1} justifyContent="center" overflow="hidden">
      <Image source={require("assets/images/zemidi-logo.png")} alt="zemidi" size={48} ml={ml} resizeMode="contain" />
    </Box>
  );
};

export default ZemidiLogo;
