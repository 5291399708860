import AppStore from "AppStore";
const { getState, setState } = AppStore;

export const linkToPage = ({ navigation, route, params }: any) => {
  if (route == "goBack") {
    navigation.goBack();
  } else if (params != undefined) {
    // setState({ params: params });
    navigation.navigate(route, params);
  } else {
    navigation.navigate(route);
  }
};
