import { Linking, TouchableOpacity } from "react-native";
import React from "react";
import { Box, HStack, Icon, Text } from "native-base";
import colors from "config/colors";
import { AntDesign } from "assets/icons";
import { _ZEMIDI_HELPLINE } from "config/defaults";

const CallUs = () => {
  return (
    <TouchableOpacity onPress={() => Linking.openURL(`tel:${_ZEMIDI_HELPLINE}`)}>
      <HStack pr="1">
        <Box pr="2">
          <Text fontWeight="medium" color={colors.primary}>
            Call Us
          </Text>
        </Box>
        <Icon size="6" color={colors.primary} as={<AntDesign name="customerservice" />} />
      </HStack>
    </TouchableOpacity>
  );
};

export default CallUs;
