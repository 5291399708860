import _APP_CONFIG from "config/app";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Center, Icon, Image, Input, Stack, Text, VStack, FlatList } from "native-base";
import { AntDesign } from "assets/icons";
import colors from "config/colors";

import { searchData } from "./actions";

import { LinkToPage, LoadingAnimation, NoData } from "widgets";
import AppStore from "AppStore";
import HeaderBack from "widgets/header-back";
import { webStyle } from "config/web-style";
import debounce from "lodash.debounce";

const SearchResults = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [results, setResults] = useState([]);
  const [resultsCategory, setResultsCategory] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const debouncedFetchData = useCallback(
    debounce((nextValue: any) => searchData(nextValue, setResults, setIsLoading, setResultsCategory), 300),
    []
  );

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  return (
    <Box flex={1} bg={colors.white} px={isBigScreen ? webStyle.xNum : 4}>
      <HeaderBack navigation={navigation} />
      <Stack w="full" p="4" alignItems="flex-start">
        <Input
          onChangeText={(e: any) => debouncedFetchData(e)}
          autoFocus
          returnKeyType="search"
          w="full"
          h="10"
          variant="unstyled"
          rounded="full"
          placeholder="Search"
          InputLeftElement={<Icon ml="2" size="6" color="black" as={<AntDesign name="search1" />} />}
          borderWidth={0}
          backgroundColor="gray.100"
        />
      </Stack>
      {isLoading ? (
        <LoadingAnimation loading={isLoading} />
      ) : !isLoading && !results.length ? (
        <NoData />
      ) : (
        <Box
          flex={1}
          py="4"
          px={results.length == 1 ? "4" : "0"}
          justifyContent="center"
          alignItems={results.length > 1 ? "center" : "flex-start"}
        >
          <FlatList
            data={results}
            showsVerticalScrollIndicator={false}
            numColumns={2}
            renderItem={({ item }) => {
              return (
                <Box
                  borderWidth="1"
                  borderRadius="lg"
                  w={[150, "40"]}
                  rounded="3xl"
                  overflow="hidden"
                  borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
                  p="3"
                  m="1"
                >
                  {/* <TouchableOpacity
                    onPress={
                      resultsCategory == "hospitals"
                        ? () =>
                            navigation.navigate("PackageDetails", {
                              id: item.id,
                              name: item.name,
                            })
                        : () =>
                            navigation.navigate("HospitalDetails", {
                              id: item.id,
                              name: item.name,
                            })
                    }
                  > */}
                  <LinkToPage
                    route={resultsCategory == "hospitals" ? "PackageDetails" : "Booking"}
                    params={
                      resultsCategory == "hospitals"
                        ? {
                            id: item.id,
                            name: item.name,
                          }
                        : { id: item.id, name: item.name }
                    }
                  >
                    <VStack justifyContent="center" alignItems="center" space="2">
                      <Center>
                        {item.image ? (
                          <Image
                            size={120}
                            borderRadius="full"
                            source={{
                              uri: `${_APP_CONFIG._IMAGE_URL}/${item.image}`,
                            }}
                            resizeMode="cover"
                            alt="loading"
                          />
                        ) : (
                          <Image
                            source={require("assets/images/no-image.png")}
                            size={120}
                            resizeMode="contain"
                            alt="loading"
                          />
                        )}
                      </Center>
                      <Stack alignItems="center">
                        <Text textAlign="center" fontSize="sm" fontWeight="medium" color={colors.primary}>
                          {item.name}
                        </Text>
                      </Stack>
                    </VStack>
                  </LinkToPage>
                </Box>
              );
            }}
            keyExtractor={(item: any) => item.id}
          />
        </Box>
      )}
    </Box>
  );
};

export default SearchResults;
