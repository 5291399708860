import { Ionicons } from "assets/icons";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import { Box, FlatList, Icon, Image } from "native-base";
import React, { useEffect } from "react";
import { TouchableOpacity } from "react-native";
import NotificationCard from "./notification-card";

const PushNotifications = ({ navigation }: any) => {
  const [pushNotification] = AppStore("pushNotification");
  const [showNotificationBadge, setShowNotificationBadge] = AppStore(
    "showNotificationBadge"
  );
  const [initialRoute, setInitialRoute] = AppStore("initialRoute");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isBigScreen] = AppStore("isBigScreen");

  useEffect(() => {
    setShowNotificationBadge(false);
  }, []);

  useEffect(() => {
    if (initialRoute == "Notifications") {
      navigation.setOptions({
        headerLeft: () => (
          <TouchableOpacity
            onPress={() => {
              setInitialRoute("Zemidi");
              navigation.replace("Zemidi", { screen: "Home" });
            }}
          >
            <Icon
              size={12}
              color={colors.primary}
              as={<Ionicons name="ios-caret-back-circle" />}
              ml={isBigScreen ? webStyle.xNum : 3}
            />
          </TouchableOpacity>
        ),
      });
    }
  }, []);

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  return (
    <Box flex="1" bg={colors.appInnerBg}>
      {pushNotification.length ? (
        <FlatList
          data={pushNotification}
          showsVerticalScrollIndicator={false}
          numColumns={1}
          renderItem={({ item }) => {
            return <NotificationCard item={item} />;
          }}
          keyExtractor={(item: any, index: any) => index}
        />
      ) : (
        <Box h="full" w="full" bg={colors.white} overflow="hidden">
          <Image
            source={require("assets/images/no-data.png")}
            alt="loading"
            size="full"
            resizeMode="contain"
          />
        </Box>
      )}
    </Box>
  );
};

export default PushNotifications;
