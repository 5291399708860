import AppStore from "AppStore";
import { Image } from "native-base";
import React from "react";

const DiscountBanner = () => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <Image
      source={
        isBigScreen
          ? require("assets/images/discount-banner-white.png")
          : require("assets/images/discount-banner-white.png")
      }
      alt="loading"
      size="full"
      resizeMode="contain"
    />
  );
};

export default DiscountBanner;
