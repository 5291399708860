import axios from "axios";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getPackagesByHospital = async (page, hospitalId, selected, callback) => {
  let paramsCount = [];
  let params = [];
  let countQuery = "";
  let countQueryToken = "";
  let dataQuery = "";
  let dataQueryToken = "";

  if (Object.keys(selected).length && selected.id > 0) {
    let id = selected.id;
    paramsCount = [hospitalId, id];
    params = [hospitalId, id, (page - 1) * 20];
    countQuery = query?.getHospitalPackagesByHospitalIdHciCount?.query;
    countQueryToken = query?.getHospitalPackagesByHospitalIdHciCount?.token;
    dataQuery = query?.getHospitalPackagesByHospitalIdHci?.query;
    dataQueryToken = query?.getHospitalPackagesByHospitalIdHci?.token;
  } else {
    paramsCount = [hospitalId];
    params = [hospitalId, (page - 1) * 20];
    countQuery = query?.getHospitalPackagesByHospitalIdCount?.query;
    countQueryToken = query?.getHospitalPackagesByHospitalIdCount?.token;
    dataQuery = query?.getHospitalPackagesByHospitalId?.query;
    dataQueryToken = query?.getHospitalPackagesByHospitalId?.token;
  }

  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: countQuery,
      sqlParams: paramsCount,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: countQueryToken,
      },
    }
  );
  // console.log("resCount", resCount);
  const body = {
    sql: dataQuery,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: dataQueryToken,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res", res);

  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      // console.log(" res?.data?.result", res?.data?.result);
      callback(
        {
          // data: [],
          data: res?.data?.result,
          totalData: resCount?.data?.result[0]?.["count(*)"],
        },
        false
      );
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }

  // let query;
  // if (Object.keys(selected).length && selected.id > 0) {
  //   let id = selected.id;
  //   query = `/hospitals_packages?join=packages&join=hospitals&join=packages_categories&filter=hospital_id,eq,${hospitalId}&filter=hospital_category_id,eq,${id}&filter=deleted,eq,0&page=${page},10`;
  // } else {
  //   query = `/hospitals_packages?join=packages&join=hospitals&join=packages_categories&filter=hospital_id,eq,${hospitalId}&filter=deleted,eq,0&page=${page},10`;
  // }

  // Api.get(query).send((res) => {
  //   // console.log("result", res);
  //   if (res.type !== "error") {
  //     callback({ data: res.records, totalData: res.results }, false);
  //   } else {
  //     callback({ data: [], totalData: 0 }, true);
  //   }
  // });
};

export const filterData = async (id, setIsLoading, setPackagesByHospital, hospitalId) => {
  setIsLoading(true);
  let params = [hospitalId, id];
  const body = {
    sql: query.getAllHospitalPackagesByHospitalIdHci.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllHospitalPackagesByHospitalIdHci.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res.data.err == undefined) {
    if (res.data.result.length) {
      setPackagesByHospital(res.data.result);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  } else {
    setIsLoading(false);
  }

  // Api.get(
  //   `/hospitals_packages?join=packages&join=hospitals&join=packages_categories&filter=hospital_id,eq,${hospitalId}&filter=hospital_category_id,eq,${id}`
  // ).send((res) => {
  //   // console.log("res", res);
  //   if (res.type !== "error") {
  //     setPackagesByHospital(res.records);
  //     setIsLoading(false);
  //   }
  //   setIsLoading(false);
  // });
};

export const getCategoriesByHospital = async (setHospitalCategories, hospitalId, setIsLoading) => {
  const body = {
    sql: query.getAllHospitalsCategories.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllHospitalsCategories.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  if (res?.data?.err == undefined) {
    setHospitalCategories(res.data.result);
  } else {
    setHospitalCategories([]);
  }
};

// export const getCategoriesByHospital = (setHospitalCategories, hospitalId, setIsLoading) => {
//   Api.get(
//     // `/hospitals_packages?join=hospitals_categories&filter=hospital_id,eq,${hospitalId}`
//     `/hospitals_categories`
//   ).send((res) => {
//     if (res.type !== "error") {
//       // console.log("resC", res.records);
//       // let data = [];
//       // for (let i in res.records) {
//       //   console.log("i", res.records[i]);
//       //   let exists = data.find((e) => e.id == res.records[i].id);
//       //   console.log("exists", exists);
//       //   if (exists !== undefined) {
//       //     data.push(res.records[i]);
//       //   }
//       // }
//       // console.log("data", data);
//       setHospitalCategories(res.records);
//       // setHospitalCategories(res.records.map((e) => e.hospital_category_id));

//       // setHospitalCategories(res.records);
//     }
//   });
// };
