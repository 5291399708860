import _APP_CONFIG from "config/app";
import { Dimensions, StyleSheet } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { Box } from "native-base";
import MapView, { Marker } from "react-native-maps";
import AppStore from "AppStore";
import { getLatLong } from "AppActions";
import LoadingAnimation from "widgets/loading-animation";

const Map = ({ route, navigation }: any) => {
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [latLong] = AppStore("latLong");
  const [markers, setMarkers] = useState<any>([]);
  const [initialCoords, setInitialCoords] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const mapRef: any = useRef(null);

  let locations: any = [];
  let providers = route.params.providers;

  useEffect(() => {
    getLatLong();
    if (providers.length) {
      for (let i in providers) {
        let latLong = providers[i].provider_id.lat_long;
        // let latLong = JSON.parse(providers[i].provider_id.lat_long);
        if (latLong && latLong.latitude !== null && latLong.longitude != null) {
          let lat = latLong.latitude;
          let long = latLong.longitude;
          let name = providers[i].provider_id.name;
          locations.push({ title: name, latitude: lat, longitude: long });
        }
      }
      setMarkers(locations);
      setIsLoading(false);
    }
  }, [route]);

  useEffect(() => {
    if (Object.keys(latLong).length) {
      let coords = { ...latLong };
      coords.latitudeDelta = 0.02;
      coords.longitudeDelta = 0.02;
      setInitialCoords(coords);
    }
  }, [latLong]);

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  const fitMarkers = () => {
    setTimeout(() => {
      if (mapRef && mapRef.current) {
        mapRef.current.fitToCoordinates(markers, {
          edgePadding: {
            bottom: 200,
            right: 50,
            top: 150,
            left: 50,
          },
          animated: true,
        });
      }
    }, 1000);
  };

  return (
    <Box flex="1" alignItems="center" justifyContent="center">
      {markers.length && initialCoords ? (
        <MapView
          style={styles.map}
          initialRegion={initialCoords}
          ref={mapRef}
          onMapReady={fitMarkers}
          showsUserLocation={true}
          loadingEnabled={true}
          // mapType="hybrid"
        >
          {markers.map((marker: any, index: any) => (
            <Marker
              key={index}
              coordinate={marker}
              title={marker.title}
              image={{
                uri: `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/ambulance.png`,
              }}
            />
          ))}
        </MapView>
      ) : (
        <LoadingAnimation loading={isLoading} />
      )}
    </Box>
  );
};

export default Map;
const styles = StyleSheet.create({
  map: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
});
