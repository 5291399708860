export const _BASE_URL = "https://db.zemidi.arodos.com/records";
export const _URL = "https://db.zemidi.arodos.com";

export const _PACKAGE_NAME = "com.zemidi.app002";
export const _APP_URL = "https://play.google.com/store/apps/details?id=com.zemidi.app002";

export const _SMS_URL = "https://arodos-notifications.vercel.app";
// export const _SMS_URL = "https://notifications.deta.dev";
export const _SMS_LOG_URL = "https://arodos-notifications.vercel.app/smsLog";
export const _EMAIL_URL = "https://emails.arodos.com/php-mailer/zemidi/booking.php";

// export const _BASE_URL = "https://db.test.arodos.com/records";
// export const _URL = "https://db.test.arodos.com";

export const _BASE_URL2 = "https://frontql.backendservices.in/sql";
export const _DATABASE = "zemidi";
