import axios from "axios";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getHospitalsByPackage = async (page, packageId, selected, callback) => {
  let paramsCount = [];
  let params = [];
  let countQuery = "";
  let countQueryToken = "";
  let dataQuery = "";
  let dataQueryToken = "";

  if (Object.keys(selected).length) {
    let id = selected.id;
    if (id == "rating") {
      paramsCount = [packageId];
      params = [packageId, (page - 1) * 20];
      countQuery = query?.getHospitalPackagesByPackageIdRatingCount?.query;
      countQueryToken = query?.getHospitalPackagesByPackageIdRatingCount?.token;
      dataQuery = query?.getHospitalPackagesByPackageIdRating?.query;
      dataQueryToken = query?.getHospitalPackagesByPackageIdRating?.token;
    } else if (id == "asc") {
      paramsCount = [packageId];
      params = [packageId, (page - 1) * 20];
      countQuery = query?.getHospitalPackagesByPackageIdPriceACount?.query;
      countQueryToken = query?.getHospitalPackagesByPackageIdPriceACount?.token;
      dataQuery = query?.getHospitalPackagesByPackageIdPriceA?.query;
      dataQueryToken = query?.getHospitalPackagesByPackageIdPriceA?.token;
    } else {
      paramsCount = [packageId];
      params = [packageId, (page - 1) * 20];
      countQuery = query?.getHospitalPackagesByPackageIdPriceDCount?.query;
      countQueryToken = query?.getHospitalPackagesByPackageIdPriceDCount?.token;
      dataQuery = query?.getHospitalPackagesByPackageIdPriceD?.query;
      dataQueryToken = query?.getHospitalPackagesByPackageIdPriceD?.token;
    }
  } else {
    paramsCount = [packageId];
    params = [packageId, (page - 1) * 20];
    countQuery = query?.getHospitalPackagesByPackageIdCount?.query;
    countQueryToken = query?.getHospitalPackagesByPackageIdCount?.token;
    dataQuery = query?.getHospitalPackagesByPackageId?.query;
    dataQueryToken = query?.getHospitalPackagesByPackageId?.token;
  }

  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: countQuery,
      sqlParams: paramsCount,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: countQueryToken,
      },
    }
  );
  // console.log("resCount", resCount);
  const body = {
    sql: dataQuery,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: dataQueryToken,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res", res);

  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      // console.log(" res?.data?.result", res?.data?.result);
      callback(
        {
          // data: [],
          data: res?.data?.result,
          totalData: resCount?.data?.result[0]?.["count(*)"],
        },
        false
      );
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }

  // let query;
  // let filter;
  // let orderBy = "asc";
  // if (Object.keys(selected).length) {
  //   let id = selected.id;
  //   if (id == "rating") {
  //     filter = "rating";
  //     orderBy = "desc";
  //   } else if (id == "asc") {
  //     filter = "price";
  //     orderBy = "asc";
  //   } else {
  //     filter = "price";
  //     orderBy = "desc";
  //   }
  //   query = `/hospitals_packages?join=hospitals&join=packages&join=hospitals_categories&join=packages_categories&filter=package_id,eq,${packageId}&filter=deleted,eq,0&order=${filter},${orderBy}&page=${page},10`;
  // } else {
  //   query = `/hospitals_packages?join=hospitals&join=packages&join=hospitals_categories&join=packages_categories&filter=package_id,eq,${packageId}&filter=deleted,eq,0&order=id&page=${page},10`;
  // }

  // Api.get(query).send((res) => {
  //   if (res.type !== "error") {
  //     // console.log("res", res.records);
  //     callback({ data: res.records, totalData: res.results }, false);
  //   } else {
  //     callback({ data: [], totalData: 0 }, true);
  //   }
  // });
};
