import React from "react";
import { Circle, Icon } from "native-base";
import colors from "config/colors";
import { Ionicons } from "assets/icons";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";

const SearchIcon = ({ circleSize = 50, iconSize = 7 }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <Circle
      bg="gray.200"
      size={circleSize}
      mr={isBigScreen ? webStyle.xNum : 3}
    >
      <Icon
        size={iconSize}
        color={colors.black}
        as={<Ionicons name="ios-search" />}
      />
    </Circle>
  );
};

export default SearchIcon;
