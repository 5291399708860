import React, { useEffect, useState } from "react";
import { Box } from "native-base";
import colors from "config/colors";

import { ActivityIndicator } from "react-native";
import LoadingAnimation from "widgets/loading-animation";
import { getAllHospitals, getHospitalCategories, getHospitals } from "./actions";
import { MenuBar } from "widgets";
import HospitalsList from "screens/hospitals/hospitals-list";
import NoData from "widgets/no-data";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import { Footer } from "widgets";
import HospitalRegistrationImage from "./hospital-registration-image";

const Hospitals = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [hospitalsTypeTwo] = AppStore("hospitalsTypeTwo");
  const [hospitalCategories, setHospitalCategories] = useState([]);
  const [hospitals, setHospitals] = useState<any>([]);
  const [filteredHospitals, setFilteredHospitals] = useState<any>([]);
  const [totalHospitals, setTotalHospitals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hospitalPage, setHospitalPage] = useState(1);
  const [selected, setSelected] = useState({
    id: 0,
    name: "All",
  });

  const resetStates = () => {
    setIsLoading(true);
    setHospitalPage(1);
    setHospitals([]);
    setTotalHospitals(0);
  };

  const reload = () => {
    setIsLoading(true);
    setHospitalPage(1);
    setHospitals([]);
    setTotalHospitals(0);
    getCategories();
    setSelected({ id: 0, name: "All" });
  };

  useEffect(() => {
    // setCurrentPageInit(reload);
    const unsubscribe = navigation.addListener("focus", () => {
      //   setCurrentPageInit(reload);
    });
    return unsubscribe;
  }, []);

  //   useEffect(() => {
  //     getCategories();
  //   }, []);

  const getCategories = () => {
    getHospitalCategories((res: any, err: any) => {
      if (!err) {
        setHospitalCategories(res.data);
      }
    });
  };

  //   useEffect(() => {
  //     getAllHospitals((res: any, err: any) => {
  //       if (!err) {
  //         setHospitals(res.data);
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(false);
  //       }
  //     });

  // getHospitals(hospitalsTypeTwo, hospitalPage, null, (res: any, err: any) => {
  // 	if (!err) {
  // 		// if (selected.id > 0) {
  // 		let data = [];

  // 		for (let i in res.data) {
  // 			let exists = data.find(e => e.id == res.data[i].hospital_id.id);
  // 			if (!exists || exists == undefined) {
  // 				data.push({
  // 					id: res.data[i].hospital_id.id,
  // 					records: res.data[i],
  // 				});
  // 			}
  // 		}
  // 		setHospitals(data.map(e => e.records));
  // 		setTotalHospitals(data.length);
  // 		setIsLoading(false);
  // 		setIsLoadingMore(false);
  // 		// } else {
  // 		// 	setHospitals([...hospitals, ...res.data]);
  // 		// 	setTotalHospitals(res.totalData);
  // 		// 	setIsLoading(false);
  // 		// 	setIsLoadingMore(false);
  // 		// }
  // 	} else {
  // 		setIsLoading(false);
  // 		setIsLoadingMore(false);
  // 	}
  // });
  //   }, []);

  const handleSelected = (value: any) => {
    if (value.id !== selected.id) {
      resetStates();
      setSelected(value);
    }
  };

  const handlePagination = () => {
    if (hospitals.length < totalHospitals && !isLoadingMore) {
      setIsLoadingMore(true);
      setHospitalPage((hospitalPage) => hospitalPage + 1);
    }
  };

  const listFooterComponent = () => {
    return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
  };

  return (
    <Box flex={1} bgColor={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      {/* {hospitalCategories.length ? (
        <Box
          bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          mx={isBigScreen ? webStyle.xNum : 0}
        >
          <MenuBar
            data={hospitalCategories}
            handleSelected={handleSelected}
            selected={selected}
            field="categories"
          />
        </Box>
      ) : null} */}
      {/* <Box
				flex={1}
				justifyContent="center"
				alignItems={
					isBigScreen
						? hospitals.length > 5
							? "center"
							: "flex-start"
						: hospitals.length > 1
						? "center"
						: "flex-start"
				}
				px={!isBigScreen ? (hospitals.length > 5 ? "4" : "0") : webStyle.xNum}
				mx={isBigScreen ? webStyle.xNum : 0}
				bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
				pt={isBigScreen ? "4" : "2"}
			>
				{hospitals.length ? (
					<HospitalsList
						hospitals={hospitals}
						handlePagination={handlePagination}
						listFooterComponent={listFooterComponent}
						navigation={navigation}
						selected={selected}
					/>
				) : !hospitals.length && !isLoading ? (
					<NoData />
				) : null}
			</Box> */}
      <Box mt="5">
        <HospitalRegistrationImage />
      </Box>

      {/* <LoadingAnimation loading={isLoading} /> */}
    </Box>
  );
};

export default Hospitals;
