import _APP_CONFIG from "config/app";
import { Box, Circle, HStack, Icon, Image, Input, InputLeftAddon, ScrollView, Stack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import AppStore from "AppStore";
import { Linking, TouchableOpacity } from "react-native";
import { webStyle } from "config/web-style";
import colors from "config/colors";
import { checkMitra, getCoords, updateMitra } from "./actions";
import { Button, LoadingAnimation, showToast } from "widgets";
import { Entypo, FontAwesome, Ionicons } from "assets/icons";

const MitraLocation = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [mitraICard, setMitraICard] = AppStore("mitraICard");

  const [phone, setPhone] = useState<any>(null);
  const [hcmNo, setHcmNo] = useState<any>(null);
  const [location, setLocation] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(mitraICard).length) {
      setHcmNo(mitraICard.hcm_no);
      setPhone(mitraICard.phone_no);
    }
    getCoords(location, setLocation);
  }, [mitraICard]);

  const handleLoginMitra = () => {
    if (phone && hcmNo) {
      if (phone.match(/^[0-9]*$/) && phone.length == 10) {
        setIsLoading(true);
        checkMitra(hcmNo, phone, (res: any, error: any) => {
          if (!error) {
            setIsLoading(false);
            setMitraICard(res.data);
            showToast(res && res.message);
          } else {
            setIsLoading(false);
            setMitraICard({});
            showToast(res && res.message);
          }
        });
      }
    } else {
      showToast("Please fill required fields");
    }
  };

  const handleUpdate = () => {
    if (Object.keys(location).length) {
      setIsLoading(true);
      updateMitra(mitraICard.id, phone, location, (error: any, message: any) => {
        if (!error) {
          setIsLoading(false);
          showToast(message);
        } else {
          setIsLoading(false);
          showToast(message);
        }
      });
    }
  };

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box>
          <Box h="56" w="full" bg="white" roundedBottom="3xl" pt="12" overflow="hidden">
            <Image
              source={{
                uri: `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/healthcare-mitra.png`,
              }}
              alt="loading"
              size="full"
              resizeMode="contain"
            />
          </Box>
          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px="10"
            space="6"
            mx={isBigScreen ? webStyle.xNum : 0}
          >
            <HStack w="full" alignItems="center" justifyContent="space-between">
              <Box w="75%">
                <Text color="white" fontSize="lg" fontWeight="bold">
                  {Object.keys(mitraICard).length ? "Update Location" : "Link your Healthcare Mitra Card"}
                </Text>
              </Box>

              <TouchableOpacity onPress={() => navigation.replace("Zemidi", { screen: "Home" })}>
                <Circle bgColor={colors.white} size={10}>
                  <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                </Circle>
              </TouchableOpacity>
            </HStack>

            <Input
              h="12"
              w="full"
              keyboardType="phone-pad"
              placeholder="Mobile Number"
              maxLength={10}
              variant="unstyled"
              isRequired
              rounded="3xl"
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => setPhone(e)}
              value={(phone && phone.toString()) || ""}
              isReadOnly={Object.keys(mitraICard).length ? true : false}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" bgColor="gray.100">
                  <Text fontSize="lg" fontWeight="semibold" color={colors.primary}>
                    +91
                  </Text>
                </InputLeftAddon>
              }
            />

            <Input
              h="12"
              w="full"
              keyboardType="default"
              placeholder="HCM Card Number"
              maxLength={10}
              autoCapitalize="characters"
              variant="unstyled"
              isRequired
              rounded="3xl"
              size="xl"
              bgColor="white"
              onChangeText={(e: any) => setHcmNo(e)}
              value={hcmNo || ""}
              isReadOnly={Object.keys(mitraICard).length ? true : false}
              InputLeftElement={
                <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                  <Icon as={<Ionicons name="ios-card" />} size={5} color={colors.primary} />
                </InputLeftAddon>
              }
            />

            {Object.keys(mitraICard).length ? (
              <>
                <Input
                  w="full"
                  h="12"
                  type="text"
                  placeholder="Name"
                  variant="unstyled"
                  rounded="3xl"
                  size="xl"
                  bgColor="white"
                  value={mitraICard.name || ""}
                  isReadOnly
                  InputLeftElement={
                    <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                      <Icon as={<FontAwesome name="user" />} size={5} color={colors.primary} />
                    </InputLeftAddon>
                  }
                />
                <Stack w="full" alignItems="flex-end" space="1">
                  <Input
                    w="full"
                    h="12"
                    keyboardType="default"
                    type="text"
                    placeholder="Address"
                    variant="unstyled"
                    rounded="3xl"
                    size="lg"
                    bgColor="white"
                    isRequired
                    isReadOnly
                    value={location.address || "Detecting Location.."}
                    InputLeftElement={
                      <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                        <Icon as={<Ionicons name="ios-location-sharp" />} size={5} color={colors.primary} />
                      </InputLeftAddon>
                    }
                  />
                  <TouchableOpacity onPress={() => getCoords(location, setLocation)}>
                    <Text color={colors.white} fontSize="md" fontWeight="semibold">
                      Detect Location Again
                    </Text>
                  </TouchableOpacity>
                </Stack>

                <Box w="full">
                  <TouchableOpacity onPress={() => Linking.openURL("https://forms.zemidi.com/census/v1")}>
                    <Box w="full" alignItems="center" borderWidth="1" borderColor={colors.white} rounded="3xl" p="2">
                      <Text color={colors.white} fontSize="md" fontWeight="semibold">
                        Take Health Census
                      </Text>
                    </Box>
                  </TouchableOpacity>
                </Box>

                <Box w="full">
                  <TouchableOpacity onPress={() => Linking.openURL("https://forms.zemidi.com/sales/v1")}>
                    <Box w="full" alignItems="center" borderWidth="1" borderColor={colors.white} rounded="3xl" p="2">
                      <Text color={colors.white} fontSize="md" fontWeight="semibold">
                        Record Daily Sales
                      </Text>
                    </Box>
                  </TouchableOpacity>
                </Box>

                <Stack w="full" alignItems="flex-end" space="1">
                  <Button title="UPDATE LOCATION" onPress={handleUpdate} />
                </Stack>
              </>
            ) : (
              <>
                <Stack w="full" alignItems="flex-end" space="1">
                  <Button title="SAVE" onPress={handleLoginMitra} />
                </Stack>
              </>
            )}

            <LoadingAnimation loading={isLoading} />
          </VStack>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default MitraLocation;
