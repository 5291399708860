import React, { useState } from "react";
import { Box, Circle, HStack, Icon, Input, InputLeftAddon, ScrollView, Stack, Text, VStack } from "native-base";
import { Entypo, MaterialIcons } from "assets/icons";
import { Platform, TouchableOpacity } from "react-native";
import colors from "config/colors";
import { AuthHeader, Button, LoadingAnimation } from "widgets";
import { sendOtp, verifyOtp } from "AppActions";
import { updatePassword } from "./actions";
import AppStore from "AppStore";
import { showToast } from "widgets/nb-toast";
import { webStyle } from "config/web-style";

const ForgotPassword = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [showFields, setShowFields] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [togglePassword, setTogglePassword] = useState(false);
  const [otpDetails, setOtpDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const requestOtp = () => {
    if (fields.phone) {
      if (fields.phone.match(/^[0-9]*$/) && fields.phone.length == 10) {
        setShowFields(true);
        sendOtp(fields.phone, "forgot-password", (res: any, err: any) => {
          if (!err) {
            setOtpDetails(res.data);
          }
        });
      } else {
        showToast("Please enter valid phone no");
      }
    } else {
      showToast("Please enter phone no");
    }
  };
  const passwordReset = () => {
    if (Object.keys(fields).length && fields.phone && fields.password && fields.otp) {
      if (fields.phone.match(/^[0-9]*$/) && fields.phone.length == 10) {
        setIsLoading(true);
        verifyOtp(fields.otp, otpDetails, (res: any, err: any) => {
          if (!err && res.success) {
            updatePassword(fields.phone, fields.password, (res: any, err: any) => {
              if (!err) {
                if (res.reset) {
                  setIsLoading(false);
                  navigation.goBack();
                  if (Platform.OS !== "web") {
                    showToast("Password reset successfully!");
                  }
                }
                if (res.invalid) {
                  setIsLoading(false);
                  showToast("Phone number not registered!");
                }
              } else {
                setIsLoading(false);
                showToast("Please try again after sometime!");
              }
            });
          } else {
            setIsLoading(false);
            showToast("OTP verification failed!");
          }
        });
      } else {
        showToast("Please enter valid phone no");
      }
    } else {
      showToast("Please enter required fields");
    }
  };

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box>
          <AuthHeader />
          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px="10"
            space="6"
            mx={isBigScreen ? webStyle.xNum : 0}
          >
            <HStack w="full" alignItems="center" justifyContent="space-between">
              <Text color="white" fontSize="2xl" fontWeight="bold">
                Forgot Password
              </Text>
              {!isBigScreen ? (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                  </Circle>
                </TouchableOpacity>
              ) : null}
            </HStack>

            <Stack w="full" alignItems="flex-end" space="1">
              <Input
                h="12"
                w="full"
                variant="unstyled"
                keyboardType="phone-pad"
                placeholder="Mobile Number"
                maxLength={10}
                rounded="3xl"
                size="xl"
                bgColor="white"
                returnKeyType="go"
                onSubmitEditing={requestOtp}
                onChangeText={(e: any) => {
                  fields.phone = e;
                  setFields({ ...fields });
                }}
                value={fields.phone || ""}
                InputLeftElement={
                  <InputLeftAddon w="20%" h="full" bgColor="gray.100">
                    <Text fontSize="lg" fontWeight="semibold" color={colors.primary}>
                      +91
                    </Text>
                  </InputLeftAddon>
                }
              />

              {showFields ? (
                <TouchableOpacity onPress={() => requestOtp()}>
                  <Text color={colors.white} fontSize="lg" fontWeight="semibold">
                    Resend OTP
                  </Text>
                </TouchableOpacity>
              ) : null}
            </Stack>

            {showFields ? (
              <>
                <Input
                  w="full"
                  h="12"
                  keyboardType="phone-pad"
                  placeholder="OTP"
                  variant="unstyled"
                  rounded="3xl"
                  size="xl"
                  bgColor="white"
                  onChangeText={(e: any) => {
                    fields.otp = e;
                    setFields({ ...fields });
                  }}
                  value={fields.otp || ""}
                  InputLeftElement={
                    <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                      <Icon as={<MaterialIcons name="verified-user" />} size={5} color={colors.primary} />
                    </InputLeftAddon>
                  }
                />
                <Input
                  w="full"
                  h="12"
                  type={togglePassword ? "text" : "password"}
                  variant="unstyled"
                  placeholder="Password"
                  rounded="3xl"
                  size="xl"
                  bgColor="white"
                  returnKeyType="go"
                  onSubmitEditing={passwordReset}
                  onChangeText={(e: any) => {
                    fields.password = e;
                    setFields({ ...fields });
                  }}
                  value={fields.password || ""}
                  InputLeftElement={
                    <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                      <Icon as={<MaterialIcons name="lock-outline" />} size={5} color={colors.primary} />
                    </InputLeftAddon>
                  }
                  InputRightElement={
                    <InputLeftAddon borderColor={colors.white} h="full" fontSize="2xl" bgColor={colors.white}>
                      <TouchableOpacity onPress={() => setTogglePassword(!togglePassword)}>
                        <Icon
                          as={<MaterialIcons name={togglePassword ? "visibility" : "visibility-off"} />}
                          size={5}
                          color={colors.black}
                        />
                      </TouchableOpacity>
                    </InputLeftAddon>
                  }
                />
              </>
            ) : null}

            {showFields ? (
              <Button title="Reset Password" onPress={passwordReset} />
            ) : (
              <Button title="Request OTP" onPress={requestOtp} />
            )}
          </VStack>
        </Box>
        <LoadingAnimation loading={isLoading} />
      </ScrollView>
    </Box>
  );
};

export default ForgotPassword;
