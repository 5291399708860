import * as React from "react";
import Svg, { Path } from "react-native-svg";

const UserIcon = ({ color }) => (
  // <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -12 17 50" fill={color}>
  //   <Path d="M12 12a6 6 0 1 0-6-6 6.006 6.006 0 0 0 6 6Zm0-10a4 4 0 1 1-4 4 4 4 0 0 1 4-4ZM12 14a9.01 9.01 0 0 0-9 9 1 1 0 0 0 2 0 7 7 0 0 1 14 0 1 1 0 0 0 2 0 9.01 9.01 0 0 0-9-9Z" />
  // </Svg>
  <Svg
    width="30"
    height="30"
    viewBox="0 0 448 512"
    fill={color}
    xmlns="http://www.w3.org/2000/svg%22%3E"
  >
    <Path
      d="M224 256C294.7 256 352 198.69 352 128C352 57.31 294.7 0 224 0C153.3 0 96 57.31 96 128C96 198.69 153.3 256 224 256ZM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.44 15.52 511.97 34.66 511.97H413.36C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304Z"
      fill={color}
    />
  </Svg>
);

export default UserIcon;
