import _APP_CONFIG from "config/app";
import React, { useEffect, useState } from "react";
import { Box, Circle, HStack, Icon, Image, Input, InputLeftAddon, ScrollView, Text, VStack } from "native-base";
import { Entypo, FontAwesome, MaterialIcons } from "assets/icons";
import { Platform, TouchableOpacity } from "react-native";
import * as ImagePicker from "expo-image-picker";
import colors from "config/colors";
import { showFlow, showToast, LoadingAnimation, Button, LinkToPage } from "widgets";

import axios from "axios";
import AppStore from "AppStore";
import { updateUser, updateUserPhoto } from "./actions";
import moment from "moment";
import { webStyle } from "config/web-style";

const Profile = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [image, setImage] = useState("");
  const [user, setUser] = AppStore("user");
  const [session] = AppStore("session");
  const [fields, setFields] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    setFields({ ...user });
  }, [user]);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      // aspect: [4, 3],
      quality: 0.8,
    });
    // @ts-ignore
    if (!result.cancelled) {
      // @ts-ignore
      setImage(result.uri);
      prepareImage(result, setImage);
    }
  };

  const handleUpload = (formData: any) => {
    axios
      .post("https://uploads.zemidi.com/api", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          username: "zemidi",
          password: "zemidi",
        },
      })
      .then((res) => {
        updateUserPhoto(user, setUser, session, setImage, setIsImageLoading, res.data.files.image);
      })
      .catch((error) => {
        setIsImageLoading(false);
      });
  };

  const prepareImage = async (file: any, setImage: any) => {
    setIsImageLoading(true);
    let extension = file.uri.split(".").pop();
    let now = moment.now();
    const formData = new FormData();

    if (Platform.OS == "android") {
      formData.append("folder", "users");
      // @ts-ignore
      formData.append("image", {
        name: `${user.id}-${now}.${extension}`,
        type: `image/${extension}`,
        uri: file.uri,
      });
      handleUpload(formData);
    } else {
      fetch(file.uri)
        .then((res) => res.blob())
        .then((blob) => {
          let type = blob.type;
          let ext = type.split("/").pop();
          let name = `${user.id}-${now}.${ext}`;
          const file = new File([blob], name);
          formData.append("folder", "users");
          formData.append("image", file);
          handleUpload(formData);
        });
    }
  };

  const handleUpdate = () => {
    if (Object.keys(fields).length && fields.name) {
      updateUser(setUser, user, session, fields, setIsLoading);
    } else {
      showToast("Please fill required fields");
    }
  };

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        // mx={isBigScreen ? webStyle.xNum : 0}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box
        // mx={isBigScreen ? webStyle.authX : 0}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            h="72"
            w="full"
            bg="white"
            roundedBottom="3xl"
            // pt="12"
            overflow="hidden"
          >
            {Object.keys(user).length && user.image ? (
              !isImageLoading ? (
                <Image
                  source={{ uri: `${_APP_CONFIG._IMAGE_URL}/${user.image}` }}
                  alt="loading"
                  size="full"
                  resizeMode="cover"
                  onLoadEnd={() => setIsImageLoading(false)}
                />
              ) : (
                showFlow
              )
            ) : (
              <Image source={require("assets/images/profile-empty.png")} alt="loading" resizeMode="contain" size="56" />
            )}
          </Box>
          <TouchableOpacity onPress={() => pickImage()}>
            <Circle right="4" bottom="4" position="absolute" bgColor={colors.primary} size="12" rounded="full">
              <Icon as={<FontAwesome name="camera" />} color={colors.white} size="md" />
            </Circle>
          </TouchableOpacity>
          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px="10"
            space="6"
            mx={isBigScreen ? webStyle.xNum : 0}
          >
            <HStack w="full" alignItems="center" justifyContent="space-between">
              <Text color="white" fontSize="3xl" fontWeight="bold">
                Your Profile
              </Text>
              {!isBigScreen ? (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                  </Circle>
                </TouchableOpacity>
              ) : null}
            </HStack>
            {Object.keys(fields).length ? (
              <>
                <Input
                  w="full"
                  h="12"
                  type="text"
                  placeholder="Name"
                  variant="unstyled"
                  rounded="3xl"
                  size="xl"
                  bgColor="white"
                  onChangeText={(e: any) => {
                    fields.name = e;
                    setFields({ ...fields });
                  }}
                  value={fields.name || ""}
                  InputLeftElement={
                    <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                      <Icon as={<FontAwesome name="user" />} size={5} color={colors.primary} />
                    </InputLeftAddon>
                  }
                />

                <Input
                  isReadOnly={true}
                  h="12"
                  w="full"
                  placeholder="Mobile Number"
                  variant="unstyled"
                  rounded="3xl"
                  size="xl"
                  bgColor="white"
                  onChangeText={(e: any) => {
                    fields.phone = e;
                    setFields({ ...fields });
                  }}
                  value={(fields.phone && fields.phone.toString()) || ""}
                  InputLeftElement={
                    <InputLeftAddon
                      w="20%"
                      // children={"+91"}
                      h="full"
                      bgColor="gray.100"
                    >
                      <Text fontSize="lg" fontWeight="semibold" color={colors.primary}>
                        +91
                      </Text>
                    </InputLeftAddon>
                  }
                />

                <Input
                  w="full"
                  h="12"
                  keyboardType="email-address"
                  type="text"
                  placeholder="Email Id"
                  variant="unstyled"
                  rounded="3xl"
                  size="xl"
                  bgColor="white"
                  onChangeText={(e: any) => {
                    fields.email = e;
                    setFields({ ...fields });
                  }}
                  value={fields.email || ""}
                  InputLeftElement={
                    <InputLeftAddon w="20%" h="full" fontSize="2xl" bgColor="gray.100">
                      <Icon as={<MaterialIcons name="email" />} size={5} color={colors.primary} />
                    </InputLeftAddon>
                  }
                />
              </>
            ) : null}
            <VStack w="full" justifyContent="center" alignItems="center" space="2">
              <Button title="SAVE" onPress={handleUpdate} />
              <LinkToPage route="Privacy">
                <Text color={colors.white} fontSize="md" fontWeight="medium">
                  *Privacy Policy
                </Text>
              </LinkToPage>
            </VStack>
          </VStack>
        </Box>
        <LoadingAnimation loading={isLoading} />
      </ScrollView>
    </Box>
  );
};

export default Profile;
