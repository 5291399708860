import _APP_CONFIG from "config/app";
import { Box, Image } from "native-base";
import React from "react";
import LinkToPage from "widgets/link-to-page";
import LocationIcon from "widgets/location-icon";

const MapImage = ({ providers }: any) => {
  return (
    <>
      <Image
        mb="2"
        mt="4"
        w="full"
        size={350}
        borderRadius="xl"
        source={{
          uri: `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/map.png`,
        }}
        resizeMode="cover"
        alt="loading"
        flex="1"
        overflow="hidden"
      />
      <Box position="absolute">
        <LinkToPage route="Map" params={{ providers: providers }}>
          <LocationIcon />
        </LinkToPage>
      </Box>
    </>
  );
};

export default MapImage;
