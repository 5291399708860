import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  Circle,
  FlatList,
  HStack,
  Icon,
  Text,
} from "native-base";
import colors from "config/colors";

import { TouchableOpacity } from "react-native";
import { AntDesign } from "assets/icons";
import AppStore from "AppStore";
import LoadingAnimation from "widgets/loading-animation";
import { webStyle } from "config/web-style";

const Filter = ({ route, navigation }: any) => {
  const { header, filterData, selected, handleFilterData } = route.params;
  const [loading, showLoading] = AppStore("loading");
  const [isBigScreen] = AppStore("isBigScreen");

  const handleSelect = (item: any) => {
    handleFilterData(item);
    navigation.goBack();
  };

  useEffect(() => {
    if (Object.keys(selected).length) {
      handleChecked(selected);
    }
  }, [selected]);

  const handleChecked = (itemId: any) => {
    if (Object.keys(selected).length) {
      return selected.id == itemId ? true : false;
    }
  };

  return (
    <Box
      bgColor={isBigScreen ? colors.webOuterBg : colors.white}
      // w="70%"
      flex="1"
      // alignSelf="flex-end"
      // borderWidth="1"
      // borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
    >
      {filterData.length ? (
        <Box
          flex="1"
          bgColor={isBigScreen ? colors.webInnerBg : colors.white}
          mx={isBigScreen ? webStyle.xNum : 0}
        >
          <FlatList
            w="full"
            p="4"
            // mt="10"
            data={filterData}
            showsVerticalScrollIndicator={false}
            ListHeaderComponent={() => (
              <Box
                justifyContent="space-between"
                flexDirection="row"
                alignItems="center"
              >
                <Text fontSize="xl" fontWeight="medium">
                  {header}
                </Text>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Circle bg="gray.200" size={50} mr={3}>
                    <Icon
                      size={6}
                      color={colors.black}
                      as={<AntDesign name="close" />}
                    />
                  </Circle>
                </TouchableOpacity>
              </Box>
            )}
            renderItem={({ item }: any) => {
              return (
                <HStack space={6} p="2" my="1">
                  <Checkbox
                    shadow={2}
                    value={item.name}
                    isChecked={handleChecked(item.id)}
                    colorScheme={"danger"}
                    onChange={() => handleSelect(item)}
                  >
                    {item.name}
                  </Checkbox>
                </HStack>
              );
            }}
            keyExtractor={(item: any) => item.id}
          />
        </Box>
      ) : (
        <LoadingAnimation loading={loading} />
      )}
    </Box>
  );
};

export default Filter;
