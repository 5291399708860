import { ActivityIndicator } from "react-native";
import React, { useEffect, useState } from "react";
import { getProducts } from "./actions";
import { Box } from "native-base";
import { LinkToPage, LoadingAnimation, MenuBar, NoData, SearchIcon } from "widgets";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import AppAlert from "widgets/app-alert";
import { linkToPage } from "widgets/link-to-page/link-to-page";
import ProductsList from "./products-list";

const Products = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isOpen, setIsOpen] = AppStore("isOpen");
  const [isLoading, setIsLoading] = AppStore("isLoading");
  const [products, setProducts] = useState<any>([]);
  // const [specializations, setSpecializations] = useState<any>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState({
    id: 0,
    name: "All",
  });

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <LinkToPage route="ProductSearch">
          <SearchIcon />
        </LinkToPage>
      ),
    });
  }, []);

  // useEffect(() => {
  //   getDoctorsSpecialisations();
  // }, []);

  // const getDoctorsSpecialisations = () => {
  //   getSpecializations((res: any, err: any) => {
  //     if (!err) {
  //       setSpecializations(res.data);
  //     }
  //   });
  // };

  useEffect(() => {
    if (!products.length || products.length < totalProducts) {
      if (page == 1) {
        setIsLoading(true);
      }
      getProducts(page, selected.id, (res: any, err: any) => {
        if (!err) {
          if (selected.id > 0) {
            let data = [];
            for (let i in res.data) {
              let exists = data.find((e) => e.id == res.data[i].id);
              if (!exists || exists == undefined) {
                data.push({
                  id: res.data[i].id,
                  records: res.data[i],
                });
              }
            }
            setProducts(data.map((e) => e.records));
            setTotalProducts(data.length);
            setIsLoading(false);
            setIsLoadingMore(false);
          } else {
            setProducts([...products, ...res.data]);
            setTotalProducts(res.totalData);
            setIsLoading(false);
            setIsLoadingMore(false);
          }
        } else {
          setIsLoading(false);
          setIsLoadingMore(false);
        }
      });
    }
  }, [page, selected]);

  // const handleSelected = (value: any) => {
  //   if (value.id !== selected.id) {
  //     resetStates();
  //     setSelected(value);
  //   }
  // };

  // const resetStates = () => {
  //   setIsLoading(true);
  //   setPage(1);
  //   setProducts([]);
  //   setTotalProducts(0);
  // };

  const reload = () => {
    setIsLoading(true);
    setProducts([]);
    setTotalProducts(0);
    setPage(1);
    // getSpecializations();
    setSelected({ id: 0, name: "All" });
  };

  useEffect(() => {
    setCurrentPageInit(reload);
    const unsubscribe = navigation.addListener("focus", () => {
      setCurrentPageInit(reload);
    });
    return unsubscribe;
  }, []);

  const handlePagination = () => {
    if (products.length < totalProducts && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage((page) => page + 1);
    }
  };

  const listFooterComponent = () => {
    return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
  };

  return (
    <Box flex="1" px={isBigScreen ? webStyle.xNum : "4"} bgColor={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      {/* {specializations.length ? (
        <Box bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg} mx={isBigScreen ? webStyle.xNum : 0}>
          <MenuBar data={specializations} handleSelected={handleSelected} selected={selected} field="categories" />
        </Box>
      ) : null} */}
      {products.length ? (
        <Box
          flex="1"
          bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          w="full"
          alignItems={isBigScreen ? "center" : null}
          pt={isBigScreen ? "4" : "2"}
        >
          <ProductsList
            products={products}
            handlePagination={handlePagination}
            listFooterComponent={listFooterComponent}
          />
        </Box>
      ) : !isLoading && !products.length ? (
        <NoData />
      ) : null}
      {isOpen ? (
        <AppAlert
          header="Products Purchase"
          text="Login to Purchase Products"
          actionText="Login"
          action={() => {
            setIsOpen(false);
            linkToPage({
              navigation: navigation,
              route: "SignUp2",
              params: { screen: "Products" },
            });
          }}
        />
      ) : null}
      <LoadingAnimation loading={isLoading} />
    </Box>
  );
};

export default Products;
