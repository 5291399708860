export const query = {
  "getAllApiKeys": {
    "query": "select * from api_keys",
    "token": "14j35jz"
  },
  "getAllAppVersions": {
    "query": "select * from app_versions",
    "token": "1q0513z"
  },
  "getAllBloodGroups": {
    "query": "select * from blood_groups",
    "token": "jajr1b"
  },
  "createEnquiry": {
    "query": "insert into hospital_enquired(user_id, hospital_id) values ({id}, ?)",
    "token": "1hyea6s"
  },
  "createBooking": {
    "query": "insert into bookings(user_id, package, package_details, hospital, hospital_details, hospital_category, package_category, price, price_suffix, status) values ({id}, ?, ?, ?, ?, ?, ?, ?, ?, ?)",
    "token": "hndoso"
  },
  "getBookings": {
    "query": "select * from bookings where user_id = {id} order by id desc limit 20 offset ?",
    "token": "95pvsq"
  },
  "getBookingsCount": {
    "query": "select count(*) from bookings where user_id = {id} order by id desc",
    "token": "jjs019"
  },
  "cancelBooking": {
    "query": "update bookings set status = 'cancelled' where id = ? and user_id = {id}",
    "token": "1lngo53"
  },
  "getDoctorsBySpecialization": {
    "query": "select doctors.*, JSON_OBJECT('id', doctors_specializations.id, 'name', doctors_specializations.name) AS specialization_id from doctors join doctors_specializations on doctors.specialization_id = doctors_specializations.id where specialization_id = ? and deleted = 0 and priority > 0 order by priority desc limit 20 offset ?",
    "token": "6iykz3"
  },
  "getDoctorsBySpecializationCount": {
    "query": "select count(*) from doctors where specialization_id = ? and deleted = 0 and priority > 0 order by priority desc",
    "token": "1qt3rme"
  },
  "getDoctors": {
    "query": "select doctors.*, JSON_OBJECT('id', doctors_specializations.id, 'name', doctors_specializations.name) AS specialization_id from doctors join doctors_specializations on doctors.specialization_id = doctors_specializations.id where deleted = 0 and priority > 0 order by priority desc limit 20 offset ?",
    "token": "1wvfgy5"
  },
  "getDoctorsCount": {
    "query": "select count(*) from doctors where deleted = 0 and priority > 0 order by priority desc",
    "token": "q03k38"
  },
  "getAllDoctorsByName": {
    "query": "select doctors.*, JSON_OBJECT('id', doctors_specializations.id, 'name', doctors_specializations.name) AS specialization_id from doctors join doctors_specializations on doctors.specialization_id = doctors_specializations.id where doctors.name like ? or keywords like ? and deleted = 0 and priority > 0 order by priority desc",
    "token": "19blh4p"
  },
  "getAllDoctorsSpecializations": {
    "query": "select * from doctors_specializations",
    "token": "1dv3br"
  },
  "getAllGalleryImages": {
    "query": "select * from gallery_images where priority > 0 order by priority desc",
    "token": "17gyeeu"
  },
  "getAllGalleryVideos": {
    "query": "select * from gallery_videos where priority > 0 order by priority desc",
    "token": "knhu8m"
  },
  "checkMitraByPhone": {
    "query": "select * from healthcare_mitras where phone_no = ?",
    "token": "npk17z"
  },
  "checkMitraByPhoneAndHcmNo": {
    "query": "select * from healthcare_mitras where phone_no = ? and hcm_no = ?",
    "token": "5qdgec"
  },
  "addMitra": {
    "query": "insert into healthcare_mitras(name, phone_no, lat_long, address, district, block, referred_by, details) values (?, ?, ?, ?, ?, ?, ?, ?)",
    "token": "1n6hfiy"
  },
  "getMitras": {
    "query": "select * from healthcare_mitras where deleted = 0 and priority > 0 order by priority desc limit 20 offset ?",
    "token": "1vngy3z"
  },
  "getMitrasCount": {
    "query": "select count(*) from healthcare_mitras where deleted = 0 and priority > 0 order by priority desc",
    "token": "1gco3a6"
  },
  "getAllMitrasByName": {
    "query": "select * from healthcare_mitras where name like ? or address like ? and deleted = 0 and priority > 0 order by priority desc",
    "token": "1x2t0sh"
  },
  "updateMitra": {
    "query": "update users set lat_long = ?, address = ? where id = ?",
    "token": "1738oj3"
  },
  "getHospitals": {
    "query": "select * from hospitals where priority > 0 order by priority desc limit ? offset ?",
    "token": "ojw5oa"
  },
  "getHospitalsCount": {
    "query": "select count(*) from hospitals where priority > 0 order by priority desc",
    "token": "14eic4c"
  },
  "getHospitals2": {
    "query": "select * from hospitals where type = 2 and priority > 0 order by priority desc",
    "token": "hlfka2"
  },
  "getHospitalById": {
    "query": "select * from hospitals where id = ? and priority > 0",
    "token": "7959um"
  },
  "getAllHospitalsByName": {
    "query": "select * from hospitals where name like ? and priority > 0",
    "token": "8gy5li"
  },
  "getAllHospitalsCategories": {
    "query": "select * from hospitals_categories where priority > 0 order by priority desc",
    "token": "13x36b"
  },
  "getHospitalsCategories": {
    "query": "select * from hospitals_categories where priority > 0 order by priority desc limit ? offset ?",
    "token": "1wwcvol"
  },
  "getHospitalsCategoriesCount": {
    "query": "select count(*) from hospitals_categories where priority > 0 order by priority desc",
    "token": "d38179"
  },
  "getAllHospitalPackagesByPackageId": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', hospitals_categories.id, 'name', hospitals_categories.name, 'image', hospitals_categories.image) AS hospital_category_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join hospitals_categories on hospitals_packages.hospital_category_id = hospitals_categories.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where package_id = ? and deleted = 0 order by price asc",
    "token": "1ruh8hy"
  },
  "getAllHospitalPackagesByPiHiPci": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', hospitals_categories.id, 'name', hospitals_categories.name, 'image', hospitals_categories.image) AS hospital_category_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join hospitals_categories on hospitals_packages.hospital_category_id = hospitals_categories.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where package_id = ? and hospital_id = ? and package_category_id != ? and deleted = 0 order by price asc",
    "token": "588go"
  },
  "getAllHospitalPackagesByPiHi": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', hospitals_categories.id, 'name', hospitals_categories.name, 'image', hospitals_categories.image) AS hospital_category_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join hospitals_categories on hospitals_packages.hospital_category_id = hospitals_categories.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where package_id = ? and hospital_id != ? and deleted = 0 order by price asc",
    "token": "3phz9e"
  },
  "getHospitalPackagesByPackageId": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', hospitals_categories.id, 'name', hospitals_categories.name, 'image', hospitals_categories.image) AS hospital_category_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join hospitals_categories on hospitals_packages.hospital_category_id = hospitals_categories.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where package_id = ? and deleted = 0 order by id desc limit 20 offset ?",
    "token": "8kaiep"
  },
  "getHospitalPackagesByPackageIdCount": {
    "query": "select count(*) from hospitals_packages  where package_id = ? and deleted = 0 order by id desc",
    "token": "1wtx4la"
  },
  "getHospitalPackagesByPackageIdRating": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', hospitals_categories.id, 'name', hospitals_categories.name, 'image', hospitals_categories.image) AS hospital_category_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join hospitals_categories on hospitals_packages.hospital_category_id = hospitals_categories.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where package_id = ? and deleted = 0 order by rating desc limit 20 offset ?",
    "token": "1i0cp9r"
  },
  "getHospitalPackagesByPackageIdRatingCount": {
    "query": "select count(*) from hospitals_packages where package_id = ? and deleted = 0 order by rating desc",
    "token": "1c9umia"
  },
  "getHospitalPackagesByPackageIdPriceA": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', hospitals_categories.id, 'name', hospitals_categories.name, 'image', hospitals_categories.image) AS hospital_category_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join hospitals_categories on hospitals_packages.hospital_category_id = hospitals_categories.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where package_id = ? and deleted = 0 order by price asc limit 20 offset ?",
    "token": "1iof7md"
  },
  "getHospitalPackagesByPackageIdPriceACount": {
    "query": "select count(*) from hospitals_packages where package_id = ? and deleted = 0 order by price asc",
    "token": "mt6qqg"
  },
  "getHospitalPackagesByPackageIdPriceD": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', hospitals_categories.id, 'name', hospitals_categories.name, 'image', hospitals_categories.image) AS hospital_category_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join hospitals_categories on hospitals_packages.hospital_category_id = hospitals_categories.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where package_id = ? and deleted = 0 order by price desc limit 20 offset ?",
    "token": "1jm9drf"
  },
  "getHospitalPackagesByPackageIdPriceDCount": {
    "query": "select count(*) from hospitals_packages where package_id = ? and deleted = 0 order by price desc",
    "token": "1vuu616"
  },
  "getAllHospitalPackagesByCategoryId": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id where hospital_category_id = ? and deleted = 0 order by hospital_order desc",
    "token": "1e2vybz"
  },
  "getAllHospitalPackagesByHospitalStatus": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id where hospital_status = 1 and deleted = 0 order by hospital_order desc",
    "token": "uqcpwh"
  },
  "getAllHospitalPackagesByHospitalIdHci": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where hospital_id = ? and hospital_category_id = ? and deleted = 0",
    "token": "t0h4li"
  },
  "getHospitalPackagesByHospitalIdHci": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where hospital_id = ? and hospital_category_id = ? and deleted = 0 limit 20 offset ?",
    "token": "wmn0yd"
  },
  "getHospitalPackagesByHospitalIdHciCount": {
    "query": "select count(*) from hospitals_packages where hospital_id = ? and hospital_category_id = ? and deleted = 0",
    "token": "1mcz07v"
  },
  "getHospitalPackagesByHospitalId": {
    "query": "select hospitals_packages.*, JSON_OBJECT('id', hospitals.id, 'name', hospitals.name, 'image', hospitals.image,'rating', hospitals.rating, 'location', hospitals.location, 'details', hospitals.details) AS hospital_id , JSON_OBJECT('id', packages.id, 'name', packages.name, 'image', packages.image, 'duration', packages.duration, 'details', packages.details) AS package_id , JSON_OBJECT('id', packages_categories.id, 'name', packages_categories.name) AS package_category_id from hospitals_packages join hospitals on hospitals_packages.hospital_id = hospitals.id join packages on hospitals_packages.package_id = packages.id join packages_categories on hospitals_packages.package_category_id = packages_categories.id where hospital_id = ? and deleted = 0 limit 20 offset ?",
    "token": "1q9dvmn"
  },
  "getHospitalPackagesByHospitalIdCount": {
    "query": "select count(*) from hospitals_packages where hospital_id = ? and deleted = 0",
    "token": "1t1niaj"
  },
  "getPackages": {
    "query": "select * from packages where priority > 0 order by priority desc limit ? offset ?",
    "token": "1wkj0vu"
  },
  "getPackagesCount": {
    "query": "select count(*) from packages where priority > 0 order by priority desc",
    "token": "6zyduo"
  },
  "getPackagesByCategoryId": {
    "query": "select * from packages where category_id = ? and category_id > 0 and priority > 0 order by priority desc limit 20 offset ?",
    "token": "1xbygql"
  },
  "getPackagesByCategoryIdCount": {
    "query": "select count(*) from packages where category_id = ? and category_id > 0 and priority > 0 order by priority desc",
    "token": "ua8usw"
  },
  "getAllPackagesByName": {
    "query": "select * from packages where name like ? or keywords like ? and priority > 0 order by priority desc",
    "token": "1fohb2d"
  },
  "getAllPackagesCategories": {
    "query": "select * from packages_categories",
    "token": "1u9b214"
  },
  "getProductsByType": {
    "query": "select * from products where product_type_id = ? and deleted = 0 and priority > 0 order by priority desc limit 20 offset ?",
    "token": "1rwdrpg"
  },
  "getProductsByTypeCount": {
    "query": "select count(*) from products where product_type_id = ? and deleted = 0 and priority > 0 order by priority desc",
    "token": "bf96nr"
  },
  "getProducts": {
    "query": "select * from products where deleted = 0 and priority > 0 order by priority desc limit 20 offset ?",
    "token": "gzq9fl"
  },
  "getProductsCount": {
    "query": "select count(*) from products where deleted = 0 and priority > 0 order by priority desc",
    "token": "12rso38"
  },
  "getAllProductsByName": {
    "query": "select * from products where name like ? or keywords like ? and deleted = 0 and priority > 0 order by priority desc",
    "token": "1qsubwr"
  },
  "createProductEnquired": {
    "query": "insert into product_enquired(user_id, product_id) values (?, ?)",
    "token": "1a50v7y"
  },
  "createReferralsInstalls": {
    "query": "insert into product_enquired(device_token, expo_token, os, subscribed, android_id, ip_address, referral_code) values (?, ?, ?, ?, ?, ?, ?)",
    "token": "mu7dj5"
  },
  "getHealthCardById": {
    "query": "select * from sales where user_id = {id}",
    "token": "1t9xuy6"
  },
  "checkHealthCardByPhone": {
    "query": "select * from sales where phone_no = ? and healthcard_no = ?",
    "token": "18ltnt4"
  },
  "updateHealthCard": {
    "query": "update sales set user_id = {id} where id = ?",
    "token": "li02ie"
  },
  "getServices": {
    "query": "select * from services where priority > 0 order by priority desc limit ? offset ?",
    "token": "y4zw2x"
  },
  "getServicesCount": {
    "query": "select count(*) from services where priority > 0 order by priority desc",
    "token": "1gcy6z5"
  },
  "getServiceProvidersByServiceId": {
    "query": "select services_providers.*, JSON_OBJECT('id', providers.id, 'name', providers.name, 'image', providers.image,'phone_no', providers.phone_no, 'email', providers.email, 'location', providers.location, 'lat_long', providers.lat_long) AS provider_id , JSON_OBJECT('id', services.id, 'name', services.name, 'image', services.image, 'type', services.type, 'priority', services.priority) AS service_id from services_providers join providers on services_providers.provider_id = providers.id join services on services_providers.service_id = services.id where service_id = ? and provider_priority > 0 order by provider_priority desc limit 20 offset ?",
    "token": "2vcxvy"
  },
  "getServiceProvidersByServiceIdCount": {
    "query": "select count(*) from services_providers where service_id = ? and provider_priority > 0 order by provider_priority desc",
    "token": "5oxl6m"
  },
  "getAllSliderImages": {
    "query": "select * from slider_images where device = ? and priority > 0 order by priority desc",
    "token": "1ahppcm"
  },
  "getUserById": {
    "query": "select * from users where id = {id}",
    "token": "khjjrs"
  },
  "getUserByInsertId": {
    "query": "select * from users where id = {insertId}",
    "token": "1846km7"
  },
  "updateUserDevInfo": {
    "query": "update users set device_info = ?, lat_long = ?, os = ? where id = {id}",
    "token": "16fnaqu"
  },
  "updateUser": {
    "query": "update users set name = ?, phone = ?, email = ? where id = {id}",
    "token": "1i201bi"
  },
  "updateUserImage": {
    "query": "update users set image = ? where id = {id}",
    "token": "yxg12i"
  },
  "checkUserByPhone": {
    "query": "select * from users where phone = ?",
    "token": "18tf4mr"
  },
  "updateUserPassword": {
    "query": "update users set password = ? where id = {id}",
    "token": "1maps9c"
  },
  "addUser": {
    "query": "insert into users(name, email, phone, password, lat_long, device_info, os, referral_code) values (?, ?, ?, ?, ?, ?, ?, ?)",
    "token": "fmizjx"
  },
  "getUserDonorsByBloodGroup": {
    "query": "select * from users where blood_group like ? and is_donor = 1 and is_available = 1 and priority > 0 order by priority desc limit 20 offset ?",
    "token": "e7ww68"
  },
  "getUserDonorsByBloodGroupCount": {
    "query": "select count(*) from users where blood_group like ? and is_donor = 1 and is_available = 1 and priority > 0 order by priority desc",
    "token": "ofmcib"
  },
  "getUserDonors": {
    "query": "select * from users where is_donor = 1 and is_available = 1 and priority > 0 order by priority desc limit 20 offset ?",
    "token": "lf89wf"
  },
  "getUserDonorsCount": {
    "query": "select count(*) from users where is_donor = 1 and is_available = 1 and priority > 0 order by priority desc",
    "token": "184qry"
  },
  "getAllUserDonorsByName": {
    "query": "select * from users where name like ? and is_donor = 1 and is_available = 1 and priority > 0",
    "token": "go6t0k"
  },
  "updateUserDonorInfo": {
    "query": "update users set is_donor = ?, is_available = ?, blood_group = ?, gender = ?, date_of_birth = ?, last_donation_date = ?, last_donation_duration = ? where id = {id}",
    "token": "jktj69"
  },
  "updateUserPrescription": {
    "query": "update users set prescription = ? where id = {id}",
    "token": "1c8wtzj"
  },
  "createUsersDoctorsCalls": {
    "query": "insert into users_doctors_calls(user_id, doctor_id) values (?, ?)",
    "token": "1emjui4"
  },
  "createUsersDonorsCalls": {
    "query": "insert into users_donors_calls(user_id, donor_id) values (?, ?)",
    "token": "1bgqkr4"
  },
  "createUsersMitrasCalls": {
    "query": "insert into users_mitras_calls(user_id, mitra_id) values (?, ?)",
    "token": "x116ky"
  },
  "checkUserInstallByTokens": {
    "query": "select * from users_installs where device_token = ? and subscribed = 1",
    "token": "1gtpeu9"
  },
  "addUserInstall": {
    "query": "insert into users_installs(device_token, expo_token, lat_long, os, subscribed, android_id, ip_address) values (?, ?, ?, ?, ?, ?, ?)",
    "token": "gbykc6"
  },
  "updateUserInstall": {
    "query": "update users_installs set device_token = ?, expo_token = ?, lat_long = ?, os = ?, subscribed = ?, android_id = ?, ip_address = ? where id= ?",
    "token": "1fy73dv"
  },
  "addUserProviderCall": {
    "query": "insert into users_providers_calls(user_id, provider_id, hospital_id, hospital_center, contact_mode, device_token, expo_token, os, lat_long) values (?, ?, ?, ?, ?, ?, ?, ?, ?)",
    "token": "9qfhuf"
  },
  "getAllHospitals": {
    "query": "select * from hospitals where priority > 0 order by priority desc",
    "token": "1tq9kym"
  }
};