import React, { useEffect, useState } from "react";
import { Box, HStack, ScrollView, Text, VStack } from "native-base";
import colors from "config/colors";
import BookingStatus from "./booking-status";
import LoadingAnimation from "widgets/loading-animation";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import HospitalizationDetails from "widgets/hospitalization-details";
import BookingTopImage from "./booking-top-image";

const BookingDetails = ({ route, navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isLoading, setIsLoading] = useState(false);

  let item = route.params.item;
  let location: any;
  let url: any;
  let duration = null;
  let los: any;
  let packageCategory: any;
  let packageImage: any;
  let hospitalImage: any;

  if (Object.keys(item).length) {
    let hospitalDetails = item.hospital_details;
    let packageDetails = item.package_details;
    // let hospitalDetails = JSON.parse(item.hospital_details);
    // let packageDetails = JSON.parse(item.package_details);
    if ("location" in hospitalDetails) {
      location = hospitalDetails.location;
      // location = JSON.parse(hospitalDetails.location);
      url = location.url;
    }
    if ("duration" in packageDetails) {
      duration = packageDetails.duration;
    }
    if ("image" in hospitalDetails) {
      hospitalImage = hospitalDetails.image;
    }
    if ("image" in packageDetails) {
      packageImage = packageDetails.image;
    }
    packageCategory = item.package_category;
    if (duration !== 0 && duration !== "0" && duration !== "" && duration !== undefined && duration !== null) {
      //   los = `${duration} (${packageCategory})`;
      los = `${duration}`;
    } else {
      //   los = packageCategory;
      los = "N/A";
    }
  }

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  return (
    <Box flex="1" px={isBigScreen ? webStyle.xNum : 4} bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      <ScrollView
        flex="1"
        bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
      >
        {Object.keys(item).length ? (
          <Box bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}>
            <Box
              justifyContent="center"
              alignItems="center"
              h="64"
              w="full"
              // bg="white"
              overflow="hidden"
            >
              <BookingTopImage packageImage={packageImage} hospitalImage={hospitalImage} />
            </Box>
            <VStack
              w={isBigScreen ? "full" : "full"}
              alignSelf="center"
              justifyContent="center"
              alignItems="flex-start"
              py="4"
              px={isBigScreen ? webStyle.xNum : 4}
              space="1"
              borderWidth={isBigScreen ? "1" : "1"}
              borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
              borderRadius="2xl"
              mt={isBigScreen ? "4" : "4"}
              bg={colors.white}
            >
              <HospitalizationDetails
                url={url}
                los={los}
                packageCategory={packageCategory}
                packageName={item.package}
                hospitalName={item.hospital}
                price={item.price}
                price_suffix={item.price_suffix}
              />

              <BookingStatus
                id={item.id}
                status={item.status}
                hospitalName={item.hospital}
                packageName={item.package}
                packageCategory={packageCategory}
                hospitalImage={hospitalImage}
                packageImage={packageImage}
                item={item}
                setIsLoading={setIsLoading}
              />
            </VStack>
          </Box>
        ) : null}
        <LoadingAnimation loading={isLoading} />
      </ScrollView>
    </Box>
  );
};

export default BookingDetails;
