import React from "react";
import { TouchableOpacity } from "react-native";
import { Box, Button, Circle, HStack, Icon, ScrollView, Stack, Text, VStack } from "native-base";

import { Entypo, TrustImage } from "assets/icons";
import colors from "config/colors";
import { LinkToPage } from "widgets";

const Trust = ({ navigation }: any) => {
  return (
    <ScrollView py="7" flex={1} bg="white">
      <HStack w="full" p="4" alignItems="center" justifyContent="space-between">
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Icon size="8" color={colors.black} as={<Entypo name="chevron-left" />} />
        </TouchableOpacity>
        <Button variant="link" onPress={() => navigation.navigate("Support")}>
          <Text color={colors.darkGrayish} fontSize="md">
            SKIP
          </Text>
        </Button>
      </HStack>
      <Box h="72">
        <TrustImage />
      </Box>
      <VStack py="10" justifyContent="center" alignItems="flex-start" space="8">
        <Stack w="full" alignItems="center" space="2">
          <Text color={colors.black} fontSize="3xl" fontWeight="bold">
            TRUST
          </Text>
          <Text mb="8" textAlign="center" color={colors.darkGrayish} fontSize="md">
            Authentic, Reliable and Broker free
          </Text>
          {/* <TouchableOpacity onPress={() => navigation.navigate("Support")}> */}
          <LinkToPage route="Support">
            <Circle bgColor={colors.primary} size={12}>
              <Icon size="8" color={colors.white} as={<Entypo name="chevron-right" />} />
            </Circle>
          </LinkToPage>
          <HStack space="4" mt="16">
            <Circle bgColor={colors.accent} size={3}></Circle>
            <Circle bgColor={colors.primary} size={3}></Circle>
            <Circle bgColor={colors.accent} size={3}></Circle>
          </HStack>
        </Stack>
      </VStack>
    </ScrollView>
  );
};

export default Trust;
