import { FontAwesome, FontAwesome5 } from "assets/icons";
import AppStore from "AppStore";
import colors from "config/colors";
import { Box, HStack, Icon, Text, VStack } from "native-base";
import React from "react";
import { Linking, TouchableOpacity } from "react-native";

const HospitalizationDetails = ({ url, hospitalName, details, phone, website }: any) => {
	const [isBigScreen] = AppStore("isBigScreen");

	return (
		<VStack space={1} w="full">
			<HStack justifyContent="space-between" alignItems="center">
				<Box flex={3 / 4}>
					<Text
						fontSize="2xl"
						fontWeight="semibold"
						lineHeight="sm"
						color={colors.primary}
					>
						{hospitalName}
					</Text>
				</Box>
				<TouchableOpacity onPress={() => Linking.openURL(url)}>
					<Icon size="8" color={colors.primary} as={<FontAwesome5 name="directions" />} />
				</TouchableOpacity>
			</HStack>

			{/* 
			<Box>
				<Text fontSize="md" fontWeight="medium" color="gray.500">
					Length of stay -{" "}
				</Text>
				<Text fontSize="lg" fontWeight="medium" color="gray.800">
					{los}
				</Text>
			</Box> 
      <Box flexDirection="row" alignItems="center" w="64">
				<Text fontSize="2xl" fontWeight="medium" color="black">
					<FontAwesome name="rupee" size={22} color="black" /> {price}
				</Text>
				<Text fontSize="lg" fontWeight="medium" color="gray.500">
					{price_suffix ? ` - ${price_suffix}` : null}
				</Text>
			</Box> */}
		</VStack>
	);
};

export default HospitalizationDetails;
