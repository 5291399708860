import { ActivityIndicator } from "react-native";
import React, { useEffect, useState } from "react";
import { getDonors, getBloodGroups } from "./actions";
import { Box, Circle, HStack, Icon } from "native-base";
import {
  LinkToPage,
  LoadingAnimation,
  MenuBar,
  NoData,
  SearchIcon,
} from "widgets";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import DonorsList from "./donors-list";
import AppAlert from "widgets/app-alert";
import { linkToPage } from "widgets/link-to-page/link-to-page";
import RegisterImage from "./register-image";
import { FontAwesome } from "assets/icons";

const BloodDonors = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [user] = AppStore("user");
  const [isOpen, setIsOpen] = AppStore("isOpen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isLoading, setIsLoading] = AppStore("isLoading");
  const [donors, setDonors] = useState<any>([]);
  const [bloodGroups, setBloodGroups] = useState<any>([]);
  const [totalDonors, setTotalDonors] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState({
    id: 0,
    name: "All",
  });

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HStack
          alignItems="center"
          space="2"
          style={
            Object.keys(user).length && user.is_donor
              ? { transform: [{ scale: 1 * 0.8 }] }
              : null
          }
        >
          {Object.keys(user).length && user.is_donor ? (
            <LinkToPage route="AddDonor">
              <Circle ml="2" size="50" rounded="full" bg={colors.lightGrayish}>
                <Icon
                  as={<FontAwesome name="user" />}
                  size="6"
                  color={colors.black}
                  alignSelf="center"
                  ml="1.5"
                />
              </Circle>
            </LinkToPage>
          ) : null}
          <LinkToPage route="DonorSearch">
            <SearchIcon circleSize="50" iconSize="6" />
          </LinkToPage>
        </HStack>
      ),
    });
  }, [user]);

  useEffect(() => {
    getAllGroups();
  }, []);

  const getAllGroups = () => {
    getBloodGroups((res: any, err: any) => {
      if (!err) {
        setBloodGroups(res.data);
      } else {
      }
    });
  };

  useEffect(() => {
    if (!donors.length || donors.length < totalDonors) {
      if (page == 1) {
        setIsLoading(true);
      }
      getDonors(page, selected.id, selected.name, (res: any, err: any) => {
        if (!err) {
          if (selected.id > 0) {
            let data = [];
            for (let i in res.data) {
              let exists = data.find((e) => e.id == res.data[i].id);
              if (!exists || exists == undefined) {
                data.push({
                  id: res.data[i].id,
                  records: res.data[i],
                });
              }
            }
            setDonors(data.map((e) => e.records));
            setTotalDonors(data.length);
            setIsLoading(false);
            setIsLoadingMore(false);
          } else {
            setDonors([...donors, ...res.data]);
            setTotalDonors(res.totalData);
            setIsLoading(false);
            setIsLoadingMore(false);
          }
        } else {
          setIsLoading(false);
          setIsLoadingMore(false);
        }
      });
    }
  }, [page, selected]);

  const handleSelected = (value: any) => {
    if (value.id !== selected.id) {
      resetStates();
      setSelected(value);
    }
  };

  const resetStates = () => {
    setIsLoading(true);
    setPage(1);
    setDonors([]);
    setTotalDonors(0);
  };

  const reload = () => {
    setIsLoading(true);
    setDonors([]);
    setTotalDonors(0);
    setPage(1);
    getAllGroups();
    setSelected({ id: 0, name: "All" });
  };

  useEffect(() => {
    setCurrentPageInit(reload);
    const unsubscribe = navigation.addListener("focus", () => {
      reload();
    });
    return unsubscribe;
  }, []);

  const handlePagination = () => {
    if (donors.length < totalDonors && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage((page) => page + 1);
    }
  };

  const listHeaderComponent = () => {
    return <RegisterImage />;
  };
  const listFooterComponent = () => {
    return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
  };

  return (
    <Box
      flex="1"
      px={isBigScreen ? webStyle.xNum : "4"}
      bgColor={isBigScreen ? colors.webOuterBg : colors.appInnerBg}
    >
      {bloodGroups.length ? (
        <Box
          bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          mx={isBigScreen ? webStyle.xNum : 0}
        >
          <MenuBar
            data={bloodGroups}
            handleSelected={handleSelected}
            selected={selected}
            field="categories"
          />
        </Box>
      ) : null}

      {donors.length ? (
        <Box
          flex="1"
          bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          w="full"
          alignItems={isBigScreen ? "center" : null}
          pt={isBigScreen ? "4" : "2"}
        >
          <DonorsList
            donors={donors}
            handlePagination={handlePagination}
            listHeaderComponent={listHeaderComponent}
            listFooterComponent={listFooterComponent}
          />
        </Box>
      ) : !isLoading && !donors.length ? (
        <>
          <RegisterImage />
          <NoData />
        </>
      ) : null}
      {isOpen ? (
        <AppAlert
          header="Blood Donor"
          text="Login to contact blood donor"
          actionText="Login"
          action={() => {
            setIsOpen(false);
            linkToPage({
              navigation: navigation,
              route: "SignUp2",
              params: { screen: "BloodDonors" },
            });
          }}
        />
      ) : null}
      <LoadingAnimation loading={isLoading} />
    </Box>
  );
};

export default BloodDonors;
