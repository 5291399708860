import _APP_CONFIG from "config/app";
import React, { useEffect, useState } from "react";
import { Box, Circle, HStack, Icon, Image, Input, InputLeftAddon, ScrollView, Text, VStack } from "native-base";
import { Entypo, FontAwesome, MaterialIcons } from "assets/icons";
import { Platform, TouchableOpacity } from "react-native";
import * as ImagePicker from "expo-image-picker";
import colors from "config/colors";
import { showFlow, showToast, LoadingAnimation, Button } from "widgets";

import axios from "axios";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";

const Privacy = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [fields, setFields] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 10 }}
        // mx={isBigScreen ? webStyle.xNum : 0}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box
        // mx={isBigScreen ? webStyle.authX : 0}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            h="72"
            w="full"
            bg="white"
            roundedBottom="3xl"
            // pt="12"
            overflow="hidden"
          >
            <Image source={require("assets/images/zemidi.png")} alt="loading" resizeMode="contain" size="56" />
          </Box>

          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px={isBigScreen ? "10" : "5"}
            space="6"
            mx={isBigScreen ? webStyle.xNum : 0}
          >
            <HStack w="full" alignItems="center" justifyContent="space-between">
              <Text color="white" fontSize="3xl" fontWeight="bold">
                Privacy Policy
              </Text>
              {!isBigScreen ? (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-left" />} />
                  </Circle>
                </TouchableOpacity>
              ) : null}
            </HStack>
            <VStack w="90%" space="2">
              <Text color={colors.white} fontSize="md" fontWeight="semibold">
                Effective Date: 14-10-2023
              </Text>
              <Text color={colors.white} fontSize="md" fontWeight="semibold" mt="1" lineHeight="sm">
                Welcome to Zemidi. This Privacy Policy outlines how we collect, use, and protect your personal
                information. We take your privacy seriously and are committed to safeguarding your data.
              </Text>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    Information We Collect
                  </Text>
                </HStack>
                <VStack ml="7" space="2">
                  <Text color={colors.white} lineHeight="xs">
                    Name: To personalize your experience.
                  </Text>
                  <Text color={colors.white} lineHeight="xs">
                    Phone Number: To contact you regarding our services.
                  </Text>
                  <Text color={colors.white} lineHeight="xs">
                    Email Address: To communicate with you and send updates.
                  </Text>
                  <Text color={colors.white} lineHeight="xs">
                    Password: To secure your account.
                  </Text>
                  <Text color={colors.white} lineHeight="xs">
                    Location: To enhance your user experience.
                  </Text>
                  <Text color={colors.white} lineHeight="xs">
                    Device Information: To improve our services and troubleshoot issues.
                  </Text>
                </VStack>
              </VStack>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    Use of Information
                  </Text>
                </HStack>
                <VStack ml="7">
                  <Text color={colors.white} lineHeight="xs" fontWeight="medium" mb="2">
                    Your data is collected to provide our services and enhance your user experience. We may use this
                    information for:
                  </Text>
                  <VStack ml="2" space="2">
                    <Text color={colors.white} lineHeight="xs">
                      Personalization of user experience
                    </Text>
                    <Text color={colors.white} lineHeight="xs">
                      Communication with users
                    </Text>
                    <Text color={colors.white} lineHeight="xs">
                      Service improvement
                    </Text>
                    <Text color={colors.white} lineHeight="xs">
                      Troubleshooting
                    </Text>
                  </VStack>
                </VStack>
              </VStack>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    Data Security
                  </Text>
                </HStack>
                <VStack ml="7">
                  <Text color={colors.white} lineHeight="xs">
                    We take data security seriously. Your information is securely stored and protected from unauthorized
                    access.
                  </Text>
                </VStack>
              </VStack>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    Sharing with Third Parties
                  </Text>
                </HStack>
                <VStack ml="7">
                  <Text color={colors.white} lineHeight="xs">
                    We utilize Google Analytics to analyze user behavior. This data helps us improve our services and
                    user experience. Please review Google Analytics' Privacy Policy for more information.
                  </Text>
                </VStack>
              </VStack>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    User Rights
                  </Text>
                </HStack>
                <VStack ml="7">
                  <Text color={colors.white} lineHeight="xs">
                    Users have the right to update their information at any time. However, please note that we do not
                    currently provide the option to delete your data.
                  </Text>
                </VStack>
              </VStack>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    Cookies and Tracking Technologies
                  </Text>
                </HStack>
                <VStack ml="7">
                  <Text color={colors.white} lineHeight="xs">
                    We use cookies and tracking technologies to enhance your experience on our website and app. For
                    detailed information, refer to our dedicated Cookie Policy.
                  </Text>
                </VStack>
              </VStack>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    Contact Information
                  </Text>
                </HStack>
                <VStack ml="7">
                  <Text color={colors.white} lineHeight="xs">
                    For privacy-related inquiries, please contact our privacy team at appadmin@zemidi.com.
                  </Text>
                </VStack>
              </VStack>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    Legal Compliance
                  </Text>
                </HStack>
                <VStack ml="7">
                  <Text color={colors.white} lineHeight="xs">
                    Zemidi is committed to complying with all applicable legal requirements and regulations concerning
                    the collection and handling of user data. If you have questions or concerns regarding your privacy
                    rights, please contact us.
                  </Text>
                </VStack>
              </VStack>
              <VStack space="2" mt="2">
                <HStack space="2" mt="2">
                  <Circle size="3" mt="2" background="white"></Circle>
                  <Text color={colors.white} fontSize="md" fontWeight="semibold">
                    Updates to this Policy
                  </Text>
                </HStack>
                <VStack ml="7">
                  <Text color={colors.white} lineHeight="xs">
                    This Privacy Policy is subject to change. We recommend reviewing it periodically to stay informed of
                    any updates.
                  </Text>
                </VStack>
              </VStack>
              <Text color={colors.white} fontSize="md" fontWeight="semibold" mt="4" lineHeight="sm">
                Thank you for trusting Zemidi with your information. Your privacy is important to us, and we are
                dedicated to ensuring a safe and enjoyable user experience.
              </Text>
            </VStack>
          </VStack>
        </Box>
        <LoadingAnimation loading={isLoading} />
      </ScrollView>
    </Box>
  );
};

export default Privacy;
