import React from "react";
import { AlertBox } from "widgets";
import { _APP_URL } from "config/url";
import AppStore from "AppStore";

const AppAlert = ({ header, text, actionText, action }: any) => {
  const [isOpen, setIsOpen] = AppStore("isOpen");

  return (
    <AlertBox
      header={header}
      text={text}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      actionText={actionText}
      action={action}
    />
  );
};

export default AppAlert;
