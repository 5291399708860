import React, { useEffect } from "react";

import colors from "config/colors";
import { Box, Text } from "native-base";
import AppStore from "AppStore";
import { useRoute } from "@react-navigation/native";
import { webStyle } from "config/web-style";
import LinkToPage from "widgets/link-to-page";
import { ZemidiLogo } from "widgets";

const WebHeader = ({ navigation }: any) => {
  const [user] = AppStore("user");
  const [selectedWebHeader, setSelectedWebHeader] = AppStore("selectedWebHeader");

  const route = useRoute();

  useEffect(() => {
    setSelectedWebHeader(route.name);
  }, [route]);

  return (
    <Box
      flex={1}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      w="96"
      // position="fixed"
      // top={0}
    >
      <Box alignItems="center" justifyContent="center" overflow="hidden" mr="10">
        <LinkToPage route="Home">
          <Box ml={webStyle.xNum} h="20">
            <ZemidiLogo />
          </Box>
        </LinkToPage>
      </Box>
      <Box flexDirection="row" alignItems="center" justifyContent="center" mt="1">
        <LinkToPage route="Home">
          <Text color={selectedWebHeader == "Home" ? colors.primary : colors.black} fontWeight="medium" fontSize="lg" mr="4">
            Home
          </Text>
        </LinkToPage>
        <LinkToPage route="About">
          <Text color={selectedWebHeader == "About" ? colors.primary : colors.black} fontWeight="medium" fontSize="lg" mr="4">
            About
          </Text>
        </LinkToPage>
        <LinkToPage route="Consultancy">
          <Text color={selectedWebHeader == "Consultancy" ? colors.primary : colors.black} fontWeight="medium" fontSize="lg" mr="4">
            Consultancy
          </Text>
        </LinkToPage>

        {/* <LinkToPage route="Packages">
          <Text
            color={selectedWebHeader == "Packages" ? colors.primary : colors.black}
            fontWeight="medium"
            fontSize="lg"
            mr="4"
          >
            Packages
          </Text>
        </LinkToPage> */}

        <LinkToPage route="Hospitals">
          <Text color={selectedWebHeader == "Hospitals" ? colors.primary : colors.black} fontWeight="medium" fontSize="lg" mr="4">
            Hospitals
          </Text>
        </LinkToPage>
        <LinkToPage route="Services">
          <Text color={selectedWebHeader == "Services" ? colors.primary : colors.black} fontWeight="medium" fontSize="lg" mr="4">
            Services
          </Text>
        </LinkToPage>
        <LinkToPage route="Gallery">
          <Text color={selectedWebHeader == "Gallery" ? colors.primary : colors.black} fontWeight="medium" fontSize="lg" mr="4">
            Gallery
          </Text>
        </LinkToPage>
        <LinkToPage route="Videos">
          <Text color={selectedWebHeader == "Videos" ? colors.primary : colors.black} fontWeight="medium" fontSize="lg" mr="4">
            Videos
          </Text>
        </LinkToPage>
      </Box>
    </Box>
  );
};

export default WebHeader;
