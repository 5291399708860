import _APP_CONFIG from "config/app";
import { Box, Image } from "native-base";
import React from "react";
import { Platform } from "react-native";
import LinkToPage from "widgets/link-to-page";
import LocationIcon from "widgets/location-icon";

const MitraMapImage = ({ data }: any) => {
  return (
    <>
      <Image
        mb="2"
        mt="4"
        size={Platform.OS != "web" ? 500 : 200}
        borderRadius="xl"
        source={{
          uri:
            Platform.OS != "web"
              ? `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/mitra-map.png`
              : `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/healthcare-mitra-no-map.png`,
        }}
        resizeMode="cover"
        alt="loading"
        flex="1"
        overflow="hidden"
      />
      {Platform.OS != "web" ? (
        <Box position="absolute">
          <LinkToPage route="MitraMap" params={{ data: data }}>
            <LocationIcon />
          </LinkToPage>
        </Box>
      ) : null}
    </>
  );
};

export default MitraMapImage;
