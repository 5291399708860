import React from "react";
import { Box, Image } from "native-base";

const NoData = () => {
  return (
    <Box h="96" w="full" overflow="hidden">
      <Image
        source={require("assets/images/no-data.png")}
        alt="loading"
        size="full"
        resizeMode="contain"
      />
    </Box>
  );
};

export default NoData;
