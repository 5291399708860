import React from "react";
import { HStack, Icon, Text } from "native-base";
import colors from "config/colors";
import { Ionicons } from "assets/icons";

const LocationIcon = () => {
  return (
    <HStack
      bg={colors.primary}
      w="40"
      rounded="full"
      alignItems="center"
      justifyContent="center"
      p="2"
      opacity="0.9"
    >
      <Icon
        size={6}
        color={colors.white}
        as={<Ionicons name="location-sharp" />}
        mr="2"
      />
      <Text fontSize="md" fontWeight="medium" color={colors.white}>
        View in Map
      </Text>
    </HStack>
  );
};

export default LocationIcon;
