import _APP_CONFIG from "config/app";
import { TouchableOpacity, Linking, Platform } from "react-native";
import React from "react";
import { Box, HStack, Image, Text, VStack } from "native-base";
import colors from "config/colors";
import { _PREFILL_TEXT, _UPI_PAY_199, _UPI_PAY_499, _UPI_PAY_99, _ZEMIDI_HELPLINE } from "config/defaults";
import AppStore from "AppStore";
import { LinkToPage, showToast } from "widgets";

type Props = {
  h?: any;
};

const BuyHealthCard = ({ h = "20" }: Props) => {
  const [isBigScreen] = AppStore("isBigScreen");
  return (
    <HStack w={["full", "500"]} bg={colors.white} rounded="2xl" p="4" alignSelf="center" shadow="2" h={h}>
      <VStack space="2" flex="2" alignItems="flex-start">
        <Box overflow="hidden" h="24" w="40">
          <Image
            size="full"
            source={{
              uri: `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/swastika1.png`,
              // uri: `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/buy-health-card.png`,
            }}
            resizeMode="contain"
            alt="loading"
          />
        </Box>
        <Box
          flex="2"
          p="2"
          pb="0"
          // style={
          //   isBigScreen
          //     ? { transform: [{ scale: 1 * 0.95 }] }
          //     : { transform: [{ scale: 1 * 0.9 }] }
          // }
        >
          <Text fontSize={isBigScreen ? "xl" : "xs"} fontWeight="bold" mb="4">
            After purchase share payment screenshot with Zemidi
          </Text>
        </Box>
        {/* <Box pl="2">
          <LinkToPage route="Benefits">
            <Text fontSize="xs" fontWeight="semibold" color="green.700" underline italic pb="2">
              Benefits & Specifications
            </Text>
          </LinkToPage>
          // <LinkToPage route="Terms">
          //   <Text
          //     fontSize="xs"
          //     fontWeight="semibold"
          //     color={colors.primary}
          //     underline
          //   >
          //     Term & Conditions
          //   </Text>
          // </LinkToPage>
        </Box> */}
      </VStack>
      <VStack space="4" justifyContent="center">
        <TouchableOpacity
          onPress={() => {
            !isBigScreen ? Linking.openURL(_UPI_PAY_499) : showToast("Open Zemidi app/website in your phone to make UPI payment");
          }}
        >
          <Box bg={colors.white} rounded="full" p="2" alignItems="center" justifyContent="center" borderWidth="1" borderColor={colors.primary}>
            <Text fontSize="xs" fontWeight="bold" color={colors.primary}>
              SUBSCRIBE @ 499
            </Text>
          </Box>
        </TouchableOpacity>
        {/* <TouchableOpacity
          onPress={() => {
            !isBigScreen
              ? Linking.openURL(_UPI_PAY_99)
              : showToast(
                  "Open Zemidi app/website in your phone to make UPI payment"
                );
          }}
        >
          <Box
            bg={colors.white}
            rounded="full"
            p="2"
            alignItems="center"
            justifyContent="center"
            borderWidth="1"
            borderColor={colors.primary}
          >
            <Text fontSize="xs" fontWeight="bold" color={colors.primary}>
              BUY NOW @ ₹99
            </Text>
            <Text fontSize="xs" fontWeight="bold" color={colors.primary}>
              (for individuals)
            </Text>
          </Box>
        </TouchableOpacity> */}

        <TouchableOpacity onPress={() => Linking.openURL(Platform.OS == "web" ? `https://wa.me/${_ZEMIDI_HELPLINE}` : `whatsapp://send?phone=${_ZEMIDI_HELPLINE}`)}>
          <Box
            // bg="green.700"
            bg={colors.white}
            rounded="full"
            p="2"
            alignItems="center"
            justifyContent="center"
            borderWidth="1"
            borderColor="green.700"
          >
            <Text fontSize="xs" fontWeight="bold" color="green.700" p="1">
              SHARE SCREENSHOT
            </Text>
          </Box>
        </TouchableOpacity>
      </VStack>
    </HStack>
  );
};

export default BuyHealthCard;
