import React from "react";
import { HStack, Text, VStack } from "native-base";
import HospitalizationDetails from "widgets/hospitalization-details";
import Button from "widgets/button";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import { booking, sendMail } from "screens/booking/actions";
import { showToast } from "widgets/nb-toast";

const BookingTopInfo = ({
  item,
  url,
  los,
  packageCategory,
  packageName,
  hospitalName,
  price,
  price_suffix,
  navigation,
  setIsLoading,
}: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [user] = AppStore("user");
  const [session] = AppStore("session");

  const handleBooking = () => {
    if (Object.keys(user).length) {
      setIsLoading(true);
      booking(user, session, item, (res: any, err: any) => {
        if (!err) {
          setIsLoading(false);
          navigation.navigate("BookingSuccess");
          showToast(`Booking request is successful`);
          sendMail(
            res.data,
            user,
            item.hospital_id.name,
            item.package_id.name,
            packageCategory,
            setIsLoading,
            "New Booking"
          );
        } else {
          setIsLoading(false);
        }
      });
    } else {
      navigation.navigate("SignUp2", {
        screen: "Booking",
        item: item,
      });
    }
  };

  return (
    <VStack
      // w={isBigScreen ? "2/3" : "full"}
      w={isBigScreen ? "full" : "full"}
      alignSelf="center"
      justifyContent="center"
      alignItems="flex-start"
      py="4"
      px={isBigScreen ? webStyle.xNum : 4}
      space="1"
      borderWidth={isBigScreen ? "1" : "1"}
      borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
      borderRadius="2xl"
      mt={isBigScreen ? "4" : "4"}
      bg={colors.white}
    >
      <HospitalizationDetails
        url={url}
        los={los}
        packageCategory={packageCategory}
        item={item}
        packageName={packageName}
        hospitalName={hospitalName}
        price={price}
        price_suffix={price_suffix}
      />

      <HStack w="full" p="4" mt="4" justifyContent="space-around">
        <Button
          title={Object.keys(user).length ? "Book Now" : "Login to continue"}
          btnColor={colors.primary}
          pressedColor={colors.accent}
          titleColor={colors.white}
          // onPress={() =>
          //   Object.keys(user).length
          //     ? booking(user, item, navigation, setIsLoading)
          //     : navigation.navigate("SignUp", {
          //         screen: "Booking",
          //         item: item,
          //       })
          // }
          onPress={handleBooking}
        />
      </HStack>
    </VStack>
  );
};

export default BookingTopInfo;
