import _APP_CONFIG from "config/app";

import { useNavigation } from "@react-navigation/native";
import AppStore from "AppStore";
import { Box, Image } from "native-base";
import React from "react";
import { TouchableOpacity } from "react-native";
import { linkToPage } from "widgets/link-to-page/link-to-page";

const RegisterImage = () => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [user] = AppStore("user");
  const navigation = useNavigation();

  return (
    <Box
      h={isBigScreen ? "64" : "48"}
      mt="5"
      w={isBigScreen ? "70%" : "full"}
      alignSelf="center"
      rounded="2xl"
      borderRadius="2xl"
    >
      <TouchableOpacity
        onPress={() =>
          linkToPage({
            navigation: navigation,
            route: !Object.keys(user).length ? "SignUp2" : Object.keys(user).length ? "AddDonor" : null,
            params: {
              screen: !Object.keys(user).length ? "BloodDonors" : null,
            },
          })
        }
        style={{ flex: 1 }}
      >
        <Image
          source={{
            uri: isBigScreen
              ? `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/blood-donation-web.png`
              : `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/blood-donation-mobile.png`,
          }}
          size="full"
          flex={1}
          resizeMode="contain"
          alt="loading"
        />
      </TouchableOpacity>
    </Box>
  );
};

export default RegisterImage;
