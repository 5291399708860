import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";
import axios from "axios";

export const getGalleryImages = async (callback) => {
  const body = {
    sql: query.getAllGalleryImages.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllGalleryImages.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  if (res?.data?.err == undefined) {
    callback(false, res?.data?.result);
  } else {
    callback(true, []);
  }
  // Api.get(`/gallery_images?filter=priority,gt,0&order=priority,desc`).send((res) => {
  //   if (res.type != "error") {
  //     if (res.records.length) {
  //       callback(false, res.records);
  //     } else {
  //       callback(true, []);
  //     }
  //   } else {
  //     callback(true, []);
  //   }
  // });
};

export const getGalleryVideos = async (callback) => {
  const body = {
    sql: query.getAllGalleryVideos.query,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getAllGalleryVideos.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  if (res?.data?.err == undefined) {
    callback(false, res?.data?.result);
  } else {
    callback(true, []);
  }
  // Api.get(`/gallery_videos?filter=priority,gt,0&order=priority,desc`).send((res) => {
  //   if (res.type != "error") {
  //     if (res.records.length) {
  //       callback(false, res.records);
  //     } else {
  //       callback(true, []);
  //     }
  //   } else {
  //     callback(true, []);
  //   }
  // });
};
