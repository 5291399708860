import * as React from "react";
import Svg, { Path } from "react-native-svg";

const TrustImage = () => (
  <Svg viewBox="0 0 950 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M464.386 852.718c245.274 0 444.107-110.973 444.107-247.865S709.66 356.988 464.386 356.988c-245.274 0-444.108 110.973-444.108 247.865s198.834 247.865 444.108 247.865Z"
      fill="#F0F0F0"
    />
    <Path
      d="M464.367 552.488c53.145 0 96.228-24.874 96.228-55.558 0-30.683-43.083-55.557-96.228-55.557-53.145 0-96.228 24.874-96.228 55.557 0 30.684 43.083 55.558 96.228 55.558Z"
      fill="#E0E0E0"
    />
    <Path
      d="M582.796 646.507c53.145 0 96.228-24.874 96.228-55.558 0-30.683-43.083-55.557-96.228-55.557-53.145 0-96.228 24.874-96.228 55.557 0 30.684 43.083 55.558 96.228 55.558ZM686.43 774.718c53.146 0 96.228-24.874 96.228-55.558 0-30.683-43.082-55.557-96.228-55.557-53.145 0-96.228 24.874-96.228 55.557 0 30.684 43.083 55.558 96.228 55.558ZM775.252 612.315c53.145 0 96.228-24.874 96.228-55.558 0-30.683-43.083-55.557-96.228-55.557-53.145 0-96.228 24.874-96.228 55.557 0 30.684 43.083 55.558 96.228 55.558ZM153.483 612.315c53.145 0 96.228-24.874 96.228-55.558 0-30.683-43.083-55.557-96.228-55.557-53.146 0-96.228 24.874-96.228 55.557 0 30.684 43.082 55.558 96.228 55.558Z"
      fill="#E0E0E0"
    />
    <Path
      d="M338.532 642.237c53.145 0 96.228-24.874 96.228-55.557 0-30.684-43.083-55.558-96.228-55.558-53.145 0-96.228 24.874-96.228 55.558 0 30.683 43.083 55.557 96.228 55.557ZM236.476 785.985c53.145 0 96.227-24.874 96.227-55.557 0-30.684-43.082-55.558-96.227-55.558-53.146 0-96.228 24.874-96.228 55.558 0 30.683 43.082 55.557 96.228 55.557ZM419.984 784.259l.891-28.883a4.382 4.382 0 0 0-4.399-4.548h-54.184a4.378 4.378 0 0 1-4.418-4.548l1.559-49.71a4.716 4.716 0 0 1 4.697-4.548h54.239a4.754 4.754 0 0 0 4.697-4.548l.891-28.902a4.753 4.753 0 0 1 4.715-4.547h71.539a4.753 4.753 0 0 1 4.715 4.547l.891 28.884a4.754 4.754 0 0 0 4.697 4.566h54.369a4.712 4.712 0 0 1 4.696 4.548l1.411 49.71a4.387 4.387 0 0 1-1.241 3.214 4.404 4.404 0 0 1-3.177 1.334h-54.276a4.386 4.386 0 0 0-3.171 1.335 4.382 4.382 0 0 0-1.229 3.213l.891 28.883a4.392 4.392 0 0 1-1.234 3.226 4.394 4.394 0 0 1-3.184 1.34h-79.985a4.379 4.379 0 0 1-4.4-4.566Z"
      fill="#E0E0E0"
    />
    <Path
      d="M835.431 487.965c-1.856-12.474 3.416-39.65 13.996-57.748 10.581-18.099 29.199-32.912 44.383-30.09 14.145 2.636 15.277 20.53.65 34.619-14.627 14.089-46.035 40.707-50.267 61.999l-2.432 18.748-6.33-27.528Z"
      fill="#F1A8A8"
    />
    <Path
      d="M835.431 487.965c-1.856-12.474 3.416-39.65 13.996-57.748 10.581-18.099 29.199-32.912 44.383-30.09 14.145 2.636 15.277 20.53.65 34.619-14.627 14.089-46.035 40.707-50.267 61.999l-2.432 18.748-6.33-27.528Z"
      fill="#000"
      opacity={0.15}
    />
    <Path
      d="M837.009 505.172a.91.91 0 0 0 .724-.854c2.116-43.807 31.556-83.531 51.01-94.149a.927.927 0 1 0-.891-1.615c-19.862 10.878-49.803 51.196-51.975 95.671a.901.901 0 0 0 .233.667.915.915 0 0 0 .639.299l.26-.019Z"
      fill="#fff"
    />
    <Path
      d="M836.731 542c.965-7.295 7.852-20.122 20.307-31.723 13.773-12.808 32.466-20.419 35.733-29.403 3.917-10.674-5.792-18.804-21.254-14.535-15.463 4.27-39.984 29.737-39.594 62.779L836.731 542Z"
      fill="#F1A8A8"
    />
    <Path
      d="M832.61 535.058a.912.912 0 0 0 .687-.724c7.722-38.388 33.69-55.279 46.406-58.843a.963.963 0 0 0-.127-1.882.963.963 0 0 0-.374.025c-13.978 3.936-39.761 21.05-47.632 60.254a.896.896 0 0 0 .387.947c.103.066.217.11.337.13a.72.72 0 0 0 .316.093Z"
      fill="#fff"
    />
    <Path
      d="M95.085 483.769s5.179-26.525-1.28-50.415c-6.46-23.89-20.03-41.822-35.956-48.263-15.927-6.441-33.84 4.4-19.64 20.233 14.2 15.834 41.933 36.16 44.012 79.262l12.864-.817Z"
      fill="#F1A8A8"
    />
    <Path
      d="M95.085 483.769s5.179-26.525-1.28-50.415c-6.46-23.89-20.03-41.822-35.956-48.263-15.927-6.441-33.84 4.4-19.64 20.233 14.2 15.834 41.933 36.16 44.012 79.262l12.864-.817Z"
      fill="#000"
      opacity={0.15}
    />
    <Path
      d="M92.393 475.658h.186a.89.89 0 0 0 .835-.984c-3.712-47.873-31.018-75.939-44.865-84.107a.912.912 0 0 0-1.244.316.928.928 0 0 0 .316 1.262c13.569 7.982 40.225 35.566 43.974 82.677a.907.907 0 0 0 .798.836Z"
      fill="#fff"
    />
    <Path
      d="M22.858 448.334c.372 3.601 3.267 6.348 6.033 8.65 2.766 2.302 5.773 4.826 6.534 8.353 1.522 7.109-6.905 13.681-5.086 20.716a11.309 11.309 0 0 0 5.959 6.627 72.425 72.425 0 0 0 6.979 2.988 29.94 29.94 0 0 0 3.508.835c3.571.341 6.93 1.848 9.56 4.288 3.898 4.214 3.397 10.692 4.79 16.28a23.782 23.782 0 0 0 12.77 15.258c5.16 2.469 13.644 3.88 15.815 1.949 1.652-1.466.26-7.963.873-10.098a89.1 89.1 0 0 0 3.174-16.706 32.075 32.075 0 0 1 1.337-7.611c.909-2.747 1.856-5.569 2.691-8.26.835-2.692 2.413-5.123 2.32-8.465a31.86 31.86 0 0 0-2.134-9.281 397.13 397.13 0 0 0-3.137-8.91 45.077 45.077 0 0 0-4.697-10.098 23.769 23.769 0 0 0-15.926-10.024c-5.29-.779-11.138.446-16.205-1.485-5.068-1.93-9.43-4.807-14.702-6.181a18.84 18.84 0 0 0-16.28 2.747 9.957 9.957 0 0 0-4.176 8.428Z"
      fill="#F1A8A8"
    />
    <Path
      d="M89.627 519.447a.826.826 0 0 0 .59-.213.828.828 0 0 0 .264-.567c2.73-50.954-44.55-73.804-56.318-76.719a.837.837 0 0 0-.984.594.817.817 0 0 0 .594.984c11.564 2.859 57.767 25.189 55.093 75.067a.78.78 0 0 0 .761.854Z"
      fill="#fff"
    />
    <Path
      d="M42.442 477.848a.78.78 0 0 0 .482-.093 43.204 43.204 0 0 1 36.698 1.467.838.838 0 0 0 1.096-.372.818.818 0 0 0-.372-1.095 44.78 44.78 0 0 0-38.164-1.429.798.798 0 0 0-.353 1.095.822.822 0 0 0 .613.427Z"
      fill="#fff"
    />
    <Path
      d="M751.251 221.797c-4.771 10.599-21.941 51.177-21.941 51.177l21.941 16.873c1.856-2.729 13.309-36.215 13.309-36.215l8.631-45.924c-11.044.297-17.541 4.362-21.94 14.089Z"
      fill="#E6E6E6"
    />
    <Path
      d="M766.602 516.124c-.409.278-1.374 1.392-3.082 2.97a61.867 61.867 0 0 1-5.568 4.251 101.005 101.005 0 0 1-12.233 7.666c-5.049 2.784-9.597 4.474-15.63 7.202a4.794 4.794 0 0 0-1.689 1.114 3.468 3.468 0 0 0-.742 1.503 6.25 6.25 0 0 0 2.524 6.033 16.714 16.714 0 0 0 6.311 2.692 32.548 32.548 0 0 0 15.741.464 44.714 44.714 0 0 0 12.641-5.847 28.367 28.367 0 0 1 10.822-4.641c3.379-.482 7.555-.334 10.488-2.357 3.267-2.246 1.968-7.054 1.04-11.138-1.003-4.51-1.244-11.379-3.286-11.23l-.167 1.856c-.167 1.949-4.882 2.84-8.539 2.84-3.025 0-8.743-.779-8.724-2.209l.093-1.169ZM819.319 541.072l-.278-2.395c-.873 0-1.263.984-1.615 3.1a45.721 45.721 0 0 1-4.233 12.994 64.114 64.114 0 0 1-9.615 14.59 61.39 61.39 0 0 0-5.105 5.903c-1.002 1.856-1.355 6.701.335 7.815 6.218 4.158 15.666 4.269 22.275 2.32a12.863 12.863 0 0 0 9.708-10.915c1.076-6.088 1.652-9.838 4.752-14.441 1.132-1.689 2.97-3.044 3.712-4.938 1.374-3.378.26-7.183-.612-10.562-.761-2.914-1.597-6.107-2.599-5.847v1.856c-1.188 1.337-5.383 2.636-9.114 2.785-2.302.018-7.63-.427-7.611-2.265Z"
      fill="#263238"
    />
    <Path
      d="M835.988 322.461c.316 15.482 3.583 117.798 3.583 117.798a226.252 226.252 0 0 1 2.042 25.208c.427 20.419-.798 73.415-.798 73.415-1.318 8.631-23.89 7.109-25.988 3.118 0 0-5.569-75.716-8.167-95.968-2.228-17.653-9.579-75.382-9.579-75.382l-5.773 56.745c.232 6.624.083 13.255-.445 19.862-.65 9.281-3.713 71.837-3.713 71.837 0 3.434-22.46 7.332-25.579-.668 0 0-1.633-85.982-1.856-94.576-.186-9.783 1.17-102.428 1.17-102.428l75.103 1.039Z"
      fill="#37474F"
    />
    <Path
      d="M795.262 348.987a51.604 51.604 0 0 1-17.393-11.137s2.766 8.093 14.145 14.85l-.743 70.537 5.736-52.55-1.745-21.7Z"
      fill="#263238"
    />
    <Path
      d="M810.316 205.685c2.896.687 12.066 3.155 19.509 5.791a20.712 20.712 0 0 1 9.393 7.184c5.402 7.889.538 25.431-3.452 58.472.315 21.328.928 47.854.928 47.854-9.82 7.146-55.576 11.991-76.218-3.081 0 0-.687-80.061.687-90.474 2.599-19.918 10.413-25.245 26.507-26.192l22.646.446Z"
      fill="#455A64"
    />
    <Path
      d="M782.194 229.074c-1.448 4.974-10.005 40.002-8.798 52.327a76.537 76.537 0 0 0 5.086 18.414c3.712 8.78 6.181.928 8.26-4.251a65.84 65.84 0 0 0 3.471-14.98c.687-6.608-1.967-26.73-.947-50.304a5.052 5.052 0 0 0-3.285-2.07 5.058 5.058 0 0 0-3.787.864Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="M782.194 229.074c-1.448 4.974-10.005 40.002-8.798 52.327a76.537 76.537 0 0 0 5.086 18.414c3.712 8.78 6.181.928 8.26-4.251a65.84 65.84 0 0 0 3.471-14.98c.687-6.608-1.967-26.73-.947-50.304a5.052 5.052 0 0 0-3.285-2.07 5.058 5.058 0 0 0-3.787.864Z"
      fill="#000"
    />
    <Path
      d="M781.804 217.825c-3.397 2.895.316 11.249.316 11.249a9.594 9.594 0 0 1 7.072 1.206s6.757-2.896 6.776-7.889c0-4.566-4.845-3.1-8.279-3.712-3.434-.613-5.402-3.286-5.885-.854Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="M781.804 217.825c-3.397 2.895.316 11.249.316 11.249a9.594 9.594 0 0 1 7.072 1.206s6.757-2.896 6.776-7.889c0-4.566-4.845-3.1-8.279-3.712-3.434-.613-5.402-3.286-5.885-.854Z"
      fill="#000"
    />
    <Path
      opacity={0.2}
      d="M782.194 229.074c.557-1.968 3.212-1.857 5.346-2.451a5.735 5.735 0 0 0 2.859-2.45c1.095-1.856 0-5.03-1.541-5.365 3.304.242 7.184-.501 7.184 3.583 0 4.993-6.775 7.889-6.775 7.889a9.597 9.597 0 0 0-7.073-1.206Z"
      fill="#000"
    />
    <Path
      d="M805.36 157.088a21.532 21.532 0 1 1-43.053-1.013 21.532 21.532 0 0 1 43.053 1.013ZM820.674 172.773s-3.879 16.298-5.327 18.953a7.43 7.43 0 0 1-4.381 3.36l.52-11.528 9.188-10.785ZM816.869 149.181a8.072 8.072 0 0 1 6.627 2.505c2.803 3.008.984 12.159-2.822 21.087l-6.589.594 2.784-24.186Z"
      fill="#263238"
    />
    <Path
      d="M812.117 173.33c1.299.817 3.248-1.67 4.919-3.341 1.671-1.671 7.017-3.861 9.523 1.856 2.506 5.718-2.636 13.143-6.85 14.349-7.258 2.079-8.223-2.561-8.223-2.561l-1.225 29.384c-5.142 6.274-17.226 6.627-23.203 5.402-4.79-.966-4.864-4.363-.149-9.56l.223-8.427a45.877 45.877 0 0 1-9.708.278c-5.272-.947-8.521-5.197-9.968-10.97-2.228-8.818-2.766-18.656-.557-34.768 2.728-19.788 25.987-19.435 38.22-11.286s6.998 29.644 6.998 29.644Z"
      fill="#B16668"
    />
    <Path
      d="M812.061 175.539c.668 0 3.286-4.102 4.975-5.568 2.32-2.005-.167-20.809-.167-20.809a9.081 9.081 0 0 0-1.318-10.135c-4.604-5.309-10.655-6.868-17.56-6.701-2.525 0-5.012.278-7.537.315a27.6 27.6 0 0 1-8.835-1.522c-3.861-1.243-7.592-2.914-11.546-3.842a3.818 3.818 0 0 0-3.713.482 3.462 3.462 0 0 0-.612 2.84 11.937 11.937 0 0 0 2.766 6.219 16.681 16.681 0 0 0-4.084-.204 5.57 5.57 0 0 0-3.713 1.726 5.222 5.222 0 0 0-.631 5.476 9.278 9.278 0 0 0 3.973 4.102 15.292 15.292 0 0 0 10.822 1.708 7.592 7.592 0 0 0 4.566 4.195 24.133 24.133 0 0 0 19.546-.185 27.153 27.153 0 0 0 2.228 2.895 14.818 14.818 0 0 0 3.044 2.302 13.39 13.39 0 0 0 1.634.817c.636.154 1.257.365 1.856.631.687.52.612 1.856.742 2.766.353 2.357.65 4.733 1.095 7.091a11.143 11.143 0 0 0 1.578 4.863 1.264 1.264 0 0 0 .891.538Z"
      fill="#263238"
    />
    <Path
      d="m816.813 150.647 8.075-4.084a4.392 4.392 0 0 0-2.522-2.343 4.39 4.39 0 0 0-3.437.19 4.771 4.771 0 0 0-2.116 6.237Z"
      fill="#263238"
    />
    <Path
      d="M787.132 200.357s11.861-2.097 16.056-4.195a13.648 13.648 0 0 0 5.848-5.568 18.853 18.853 0 0 1-3.416 6.589c-3.1 3.713-18.563 6.497-18.563 6.497l.075-3.323Z"
      fill="#9A4A4D"
    />
    <Path
      d="M791.086 170.435a2.372 2.372 0 0 0 1.632 2.052 2.37 2.37 0 0 0 1.368.03 2.37 2.37 0 0 0 1.722-1.977 2.378 2.378 0 0 0-2.476-2.686 2.42 2.42 0 0 0-2.128 1.644c-.101.301-.141.62-.118.937ZM792.645 161.525l5.197 2.524a3.042 3.042 0 0 0-1.392-3.972 2.817 2.817 0 0 0-3.805 1.448ZM768.142 163.808l5.569-1.856a2.8 2.8 0 0 0-2.5-2.009 2.809 2.809 0 0 0-1.12.152 3.028 3.028 0 0 0-1.949 3.713ZM770.834 169.766a2.373 2.373 0 0 0 1.331 2.255 2.376 2.376 0 1 0 1.268-4.501 2.431 2.431 0 0 0-2.599 2.246Z"
      fill="#000"
    />
    <Path
      d="m783.642 168.411-.854 14.238-7.425-2.562 8.279-11.676ZM791.68 185.08l-9.282 2.543a4.753 4.753 0 0 0 5.848 3.564 5.046 5.046 0 0 0 3.434-6.107Z"
      fill="#9A4A4D"
    />
    <Path
      d="M791.698 187.438a4.231 4.231 0 0 0-1.281-.223 4.417 4.417 0 0 0-4.38 3.991h.167c.66.147 1.344.147 2.004 0a5.017 5.017 0 0 0 3.49-3.768Z"
      fill="#F28F8F"
    />
    <Path
      d="M787.057 218.344c6.572-1.373 22.09-7.425 23.593-15.054l.205-4.176c2.543 0 3.712 2.858 4.974 6.923.52 10.748-10.803 20.549-22.182 23.185a19.787 19.787 0 0 0-2.506-7.054 9.579 9.579 0 0 0-4.084-3.824Z"
      fill="#37474F"
    />
    <Path
      d="M810.595 204.645s-4.845 24.336-6.961 40.838c-2.116 16.502-1.689 62.927-.575 92.441.816 21.495 4.028 61.256 4.028 61.256s5.308 1.077 18.117-1.039a50.138 50.138 0 0 0 18.748-6.423s-2.024-60.402-3.546-81.489c-1.41-19.435-1.355-30.146-1.355-30.146l4.585-44.383s-.353-27.843-9.021-29.069c-8.984-1.392-24.02-1.986-24.02-1.986Z"
      fill="#FAFAFA"
    />
    <Path
      d="M818.929 244.48c0-1.021 0-3.23.279-5.995.854-13.607-.39-33.283-4.975-34.267l-.408 1.857c3.36.724 3.879 25.672 3.471 32.28-.186 2.84-.334 5.086-.279 6.218l1.912-.093Z"
      fill="#F1A8A8"
    />
    <Path
      d="M834.875 351.289c2.376-4.047 2.505-7.054 6.088-10.673a11.955 11.955 0 0 0 3.23-5.402 39.537 39.537 0 0 0 1.299-10.154l20.79.965a131.256 131.256 0 0 1-2.543 22.999c-3.972 17.319-7.685 24.54-15.24 26.396-11.898 2.692-14.85-2.431-10.654-11.026 1.8-3.582 3.088-7.4 3.823-11.342a21.169 21.169 0 0 1-4.436 3.954 4.175 4.175 0 0 1-5.662-.612c-.519-.836 1.43-1.986 3.305-5.105Z"
      fill="#B16668"
    />
    <Path
      d="M831.106 206.502s10.284-.149 16.057 5.049c5.773 5.197 8.891 19.082 12.604 33.022 3.712 13.941 7.054 27.473 8.037 37.125.836 8.093-.501 50.119-.501 50.119s-14.274 3.026-23.333-1.856l.743-47.26-14.962-47.465a44.647 44.647 0 0 1 1.355-28.734Z"
      fill="#F0F0F0"
    />
    <Path
      d="M810.929 196.608c1.355-.26 2.599.445 3.712 2.32a700.358 700.358 0 0 0 3.527 5.81s1.244 12.994 0 16.317c-1.076 2.821-8.297 16.13-8.297 16.13s.482 12.994.594 16.707c.111 3.712-7.425 19.954-8.186 27.435 0 0-.371-30.405.724-38.851 1.856-14.683 4.38-33.079 7.647-39.112l.279-6.756Z"
      fill="#EBEBEB"
    />
    <Path
      d="M816.089 287.378a10.277 10.277 0 0 1-5.865 1.708l.167-1.336a8.245 8.245 0 0 0 4.51-1.244 16.395 16.395 0 0 1-3.36-3.62c-1.856-2.97-4.937-23.259-3.081-27.844 1.522-3.712 6.014-11.88 9.411-11.88h.093c3.416.149 7.24 8.743 8.465 12.66 1.466 4.641-3.267 24.614-5.439 27.417a15.937 15.937 0 0 1-3.713 3.304 8.56 8.56 0 0 0 4.4 1.634v1.355h-.167a10.71 10.71 0 0 1-5.421-2.154Zm-6.441-31.76c-1.708 4.139 1.188 23.778 2.97 26.544a15.738 15.738 0 0 0 3.471 3.601 14.84 14.84 0 0 0 3.713-3.304c2.005-2.58 6.552-21.922 5.216-26.191-1.634-5.235-5.235-11.621-7.239-11.695-1.931 0-6.015 5.996-8.131 11.045Z"
      fill="#455A64"
    />
    <Path
      d="M808.33 288.344a1.97 1.97 0 0 0 3.282 1.57 1.963 1.963 0 0 0-.144-3.041 1.964 1.964 0 0 0-2.49.118 1.967 1.967 0 0 0-.648 1.353ZM819.783 288.826a1.988 1.988 0 0 0 3.312 1.604 1.987 1.987 0 0 0-1.251-3.46 1.987 1.987 0 0 0-2.061 1.856Z"
      fill="#263238"
    />
    <Path
      d="M808.126 263.897h1.132a.446.446 0 0 0 .409-.464 22.661 22.661 0 0 1 .334-7.611c1.986-4.845 6.107-10.915 7.889-10.84 1.782.074 5.364 6.478 6.942 11.471.31 2.532.197 5.098-.334 7.592a.446.446 0 0 0 .353.501l1.132.167a.443.443 0 0 0 .501-.371c.607-2.801.682-5.691.223-8.52-.835-2.673-4.789-12.715-8.762-12.882-3.972-.167-8.78 9.504-9.838 12.102a22.655 22.655 0 0 0-.501 8.502.446.446 0 0 0 .52.353Z"
      fill="#F1A8A8"
    />
    <Path
      d="M787.095 201.434c-1.857.148-2.562 2.599-6.646 3.712-7.61 2.265-13.569 3.156-16.706 7.277-5.105 6.812-4.102 12.381-5.383 46.87-1.281 34.489-1.689 132.704-1.689 132.704a26.466 26.466 0 0 0 11.638 2.357s-.297-108.386.427-132.778c.724-24.391 4.418-36.252 9.69-44.29a29.703 29.703 0 0 1 8.409-14.311l.26-1.541Z"
      fill="#FAFAFA"
    />
    <Path
      d="M778.482 217.379c-5.272 8.038-8.966 19.918-9.69 44.29-.093 2.915-.167 6.98-.241 11.88 0 0-4.344-22.089-3.564-26.785.779-4.697 5.847-14.423 5.847-14.423a43.591 43.591 0 0 1 4.306-22.74 15.507 15.507 0 0 1 2.692-3.526 26.868 26.868 0 0 1 3.286-2.228 11.894 11.894 0 0 1 5.977-2.413v1.689a29.545 29.545 0 0 0-8.613 14.256Z"
      fill="#EBEBEB"
    />
    <Path
      d="M787.058 203.123a22.138 22.138 0 0 0-5.718 4.901 18.823 18.823 0 0 0-2.134 18.896 15.864 15.864 0 0 1 3.378-5.717 10.086 10.086 0 0 1 4.474-2.859c-2.098-2.227-1.281-6.125-.149-9.559l.149-5.662Z"
      fill="#37474F"
    />
    <Path
      d="m706.478 250.513 60.124 21.217 16.539 71.54-1.337 1.429-57.172-21.347-19.509-71.409 1.355-1.43Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.35}
      d="m765.247 273.159 16.557 71.54 1.337-1.429-16.539-71.54-1.355 1.429Z"
      fill="#000"
    />
    <Path
      opacity={0.25}
      d="m705.123 251.943 60.124 21.216 16.557 71.54-57.172-21.347-19.509-71.409Z"
      fill="#000"
    />
    <Path
      d="M733.542 308.818c-.557 1.392-5.16 4.492-11.824 3.935l-1.17-4.158 12.994.223Z"
      fill="#000"
      opacity={0.2}
    />
    <Path
      d="m723.24 266.607-.502-2.413c-.259-1.318 3.267-.631 7.89 1.559l10.859 3.286c4.622 2.19 8.984 3.805 9.281 5.141l.501 2.395-28.029-9.968Z"
      fill="#000"
      opacity={0.1}
    />
    <Path
      d="M719.676 255.748c-.186-.835 1.188-.65 3.118-.149l3.453 1.188s-1.411-3.489 6.794-1.262c9.003 2.432 9.281 6.942 9.281 6.942a39.53 39.53 0 0 0 4.214 1.578c2.988.966 3.322 1.56 3.564 2.395l.445 1.522-30.647-10.785s-.055-.557-.222-1.429Z"
      fill="#37474F"
    />
    <Path
      d="M722.887 260.333c2.84 1.281 4.028 1.856 4.436 3.954.483 2.338.594 3.304.594 3.304l15.964 6.292s-.872-3.434-1.076-4.566c-.205-1.132 1.856-1.169 4.659.111l-.669-2.858-24.799-9.281.891 3.044Z"
      fill="#37474F"
    />
    <Path
      d="m722.404 266.644-.501-2.395c-.278-1.336 3.249-.631 7.889 1.541l10.841 3.286c4.622 2.19 9.003 3.712 9.281 5.142l.483 2.394-27.993-9.968Z"
      fill="#37474F"
    />
    <Path
      d="M711.824 276.426s2.116 7.834 2.673 9.82c.557 1.986 10.413 6.534 15.351 7.147 9.43 1.169 10.488 3.229 8.223 9.912-2.097 6.181-8.223 8.501-16.706 7.425a15.413 15.413 0 0 1-11.676-10.191c-2.747-7.889-1.262-16.316-2.357-20.92-1.096-4.603 2.079-5.94 4.492-3.193Z"
      fill="#B16668"
    />
    <Path
      d="M663.766 684.894c.204-5.569-3.861-60.606-3.861-60.606l26.767 19.416c-5.569 32.8-6.664 40.522-6.757 45.886.111.45.161.912.148 1.374 0 6.367-7.22 9.801-12.826 14.052a105.366 105.366 0 0 1-15.166 9.912c-4.065 2.042-12.344 2.228-13.365-.39 6.813-5.216 16.706-12.993 22.164-18.97 2.599-2.84 2.71-5.087 2.896-10.674Z"
      fill="#FFA8A7"
    />
    <Path
      d="M627.068 721.388a5.452 5.452 0 0 0 1.021 5.569 23.461 23.461 0 0 0 17.151 3.898c8.502-1.095 13.105-3.713 16.521-6.59 3.415-2.877 8.167-6.868 12.01-7.722a23.061 23.061 0 0 0 8.613-3.508c1.132-1.095.984-6.014.984-6.014l-56.3 14.367Z"
      fill="#455A64"
    />
    <Path
      d="M679.971 688.031a1.355 1.355 0 0 1 1.28 1.151c.279 1.355.557 5.29 1.114 7.518a39.864 39.864 0 0 1 .835 11.936c-.464 2.524-6.292 4.269-10.023 5.68a47.134 47.134 0 0 0-12.066 7.629 33.945 33.945 0 0 1-17.95 6.682c-6.552 0-13.94-2.171-15.648-5.216-2.079-3.712-.445-5.94 9.281-10.45 1.857-.854 8.316-4.678 10.525-6.07 5.959-3.713 11.862-8.985 14.609-15.63a3.712 3.712 0 0 1 1.392-2.023 3.001 3.001 0 0 1 1.856-.167c1.629.225 3.213.701 4.697 1.411 1.299.612 3.155 1.336 3.118 3.007a4.936 4.936 0 0 1-.371 1.707c-.112.334-.204.799.093.984.297.186.668 0 .909-.241a25.487 25.487 0 0 0 2.636-3.156 8.185 8.185 0 0 1 1.541-1.559 16.766 16.766 0 0 0 1.652-1.077c.631-.594.501-1.336.52-2.116Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.9}
      d="M679.971 688.031a1.355 1.355 0 0 1 1.28 1.151c.279 1.355.557 5.29 1.114 7.518a39.864 39.864 0 0 1 .835 11.936c-.464 2.524-6.292 4.269-10.023 5.68a47.134 47.134 0 0 0-12.066 7.629 33.945 33.945 0 0 1-17.95 6.682c-6.552 0-13.94-2.171-15.648-5.216-2.079-3.712-.445-5.94 9.281-10.45 1.857-.854 8.316-4.678 10.525-6.07 5.959-3.713 11.862-8.985 14.609-15.63a3.712 3.712 0 0 1 1.392-2.023 3.001 3.001 0 0 1 1.856-.167c1.629.225 3.213.701 4.697 1.411 1.299.612 3.155 1.336 3.118 3.007a4.936 4.936 0 0 1-.371 1.707c-.112.334-.204.799.093.984.297.186.668 0 .909-.241a25.487 25.487 0 0 0 2.636-3.156 8.185 8.185 0 0 1 1.541-1.559 16.766 16.766 0 0 0 1.652-1.077c.631-.594.501-1.336.52-2.116Z"
      fill="#fff"
    />
    <Path
      d="M661.148 721.945a71.665 71.665 0 0 1 5.309-3.954s-11.434 6.961-16.057 3.713a4.141 4.141 0 0 1-1.484-5.309c0-.78-7.277-4.158-10.173-4.511-.817.446-1.485.817-1.856 1.002-9.727 4.511-11.379 6.701-9.281 10.451 1.707 3.044 7.053 5.42 15.537 5.42 5.457.075 13.977-3.508 18.005-6.812Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.8}
      d="M661.148 721.945a71.665 71.665 0 0 1 5.309-3.954s-11.434 6.961-16.057 3.713a4.141 4.141 0 0 1-1.484-5.309c0-.78-7.277-4.158-10.173-4.511-.817.446-1.485.817-1.856 1.002-9.727 4.511-11.379 6.701-9.281 10.451 1.707 3.044 7.053 5.42 15.537 5.42 5.457.075 13.977-3.508 18.005-6.812Z"
      fill="#fff"
    />
    <Path
      d="M649.677 714.167c-6.052 2.432-3.471 3.564-1.857 3.806 1.615.241 11.88-5.718 18.08-10.618 6.2-4.901 10.562-10.729 12.994-12.623.891-.705 1.856-1.559 2.729-2.357-.167-1.337-.297-2.562-.427-3.193a1.36 1.36 0 0 0-1.281-1.151c0 .78.111 1.522-.483 2.116-.529.391-1.08.751-1.652 1.077a8.18 8.18 0 0 0-1.54 1.559 25.575 25.575 0 0 1-2.636 3.156 78.621 78.621 0 0 1-23.927 18.228Z"
      fill="#F1A8A8"
    />
    <Path
      d="M648.322 717.082a.63.63 0 0 0 .389-.149.612.612 0 0 0 0-.835 14.302 14.302 0 0 0-11.137-4.362.577.577 0 0 0-.222.07.585.585 0 0 0-.287.356.593.593 0 0 0 .05.455.592.592 0 0 0 .589.307 13.343 13.343 0 0 1 10.024 3.935.588.588 0 0 0 .594.223ZM653.593 713.833a.7.7 0 0 0 .39-.13.586.586 0 0 0 .181-.427.601.601 0 0 0-.181-.427 14.443 14.443 0 0 0-11.137-4.362.593.593 0 0 0-.52.65.581.581 0 0 0 .213.404.592.592 0 0 0 .436.134 13.314 13.314 0 0 1 10.024 3.935.616.616 0 0 0 .594.223ZM658.884 710.752a.583.583 0 0 0 .371-.13.615.615 0 0 0 0-.854 14.417 14.417 0 0 0-11.138-4.362.61.61 0 0 0-.538.668.609.609 0 0 0 .072.223.59.59 0 0 0 .596.297 13.346 13.346 0 0 1 10.024 3.935.555.555 0 0 0 .613.223ZM663.766 707.243a.624.624 0 0 0 .389-.148.614.614 0 0 0 0-.835 14.36 14.36 0 0 0-11.137-4.363.584.584 0 0 0-.399.214.592.592 0 0 0-.121.436.581.581 0 0 0 .415.521.565.565 0 0 0 .235.017 13.36 13.36 0 0 1 10.024 3.936.616.616 0 0 0 .594.222ZM668.573 703.735a.631.631 0 0 0 .483-.984 14.699 14.699 0 0 0-12.103-4.418.583.583 0 0 0-.521.416.616.616 0 0 0 .207.64c.125.1.284.148.444.132a13.603 13.603 0 0 1 11.026 3.973.607.607 0 0 0 .464.241Z"
      fill="#FAFAFA"
    />
    <Path
      d="m715.444 643.89 27.008-.483c-.39 18.024-3.267 38.034-3.712 54.963-.093 3.175 0 24.744-1.114 31.297-1.856 9.838-13.495 8.52-14.126 2.617-.631-5.903.761-31.055.724-33.654 0-10.859-4.994-29.161-7.648-48.652-.149-.947-1.002-5.16-1.132-6.088Z"
      fill="#FFA8A7"
    />
    <Path
      d="M719.082 736.386c-.242 5.792.26 9.69 1.967 13.273a8.52 8.52 0 0 0 9.449 4.436c3.991-.612 9.281-2.747 10.877-7.592a31.718 31.718 0 0 0 1.374-13.699l-23.667 3.582Z"
      fill="#455A64"
    />
    <Path
      d="M738.795 696.292c1.578.297 1.281 8.056 1.43 15.889.167 8.39 2.301 12.066 2.487 18.952.241 7.871-.52 11.286-3.713 15.723-3.192 4.436-13.29 8.056-17.356.854-3.322-5.866-2.858-11.973-1.726-19.695a195.345 195.345 0 0 0 2.339-19.695c.278-5.847.427-11.509 1.856-12.028v2.821c0 .854-.13 3.805.761 3.935.137-.723.217-1.455.242-2.19a2.576 2.576 0 0 1 .631-1.856 2.928 2.928 0 0 1 1.856-.613 47.234 47.234 0 0 1 4.845-.26c.813.003 1.624.053 2.432.149a6.75 6.75 0 0 1 2.004.482 1.455 1.455 0 0 1 .78.873c.055.3.055.609 0 .909a6.712 6.712 0 0 0 0 1.615c0 .223.167.52.408.483.242-.037.279-.241.297-.427.112-.947 0-1.633.13-2.729v-1.707c0-.502.279-.984.297-1.485Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.9}
      d="M738.795 696.292c1.578.297 1.281 8.056 1.43 15.889.167 8.39 2.301 12.066 2.487 18.952.241 7.871-.52 11.286-3.713 15.723-3.192 4.436-13.29 8.056-17.356.854-3.322-5.866-2.858-11.973-1.726-19.695a195.345 195.345 0 0 0 2.339-19.695c.278-5.847.427-11.509 1.856-12.028v2.821c0 .854-.13 3.805.761 3.935.137-.723.217-1.455.242-2.19a2.576 2.576 0 0 1 .631-1.856 2.928 2.928 0 0 1 1.856-.613 47.234 47.234 0 0 1 4.845-.26c.813.003 1.624.053 2.432.149a6.75 6.75 0 0 1 2.004.482 1.455 1.455 0 0 1 .78.873c.055.3.055.609 0 .909a6.712 6.712 0 0 0 0 1.615c0 .223.167.52.408.483.242-.037.279-.241.297-.427.112-.947 0-1.633.13-2.729v-1.707c0-.502.279-.984.297-1.485Z"
      fill="#fff"
    />
    <Path
      d="M742.582 728.887c0 1.133.241 8.65-1.856 9.783-2.098 1.132-2.952-4.734-2.952-4.734s-4.956-2.079-12.752.446c.26 1.225-1.281 5.086-3.249 4.046-1.967-1.039-1.856-9.634-1.856-9.745-1.058 7.425-1.392 13.328 1.856 19.027 4.065 7.202 14.163 3.564 17.356-.854 3.193-4.418 3.954-7.852 3.713-15.723-.149-.779-.204-1.522-.26-2.246Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.8}
      d="M742.582 728.887c0 1.133.241 8.65-1.856 9.783-2.098 1.132-2.952-4.734-2.952-4.734s-4.956-2.079-12.752.446c.26 1.225-1.281 5.086-3.249 4.046-1.967-1.039-1.856-9.634-1.856-9.745-1.058 7.425-1.392 13.328 1.856 19.027 4.065 7.202 14.163 3.564 17.356-.854 3.193-4.418 3.954-7.852 3.713-15.723-.149-.779-.204-1.522-.26-2.246Z"
      fill="#fff"
    />
    <Path
      d="M737.087 734.382c-.241 2.803 2.618 2.134 3.137-1.857.52-3.99-.705-13.587 0-20.418-.148-7.834.149-15.593-1.429-15.89a1.637 1.637 0 0 0 0 .297l-.185 3.935c0 .576 0 1.096-.093 1.708-.52 5.717-.836 16.874-.873 22.145a86.382 86.382 0 0 1-.557 10.08ZM725.839 734.382a95.822 95.822 0 0 1-.539-10.005c0-5.235-.334-16.261-.872-22.016a15.637 15.637 0 0 1-.223-3.248v-2.803c-1.058.371-1.429 3.471-1.671 7.425 0 2.265 0 5.068.223 8.483.706 6.813-.538 16.502 0 20.419.538 3.917 3.36 4.548 3.082 1.745Z"
      fill="#F1A8A8"
    />
    <Path
      d="M724.78 734.586h.223a18.93 18.93 0 0 1 12.511 0 .614.614 0 0 0 .427-1.132 19.986 19.986 0 0 0-13.383 0 .608.608 0 0 0-.334.779.624.624 0 0 0 .556.353ZM724.057 728.349h.204a23.367 23.367 0 0 1 13.996 0 .612.612 0 0 0 .735-.848.602.602 0 0 0-.345-.303 24.428 24.428 0 0 0-14.85 0 .594.594 0 0 0 .204 1.151h.056ZM738.461 722.539a.578.578 0 0 0 .538-.353.608.608 0 0 0-.297-.798 20.572 20.572 0 0 0-14.757 0 .595.595 0 0 0-.011 1.103.612.612 0 0 0 .457.011s7.425-2.877 13.81 0c.083.03.172.042.26.037ZM738.591 716.97a.57.57 0 0 0 .538-.353.588.588 0 0 0-.091-.658.6.6 0 0 0-.187-.14 18.558 18.558 0 0 0-15.054-.167.6.6 0 0 0-.306.341.588.588 0 0 0 .027.457.61.61 0 0 0 .798.297 17.62 17.62 0 0 1 14.015.149.46.46 0 0 0 .26.074Z"
      fill="#FAFAFA"
    />
    <Path
      d="M738.721 710.789a.614.614 0 0 0 .26-1.151c-7.147-3.527-14.85-.149-15.166 0a.597.597 0 0 0-.306.789.597.597 0 0 0 .789.306s7.518-3.285 14.163 0a.43.43 0 0 0 .26.056Z"
      fill="#EBEBEB"
    />
    <Path
      d="M728.92 457.318a110.672 110.672 0 0 1 8.483 31.816c3.081 39.928 1.411 97.231 1.411 97.231.352 4.176 3.007 13.643 4.77 32.001 2.116 21.7-.519 95.207-.519 95.207s-12.994 7.722-23.074-1.076c0 0-11.75-101.055-14.46-122.513-2.376-18.822-9.021-59.548-9.021-59.548l-7.759 60.216s.724 16.948.222 27.844c-.594 12.734-4.158 77.61-4.158 77.61s-17.82 6.033-25.783-2.543l-2.172-105.305c-.519-30.053 0-84.812 4.882-116.331l67.178-14.609Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.6}
      d="M728.92 457.318a110.672 110.672 0 0 1 8.483 31.816c3.081 39.928 1.411 97.231 1.411 97.231.352 4.176 3.007 13.643 4.77 32.001 2.116 21.7-.519 95.207-.519 95.207s-12.994 7.722-23.074-1.076c0 0-11.75-101.055-14.46-122.513-2.376-18.822-9.021-59.548-9.021-59.548l-7.759 60.216s.724 16.948.222 27.844c-.594 12.734-4.158 77.61-4.158 77.61s-17.82 6.033-25.783-2.543l-2.172-105.305c-.519-30.053 0-84.812 4.882-116.331l67.178-14.609Z"
      fill="#fff"
    />
    <Path
      opacity={0.2}
      d="m696.51 530.343-2.785-19.175s-16.186-2.154-23.518-9.04a26.627 26.627 0 0 0 19.082 12.047l2.859 17.319-2.469 51.826 6.831-52.977Z"
      fill="#000"
    />
    <Path
      d="M707.907 428.026a41.81 41.81 0 0 0 9.653-.389 15.938 15.938 0 0 0 11.267-7.425 16.239 16.239 0 0 1 1.968-3.509c1.41-1.54 3.712-1.986 5.568-2.988a11.887 11.887 0 0 0 5.773-8.873 20.822 20.822 0 0 0-1.726-10.618c-1.355-3.378-4.084-6.46-5.717-9.727a44.736 44.736 0 0 1-5.922-18.989c-.519-6.516-.297-13.254-.519-19.825-.242-7.425-.409-17.857-9.374-25.987a13.634 13.634 0 0 0-8.019-3.472c-3.713-6.793-11.138-9.782-17.338-10.84a48.26 48.26 0 0 0-15.722.26 26.86 26.86 0 0 0-14.015 6.701 19.746 19.746 0 0 0-5.977 13.291 16.183 16.183 0 0 0 3.917 11.137 8.934 8.934 0 0 0-2.655 4.455c-.464 3.713.501 8.112 6.776 10.507l9.132 8.483a91.972 91.972 0 0 0-2.877 18.265c0 3.954 0 7.926.371 11.861.984 11.769 4.066 25.71 14.72 32.912a26.838 26.838 0 0 0 10.265 4.065 26.31 26.31 0 0 0 4.511.52c1.875-.019 3.935.093 5.94.185Z"
      fill="#F1A8A8"
    />
    <Path
      d="M707.907 428.026a41.81 41.81 0 0 0 9.653-.389 15.938 15.938 0 0 0 11.267-7.425 16.239 16.239 0 0 1 1.968-3.509c1.41-1.54 3.712-1.986 5.568-2.988a11.887 11.887 0 0 0 5.773-8.873 20.822 20.822 0 0 0-1.726-10.618c-1.355-3.378-4.084-6.46-5.717-9.727a44.736 44.736 0 0 1-5.922-18.989c-.519-6.516-.297-13.254-.519-19.825-.242-7.425-.409-17.857-9.374-25.987a13.634 13.634 0 0 0-8.019-3.472c-3.713-6.793-11.138-9.782-17.338-10.84a48.26 48.26 0 0 0-15.722.26 26.86 26.86 0 0 0-14.015 6.701 19.746 19.746 0 0 0-5.977 13.291 16.183 16.183 0 0 0 3.917 11.137 8.934 8.934 0 0 0-2.655 4.455c-.464 3.713.501 8.112 6.776 10.507l9.132 8.483a91.972 91.972 0 0 0-2.877 18.265c0 3.954 0 7.926.371 11.861.984 11.769 4.066 25.71 14.72 32.912a26.838 26.838 0 0 0 10.265 4.065 26.31 26.31 0 0 0 4.511.52c1.875-.019 3.935.093 5.94.185Z"
      fill="#000"
      opacity={0.2}
    />
    <Path
      d="m650.141 479.092 12.641 15.667s8.798-14.591 8.446-14.591c-.353 0-11.138-17.69-11.138-17.69l-9.949 16.614Z"
      fill="#FFC4C3"
    />
    <Path
      d="M675.701 378.576c-9.931-.149-15.258 4.306-22.553 15.741-6.2 9.745-20.586 33.078-24.633 40.113-4.566 7.945-5.216 11.138-1.633 17.115 3.397 5.569 25.078 30.591 25.078 30.591a37.13 37.13 0 0 0 10.822-15.964l-12.641-21.142 24.298-35.919 1.262-30.535Z"
      fill="#455A64"
    />
    <Path
      d="M676.332 378.13c-7.183-.371-11.638 1.114-15.963 5.309-4.325 4.195-26.6 41.543-26.6 41.543s1.058 6.664 19.861 16.706l20.512-24.038 2.19-39.52Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.6}
      d="M676.332 378.13c-7.183-.371-11.638 1.114-15.963 5.309-4.325 4.195-26.6 41.543-26.6 41.543s1.058 6.664 19.861 16.706l20.512-24.038 2.19-39.52Z"
      fill="#fff"
    />
    <Path
      d="M663.004 428.472c.706 7.87.891 23.277.891 23.277-2.858 20.122-4.455 39.91-4.455 39.91 30.053 16.056 74.752 2.932 80.45-3.713-2.821-19.713-9.04-31.983-13.105-43.083l7.537-33.543s-12.994-10.785-11.806-32.67l-17.765-1.856-16.947.482-12.047.854a101.828 101.828 0 0 0-15.741 19.621c-5.903 9.504-6.126 21.235 2.988 30.721Z"
      fill="#F1A8A8"
    />
    <Path
      d="M663.004 428.472c.706 7.87.891 23.277.891 23.277-2.858 20.122-4.455 39.91-4.455 39.91 30.053 16.056 74.752 2.932 80.45-3.713-2.821-19.713-9.04-31.983-13.105-43.083l7.537-33.543s-12.994-10.785-11.806-32.67l-17.765-1.856-16.947.482-12.047.854a101.828 101.828 0 0 0-15.741 19.621c-5.903 9.504-6.126 21.235 2.988 30.721Z"
      fill="#fff"
      opacity={0.75}
    />
    <Path
      opacity={0.1}
      d="M686.041 427.692s7.851 6.943 17.485 4.195a16.112 16.112 0 0 0 11.528-10.877s.315 10.84-11.806 13.736a15.59 15.59 0 0 1-17.207-7.054Z"
      fill="#000"
    />
    <Path
      d="M673.641 321.292c-3.713 1.968-9.152 9.133-8.372 28.939.613 15.518 5.123 19.491 7.722 20.66 2.599 1.169 7.704.613 12.66 0l-.242 9.281s-9.077 15.314-3.972 21.607a41.54 41.54 0 0 0 19.992-11.509c5.569-5.568 5.569-10.191 5.569-10.191l.408-20.27s2.821 2.785 7.852-1.243c4.158-3.323 5.661-9.04 2.58-12.215a7.24 7.24 0 0 0-11.323 1.857s-5.569.241-6.497-10.061c0 0-8.26-.743-12.789-3.063-10.34-5.16-12.4-11.23-13.588-13.792Z"
      fill="#FFC4C3"
    />
    <Path
      d="M693.447 386.91c-8.409 0-9.281-2.357-8.353-6.274-1.522 2.748-8.205 15.463-3.713 21.069a41.706 41.706 0 0 0 20.048-11.435 17.098 17.098 0 0 0 5.569-9.411c-2.116 3.546-6.869 6.126-13.551 6.051Z"
      fill="#455A64"
    />
    <Path
      d="M685.651 370.761c7.109-1.708 15.963-5.216 17.745-9.281a16.706 16.706 0 0 1-4.714 6.775c-3.267 2.562-13.124 5.569-13.124 5.569l.093-3.063Z"
      fill="#F28F8F"
    />
    <Path
      d="M688.305 343.084a2.319 2.319 0 0 0 2.079 2.451 2.211 2.211 0 0 0 2.506-1.857 2.304 2.304 0 0 0-2.079-2.431 2.209 2.209 0 0 0-2.506 1.837Z"
      fill="#000"
    />
    <Path
      d="m687.637 357.749-7.926 1.949a4.038 4.038 0 0 0 1.837 2.541 4.042 4.042 0 0 0 3.1.466 4.142 4.142 0 0 0 2.989-4.956Z"
      fill="#F28F8F"
    />
    <Path
      d="M687.507 360.032a2.875 2.875 0 0 0-1.856-.668 2.933 2.933 0 0 0-2.933 2.933 1.84 1.84 0 0 0 0 .445c.574.129 1.17.129 1.745 0a4.136 4.136 0 0 0 3.044-2.71Z"
      fill="#FFA8A7"
    />
    <Path
      d="m668.87 337.757 4.232-2.896a2.55 2.55 0 0 0-1.633-1.072 2.549 2.549 0 0 0-1.912.404c-.28.188-.519.43-.705.712a2.56 2.56 0 0 0 .018 2.852ZM689.475 336.142l4.714 2.153a2.368 2.368 0 0 0-.348-2.745 2.36 2.36 0 0 0-.802-.559 2.81 2.81 0 0 0-2.003-.107 2.796 2.796 0 0 0-1.561 1.258ZM669.743 342.008a2.281 2.281 0 0 0 2.06 2.431 2.19 2.19 0 0 0 2.506-1.856 2.321 2.321 0 0 0-2.023-2.431 2.167 2.167 0 0 0-2.543 1.856Z"
      fill="#000"
    />
    <Path
      d="m681.437 341.581-1.132 12.567-6.776-2.469 7.908-10.098Z"
      fill="#FFA8A7"
    />
    <Path
      d="M749.079 467.49a59.047 59.047 0 0 1-4.269 6.163 8.671 8.671 0 0 1-5.124 3.49 65.013 65.013 0 0 0-12.567 2.951c-3.582 1.132-5.847.835-5.977 1.745-.148 1.188.483 2.562 3.713 3.248 2.381.272 4.784.272 7.165 0a46.224 46.224 0 0 1-9.82 8.743c-7.258 4.79-9.726 8.409-2.171 12.994 5.568 3.36 15.128 2.45 28.4-9.931a143.123 143.123 0 0 0 17.282-20.419l-16.632-8.984Z"
      fill="#FFC4C3"
    />
    <Path
      d="M765.098 478.461c21.366-26.638 21.718-31.148 15.426-40.467-37.385-55.353-37.255-54.889-55.948-58.843-8.13 13.996 0 27.64 6.776 35.269l27.212 33.672c-3.582 7.592-7.425 14.85-10.172 19.732 0 0 6.812 8.428 16.706 10.637Z"
      fill="#455A64"
    />
    <Path
      d="M722.405 378.687c10.246.464 17.782 1.857 24.985 10.711 7.703 9.392 25.56 35.269 25.56 35.269s-4.789 14.738-24.02 19.694l-24.224-31.11s-8.798-17.412-2.301-34.564Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.6}
      d="M722.405 378.687c10.246.464 17.782 1.857 24.985 10.711 7.703 9.392 25.56 35.269 25.56 35.269s-4.789 14.738-24.02 19.694l-24.224-31.11s-8.798-17.412-2.301-34.564Z"
      fill="#fff"
    />
    <Path
      d="M708.835 377.277c3.174.686 4.028 14.033 3.267 30.219-.13 2.673-.315 4.789-.26 5.847h1.857c0-.965.092-3.044.26-5.568.798-12.79.89-31.371-4.678-32.225a7.29 7.29 0 0 0-2.265-.204v1.856l1.819.075Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="M708.835 377.277c3.174.686 4.028 14.033 3.267 30.219-.13 2.673-.315 4.789-.26 5.847h1.857c0-.965.092-3.044.26-5.568.798-12.79.89-31.371-4.678-32.225a7.29 7.29 0 0 0-2.265-.204v1.856l1.819.075Z"
      fill="#000"
    />
    <Path
      d="M711.026 453.624a9.78 9.78 0 0 1-5.569 1.596l.148-1.243a8.139 8.139 0 0 0 4.27-1.188 14.976 14.976 0 0 1-3.174-3.397c-1.857-2.785-4.641-21.885-2.896-26.136 1.448-3.527 5.661-11.138 8.854-11.138h.093c3.211.13 6.812 8.223 7.963 11.899 1.374 4.381-3.081 23.166-5.123 25.783a15.258 15.258 0 0 1-3.434 3.119 7.968 7.968 0 0 0 4.139 1.54v1.263h-.148a9.931 9.931 0 0 1-5.123-2.098Zm-6.015-29.941c-1.596 3.898 1.114 22.368 2.785 24.966a14.837 14.837 0 0 0 3.285 3.397 14.85 14.85 0 0 0 3.546-3.1c1.856-2.45 6.181-20.623 4.919-24.651-1.541-4.919-4.938-10.933-6.813-11.007-1.949.055-5.791 5.717-7.722 10.395Z"
      fill="#455A64"
    />
    <Path
      d="M703.712 454.515a1.858 1.858 0 0 0 1.856 1.856 1.86 1.86 0 0 0 1.715-1.146c.141-.339.178-.712.106-1.072a1.867 1.867 0 0 0-.508-.951 1.863 1.863 0 0 0-2.023-.402 1.863 1.863 0 0 0-1.146 1.715ZM714.497 454.979a1.855 1.855 0 0 0 2.218 1.821c.36-.072.691-.249.951-.508a1.856 1.856 0 1 0-3.169-1.313Z"
      fill="#263238"
    />
    <Path
      d="M703.526 431.535h1.058a.432.432 0 0 0 .39-.446 21.804 21.804 0 0 1 .316-7.165c1.856-4.548 5.736-10.265 7.425-10.191 1.689.074 5.049 6.089 6.534 10.785.304 2.388.204 4.81-.297 7.165a.443.443 0 0 0 .353.483l1.058.167a.427.427 0 0 0 .464-.371c.577-2.629.652-5.344.222-8.001-.798-2.524-4.51-11.954-8.26-12.121-3.75-.167-8.242 8.928-9.281 11.379a21.41 21.41 0 0 0-.464 8 .408.408 0 0 0 .482.316Z"
      fill="#F1A8A8"
    />
    <Path
      d="M703.526 431.535h1.058a.432.432 0 0 0 .39-.446 21.804 21.804 0 0 1 .316-7.165c1.856-4.548 5.736-10.265 7.425-10.191 1.689.074 5.049 6.089 6.534 10.785.304 2.388.204 4.81-.297 7.165a.443.443 0 0 0 .353.483l1.058.167a.427.427 0 0 0 .464-.371c.577-2.629.652-5.344.222-8.001-.798-2.524-4.51-11.954-8.26-12.121-3.75-.167-8.242 8.928-9.281 11.379a21.41 21.41 0 0 0-.464 8 .408.408 0 0 0 .482.316Z"
      fill="#000"
      opacity={0.2}
    />
    <Path
      d="M685.502 374.863c-2.84.39-11.62 4.715-15.778 17.746-4.158 13.031-3.898 33.413-3.712 39.167.185 5.754.519 10.005.519 10.005h2.673s-.464-6.348-.594-10.005c-.13-3.657.334-29.867 3.509-38.146 3.805-9.857 8.111-13.662 13.383-16.168v-2.599Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="M685.502 374.863c-2.84.39-11.62 4.715-15.778 17.746-4.158 13.031-3.898 33.413-3.712 39.167.185 5.754.519 10.005.519 10.005h2.673s-.464-6.348-.594-10.005c-.13-3.657.334-29.867 3.509-38.146 3.805-9.857 8.111-13.662 13.383-16.168v-2.599Z"
      fill="#000"
    />
    <Path
      d="M675.218 442.746a7.428 7.428 0 0 1-4.583 6.86 7.43 7.43 0 0 1-8.092-1.609 7.43 7.43 0 0 1 1.125-11.424 7.425 7.425 0 0 1 11.55 6.173Z"
      fill="#455A64"
    />
    <Path
      d="M667.849 446.942a4.196 4.196 0 1 0-.001-8.391 4.196 4.196 0 0 0 .001 8.391Z"
      fill="#37474F"
    />
    <Path
      d="M509.066 598.69a5.565 5.565 0 0 0 1.021 5.569 23.368 23.368 0 0 0 17.096 3.898c8.483-1.095 13.086-3.713 16.483-6.571 3.397-2.859 8.149-6.85 11.992-7.704a23.302 23.302 0 0 0 8.575-3.49c1.133-1.113.984-6.014.984-6.014l-56.151 14.312Z"
      fill="#455A64"
    />
    <Path
      d="M561.82 565.444a1.359 1.359 0 0 1 1.3 1.133c.26 1.355.538 5.29 1.095 7.425a39.678 39.678 0 0 1 .835 11.898c-.464 2.506-6.274 4.251-9.986 5.68a46.447 46.447 0 0 0-12.029 7.592 34.017 34.017 0 0 1-17.913 6.664c-6.534 0-13.903-2.153-15.592-5.197-2.098-3.713-.464-5.922 9.281-10.414 1.856-.854 8.297-4.677 10.488-6.051 5.94-3.713 11.843-8.966 14.571-15.593a3.712 3.712 0 0 1 1.393-2.023 3.136 3.136 0 0 1 1.856-.167 15.75 15.75 0 0 1 4.659 1.411c1.318.612 3.156 1.336 3.137 3.007a5.968 5.968 0 0 1-.371 1.689c-.112.334-.204.798.093 1.002.297.205.649 0 .909-.259a28.622 28.622 0 0 0 2.617-3.137 8.685 8.685 0 0 1 1.523-1.56 15.169 15.169 0 0 0 1.67-1.076c.594-.52.446-1.244.464-2.024Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.9}
      d="M561.82 565.444a1.359 1.359 0 0 1 1.3 1.133c.26 1.355.538 5.29 1.095 7.425a39.678 39.678 0 0 1 .835 11.898c-.464 2.506-6.274 4.251-9.986 5.68a46.447 46.447 0 0 0-12.029 7.592 34.017 34.017 0 0 1-17.913 6.664c-6.534 0-13.903-2.153-15.592-5.197-2.098-3.713-.464-5.922 9.281-10.414 1.856-.854 8.297-4.677 10.488-6.051 5.94-3.713 11.843-8.966 14.571-15.593a3.712 3.712 0 0 1 1.393-2.023 3.136 3.136 0 0 1 1.856-.167 15.75 15.75 0 0 1 4.659 1.411c1.318.612 3.156 1.336 3.137 3.007a5.968 5.968 0 0 1-.371 1.689c-.112.334-.204.798.093 1.002.297.205.649 0 .909-.259a28.622 28.622 0 0 0 2.617-3.137 8.685 8.685 0 0 1 1.523-1.56 15.169 15.169 0 0 0 1.67-1.076c.594-.52.446-1.244.464-2.024Z"
      fill="#fff"
    />
    <Path
      d="M543.035 599.247a67.278 67.278 0 0 1 5.29-3.954s-11.416 6.924-16.019 3.712a4.137 4.137 0 0 1-1.485-5.29c0-.78-7.258-4.158-10.154-4.492-.798.445-1.466.798-1.856 1.002-9.708 4.492-11.342 6.664-9.281 10.414 1.689 3.044 7.016 5.402 15.481 5.402 5.531.074 14.033-3.509 18.024-6.794Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.8}
      d="M543.035 599.247a67.278 67.278 0 0 1 5.29-3.954s-11.416 6.924-16.019 3.712a4.137 4.137 0 0 1-1.485-5.29c0-.78-7.258-4.158-10.154-4.492-.798.445-1.466.798-1.856 1.002-9.708 4.492-11.342 6.664-9.281 10.414 1.689 3.044 7.016 5.402 15.481 5.402 5.531.074 14.033-3.509 18.024-6.794Z"
      fill="#fff"
    />
    <Path
      d="M531.619 591.506c-6.033 2.413-3.471 3.527-1.856 3.713 1.615.185 11.843-5.68 18.043-10.581 6.2-4.9 10.525-10.692 12.993-12.585.949-.74 1.86-1.527 2.729-2.358-.167-1.318-.297-2.524-.408-3.174a1.356 1.356 0 0 0-1.3-1.132c0 .779.13 1.503-.464 2.097-.532.395-1.091.755-1.67 1.077a8.647 8.647 0 0 0-1.523 1.559 28.528 28.528 0 0 1-2.617 3.137c-5.272 6.738-17.894 15.834-23.927 18.247Z"
      fill="#F1A8A8"
    />
    <Path
      d="M530.264 594.458a.596.596 0 0 0 .39-.149.593.593 0 0 0 0-.835 14.384 14.384 0 0 0-11.045-4.362.612.612 0 0 0-.471.894.576.576 0 0 0 .601.294 13.248 13.248 0 0 1 9.987 3.935.61.61 0 0 0 .538.223ZM535.536 591.154a.583.583 0 0 0 .52-.308.601.601 0 0 0-.056-.658 14.359 14.359 0 0 0-11.138-4.362.593.593 0 0 0-.519.65.58.58 0 0 0 .212.404.601.601 0 0 0 .437.134 13.286 13.286 0 0 1 10.005 3.917.56.56 0 0 0 .539.223ZM540.789 588.091c.141.006.28-.04.39-.13a.615.615 0 0 0 0-.854 14.295 14.295 0 0 0-11.138-4.344.6.6 0 0 0-.309 1.06.6.6 0 0 0 .439.128 13.26 13.26 0 0 1 9.987 3.917.609.609 0 0 0 .631.223ZM545.671 584.582a.594.594 0 0 0 .464-.965 14.356 14.356 0 0 0-11.138-4.362.598.598 0 0 0 .13 1.188 13.252 13.252 0 0 1 9.987 3.917.612.612 0 0 0 .557.222ZM550.442 581.074a.537.537 0 0 0 .371-.13.596.596 0 0 0 .111-.835 14.663 14.663 0 0 0-12.065-4.418.611.611 0 0 0-.539.668.593.593 0 0 0 .65.52 13.604 13.604 0 0 1 11.007 3.972.623.623 0 0 0 .465.223Z"
      fill="#FAFAFA"
    />
    <Path
      d="M600.82 613.651c-.47 4.507.207 9.06 1.968 13.235a8.497 8.497 0 0 0 9.411 4.418c3.991-.594 9.281-2.729 10.859-7.573a31.762 31.762 0 0 0 1.374-13.644l-23.612 3.564Z"
      fill="#455A64"
    />
    <Path
      d="M620.478 573.668c1.578.297 1.281 8.037 1.429 15.852.167 8.353 2.283 12.028 2.487 18.897.242 7.833-.519 11.248-3.712 15.666-3.193 4.418-13.235 8.038-17.3.854-3.304-5.847-2.859-11.935-1.727-19.62 1.17-7.964 2.042-13.551 2.339-19.658.26-5.829.427-11.472 1.856-11.991v2.803c0 .835-.129 3.805.762 3.935a10.9 10.9 0 0 0 .241-2.191 2.547 2.547 0 0 1 .631-1.856 2.935 2.935 0 0 1 1.856-.612 41.939 41.939 0 0 1 4.845-.26c.8-.003 1.6.04 2.395.13a6.203 6.203 0 0 1 2.004.501 1.423 1.423 0 0 1 .799.872c.055.295.055.597 0 .891a6.712 6.712 0 0 0 0 1.615c0 .223.167.52.408.501a.485.485 0 0 0 .316-.445c.092-.947 0-1.634.111-2.729v-1.689c0-.501.241-.965.26-1.466Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.9}
      d="M620.478 573.668c1.578.297 1.281 8.037 1.429 15.852.167 8.353 2.283 12.028 2.487 18.897.242 7.833-.519 11.248-3.712 15.666-3.193 4.418-13.235 8.038-17.3.854-3.304-5.847-2.859-11.935-1.727-19.62 1.17-7.964 2.042-13.551 2.339-19.658.26-5.829.427-11.472 1.856-11.991v2.803c0 .835-.129 3.805.762 3.935a10.9 10.9 0 0 0 .241-2.191 2.547 2.547 0 0 1 .631-1.856 2.935 2.935 0 0 1 1.856-.612 41.939 41.939 0 0 1 4.845-.26c.8-.003 1.6.04 2.395.13a6.203 6.203 0 0 1 2.004.501 1.423 1.423 0 0 1 .799.872c.055.295.055.597 0 .891a6.712 6.712 0 0 0 0 1.615c0 .223.167.52.408.501a.485.485 0 0 0 .316-.445c.092-.947 0-1.634.111-2.729v-1.689c0-.501.241-.965.26-1.466Z"
      fill="#fff"
    />
    <Path
      d="M624.246 606.171c0 1.132.26 8.613-1.856 9.745-2.116 1.132-2.933-4.696-2.933-4.696s-4.956-2.079-12.734.427c.26 1.225-1.281 5.067-3.23 4.028-1.949-1.04-1.856-9.597-1.856-9.709-1.058 7.425-1.392 13.291 1.856 18.971 4.065 7.184 14.126 3.546 17.3-.854 3.175-4.399 3.954-7.833 3.713-15.666-.13-.799-.186-1.541-.26-2.246Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.8}
      d="M624.246 606.171c0 1.132.26 8.613-1.856 9.745-2.116 1.132-2.933-4.696-2.933-4.696s-4.956-2.079-12.734.427c.26 1.225-1.281 5.067-3.23 4.028-1.949-1.04-1.856-9.597-1.856-9.709-1.058 7.425-1.392 13.291 1.856 18.971 4.065 7.184 14.126 3.546 17.3-.854 3.175-4.399 3.954-7.833 3.713-15.666-.13-.799-.186-1.541-.26-2.246Z"
      fill="#fff"
    />
    <Path
      d="M618.789 611.647c-.26 2.802 2.598 2.134 3.118-1.857.52-3.991-.705-13.532 0-20.418-.149-7.815.149-15.556-1.429-15.853v.297l-.186 3.935v1.69c-.538 5.698-.854 16.817-.872 22.089a95.56 95.56 0 0 1-.631 10.117ZM607.558 611.646a95.374 95.374 0 0 1-.538-9.968c0-5.234-.334-16.205-.854-21.959a14.616 14.616 0 0 1-.241-3.248V573.686c-1.058.371-1.43 3.471-1.652 7.425 0 2.265 0 5.068.223 8.483.705 6.794-.539 16.447 0 20.419.538 3.972 3.322 4.436 3.062 1.633Z"
      fill="#F1A8A8"
    />
    <Path
      d="M606.519 611.851h.223a18.878 18.878 0 0 1 12.455 0 .616.616 0 0 0 .78-.353.582.582 0 0 0-.151-.644.57.57 0 0 0-.202-.117 19.863 19.863 0 0 0-13.328 0 .613.613 0 0 0-.198.126.605.605 0 0 0-.181.423.61.61 0 0 0 .045.231.617.617 0 0 0 .557.334ZM605.795 605.595h.185a23.465 23.465 0 0 1 13.978 0 .61.61 0 0 0 .742-.371.571.571 0 0 0 .033-.232.587.587 0 0 0-.404-.529 24.479 24.479 0 0 0-14.738 0 .589.589 0 0 0-.345.303.588.588 0 0 0-.027.458.614.614 0 0 0 .576.371ZM620.144 599.766a.572.572 0 0 0 .538-.352.613.613 0 0 0-.297-.798 20.578 20.578 0 0 0-14.72-.093.583.583 0 0 0-.32.324.596.596 0 0 0 .004.455.573.573 0 0 0 .761.334s7.333-2.877 13.774 0a.632.632 0 0 0 .26.13ZM620.311 594.272a.58.58 0 0 0 .538-.334.598.598 0 0 0-.091-.659.593.593 0 0 0-.187-.139 18.547 18.547 0 0 0-7.482-1.666 18.543 18.543 0 0 0-7.517 1.499.61.61 0 0 0-.297.798.596.596 0 0 0 .798.278 17.757 17.757 0 0 1 13.978.167l.26.056Z"
      fill="#FAFAFA"
    />
    <Path
      d="M620.403 588.109a.595.595 0 0 0 .539-.315.613.613 0 0 0-.279-.817c-7.109-3.508-14.85-.149-15.128 0a.592.592 0 0 0-.297.78.598.598 0 0 0 .332.328.602.602 0 0 0 .466-.013s7.425-3.285 14.108 0a.586.586 0 0 0 .259.037Z"
      fill="#EBEBEB"
    />
    <Path
      d="M610.64 335.381a110.171 110.171 0 0 1 8.446 31.742c3.081 39.798 1.41 96.933 1.41 96.933.353 4.158 2.989 13.606 4.771 31.909 2.097 21.625-.538 94.929-.538 94.929s-12.994 7.722-22.999-1.058c0 0-11.713-100.758-14.423-122.272-2.358-18.766-8.985-59.4-8.985-59.4l-7.74 60.069s.705 16.873.204 27.843c-.575 12.697-4.121 77.387-4.121 77.387s-17.783 6.015-25.728-2.524c0 0-1.856-89.731-2.153-105.008-.52-29.96 0-84.571 4.864-115.997l66.992-14.553Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="M610.64 335.381a110.171 110.171 0 0 1 8.446 31.742c3.081 39.798 1.41 96.933 1.41 96.933.353 4.158 2.989 13.606 4.771 31.909 2.097 21.625-.538 94.929-.538 94.929s-12.994 7.722-22.999-1.058c0 0-11.713-100.758-14.423-122.272-2.358-18.766-8.985-59.4-8.985-59.4l-7.74 60.069s.705 16.873.204 27.843c-.575 12.697-4.121 77.387-4.121 77.387s-17.783 6.015-25.728-2.524c0 0-1.856-89.731-2.153-105.008-.52-29.96 0-84.571 4.864-115.997l66.992-14.553Z"
      fill="#000"
    />
    <Path
      opacity={0.2}
      d="m578.322 408.202-2.134-14.702s-13.922.204-21.217-6.664c0 0 2.84 9.17 16.984 10.302l2.005 12.214-2.45 51.697 6.812-52.847Z"
      fill="#000"
    />
    <Path
      d="M561.078 258.476c-6.2-.278-14.572.576-19.138 5.996-5.439 6.46-6.924 10.636-11.899 29.7-3.285 12.623-8.705 40.652-3.991 45.181 4.715 4.53 16.521 2.859 16.521 2.859l18.507-83.736Z"
      fill="#F28F8F"
    />
    <Path
      d="M564.215 258.068c-6.794-1.095-18.99-1.188-25.078 8.483-6.089 9.671-17.319 43.641-17.319 43.641a33.902 33.902 0 0 0 21.57 3.898c12.492-1.782 20.827-56.022 20.827-56.022Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.1}
      d="M564.215 258.068c-6.794-1.095-18.99-1.188-25.078 8.483-6.089 9.671-17.319 43.641-17.319 43.641a33.902 33.902 0 0 0 21.57 3.898c12.492-1.782 20.827-56.022 20.827-56.022Z"
      fill="#000"
    />
    <Path
      d="M591.001 257.381c7.128.687 23.314 2.488 28.938 3.601 4.901 2.005 3.713 5.384 3.713 11.286.483 20.289-7.573 62.649-7.573 62.649l5.68 39.686c-9.783 9.764-61.609 15.222-85.221-.408 0 0 4.01-57.933 8.52-86.817 3.212-20.678 10.655-28.994 18.21-29.31l27.733-.687Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="m584.485 297.179 19.973.798v3.843l-20.251-.854.278-3.787Z"
      fill="#000"
    />
    <Path
      d="m590.926 297.439.464-5.643h13.068l-.556 6.163-12.976-.52Z"
      fill="#FAFAFA"
    />
    <Path
      d="M585.376 297.216c.316-6.515 1.021-9.745 1.541-10.153a1.203 1.203 0 0 1 1.54 0c.483.631.854 3.991.817 10.302l-3.898-.149Z"
      fill="#455A64"
    />
    <Path
      d="M586.898 296.641c0 2.487-.185 7.239.687 7.314.872.074.798-4.604.817-7.314h-1.504Z"
      fill="#455A64"
    />
    <Path
      opacity={0.1}
      d="M602.138 258.569c-4.306-.501-8.39-.928-11.137-1.188l-19.231.483c-2.599 5.699-5.383 13.94-.371 24.261a59.965 59.965 0 0 0 20.882-11.861c6.367-5.792 8.985-9.3 9.857-11.695Z"
      fill="#000"
    />
    <Path
      d="M600.412 196.942s5.568.612 7.647 4.715c1.857 3.545 1.448 12.269-.631 21.811a87.66 87.66 0 0 1-5.42 17.987 7.318 7.318 0 0 1-4.121 3.471v-11.249l.186-7.852s-5.216-6.738-5.922-12.363c-.909-7.22.539-10.228.539-10.228l7.722-6.292Z"
      fill="#455A64"
    />
    <Path
      d="M593.358 209.88a21.015 21.015 0 0 1-35.47 15.829 21.014 21.014 0 0 1 13.956-36.248 20.993 20.993 0 0 1 21.514 20.419Z"
      fill="#455A64"
    />
    <Path
      d="M598.259 224.396c1.262.742 3.044-1.689 4.584-3.323 1.541-1.633 6.59-3.917 9.17 1.392 2.581 5.309-2.134 12.641-6.107 13.941-6.868 2.209-7.944-2.191-7.944-2.191l-.279 25.598c-4.733 6.163-14.59 14.274-24.131 17.3a21.883 21.883 0 0 1 1.633-18.562v-7.555a43.443 43.443 0 0 1-9.281.557c-5.067-.743-8.297-4.715-9.875-10.173-2.543-8.78-3.546-15.889-1.597-33.208 2.117-19.008 24.132-19.398 36.179-11.991 2.524 1.559.779 4.585 2.042 7.016a23.113 23.113 0 0 0 .131 11.306 23.117 23.117 0 0 0 5.475 9.893Z"
      fill="#F28F8F"
    />
    <Path
      d="M575.204 251.051s11.286-2.376 15.221-4.51a13.001 13.001 0 0 0 5.402-5.458 17.753 17.753 0 0 1-3.044 6.404c-2.859 3.713-17.561 6.572-17.561 6.572l-.018-3.008Z"
      fill="#B16668"
    />
    <Path
      d="M577.617 221.76a2.262 2.262 0 0 0 2.787 2.072 2.27 2.27 0 0 0 1.706-1.817 2.271 2.271 0 0 0-.998-2.284 2.261 2.261 0 0 0-1.249-.366 2.357 2.357 0 0 0-2.246 2.395ZM579.993 213.184l4.863 2.599a2.914 2.914 0 0 0-1.169-3.861 2.683 2.683 0 0 0-2.118-.162 2.687 2.687 0 0 0-1.576 1.424Z"
      fill="#000"
    />
    <Path
      d="m577.84 235.552-7.425 2.896a3.961 3.961 0 0 0 2.05 2.318 3.954 3.954 0 0 0 3.092.132 4.23 4.23 0 0 0 2.283-5.346Z"
      fill="#B16668"
    />
    <Path
      d="m557.402 215.263 4.381-3.434a2.665 2.665 0 0 0-1.801-1.105 2.662 2.662 0 0 0-2.041.548 2.932 2.932 0 0 0-.539 3.991ZM558.813 221.352a2.268 2.268 0 0 0 3.908 1.444 2.27 2.27 0 0 0 .431-2.459 2.265 2.265 0 0 0-2.093-1.361 2.29 2.29 0 0 0-1.618.719 2.3 2.3 0 0 0-.628 1.657Z"
      fill="#000"
    />
    <Path
      d="m570.34 219.031-.631 13.625-7.128-2.357 7.759-11.268Z"
      fill="#B16668"
    />
    <Path
      d="M551.37 187.939a.891.891 0 0 1 .742-.594c.26.009.509.107.706.278a31.47 31.47 0 0 0 4.696 2.97 16.207 16.207 0 0 1-.26-6.274c.204-1.281.483-4.232 2.042-4.585.928-.222 1.856.539 2.487 1.17 6.479 5.754 14.646 2.784 22.275 2.821 6.367 0 14.108 2.413 17.152 8.52 1.392 2.803 1.708 5.773-.835 7.797a17.934 17.934 0 0 1-7.685 3.118c-12.604 2.543-29.552 7.425-39.241-4.213a11.134 11.134 0 0 1-2.71-5.773 9.28 9.28 0 0 1 .612-5.161s0 .019.019-.074Z"
      fill="#37474F"
    />
    <Path
      d="M553.486 199.076a11.131 11.131 0 0 1-2.71-5.772 9.28 9.28 0 0 1 .612-5.161c-1.336 3.564-.594 9.281 7.574 12.567 8.167 3.286 18.377 1.244 23.927.631 3.39-.365 6.814.28 9.838 1.856-12.66 2.581-29.607 7.388-39.241-4.121Z"
      fill="#263238"
    />
    <Path
      d="m613.313 323.148-2.822-26.507c-3.026-21.198 3.917-34.099 6.831-36.123 8.279 1.448 13.291 1.745 18.024 19.881 1.745 6.719 1.857 14.85 2.84 27.528.78 10.005.799 20.289.613 32.002-.111 8.427-3.713 10.042-14.256 10.58-8.724.446-36.16-2.803-51.492-5.717-6.2-1.169-15.296-3.582-20.104-4.882-3.508-.947-16.891-3.824-16.316-9.095a4.774 4.774 0 0 1 2.562-3.453 12.444 12.444 0 0 1 4.25-1.207 68.97 68.97 0 0 1 19.547 0c4.863.669 9.522 1.541 14.423 1.857 4.9.315 14.85-5.773 19.49-5.569 8.855.334 16.41.705 16.41.705Z"
      fill="#F28F8F"
    />
    <Path
      d="M615.187 260.203c7.295-.223 12.326 1.503 15.444 6.497 3.119 4.993 5.569 9.281 7.555 23.518 2.766 20.011 3.119 29.478 3.119 29.478s-15.853 6.478-29.162 0l-2.97-26.675s-2.172-21.031 6.014-32.818Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.1}
      d="M615.187 260.203c7.295-.223 12.326 1.503 15.444 6.497 3.119 4.993 5.569 9.281 7.555 23.518 2.766 20.011 3.119 29.478 3.119 29.478s-15.853 6.478-29.162 0l-2.97-26.675s-2.172-21.031 6.014-32.818Z"
      fill="#000"
    />
    <Path
      d="M626.511 333.877s-.65-7.425-13.198-10.729c0 0-7.555-.371-16.41-.724-4.714-.185-14.85 5.959-19.49 5.569-4.641-.39-9.56-1.262-14.423-1.856a68.97 68.97 0 0 0-19.547 0 12.442 12.442 0 0 0-4.25 1.206 4.766 4.766 0 0 0-2.562 3.453c-.575 5.272 12.808 8.149 16.316 9.096 2.525.686 6.275 1.67 10.136 2.635 14.089-4.881 44.809-17.875 50.23-16.873 6.144 1.17 11.378 4.362 13.198 8.223Z"
      fill="#B16668"
    />
    <Path
      d="M607.688 323.798c-11.564 2.097-50.583 17.987-68.57 18.674-5.568-1.448-6.311-4.233-8.26-11.676-1.949-7.444 6.33-15.945 11.657-16.706 53.312 0 70.538 7.276 70.538 7.276l.241 1.856s-2.265.093-5.606.576Z"
      fill="#F28F8F"
    />
    <Path
      d="m613.071 321.366.242 1.782-2.339.223.185-2.654 1.912.649ZM541.587 314.312c-6.144 1.448-10.172 8.484-10.172 8.484a16.02 16.02 0 0 1 5.16-8.372c1.671.091 3.347.053 5.012-.112Z"
      fill="#B16668"
    />
    <Path
      d="M432.959 123.546a349.229 349.229 0 0 1-14.72 69.479h64.524c4.176-21.811 6.422-44.253 0-69.479h-49.804Z"
      fill="#37474F"
    />
    <Path
      d="M478.605 149.44c9.708.26 12.474 4.158 18.321 12.512 5.847 8.353 21.291 42.081 21.291 42.081s2.729 16.966-9.467 14.478c-4.009-.835-6.757-4.046-8.631-6.923-1.578-2.376-13.922-21.848-13.922-21.848l-7.592-40.3Z"
      fill="#E6E6E6"
    />
    <Path
      d="M471.18 149.7c9.17-2.301 13.216 2.84 16.279 8.446 4.9 8.966 7.833 27.287 8.242 60.792.278 21.533 7.759 47.576 9.095 67.995 2.005 30.702 2.989 54.611 2.989 54.611a392.025 392.025 0 0 0-54.778-3.249L471.18 149.7Z"
      fill="#E0E0E0"
    />
    <Path
      d="M418.258 462.051s-5.569-37.7-3.211-59.789c1.986-18.953 4.77-26.898 5.16-31.204 0 0 3.193-44.55 2.32-82.195-.408-17.875.501-37.57 13.217-58.657l.464-.836c1.132 6.924 11.768 11.398 21.272 12.456 9.158 1.002 20.549-.811 34.174-5.439 3.026 17.876 6.478 51.975 9.987 89.1 1.726 18.154 1.429 33.413 1.726 45.088.408 15.834-1.021 77.851-1.021 77.851v2.618c.093 2.896.798 4.9 2.822 9.485a55.846 55.846 0 0 0 10.432 16.057c1.373 1.373 10.747 9.04 12.01 10.71 4.065 4.437-3.973 7.277-10.024 6.664-6.386-.631-16.335-2.71-19.249-6.497-1.968-2.506-3.713-11.508-5.662-14.051-6.757-8.595-9.541-12.307-9.69-15.11-.241-5.086 1.43-7.165 1.968-10.061-.724-5.996-12.882-37.311-13.681-54.797a182.573 182.573 0 0 1 1.448-23.11l-10.673-60.755c-2.376 17.078-6.887 55.576-8.52 67.827-2.246 16.874-10.581 45.423-15.834 67.846a117.873 117.873 0 0 0-3.434 17.672s-.13 1.856-.13 1.967c0 2.488-.241 29.051-1.04 34.675-1.67 11.769-12.102 10.636-14.089 1.244-1.689-8.001-.742-32.967-.798-36.011l.056-2.748Z"
      fill="#FFA8A7"
    />
    <Path
      d="M417.979 461.884c-2.932.149-.631 11.435-1.15 18.767-.539 7.833-2.915 13.644-3.434 20.085a25.838 25.838 0 0 0 2.988 16.335c2.19 4.622 12.678 10.692 16.354 3.861a37.58 37.58 0 0 0 3.564-21.793c-.706-7.425-1.337-12.993-1.69-18.748-.297-5.049 3.193-17.059-.612-17.671l-.13 1.986c-1.281 2.766-14.219 5.086-15.927-.111l.037-2.711Z"
      fill="#37474F"
    />
    <Path
      d="M482.261 464.279c-.779 3.712.483 13.68.483 13.68a13.33 13.33 0 0 0 7.425 5.569l2.079-14.014-9.987-5.235Z"
      fill="#263238"
    />
    <Path
      d="m490.15 483.473 12.307-4.493 2.636-11.137-13.736 7.536-1.207 8.094Z"
      fill="#000"
    />
    <Path
      d="M502.012 450.858v-2.617c1.726.761 5.884 14.237 11.23 23.407 4.864 8.372 14.108 12.808 17.189 16.428 4.121 4.826-3.341 11.472-15.76 9.281-6.162-1.114-15.555-4.715-18.191-8.761-2.636-4.047-3.712-11.602-5.903-14.553-2.19-2.952-7.146-6.312-8.297-9.727a18.92 18.92 0 0 1-.297-9.541c.854-3.174 1.578-6.998 2.654-6.683l.167 2.376c.873 1.652 3.917 3.527 10.154 3.713 2.933-.037 5.792-.594 7.054-3.323Z"
      fill="#37474F"
    />
    <Path
      d="M469.657 400.108a129.94 129.94 0 0 1 1.04-28.66l-8.966-59.753s-6.125 56.894-7.146 65.711c-1.021 8.818-17.412 103.95-17.412 103.95-8.39 4.957-19.156 3.379-26.154-.334 0-38.461 1.206-76.997 2.598-87.652a180.92 180.92 0 0 1 4.994-21.662s.891-56.115.835-77.406c-.056-21.291 1.559-35.566 5.569-45.144a137.156 137.156 0 0 1 11.137-19.973s3.119 10.506 27.844 10.543c24.725.037 27.398-6.237 27.398-6.237s7.221 25.227 9.634 63.336c1.856 30.702 2.376 44.68 2.952 58.36.501 11.657.816 25.134 1.076 48.708.167 17.931 2.729 56.913 2.729 56.913s-12.047 6.329-31.928-.873c0 .037-4.974-44.476-6.2-59.827Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.4}
      d="M469.657 400.108a129.94 129.94 0 0 1 1.04-28.66l-8.966-59.753s-6.125 56.894-7.146 65.711c-1.021 8.818-17.412 103.95-17.412 103.95-8.39 4.957-19.156 3.379-26.154-.334 0-38.461 1.206-76.997 2.598-87.652a180.92 180.92 0 0 1 4.994-21.662s.891-56.115.835-77.406c-.056-21.291 1.559-35.566 5.569-45.144a137.156 137.156 0 0 1 11.137-19.973s3.119 10.506 27.844 10.543c24.725.037 27.398-6.237 27.398-6.237s7.221 25.227 9.634 63.336c1.856 30.702 2.376 44.68 2.952 58.36.501 11.657.816 25.134 1.076 48.708.167 17.931 2.729 56.913 2.729 56.913s-12.047 6.329-31.928-.873c0 .037-4.974-44.476-6.2-59.827Z"
      fill="#000"
    />
    <Path
      opacity={0.2}
      d="m461.731 311.695 4.882-36.141a30.928 30.928 0 0 0 18.86-9.133s-2.748 8.762-14.331 13.681l-6.534 31.704 4.771 50.88-7.648-50.991Z"
      fill="#000"
    />
    <Path
      d="m450.185 152.225-11.62 1.225c-7.703 1.856-11.137 5.29-11.972 12.121a53.22 53.22 0 0 0 .408 22.275c2.45 9.616 8.947 33.153 8.947 33.153s-14.998 19.528-17.709 53.07c27.417 17.876 69.443 8.242 80.803-2.562-.965-10.228-7.425-38.313-8.316-47.445 0 0 .594-14.238 1.726-20.419 8.669-9.467 7.555-18.563 3.713-25.821-7.184-13.476-18.043-25.319-21.533-25.3l-24.447-.297Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.6}
      d="m450.185 152.225-11.62 1.225c-7.703 1.856-11.137 5.29-11.972 12.121a53.22 53.22 0 0 0 .408 22.275c2.45 9.616 8.947 33.153 8.947 33.153s-14.998 19.528-17.709 53.07c27.417 17.876 69.443 8.242 80.803-2.562-.965-10.228-7.425-38.313-8.316-47.445 0 0 .594-14.238 1.726-20.419 8.669-9.467 7.555-18.563 3.713-25.821-7.184-13.476-18.043-25.319-21.533-25.3l-24.447-.297Z"
      fill="#fff"
    />
    <Path
      d="M471.31 144.057c3.434 0 3.823.576 6.793 4.53 1.764 2.376 12.901 20.734 12.901 20.734s-8.872-11.657-15.555-15.184a14.68 14.68 0 0 0-4.121-1.634l-.018-8.446Z"
      fill="#EBEBEB"
    />
    <Path
      d="M449.536 152.225s9.578 25.152 11.75 42.229c2.172 17.078-5.309 64.728-8.502 99.05-2.079 22.182-5.197 54.815-5.197 54.815a113.245 113.245 0 0 1-19.806-1.689c-11.268-2.116-15.519-5.086-17.449-7.425 0 0 5.569-55.91 8.297-77.647 2.58-20.419 13.588-40.633 13.588-40.633l-13.235-54.704s.668-9.281 9.281-11.806c8.279-2.469 21.273-2.19 21.273-2.19Z"
      fill="#FAFAFA"
    />
    <Path
      d="m457.703 249.418-34.34-7.648c-1.188 3.564-2.321 7.425-3.23 11.398A224.807 224.807 0 0 0 456.2 262.3a8984.34 8984.34 0 0 0 1.503-12.882Z"
      fill="#F0F0F0"
    />
    <Path
      d="M432.607 203.364s1.039-5.234 2.617-16.52c1.856-12.994 1.151-19.435-.817-23.556l-11.026 21.087 8.817 36.55a139.088 139.088 0 0 0-12.659 34.953c8.65-24.484 18.135-31.835 18.135-31.835l-5.067-20.679Z"
      fill="#F0F0F0"
    />
    <Path
      d="M445.935 183.131c0-1.058-.112-3.341-.297-6.237-.891-14.089-1.3-26.822 3.452-27.843l.427 1.856c-3.49.742-2.32 18.952-1.856 25.802.186 2.933.334 5.253.297 6.422h-2.023Z"
      fill="#F1A8A8"
    />
    <Path
      d="m430.008 90.597 7.425 6.07a4.503 4.503 0 0 0-.631-6.515 4.957 4.957 0 0 0-6.794.445Z"
      fill="#37474F"
    />
    <Path
      d="M449.406 144.967c0-4.659-10.859-11.62-16.447-21.421-5.587-9.801-2.821-25.876 4.864-28.42 0 0 .26-2.06.297-2.208a18.386 18.386 0 0 1 2.134-5.216 23.246 23.246 0 0 1 15.927-10.414c24.298-3.545 35.454 5.884 39.928 16.28a25.023 25.023 0 0 1 1.856 11.137 12.698 12.698 0 0 1-9.819 11.806l-38.74 28.456Z"
      fill="#37474F"
    />
    <Path
      d="M483.078 90.078c4.771 2.078 9.281 9.28 9.281 29.7 0 17.263-5.364 21.643-8.037 22.924-2.673 1.281-7.889.631-12.994-.148v9.931s3.713 3.712 2.469 6.088c-1.244 2.376-6.274 4.325-13.922 1.856-8.687-2.728-10.673-8.52-10.673-8.52l-.167-21.718s-2.915 3.081-8.057-1.429c-4.232-3.713-5.773-10.08-2.58-13.569 3.193-3.49 7.277-3.713 10.971.13l2.654-1.411c4.715-.539 11.694-1.337 16.706-3.917 13.105-6.552 14.442-13.792 14.349-19.918Z"
      fill="#FFA8A7"
    />
    <Path
      d="M449.406 112.594v9.597a4.502 4.502 0 0 0 3.303-1.327 4.487 4.487 0 0 0 1.3-3.314 4.955 4.955 0 0 0-4.603-4.956Z"
      fill="#37474F"
    />
    <Path
      d="M483.524 113.93a2.286 2.286 0 0 0 .537 1.769 2.305 2.305 0 0 0 1.665.804 2.31 2.31 0 0 0 1.718-.68 2.296 2.296 0 0 0 .665-1.726 2.34 2.34 0 0 0-2.209-2.468 2.358 2.358 0 0 0-2.376 2.301ZM465.258 114.951a2.47 2.47 0 1 0 2.543-2.469 2.523 2.523 0 0 0-2.543 2.469ZM489.594 109.68l-5.903-2.358a3.042 3.042 0 0 1 4.083-1.856 3.284 3.284 0 0 1 1.82 4.214Z"
      fill="#000"
    />
    <Path
      d="m477.936 112.074-.297 14.145 7.611-1.708-7.314-12.437Z"
      fill="#F28F8F"
    />
    <Path
      d="m468.989 129.096 7.425 2.302a3.702 3.702 0 0 1-3.195 2.976 3.722 3.722 0 0 1-1.538-.136 4.255 4.255 0 0 1-2.692-5.142Z"
      fill="#B16668"
    />
    <Path
      d="M473.092 134.368a3.917 3.917 0 0 0-3.917-3.88h-.297a4.142 4.142 0 0 0 2.784 3.713c.459.156.947.213 1.43.167ZM471.31 142.572c-5.346-.631-16.391-3.712-18.173-8.186a11.652 11.652 0 0 0 3.954 5.699c3.322 2.859 14.219 5.829 14.219 5.829v-3.342Z"
      fill="#F28F8F"
    />
    <Path
      d="M449.146 144.113c-1.411-.278-2.655.334-3.713 2.265-1.058 1.93-3.712 6.032-3.712 6.032s-1.299 13.551 0 16.911c1.114 2.933 13.513 13.885 13.513 13.885s-3.229 12.177-3.341 16.168c-.111 3.99 7.425 19.156 8.335 26.915 0 0 2.673-24.391 1.522-33.152a154.343 154.343 0 0 0-12.53-41.135l-.074-7.889Z"
      fill="#EBEBEB"
    />
    <Path
      d="M443.076 230.243h-.167v-1.392a9.019 9.019 0 0 0 4.566-1.689 16.333 16.333 0 0 1-3.712-3.434c-2.246-2.896-7.147-23.593-5.569-28.42 1.262-4.046 5.235-12.993 8.78-13.105h.093c3.527 0 8.167 8.428 9.764 12.307 1.856 4.678-1.225 25.728-3.212 28.791a17.065 17.065 0 0 1-3.471 3.712 8.55 8.55 0 0 0 4.678 1.299l.167 1.393a10.878 10.878 0 0 1-6.07-1.857 11.138 11.138 0 0 1-5.847 2.395Zm3.898-46.648c-2.079 0-5.81 6.701-7.425 12.122-1.392 4.436 3.323 24.465 5.402 27.157a15.958 15.958 0 0 0 3.916 3.415 16.107 16.107 0 0 0 3.602-3.712c1.856-2.859 4.844-23.222 3.081-27.51-2.302-5.272-6.478-11.472-8.576-11.472Z"
      fill="#455A64"
    />
    <Path
      d="M456.961 228.962a2.036 2.036 0 0 1-.539 1.468 2.038 2.038 0 0 1-1.42.658 2.046 2.046 0 0 1-2.125-1.959 2.045 2.045 0 0 1 4.084-.167ZM444.988 229.463a2.043 2.043 0 1 1-4.082.187 2.043 2.043 0 0 1 4.082-.187Z"
      fill="#263238"
    />
    <Path
      d="M457.072 203.643h-1.169a.445.445 0 0 1-.409-.483 24.118 24.118 0 0 0-.371-7.889c-2.06-5.03-6.311-11.323-8.167-11.23-1.857.093-5.569 6.701-7.203 11.88a24.388 24.388 0 0 0 .316 7.889.483.483 0 0 1-.371.538l-1.17.167a.467.467 0 0 1-.52-.39 23.89 23.89 0 0 1-.241-8.835c.873-2.766 4.975-13.161 9.096-13.347 4.121-.185 9.095 9.838 10.191 12.53.722 2.881.898 5.871.519 8.817a.478.478 0 0 1-.501.353ZM522.282 188.719l-52.792 18.618-7.759 70.816 1.17 1.243 50.174-18.729 10.377-70.686-1.17-1.262Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="m470.678 208.599-7.777 70.797-1.17-1.243 7.759-70.816 1.188 1.262Z"
      fill="#000"
    />
    <Path
      opacity={0.25}
      d="m523.452 189.981-52.774 18.618-7.777 70.797 50.174-18.729 10.377-70.686Z"
      fill="#000"
    />
    <Path
      d="M507.562 202.789s.186-.947.427-2.116c.241-1.17-2.859-.557-6.924 1.374l-9.522 2.877c-4.066 1.856-7.889 3.341-8.131 4.51l-.445 2.098 24.595-8.743Z"
      fill="#000"
      opacity={0.1}
    />
    <Path
      d="M510.681 193.322c.167-.742-1.04-.594-2.729-.148l-3.044 1.039s1.243-3.044-5.94-1.095c-7.927 2.135-8.094 6.088-8.094 6.088-1.21.534-2.45.999-3.712 1.393-2.617.854-2.914 1.355-3.137 2.097l-.371 1.337 26.897-9.467s-.019-.52.13-1.244Z"
      fill="#37474F"
    />
    <Path
      d="M507.859 197.35c-2.487 1.133-3.527 1.689-3.898 3.471-.427 2.061-.52 2.896-.52 2.896l-13.996 5.569s.761-3.007.947-4.01c.185-1.002-1.671-1.021-4.103.112l.576-2.525 21.792-8.093-.798 2.58Z"
      fill="#37474F"
    />
    <Path
      d="m508.286 202.789.427-2.098c.241-1.169-2.859-.556-6.924 1.374l-9.56 2.952c-4.065 1.856-7.889 3.341-8.13 4.51l-.445 2.098 24.632-8.836Z"
      fill="#37474F"
    />
    <Path
      d="m513.836 206.65-38.573 14.627-6.181 60.087 39.093-15.091 5.661-59.623Z"
      fill="#455A64"
    />
    <Path
      d="m475.635 222.818 38.034-14.479a12.7 12.7 0 0 0 6.534 4.437l-37.756 14.441a29.878 29.878 0 0 1-6.812-4.399Z"
      fill="#F5F5F5"
    />
    <Path
      d="M485.343 227.384c-.94.374-1.954.52-2.961.427a6.421 6.421 0 0 1-2.831-.965l-4.789-3.416a1.86 1.86 0 0 1-.9-1.77 1.859 1.859 0 0 1 1.216-1.571l37.57-14.293 1.188.854-37.125 14.145a1.059 1.059 0 0 0-.678 1.573c.115.192.287.343.493.431l4.455 3.137a3.867 3.867 0 0 0 3.489.335l37.125-14.127 1.337.966-37.589 14.274Z"
      fill="#263238"
    />
    <Path
      d="m522.913 213.11-5.569 59.622-37.57 14.275a6.399 6.399 0 0 1-5.791-.538l-4.808-3.416a2.053 2.053 0 0 1-1.04-1.856l5.532-59.548a2.006 2.006 0 0 0 1.039 1.856l4.79 3.415a6.4 6.4 0 0 0 5.791.539l37.626-14.349Z"
      fill="#455A64"
    />
    <Path
      d="M482.391 227.811a5.883 5.883 0 0 1-2.84-.965l-4.789-3.415a2.01 2.01 0 0 1-1.039-1.857l-5.625 59.697a2.063 2.063 0 0 0 1.04 1.856l4.807 3.416a6.024 6.024 0 0 0 2.822.965l5.624-59.697Z"
      fill="#37474F"
    />
    <Path
      d="M522.115 221.649c3.156 2.134 3.397 5.921 3.397 9.949s0 10.321-2.599 13.736c-2.599 3.416-7.629 8.298-10.877 6.683-3.249-1.615-3.026-13.161.798-17.338a65.776 65.776 0 0 1 8.761-7.666l.52-5.364ZM482.837 239.617c-3.744.612-7.555.7-11.323.26a19.552 19.552 0 0 0 4.826-2.914 3.953 3.953 0 0 0 1.04-5.291c-.539-.687-2.117.78-5.569 1.56-4.344 1.02-7.11.278-11.379 2.505a11.584 11.584 0 0 1-5.662.91c-3.248 0-7.629-1.225-16.056-3.824a188.262 188.262 0 0 1-20.53-8.353l12.789-37.218s7.425-24.799-2.673-32.837c-11.805 0-15.258 5.569-18.878 16.168-3.304 9.56-14.423 54.24-15.035 61.015-.613 6.775 2.45 9.467 9.188 12.4s22.275 6.682 36.494 10.543a120.897 120.897 0 0 0 21.607 4.195c16.836 1.3 24.521 0 28.14-6.367 5.811-10.023 1.987-14.126-6.979-12.752Z"
      fill="#FFA8A7"
    />
    <Path
      d="M430.008 153.951c-7.035-.315-13.216-.668-17.82 7.629-4.603 8.298-11.49 33.932-15.203 52.328-3.712 18.395-6.348 25.709 2.135 29.459 8.483 3.749 36.884 10.822 36.884 10.822s-1.04-11.565 6.794-20.122l-23.241-9.913 12.437-36.419s7.871-23.37-1.986-33.784Z"
      fill="#FAFAFA"
    />
    <Path
      d="M413.376 578.345a5.465 5.465 0 0 1-1.021 5.569 23.462 23.462 0 0 1-17.096 3.88c-8.483-1.095-12.994-3.713-16.465-6.571-3.471-2.859-8.167-6.85-11.991-7.685a23.463 23.463 0 0 1-8.576-3.509c-1.151-1.113-.984-6.014-.984-6.014l56.133 14.33Z"
      fill="#455A64"
    />
    <Path
      d="M360.621 545.1a1.334 1.334 0 0 0-1.28 1.132c-.26 1.355-.539 5.291-1.114 7.425a39.685 39.685 0 0 0-.836 11.899c.483 2.524 6.293 4.269 10.006 5.68a47.631 47.631 0 0 1 12.028 7.592 33.797 33.797 0 0 0 17.894 6.664c6.553 0 13.922-2.153 15.611-5.197 2.079-3.713.446-5.922-9.281-10.414-1.856-.854-8.279-4.678-10.469-6.051-5.959-3.713-11.843-8.966-14.59-15.593a3.712 3.712 0 0 0-1.392-2.023 3.063 3.063 0 0 0-1.857-.167 15.675 15.675 0 0 0-4.677 1.411c-1.3.612-3.156 1.336-3.119 3.007a4.86 4.86 0 0 0 .371 1.689c.112.334.205.798-.092 1.002-.297.204-.669 0-.91-.26a24.842 24.842 0 0 1-2.617-3.137 9.431 9.431 0 0 0-1.541-1.559 16.766 16.766 0 0 1-1.652-1.077c-.668-.519-.446-1.243-.483-2.023Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.9}
      d="M360.621 545.1a1.334 1.334 0 0 0-1.28 1.132c-.26 1.355-.539 5.291-1.114 7.425a39.685 39.685 0 0 0-.836 11.899c.483 2.524 6.293 4.269 10.006 5.68a47.631 47.631 0 0 1 12.028 7.592 33.797 33.797 0 0 0 17.894 6.664c6.553 0 13.922-2.153 15.611-5.197 2.079-3.713.446-5.922-9.281-10.414-1.856-.854-8.279-4.678-10.469-6.051-5.959-3.713-11.843-8.966-14.59-15.593a3.712 3.712 0 0 0-1.392-2.023 3.063 3.063 0 0 0-1.857-.167 15.675 15.675 0 0 0-4.677 1.411c-1.3.612-3.156 1.336-3.119 3.007a4.86 4.86 0 0 0 .371 1.689c.112.334.205.798-.092 1.002-.297.204-.669 0-.91-.26a24.842 24.842 0 0 1-2.617-3.137 9.431 9.431 0 0 0-1.541-1.559 16.766 16.766 0 0 1-1.652-1.077c-.668-.519-.446-1.243-.483-2.023Z"
      fill="#fff"
    />
    <Path
      d="M379.425 578.902a67.78 67.78 0 0 0-5.29-3.954s11.397 6.943 16.019 3.713a4.117 4.117 0 0 0 1.485-5.272c0-.798 7.258-4.176 10.135-4.51.799.445 1.485.798 1.857 1.002 9.708 4.492 11.341 6.664 9.281 10.413-1.689 3.045-7.035 5.402-15.481 5.402-5.439.074-14.015-3.508-18.006-6.794Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.8}
      d="M379.425 578.902a67.78 67.78 0 0 0-5.29-3.954s11.397 6.943 16.019 3.713a4.117 4.117 0 0 0 1.485-5.272c0-.798 7.258-4.176 10.135-4.51.799.445 1.485.798 1.857 1.002 9.708 4.492 11.341 6.664 9.281 10.413-1.689 3.045-7.035 5.402-15.481 5.402-5.439.074-14.015-3.508-18.006-6.794Z"
      fill="#fff"
    />
    <Path
      d="M390.841 571.162c6.033 2.413 3.453 3.545 1.856 3.712-1.596.167-11.842-5.699-18.024-10.599-6.46-5.105-10.543-10.692-12.994-12.567a39.883 39.883 0 0 1-2.71-2.376c.149-1.318.279-2.524.409-3.174a1.333 1.333 0 0 1 1.281-1.132c0 .779-.112 1.503.482 2.097.529.391 1.081.751 1.652 1.077.573.458 1.09.981 1.541 1.559a24.842 24.842 0 0 0 2.617 3.137c5.235 6.757 17.857 15.853 23.89 18.266Z"
      fill="#F1A8A8"
    />
    <Path
      d="M392.178 574.039a.612.612 0 0 1-.464-.984 14.414 14.414 0 0 1 5.094-3.392 14.419 14.419 0 0 1 6.043-.97.61.61 0 0 1 .454.889.578.578 0 0 1-.584.299 13.315 13.315 0 0 0-10.005 3.935.598.598 0 0 1-.538.223ZM386.924 570.809a.573.573 0 0 1-.389-.13.594.594 0 0 1 0-.835 14.358 14.358 0 0 1 11.044-4.362.587.587 0 0 1 .401.22.599.599 0 0 1-.092.84.596.596 0 0 1-.439.128 13.249 13.249 0 0 0-9.986 3.916.613.613 0 0 1-.539.223ZM381.653 567.746a.572.572 0 0 1-.372-.13.592.592 0 0 1 0-.835 14.284 14.284 0 0 1 11.138-4.362.596.596 0 0 1 .102 1.165.607.607 0 0 1-.232.023 13.291 13.291 0 0 0-10.005 3.916.595.595 0 0 1-.631.223ZM376.771 564.238a.57.57 0 0 1-.371-.13.594.594 0 0 1 0-.835 14.4 14.4 0 0 1 11.137-4.362.596.596 0 1 1-.13 1.187 13.273 13.273 0 0 0-10.005 3.936.614.614 0 0 1-.631.204ZM372 560.748a.68.68 0 0 1-.371-.13.612.612 0 0 1-.093-.854 14.585 14.585 0 0 1 12.066-4.399.594.594 0 0 1-.13 1.169 13.632 13.632 0 0 0-11.008 3.973.588.588 0 0 1-.205.172.599.599 0 0 1-.259.069Z"
      fill="#FAFAFA"
    />
    <Path
      d="M321.622 593.307c.241 5.773-.26 9.671-1.968 13.235a8.48 8.48 0 0 1-9.411 4.418c-3.991-.594-9.281-2.729-10.841-7.574a31.556 31.556 0 0 1-1.373-13.643l23.593 3.564Z"
      fill="#455A64"
    />
    <Path
      d="M301.983 553.342c-1.597.278-1.3 8.019-1.448 15.834-.149 8.353-2.284 12.028-2.488 18.896-.222 7.834.539 11.268 3.713 15.667 3.174 4.399 13.253 8.037 17.3.854 3.323-5.847 2.859-11.936 1.745-19.621a195.662 195.662 0 0 1-2.339-19.639c-.278-5.828-.445-11.49-1.856-11.991v2.784c0 .928.13 3.713-.761 3.935a14.373 14.373 0 0 1-.242-2.19 2.634 2.634 0 0 0-.612-1.856 2.97 2.97 0 0 0-1.856-.613 42.402 42.402 0 0 0-4.845-.26c-.798 0-1.615 0-2.413.13a6.261 6.261 0 0 0-2.005.501 1.455 1.455 0 0 0-.78.873 2.894 2.894 0 0 0 0 .891 6.712 6.712 0 0 1 0 1.615c0 .241-.167.52-.408.501-.241-.019-.279-.26-.297-.445-.111-.929 0-1.634-.111-2.711v-1.707c0-.502-.279-.966-.297-1.448Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.9}
      d="M301.983 553.342c-1.597.278-1.3 8.019-1.448 15.834-.149 8.353-2.284 12.028-2.488 18.896-.222 7.834.539 11.268 3.713 15.667 3.174 4.399 13.253 8.037 17.3.854 3.323-5.847 2.859-11.936 1.745-19.621a195.662 195.662 0 0 1-2.339-19.639c-.278-5.828-.445-11.49-1.856-11.991v2.784c0 .928.13 3.713-.761 3.935a14.373 14.373 0 0 1-.242-2.19 2.634 2.634 0 0 0-.612-1.856 2.97 2.97 0 0 0-1.856-.613 42.402 42.402 0 0 0-4.845-.26c-.798 0-1.615 0-2.413.13a6.261 6.261 0 0 0-2.005.501 1.455 1.455 0 0 0-.78.873 2.894 2.894 0 0 0 0 .891 6.712 6.712 0 0 1 0 1.615c0 .241-.167.52-.408.501-.241-.019-.279-.26-.297-.445-.111-.929 0-1.634-.111-2.711v-1.707c0-.502-.279-.966-.297-1.448Z"
      fill="#fff"
    />
    <Path
      d="M298.196 585.826c0 1.132-.242 8.632 1.856 9.764 2.098 1.132 2.933-4.715 2.933-4.715s4.956-2.079 12.715.427c-.26 1.244 1.281 5.068 3.249 4.028 1.967-1.039 1.856-9.597 1.856-9.708 1.039 7.425 1.373 13.291-1.856 18.971-4.047 7.184-14.126 3.564-17.301-.854-3.174-4.418-3.935-7.833-3.712-15.667.148-.798.185-1.522.26-2.246Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.8}
      d="M298.196 585.826c0 1.132-.242 8.632 1.856 9.764 2.098 1.132 2.933-4.715 2.933-4.715s4.956-2.079 12.715.427c-.26 1.244 1.281 5.068 3.249 4.028 1.967-1.039 1.856-9.597 1.856-9.708 1.039 7.425 1.373 13.291-1.856 18.971-4.047 7.184-14.126 3.564-17.301-.854-3.174-4.418-3.935-7.833-3.712-15.667.148-.798.185-1.522.26-2.246Z"
      fill="#fff"
    />
    <Path
      d="M303.672 591.302c.241 2.803-2.618 2.135-3.137-1.856-.52-3.991.705-13.532 0-20.326.148-7.815-.149-15.556 1.447-15.853a1.637 1.637 0 0 0 0 .297l.186 3.936a13.22 13.22 0 0 0 0 1.689c.538 5.717.835 16.818.873 22.089.024 3.351.235 6.697.631 10.024ZM314.883 591.302c.363-3.31.543-6.638.539-9.968 0-5.235.334-16.205.872-21.959a15.51 15.51 0 0 0 .223-3.249v-2.784c1.058.371 1.429 3.471 1.671 7.425 0 2.246 0 5.049-.223 8.464-.706 6.794.52 16.447 0 20.419-.52 3.972-3.323 4.455-3.082 1.652Z"
      fill="#F1A8A8"
    />
    <Path
      d="M315.886 591.506h-.223a19.088 19.088 0 0 0-12.474 0 .566.566 0 0 1-.457-.011.582.582 0 0 1-.304-.341.57.57 0 0 1 .005-.452.58.58 0 0 1 .329-.309 19.955 19.955 0 0 1 13.347 0 .632.632 0 0 1 .334.779.595.595 0 0 1-.557.334ZM316.665 585.306h-.204a23.561 23.561 0 0 0-13.959 0 .614.614 0 0 1-.761-.371.608.608 0 0 1 .026-.458.602.602 0 0 1 .345-.303 24.544 24.544 0 0 1 14.758 0 .592.592 0 0 1 .352.761.575.575 0 0 1-.557.371ZM302.317 579.422a.601.601 0 0 1-.33-.095.604.604 0 0 1-.227-.258.608.608 0 0 1 .297-.798 20.42 20.42 0 0 1 14.72 0 .575.575 0 0 1 .334.761.6.6 0 0 1-.549.379.59.59 0 0 1-.231-.045s-7.313-2.877-13.773 0a.575.575 0 0 1-.241.056ZM302.168 574.039a.616.616 0 0 1-.538-.353.612.612 0 0 1 .278-.798 18.563 18.563 0 0 1 15.017-.149.567.567 0 0 1 .305.331.585.585 0 0 1-.026.449.614.614 0 0 1-.798.297 17.617 17.617 0 0 0-13.978.149.427.427 0 0 1-.26.074Z"
      fill="#FAFAFA"
    />
    <Path
      d="M302.038 567.783a.589.589 0 0 1-.52-.334.567.567 0 0 1-.043-.455.587.587 0 0 1 .303-.343c7.128-3.527 14.85-.167 15.129 0a.597.597 0 0 1 .306.789.597.597 0 0 1-.789.306s-7.425-3.285-14.107 0a.513.513 0 0 1-.279.037Z"
      fill="#EBEBEB"
    />
    <Path
      d="M311.821 315.055a110.009 110.009 0 0 0-8.465 31.723c-3.063 39.798-1.411 96.934-1.411 96.934-.352 4.158-2.988 13.606-4.752 31.909-2.097 21.643.52 94.928.52 94.928s12.994 7.722 23.018-1.058c0 0 11.694-100.757 14.404-122.271 2.376-18.767 8.984-59.4 8.984-59.4l7.759 60.05s-.723 16.892-.222 27.843c.575 12.697 4.139 77.369 4.139 77.369s17.764 6.014 25.709-2.525c0 0 1.856-89.712 2.153-105.008.539-29.96 0-84.57-4.844-115.997l-66.992-14.497Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="M311.821 315.055a110.009 110.009 0 0 0-8.465 31.723c-3.063 39.798-1.411 96.934-1.411 96.934-.352 4.158-2.988 13.606-4.752 31.909-2.097 21.643.52 94.928.52 94.928s12.994 7.722 23.018-1.058c0 0 11.694-100.757 14.404-122.271 2.376-18.767 8.984-59.4 8.984-59.4l7.759 60.05s-.723 16.892-.222 27.843c.575 12.697 4.139 77.369 4.139 77.369s17.764 6.014 25.709-2.525c0 0 1.856-89.712 2.153-105.008.539-29.96 0-84.57-4.844-115.997l-66.992-14.497Z"
      fill="#000"
    />
    <Path
      opacity={0.2}
      d="m344.119 387.857 2.785-19.119s16.131-2.154 23.444-9.022a26.48 26.48 0 0 1-19.008 11.992l-2.858 17.281 2.468 51.697-6.831-52.829Z"
      fill="#000"
    />
    <Path
      d="M364.872 236.202c9.671.928 12.994 3.415 16.706 11.026 3.713 7.61 13.551 41.765 13.551 41.765l-25.022 9.467-5.235-62.258Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.1}
      d="M364.872 236.202c9.671.928 12.994 3.415 16.706 11.026 3.713 7.61 13.551 41.765 13.551 41.765l-25.022 9.467-5.235-62.258Z"
      fill="#000"
    />
    <Path
      d="M377.588 286.32c-.706 7.852-.873 23.203-.873 23.203 2.84 20.066 5.123 39.668 5.123 39.668-29.978 16.001-75.215 3.045-80.914-3.545 2.803-19.658 9.022-31.89 12.994-42.954l-7.425-33.412s12.994-10.748 11.75-32.559l17.727-1.856 16.892.464 12.01.854a101.365 101.365 0 0 1 15.704 19.583c5.847 9.356 6.051 21.05-2.988 30.554Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.1}
      d="M364.538 248.805a51.969 51.969 0 0 0-6.645-13.16l-5.031-.372-16.892-.464-6.608.687c-.464 2.32 5.569 9.467 10.896 13.755 5.328 4.288 16.874 10.339 23.129 11.602a15.888 15.888 0 0 0 1.151-12.048Z"
      fill="#000"
    />
    <Path
      opacity={0.2}
      d="m348.166 274.83-19.973.798v3.843l20.251-.854-.278-3.787Z"
      fill="#000"
    />
    <Path
      d="m341.725 275.071-.446-5.624h-13.086l.557 6.163 12.975-.539Z"
      fill="#FAFAFA"
    />
    <Path
      d="M347.275 274.849c-.316-6.516-1.021-9.727-1.541-10.136a1.187 1.187 0 0 0-1.522 0c-.501.632-.872 3.991-.835 10.303l3.898-.167Z"
      fill="#455A64"
    />
    <Path
      d="M345.753 274.292c0 2.487.185 7.239-.687 7.313-.872.075-.78-4.603-.817-7.313h1.504Z"
      fill="#455A64"
    />
    <Path
      d="M332.277 224.192c0-3.008-4.214-9.282-8.057-16.261-3.842-6.98-8-18.284 0-28.846 8.001-10.562 26.099-16.855 36.754-11.862 10.655 4.994 13.662 17.189 13.662 17.189l-42.359 39.78Z"
      fill="#000"
    />
    <Path
      d="M329.9 213.165c4.344 4.66 6.943 9.282 2.284 13.662a11.546 11.546 0 0 1-15.76-16.854c4.641-4.344 9.114-1.448 13.476 3.192Z"
      fill="#000"
    />
    <Path
      d="M367.397 175.094c4.009 2.042 9.596 6.404 8.724 26.785-.668 15.909-6.478 23.241-9.281 24.651-2.803 1.411-6.07 1.708-11.379 1.151v9.059s10.302 12.567 6.571 20.827c0 0-10.859-3.917-16.706-8.149-11.472-8.26-12.882-12.771-12.882-12.771l-.279-21.774s-3.545 2.599-9.077-1.67c-4.566-3.527-6.423-9.82-3.248-13.458a7.18 7.18 0 0 1 6.719-2.246 8.925 8.925 0 0 1 6.126 4.9 1.701 1.701 0 0 0 1.169 1.058 1.3 1.3 0 0 0 1.058-.501 3.11 3.11 0 0 0 .539-1.077c1.132-3.378.612-6.923 1.41-10.357a19.603 19.603 0 0 1 3.453-7.425 24.651 24.651 0 0 1 15.852-9.282 33.072 33.072 0 0 1 11.231.279Z"
      fill="#FFBDA7"
    />
    <Path
      d="m378.646 191.633 1.095.111a1.617 1.617 0 0 1 1.114 1.764 3.865 3.865 0 0 1-1.114 1.54 1.864 1.864 0 0 0-.446.724 31.482 31.482 0 0 1-1.578 5.569 7.036 7.036 0 0 1-6.905 3.88 11.082 11.082 0 0 1-2.32-.204h-.167a7.293 7.293 0 0 1-1.856-.669h-.26l-.204-.13-.186-.111-.204-.149-.186-.167a3.17 3.17 0 0 1-.371-.334 7.401 7.401 0 0 1-1.485-2.134c-.947-1.968-.65-4.697-2.265-5.031h-.408c-2.209 0-2.023 2.97-3.286 5.086-1.262 2.116-3.341 4.158-8.13 4.158h-1.095l-1.151-.148H346.31a6.383 6.383 0 0 1-1.3-.557l-.427-.26a7.311 7.311 0 0 1-.742-.594 6.592 6.592 0 0 1-1.411-1.986 29.782 29.782 0 0 1-1.206-5.569 2.446 2.446 0 0 0-.353-.817l-.464-.575-11.138 3.545a6.944 6.944 0 0 0-1.076-.575c.454-.775.949-1.525 1.485-2.246l11.527-3.88 1.169-.13a40.61 40.61 0 0 1 11.639.279c3.694.928 5.105 2.005 7.017 1.986 1.912-.019 3.193-1.077 6.701-2.023a35.685 35.685 0 0 1 10.915-.353Z"
      fill="#37474F"
    />
    <Path
      d="M352.695 197.313a2.352 2.352 0 0 1-.517 1.687 2.35 2.35 0 0 1-1.543.856 2.205 2.205 0 0 1-2.244-1.124 2.195 2.195 0 0 1-.262-.843 2.376 2.376 0 0 1 2.06-2.544 2.25 2.25 0 0 1 .88.06 2.24 2.24 0 0 1 1.359 1.068c.144.259.235.545.267.84Z"
      fill="#000"
    />
    <Path
      d="m352.38 212.534 8 2.024a4.048 4.048 0 0 1-4.938 3.118 4.304 4.304 0 0 1-3.062-5.142Z"
      fill="#F0997A"
    />
    <Path
      d="m372.798 190.204-5.03-1.244a2.465 2.465 0 0 1 3.044-1.856 2.687 2.687 0 0 1 1.986 3.1ZM349.725 188.719l-4.696 2.209a2.5 2.5 0 0 1-.157-1.955 2.494 2.494 0 0 1 1.308-1.461 2.711 2.711 0 0 1 3.545 1.207ZM371.425 197.22a2.36 2.36 0 0 1-2.042 2.543 2.22 2.22 0 0 1-2.244-1.14 2.227 2.227 0 0 1-.262-.846 2.338 2.338 0 0 1 2.06-2.524 2.19 2.19 0 0 1 2.488 1.967Z"
      fill="#000"
    />
    <Path
      d="m360.176 196.719.854 12.623 6.738-2.543-7.592-10.08ZM355.517 227.681c-5.569-.835-17.505-3.824-19.547-8.223a13 13 0 0 0 4.567 6.293c3.582 2.71 14.98 5.142 14.98 5.142v-3.212Z"
      fill="#F0997A"
    />
    <Path
      d="M374.766 184.895s-2.914-4.697-16.224-3.434a50.448 50.448 0 0 0-20.678 6.942 29.692 29.692 0 0 0-9.671 9.597 7.977 7.977 0 0 0-1.634-.501 7.233 7.233 0 0 0-6.738 2.246 22.679 22.679 0 0 1-3.285-15.518c1.429-9.282 10.617-15.667 20.827-20.011 12.065-5.123 25.282-3.805 30.591 1.188 5.309 4.993 6.682 19.008 6.812 19.491Z"
      fill="#F1A8A8"
    />
    <Path
      d="m404.466 271.563-53.608 18.915-7.908 71.93 1.188 1.281 50.972-19.027 10.544-71.818-1.188-1.281Z"
      fill="#37474F"
    />
    <Path
      d="m352.046 291.759-7.908 71.93-1.188-1.281 7.908-71.93 1.188 1.281Z"
      fill="#263238"
    />
    <Path
      d="m405.654 272.844-53.608 18.915-7.908 71.93 50.973-19.027 10.543-71.818Z"
      fill="#455A64"
    />
    <Path
      d="m389.505 285.912.445-2.135c.241-1.188-2.896-.575-7.035 1.374l-9.671 3.026c-4.121 1.949-8.019 3.396-8.26 4.584l-.446 2.135 24.967-8.984Z"
      fill="#000"
      opacity={0.1}
    />
    <Path
      d="M392.697 276.241c.149-.743-1.076-.594-2.784-.13l-3.081 1.039s1.243-3.1-6.052-1.113c-8.037 2.171-8.223 6.181-8.223 6.181a31.103 31.103 0 0 1-3.861 1.411c-2.673.872-2.951 1.392-3.174 2.134l-.39 1.355 27.324-9.615s.074-.464.241-1.262Z"
      fill="#E0E0E0"
    />
    <Path
      d="M389.82 280.343c-2.524 1.151-3.582 1.708-3.954 3.527-.226.974-.4 1.959-.519 2.951l-14.238 5.569s.761-3.063.966-4.084c.204-1.02-1.708-1.02-4.158.112l.575-2.562 22.126-8.223-.798 2.71Z"
      fill="#EBEBEB"
    />
    <Path
      d="m390.247 285.949.427-2.135c.26-1.188-2.896-.575-7.017 1.374l-9.671 2.989c-4.139 1.949-8.037 3.396-8.278 4.584l-.446 2.135 24.985-8.947Z"
      fill="#EBEBEB"
    />
    <Path
      d="M364.408 323.26c-3.81.634-7.691.728-11.527.278a20.824 20.824 0 0 0 4.919-2.951 4.05 4.05 0 0 0 1.058-5.384c-.557-.686-2.153.78-5.569 1.597-4.418 1.021-7.221.278-11.564 2.543a11.683 11.683 0 0 1-5.755.909c-3.285.093-7.74-1.243-16.316-3.861a144.846 144.846 0 0 1-17.56-6.998l12.994-31.24c-2.061 4.176 13.309-32.782 3.267-41.506-11.992 0-18.563 2.933-23.853 16.855-3.713 9.578-19.212 54.759-19.825 61.627-.612 6.868 2.487 9.616 9.281 12.604 6.794 2.989 22.628 6.775 37.125 10.692a121.816 121.816 0 0 0 21.941 4.27c17.115 1.336 24.911-.093 28.605-6.479 5.754-10.172 1.801-14.349-7.221-12.956Z"
      fill="#FFBDA7"
    />
    <Path
      d="M321.325 236.313c-8.019-.446-19.732-.706-26.489 14.85-6.757 15.555-13.699 38.053-13.699 38.053a46.182 46.182 0 0 0 26.563 9.281l11.898-31.24s5.885-21.774 1.727-30.944Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.1}
      d="M321.325 236.313c-8.019-.446-19.732-.706-26.489 14.85-6.757 15.555-13.699 38.053-13.699 38.053a46.182 46.182 0 0 0 26.563 9.281l11.898-31.24s5.885-21.774 1.727-30.944Z"
      fill="#000"
    />
    <Path
      d="M151.831 193.619c9.93.279 12.733 4.251 18.729 12.79 5.996 8.538 26.322 40.837 26.322 40.837l28.809 11.138-3.713 16.817s-24.391-4.863-36.865-7.425a10.647 10.647 0 0 1-7.833-5.327c-1.597-2.432-17.616-27.64-17.616-27.64l-7.833-41.19Z"
      fill="#E6E6E6"
    />
    <Path
      d="M144.238 193.879c9.282-2.339 13.514 2.896 16.707 8.632 4.993 9.169 8 27.843 8.427 62.128.26 22.015 7.926 48.615 9.281 69.498 2.061 31.371 3.045 55.799 3.045 55.799a398.849 398.849 0 0 0-56.022-3.378l18.562-192.679Z"
      fill="#E0E0E0"
    />
    <Path
      d="M90.166 513.098s-5.569-38.517-3.286-61.108c2.024-19.379 4.864-27.491 5.272-31.89 0 0 3.267-45.478 2.376-83.995-.427-18.229.464-38.406 13.458-59.938l.464-.854c1.151 7.053 12.028 11.638 21.737 12.715 9.281 1.039 21.012-.835 34.916-5.569 3.1 18.266 6.627 53.07 10.228 90.956 1.856 18.563 1.448 34.155 1.856 46.091.408 16.168-1.058 79.559-1.058 79.559v2.673c0 2.951.817 5.012 2.877 9.69a57.84 57.84 0 0 0 10.673 16.409c1.393 1.411 10.971 9.281 12.27 10.952 4.158 4.529-4.046 7.425-10.228 6.812-6.534-.65-16.706-2.784-19.694-6.645-1.987-2.562-3.713-11.75-5.773-14.368-6.906-8.78-9.764-12.566-9.894-15.444-.26-5.197 1.448-7.313 2.005-10.265-.743-6.125-13.18-38.146-13.997-56.003.001-7.9.503-15.793 1.504-23.63l-10.952-62.017c-2.413 17.467-7.017 56.801-8.687 69.331-2.302 17.244-10.822 46.406-16.187 69.331a117.382 117.382 0 0 0-3.508 18.061l-.13 2.005c0 2.543-.26 29.7-1.077 35.454-1.707 12.01-12.362 10.859-14.386 1.244-1.726-8.168-.76-33.672-.816-36.791l.037-2.766Z"
      fill="#F28F8F"
    />
    <Path
      d="M89.813 512.931c-2.988.149-.63 11.676-1.17 19.157-.556 8.037-2.988 13.959-3.526 20.53a26.694 26.694 0 0 0 3.063 16.706c2.246 4.715 12.993 10.933 16.706 3.954a38.642 38.642 0 0 0 3.712-22.275c-.742-7.685-1.392-13.309-1.744-19.175-.297-5.161 3.267-17.43-.632-18.043l-.092 2.023c-1.3 2.822-14.535 5.198-16.28-.111l-.037-2.766Z"
      fill="#37474F"
    />
    <Path
      d="M155.562 515.381c-.799 3.713.501 13.978.501 13.978s1.726 3.471 7.573 5.569l2.116-14.349-10.19-5.198Z"
      fill="#263238"
    />
    <Path
      d="m163.636 535.002 12.567-4.604 2.692-11.378-14.034 7.703-1.225 8.279Z"
      fill="#000"
    />
    <Path
      d="M175.758 501.645v-2.673c1.744.798 6.014 14.572 11.471 23.927 4.975 8.558 14.423 13.105 17.579 16.707 4.195 4.937-3.434 11.731-16.112 9.448-6.293-1.114-15.89-4.808-18.563-8.947-2.673-4.14-3.824-11.843-6.033-14.85-2.209-3.007-7.313-6.442-8.483-9.931a19.337 19.337 0 0 1-.297-9.746c.891-3.248 1.615-7.165 2.729-6.849l.148 2.432c.91 1.707 4.01 3.601 10.377 3.712 2.97.13 5.903-.427 7.184-3.23Z"
      fill="#37474F"
    />
    <Path
      d="M142.698 449.8a132.113 132.113 0 0 1 1.058-29.292l-9.17-61.07s-6.181 58.138-7.295 67.14c-1.114 9.003-17.802 106.215-17.802 106.215-8.557 5.086-19.564 3.471-26.73-.334 0-39.315 1.226-78.687 2.655-89.583a187.299 187.299 0 0 1 5.105-22.033s.91-57.34.853-79.114c-.055-21.774 1.597-36.327 5.736-46.109a138.347 138.347 0 0 1 11.416-20.419s3.174 10.729 28.382 10.748c25.208.019 27.993-6.348 27.993-6.348s7.424 25.764 9.838 64.708c1.986 31.39 2.45 45.664 3.044 59.66.501 11.917.817 25.691 1.095 49.766.204 18.173 2.766 58.138 2.766 58.138s-12.289 6.478-32.614-.947c0 0-5.068-45.441-6.33-61.126Z"
      fill="#455A64"
    />
    <Path
      d="m134.586 359.438 4.956-36.939a31.563 31.563 0 0 0 19.287-9.282s-2.822 8.947-14.665 13.959l-6.682 32.41 4.882 51.975-7.778-52.123Z"
      fill="#37474F"
    />
    <Path
      d="m122.836 196.459-11.88 1.262c-7.871 1.857-11.435 5.402-12.233 12.382a54.217 54.217 0 0 0 .409 22.757c2.506 9.82 9.151 33.895 9.151 33.895s-8.65 21.644-12.734 32.559c28.011 18.266 62.574 11.138 74.25 0-.965-10.451-4.678-20.177-5.569-29.514 0 0 .613-14.553 1.764-20.865 8.873-9.671 7.74-18.915 3.712-26.377-7.425-13.773-18.414-25.876-21.996-25.857l-24.874-.242Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.6}
      d="m122.836 196.459-11.88 1.262c-7.871 1.857-11.435 5.402-12.233 12.382a54.217 54.217 0 0 0 .409 22.757c2.506 9.82 9.151 33.895 9.151 33.895s-8.65 21.644-12.734 32.559c28.011 18.266 62.574 11.138 74.25 0-.965-10.451-4.678-20.177-5.569-29.514 0 0 .613-14.553 1.764-20.865 8.873-9.671 7.74-18.915 3.712-26.377-7.425-13.773-18.414-25.876-21.996-25.857l-24.874-.242Z"
      fill="#fff"
    />
    <Path
      d="M122.131 196.459s9.782 25.709 12.009 43.158c2.228 17.449-5.42 70.185-6.608 101.425-.872 22.758-3.025 55.521-3.025 55.521a80.796 80.796 0 0 1-20.809-.724c-11.509-2.172-15.073-4.047-21.625-8.335 0 0 5.68-57.154 8.464-79.373 2.636-20.901 13.885-41.506 13.885-41.506l-13.514-55.854s.669-9.448 9.542-12.084c8.445-2.506 21.681-2.228 21.681-2.228Z"
      fill="#FAFAFA"
    />
    <Path
      d="M104.83 248.713s1.857-6.646 4.362-18.006c2.506-11.36 2.135-17.968 1.244-22.925l-14.664 21.496 8.65 37.403a142.55 142.55 0 0 0-12.994 35.714c8.854-25.022 18.563-32.54 18.563-32.54l-5.161-21.142Z"
      fill="#F5F5F5"
    />
    <Path
      d="M121.722 188.18c-1.429-.297-2.691.334-3.861 2.302a1480.036 1480.036 0 0 1-3.712 6.181s-1.337 13.848 0 17.282c1.132 2.989 13.81 14.182 13.81 14.182s-3.322 12.455-3.434 16.52c-.111 4.066 7.852 19.584 8.743 27.51 0 0 2.506-24.929 1.337-33.876a158.211 158.211 0 0 0-12.79-42.045l-.093-8.056Z"
      fill="#EBEBEB"
    />
    <Path
      d="M91.744 344.885c-1.856-3.583-1.856-6.181-4.79-9.43a10.285 10.285 0 0 1-2.561-4.77 34.252 34.252 0 0 1-.705-8.78l-16.818.259c-.13 5.569-.891 13.625.167 19.584 2.692 15.11 5.68 21.514 11.991 23.277 10.135 2.785 12.864-1.485 9.616-9.058a36.443 36.443 0 0 1-2.803-9.894 19.366 19.366 0 0 0 3.712 3.601 3.598 3.598 0 0 0 4.882-.316c.409-.612-1.3-1.67-2.691-4.473Z"
      fill="#F28F8F"
    />
    <Path
      d="M108.153 197.22s-10.878-.668-17.245 4.53c-6.367 5.197-10.32 19.769-14.998 34.34-4.678 14.572-8.836 28.716-10.376 38.981-1.281 8.521.39 53.683.39 53.683 6.677.628 13.4-.566 19.453-3.453l4.548-48.262 18.228-49.45a47.26 47.26 0 0 0 0-30.369Z"
      fill="#F0F0F0"
    />
    <Path
      d="M118.437 228.053c0-1.096-.093-3.416-.279-6.367-.928-14.405-1.355-27.436 3.527-28.475l.427 1.986c-3.564.761-2.376 19.361-1.856 26.359.185 3.007.334 5.383.278 6.571l-2.097-.074Z"
      fill="#F1A8A8"
    />
    <Path
      d="M115.541 276.204h-.13v-1.43a9.104 9.104 0 0 0 4.659-1.726 17.426 17.426 0 0 1-3.861-3.434c-2.302-2.97-7.314-24.131-5.773-29.05 1.3-4.121 5.365-13.235 8.984-13.384 3.602 0 8.335 8.613 9.968 12.586 1.968 4.77-1.243 26.284-3.267 29.421a17.402 17.402 0 0 1-3.564 3.824 9.065 9.065 0 0 0 4.79 1.336l.167 1.411a11.131 11.131 0 0 1-6.2-1.856 11.362 11.362 0 0 1-5.773 2.302Zm3.972-47.687c-2.134 0-5.94 6.868-7.685 12.399-1.41 4.53 3.416 25.004 5.569 27.844a16.336 16.336 0 0 0 4.01 3.49 16.575 16.575 0 0 0 3.712-3.824c1.856-2.914 4.938-23.723 3.137-28.104-2.283-5.438-6.608-11.805-8.743-11.805Z"
      fill="#455A64"
    />
    <Path
      d="M129.611 274.886a2.095 2.095 0 0 1-2.003 2.014 2.092 2.092 0 0 1-2.173-1.829 2.118 2.118 0 0 1 2.004-2.19 2.094 2.094 0 0 1 2.172 2.005ZM117.471 275.405a2.083 2.083 0 0 1-.48 1.618 2.094 2.094 0 0 1-2.367.597 2.091 2.091 0 0 1-1.329-2.047 2.099 2.099 0 0 1 3.451-1.506c.401.34.66.817.725 1.338Z"
      fill="#263238"
    />
    <Path
      d="m129.834 249.01-1.188-.093a.482.482 0 0 1-.446-.501 24.694 24.694 0 0 0-.352-8.057c-2.116-5.123-6.46-11.564-8.353-11.49-1.894.075-5.699 6.868-7.425 12.159-.354 2.69-.248 5.42.315 8.074a.464.464 0 0 1-.39.52l-1.188.186a.482.482 0 0 1-.538-.409 24.358 24.358 0 0 1-.241-9.002c.891-2.84 5.067-13.477 9.281-13.644 4.214-.167 9.281 10.042 10.414 12.79.732 2.948.909 6.008.519 9.021a.482.482 0 0 1-.408.446ZM243.177 232.025l-53.924 19.027-14.85 64.17 1.206 1.281 51.27-19.157 17.504-64.04-1.206-1.281Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.35}
      d="m190.441 252.332-14.832 64.171-1.206-1.281 14.85-64.17 1.188 1.28Z"
      fill="#000"
    />
    <Path
      opacity={0.25}
      d="m244.383 233.306-53.943 19.026-14.831 64.171 51.27-19.157 17.504-64.04Z"
      fill="#000"
    />
    <Path
      d="M218.86 284.316c.501 1.262 4.622 4.028 10.617 3.526l1.003-3.712-11.62.186Z"
      fill="#000"
      opacity={0.2}
    />
    <Path
      d="M228.141 246.448s.186-.965.445-2.153c.26-1.188-2.932-.557-7.072 1.392l-9.745 2.951c-4.14 1.949-8.075 3.416-8.316 4.604l-.446 2.153 25.134-8.947Z"
      fill="#000"
      opacity={0.1}
    />
    <Path
      d="M231.334 236.721c.167-.742-1.077-.594-2.785-.148l-3.118 1.058s1.262-3.119-6.089-1.114c-8.074 2.19-8.26 6.219-8.26 6.219a30.832 30.832 0 0 1-3.88 1.41c-2.691.873-2.988 1.411-3.192 2.154l-.409 1.373 27.491-9.615s.093-.594.242-1.337Z"
      fill="#37474F"
    />
    <Path
      d="M228.456 240.842c-2.561 1.151-3.712 1.708-3.99 3.546-.279 1.837-.52 2.97-.52 2.97l-14.33 5.568s.779-3.081.965-4.102c.185-1.021-1.689-1.039-4.177.111l.594-2.58 22.275-8.279-.817 2.766Z"
      fill="#37474F"
    />
    <Path
      d="M228.865 246.485s.204-.965.445-2.153c.242-1.188-2.914-.576-7.053 1.392l-9.746 2.951c-4.139 1.95-8.074 3.416-8.316 4.604l-.445 2.153 25.115-8.947Z"
      fill="#37474F"
    />
    <Path
      d="M238.387 255.247s-1.856 7.053-2.413 8.835c-.557 1.782-9.281 5.848-13.755 6.404-8.464 1.058-9.429 2.878-7.425 8.892 1.857 5.569 7.425 7.629 15.018 6.72a13.809 13.809 0 0 0 10.469-9.133c2.469-7.091 1.132-14.646 2.116-18.767.984-4.121-1.856-5.402-4.01-2.951Z"
      fill="#F28F8F"
    />
    <Path
      d="M133.064 118.924h3.1a31.685 31.685 0 0 1 31.686 31.686v32.577a20.904 20.904 0 0 1-20.883 20.901h-45.589V150.61a31.689 31.689 0 0 1 9.28-22.406 31.689 31.689 0 0 1 22.406-9.28Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.15}
      d="M133.064 118.924h3.1a31.685 31.685 0 0 1 31.686 31.686v32.577a20.904 20.904 0 0 1-20.883 20.901h-45.589V150.61a31.689 31.689 0 0 1 9.28-22.406 31.689 31.689 0 0 1 22.406-9.28Z"
      fill="#000"
    />
    <Path
      d="M113.313 192.988h19.807v24.131a18.875 18.875 0 0 1-18.879 18.878h-.928v-43.009Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.3}
      d="M113.313 192.988h19.807v24.131a18.875 18.875 0 0 1-18.879 18.878h-.928v-43.009Z"
      fill="#000"
    />
    <Path
      d="M156.972 135.964c4.474 3.211 5.996 6.868 5.569 27.324-.445 17.226-6.051 21.495-8.873 22.795-2.821 1.299-8.242.482-13.513-.409v12.27a10.495 10.495 0 0 1 3.508 6.089c.353 1.281-3.898 3.285-14.386 1.856a13.726 13.726 0 0 1-10.822-7.072v-38.722s0-15.704 11.268-21.866a31.55 31.55 0 0 1 27.249-2.265Z"
      fill="#F28F8F"
    />
    <Path
      d="M118.455 160.095s10.265-18.339 25.208-20.567c14.943-2.227 18.247 5.272 18.247 5.272s-1.856-16.948-18.247-16.521c-22.535.538-25.208 17.802-25.208 31.816Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.4}
      d="M118.455 160.095s10.265-18.339 25.208-20.567c14.943-2.227 18.247 5.272 18.247 5.272s-1.856-16.948-18.247-16.521c-22.535.538-25.208 17.802-25.208 31.816Z"
      fill="#000"
    />
    <Path
      d="M140.303 158.833a2.16 2.16 0 0 1-.564 1.566 2.184 2.184 0 0 1-1.505.708 2.178 2.178 0 0 1-2.274-2.07 2.2 2.2 0 0 1 .102-.856 2.197 2.197 0 0 1 1.939-1.501 2.244 2.244 0 0 1 2.302 2.153Z"
      fill="#000"
    />
    <Path
      d="m138.54 172.068 8 2.562a4.122 4.122 0 0 1-5.142 2.877 4.417 4.417 0 0 1-2.858-5.439Z"
      fill="#B16668"
    />
    <Path
      d="m159.107 152.41-4.381-3.044a2.566 2.566 0 0 1 2.747-1.195c.349.076.678.224.966.434a2.804 2.804 0 0 1 .668 3.805ZM133.973 152.151l4.4-3.063a2.55 2.55 0 0 0-1.693-1.191 2.561 2.561 0 0 0-2.02.448 2.828 2.828 0 0 0-1.098 1.767c-.127.707.02 1.436.411 2.039ZM158.105 158.388a2.179 2.179 0 0 1-1.317 1.993 2.178 2.178 0 0 1-2.352-.423 2.166 2.166 0 0 1-.54-2.327 2.171 2.171 0 0 1 1.925-1.415 2.219 2.219 0 0 1 1.592.613 2.229 2.229 0 0 1 .692 1.559Z"
      fill="#000"
    />
    <Path
      d="m147.487 157.385.353 13.124 6.868-2.135-7.221-10.989ZM140.155 185.619c-5.569-.724-16.985-4.01-18.767-8.558a11.728 11.728 0 0 0 4.01 5.792c3.415 2.933 14.85 6.237 14.85 6.237l-.093-3.471Z"
      fill="#B16668"
    />
    <Path
      d="m147.208 187.939-7.035-2.302a2.302 2.302 0 0 1-2.283 2.302h-11.007a8.431 8.431 0 0 1-5.967-2.487 8.432 8.432 0 0 1-2.461-5.977l-7.016 8.464h-10.061v13.309a8.23 8.23 0 0 0 2.4 5.834 8.239 8.239 0 0 0 5.823 2.427h30.35a20.08 20.08 0 0 0 14.172-5.917 20.073 20.073 0 0 0 5.838-14.205v-1.448h-12.753Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.15}
      d="m147.208 187.939-7.035-2.302a2.302 2.302 0 0 1-2.283 2.302h-11.007a8.431 8.431 0 0 1-5.967-2.487 8.432 8.432 0 0 1-2.461-5.977l-7.016 8.464h-10.061v13.309a8.23 8.23 0 0 0 2.4 5.834 8.239 8.239 0 0 0 5.823 2.427h30.35a20.08 20.08 0 0 0 14.172-5.917 20.073 20.073 0 0 0 5.838-14.205v-1.448h-12.753Z"
      fill="#000"
    />
    <Path
      d="M270.018 702.12c-.223-5.365 4.084-58.323 4.084-58.323l-25.691 16.984c2.71 31 4.455 40.689 4.548 45.868-.112.43-.162.874-.149 1.318 0 6.126 7.611 9.43 13.533 13.514a112.695 112.695 0 0 0 16.019 9.541c4.306 1.967 12.994 2.153 14.126-.371-7.202-5.012-17.69-12.53-23.426-18.247-2.729-2.766-2.84-4.919-3.044-10.284Z"
      fill="#9E6767"
    />
    <Path
      d="M308.776 737.222a4.935 4.935 0 0 1-1.095 5.29 26.563 26.563 0 0 1-18.098 3.713c-8.984-1.058-13.848-3.583-17.43-6.33-3.583-2.747-8.632-6.608-12.697-7.425a25.65 25.65 0 0 1-9.096-3.397c-1.206-1.058-1.039-5.792-1.039-5.792l59.455 13.941Z"
      fill="#455A64"
    />
    <Path
      d="M252.903 705.127a1.43 1.43 0 0 0-1.355 1.095c-.278 1.318-.575 5.105-1.169 7.24a35.49 35.49 0 0 0-.891 11.49c.501 2.413 6.664 4.102 10.58 5.476a50.933 50.933 0 0 1 12.753 7.332 38.108 38.108 0 0 0 18.934 6.441c6.923 0 14.72-2.097 16.52-5.03 2.209-3.62.464-5.717-9.801-10.061-1.856-.817-8.78-4.492-11.137-5.829-6.293-3.619-12.53-8.65-15.426-15.054a3.709 3.709 0 0 0-1.485-1.949 3.475 3.475 0 0 0-2.004-.167c-1.701.224-3.362.68-4.938 1.355-1.374.613-3.341 1.299-3.304 2.914.039.563.171 1.115.39 1.634.13.334.222.761 0 .965-.223.204-.706 0-.966-.26a23.797 23.797 0 0 1-2.765-3.025 9.75 9.75 0 0 0-1.634-1.504 18.223 18.223 0 0 1-1.763-1.039c-.669-.576-.502-1.318-.539-2.024Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.9}
      d="M252.903 705.127a1.43 1.43 0 0 0-1.355 1.095c-.278 1.318-.575 5.105-1.169 7.24a35.49 35.49 0 0 0-.891 11.49c.501 2.413 6.664 4.102 10.58 5.476a50.933 50.933 0 0 1 12.753 7.332 38.108 38.108 0 0 0 18.934 6.441c6.923 0 14.72-2.097 16.52-5.03 2.209-3.62.464-5.717-9.801-10.061-1.856-.817-8.78-4.492-11.137-5.829-6.293-3.619-12.53-8.65-15.426-15.054a3.709 3.709 0 0 0-1.485-1.949 3.475 3.475 0 0 0-2.004-.167c-1.701.224-3.362.68-4.938 1.355-1.374.613-3.341 1.299-3.304 2.914.039.563.171 1.115.39 1.634.13.334.222.761 0 .965-.223.204-.706 0-.966-.26a23.797 23.797 0 0 1-2.765-3.025 9.75 9.75 0 0 0-1.634-1.504 18.223 18.223 0 0 1-1.763-1.039c-.669-.576-.502-1.318-.539-2.024Z"
      fill="#fff"
    />
    <Path
      d="M272.821 737.76a66.148 66.148 0 0 0-5.569-3.805s12.084 6.701 16.966 3.712c3.137-1.949 1.634-4.325 1.559-5.086-.074-.761 7.685-4.028 10.748-4.362.854.445 1.559.78 2.005.965 10.265 4.344 12.01 6.441 9.801 10.061-1.856 2.933-7.425 5.216-16.391 5.216a38.972 38.972 0 0 1-19.119-6.701Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.8}
      d="M272.821 737.76a66.148 66.148 0 0 0-5.569-3.805s12.084 6.701 16.966 3.712c3.137-1.949 1.634-4.325 1.559-5.086-.074-.761 7.685-4.028 10.748-4.362.854.445 1.559.78 2.005.965 10.265 4.344 12.01 6.441 9.801 10.061-1.856 2.933-7.425 5.216-16.391 5.216a38.972 38.972 0 0 1-19.119-6.701Z"
      fill="#fff"
    />
    <Path
      d="M284.905 730.279c6.386 2.339 3.713 3.416 1.856 3.713-1.856.297-12.548-5.569-19.101-10.228-6.831-4.938-11.137-10.321-13.699-12.14-.946-.668-1.949-1.503-2.858-2.283.167-1.281.297-2.45.427-3.063a1.407 1.407 0 0 1 1.355-1.095c0 .761-.112 1.448.501 2.023.568.379 1.157.726 1.763 1.04a9.667 9.667 0 0 1 1.634 1.503 23.672 23.672 0 0 0 2.766 3.026c5.587 6.404 18.971 15.184 25.356 17.504Z"
      fill="#F1A8A8"
    />
    <Path
      d="M286.316 733.082a.727.727 0 0 1-.39-.13.535.535 0 0 1-.093-.816 16.03 16.03 0 0 1 11.695-4.214.59.59 0 0 1 .387.214.576.576 0 0 1-.092.814.563.563 0 0 1-.425.123 14.851 14.851 0 0 0-10.581 3.712.668.668 0 0 1-.501.297ZM280.747 729.964a.612.612 0 0 1-.39-.13.537.537 0 0 1-.16-.162.532.532 0 0 1-.051-.442.523.523 0 0 1 .118-.194 15.945 15.945 0 0 1 11.695-4.214.582.582 0 0 1 .415.195.576.576 0 0 1 .142.436.59.59 0 0 1-.448.51.584.584 0 0 1-.239.01 14.851 14.851 0 0 0-10.581 3.712.723.723 0 0 1-.501.279ZM275.178 726.994a.813.813 0 0 1-.408-.13.558.558 0 0 1 0-.817 15.934 15.934 0 0 1 11.694-4.195.578.578 0 0 1 .557.631.585.585 0 0 1-.238.395.585.585 0 0 1-.449.106 14.854 14.854 0 0 0-10.599 3.713.582.582 0 0 1-.236.228.586.586 0 0 1-.321.069ZM270.018 723.615a.828.828 0 0 1-.409-.129.594.594 0 0 1-.17-.409.58.58 0 0 1 .17-.408 16.05 16.05 0 0 1 11.713-4.214.588.588 0 0 1 .388.215.563.563 0 0 1 .123.425.588.588 0 0 1-.215.388.563.563 0 0 1-.426.123 14.85 14.85 0 0 0-10.599 3.712.589.589 0 0 1-.575.297ZM264.95 720.218a.713.713 0 0 1-.389-.111.525.525 0 0 1-.188-.408.542.542 0 0 1 .188-.409c2.468-2.858 8.074-4.733 12.77-4.269a.596.596 0 0 1 .557.65.613.613 0 0 1-.686.501c-4.233-.409-9.467 1.299-11.658 3.842a.715.715 0 0 1-.594.204Z"
      fill="#FAFAFA"
    />
    <Path
      d="m220.382 662.638-25.988-.464c.39 17.337 3.137 36.605 3.601 52.884 0 3.081 0 23.834 1.077 30.127 1.726 9.485 12.994 8.205 13.569 2.525.576-5.681-.724-29.886-.687-32.392 0-10.432 4.808-28.066 7.425-46.815-.018-.928.873-4.974 1.003-5.865Z"
      fill="#9E6767"
    />
    <Path
      d="M218.006 751.663c.26 5.569-.297 9.282-2.098 12.771-1.8 3.49-5.773 4.827-10.005 4.251-4.232-.575-9.82-2.617-11.527-7.295a27.93 27.93 0 0 1-1.467-13.179l25.097 3.452Z"
      fill="#455A64"
    />
    <Path
      d="M197.086 713.072c-1.689.278-1.355 7.759-1.522 15.296-.167 8.074-2.432 11.601-2.636 18.228-.241 7.573.557 10.877 3.935 15.128 3.378 4.251 14.089 7.759 18.396.836 3.526-5.662 3.044-11.528 1.856-18.953a171.388 171.388 0 0 1-2.469-18.952c-.297-5.569-.464-11.137-2.023-11.583v2.71c0 .854.148 3.713-.799 3.713a11.428 11.428 0 0 1-.259-2.116 2.25 2.25 0 0 0-.669-1.745 3.335 3.335 0 0 0-1.967-.594c-1.708-.149-3.434-.279-5.142-.26-.854 0-1.708 0-2.562.13a7.59 7.59 0 0 0-2.134.482 1.405 1.405 0 0 0-.836.854 2.66 2.66 0 0 0 0 .854 4.854 4.854 0 0 1 0 1.559c0 .223-.167.502-.427.464-.26-.037-.297-.241-.315-.408-.112-.91 0-1.578-.13-2.636v-1.633c0-.483-.297-.891-.297-1.374Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.9}
      d="M197.086 713.072c-1.689.278-1.355 7.759-1.522 15.296-.167 8.074-2.432 11.601-2.636 18.228-.241 7.573.557 10.877 3.935 15.128 3.378 4.251 14.089 7.759 18.396.836 3.526-5.662 3.044-11.528 1.856-18.953a171.388 171.388 0 0 1-2.469-18.952c-.297-5.569-.464-11.137-2.023-11.583v2.71c0 .854.148 3.713-.799 3.713a11.428 11.428 0 0 1-.259-2.116 2.25 2.25 0 0 0-.669-1.745 3.335 3.335 0 0 0-1.967-.594c-1.708-.149-3.434-.279-5.142-.26-.854 0-1.708 0-2.562.13a7.59 7.59 0 0 0-2.134.482 1.405 1.405 0 0 0-.836.854 2.66 2.66 0 0 0 0 .854 4.854 4.854 0 0 1 0 1.559c0 .223-.167.502-.427.464-.26-.037-.297-.241-.315-.408-.112-.91 0-1.578-.13-2.636v-1.633c0-.483-.297-.891-.297-1.374Z"
      fill="#fff"
    />
    <Path
      d="M193.095 744.443c0 1.095-.26 8.316 2.023 9.411 2.283 1.095 3.119-4.548 3.119-4.548a22.849 22.849 0 0 1 13.532.408c-.279 1.207 1.355 4.901 3.434 3.898 2.079-1.002 2.023-9.281 2.023-9.281 1.114 7.11 1.467 12.808-1.856 18.303-4.307 6.924-15.017 3.415-18.395-.836-3.379-4.25-4.177-7.554-3.936-15.128-.074-.817-.018-1.541.056-2.227Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.8}
      d="M193.095 744.443c0 1.095-.26 8.316 2.023 9.411 2.283 1.095 3.119-4.548 3.119-4.548a22.849 22.849 0 0 1 13.532.408c-.279 1.207 1.355 4.901 3.434 3.898 2.079-1.002 2.023-9.281 2.023-9.281 1.114 7.11 1.467 12.808-1.856 18.303-4.307 6.924-15.017 3.415-18.395-.836-3.379-4.25-4.177-7.554-3.936-15.128-.074-.817-.018-1.541.056-2.227Z"
      fill="#fff"
    />
    <Path
      d="M198.942 749.733c.279 2.71-2.766 2.06-3.323-1.745-.556-3.805.761-12.994 0-19.62.167-7.537-.167-15.018 1.523-15.296v.278l.185 3.806c0 .557 0 1.039.093 1.633.557 5.569.891 16.224.91 21.31a82.3 82.3 0 0 0 .612 9.634ZM210.841 749.733a82.71 82.71 0 0 0 .575-9.634c0-5.049.353-15.648.91-21.198.22-1.024.301-2.073.241-3.119v-2.71c1.114.371 1.504 3.36 1.856 7.109 0 2.191 0 4.901-.241 8.187-.743 6.552.557 15.87 0 19.676-.557 3.805-3.601 4.399-3.341 1.689Z"
      fill="#F1A8A8"
    />
    <Path
      d="M211.936 749.919a.576.576 0 0 1-.241 0 22.272 22.272 0 0 0-13.254 0 .652.652 0 0 1-.817-.334.563.563 0 0 1 .02-.455.556.556 0 0 1 .351-.288 23.13 23.13 0 0 1 14.182 0 .551.551 0 0 1 .34.294.55.55 0 0 1 .013.449.679.679 0 0 1-.594.334ZM212.734 743.923h-.223a27.17 27.17 0 0 0-14.85 0 .65.65 0 0 1-.798-.371.553.553 0 0 1 .042-.454.559.559 0 0 1 .366-.27 28.362 28.362 0 0 1 15.667 0 .578.578 0 0 1 .356.287.579.579 0 0 1 .034.455.612.612 0 0 1-.594.353ZM197.457 738.261a.69.69 0 0 1-.575-.334.592.592 0 0 1 .315-.779 23.733 23.733 0 0 1 15.648 0 .544.544 0 0 1 .349.289.538.538 0 0 1 .004.453.647.647 0 0 1-.817.316s-7.796-2.766-14.645 0l-.279.055ZM197.327 732.971a.655.655 0 0 1-.575-.334.54.54 0 0 1 .334-.817 21.474 21.474 0 0 1 15.945-.167.546.546 0 0 1 .323.312c.029.073.041.15.036.228a.52.52 0 0 1-.062.221.63.63 0 0 1-.835.279 20.413 20.413 0 0 0-14.85.167.762.762 0 0 1-.316.111Z"
      fill="#FAFAFA"
    />
    <Path
      d="M197.179 727.012a.626.626 0 0 1-.557-.315.563.563 0 0 1-.035-.452.567.567 0 0 1 .313-.328c7.574-3.378 15.741-.148 16.094 0a.56.56 0 0 1 .368.537.54.54 0 0 1-.052.224.635.635 0 0 1-.836.279c-.093 0-7.982-3.156-14.998 0a.76.76 0 0 1-.297.055Z"
      fill="#EBEBEB"
    />
    <Path
      d="M207.407 483.083a106.66 106.66 0 0 0-8.168 30.628c-2.951 38.406-4.436 94.186-4.436 94.186-.353 4.028-2.896 13.142-4.604 30.814-2.023 20.864 3.601 90.956 3.601 90.956s12.493 7.425 22.275-1.021c0 0 11.286-97.268 13.922-118.02 2.283-18.117 8.669-57.303 8.669-57.303l4.826 59.976s-.687 16.297-.204 26.841c.557 12.251 6.552 72.691 6.552 72.691s17.171 5.81 24.837-2.432c0 0 1.856-86.594 2.079-101.351.501-28.921-1.002-82.845-2.023-112.229l-67.326-13.736Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.6}
      d="M207.407 483.083a106.66 106.66 0 0 0-8.168 30.628c-2.951 38.406-4.436 94.186-4.436 94.186-.353 4.028-2.896 13.142-4.604 30.814-2.023 20.864 3.601 90.956 3.601 90.956s12.493 7.425 22.275-1.021c0 0 11.286-97.268 13.922-118.02 2.283-18.117 8.669-57.303 8.669-57.303l4.826 59.976s-.687 16.297-.204 26.841c.557 12.251 6.552 72.691 6.552 72.691s17.171 5.81 24.837-2.432c0 0 1.856-86.594 2.079-101.351.501-28.921-1.002-82.845-2.023-112.229l-67.326-13.736Z"
      fill="#fff"
    />
    <Path
      opacity={0.2}
      d="m238.592 553.36 2.673-18.451s15.574-2.079 22.646-8.706a25.551 25.551 0 0 1-18.358 11.583l-2.766 16.707-.186 51.975-4.009-53.108Z"
      fill="#000"
    />
    <Path
      d="m288.562 488.429-12.177 15.072s-8.465-14.033-8.131-14.033c.335 0 10.73-17.022 10.73-17.022l9.578 15.983Z"
      fill="#9E6767"
    />
    <Path
      d="M249.581 393.203c9.559-.148 20.14-.408 29.384 10.562 7.147 8.52 26.507 34.935 30.405 41.692 4.4 7.647 5.012 10.747 1.56 16.464-3.453 5.718-24.132 29.441-24.132 29.441a35.51 35.51 0 0 1-10.413-15.352l12.177-20.418-23.389-34.545-15.592-27.844Z"
      fill="#37474F"
    />
    <Path
      d="M249.061 392.776c14.85-1.262 21.347.835 27.027 6.85 3.972 4.213 28.308 36.735 28.308 36.735s-1.021 6.422-19.101 16.056l-19.843-23.166-16.391-36.475Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.6}
      d="M249.061 392.776c14.85-1.262 21.347.835 27.027 6.85 3.972 4.213 28.308 36.735 28.308 36.735s-1.021 6.422-19.101 16.056l-19.843-23.166-16.391-36.475Z"
      fill="#fff"
    />
    <Path
      d="M225.802 393.463c-3.36.817-15.333.817-23.222 3.62-4.288 1.522-5.253 6.868-5.773 12.993-1.132 13.124.91 37.738.91 37.738l-.167 62.797c10.878 7.908 55.557 11.936 78.445-4.771 0 0-2.803-74.769-2.803-86.408 0-11.639-6.46-25.616-24.317-26.656l-23.073.687Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.75}
      d="M225.802 393.463c-3.36.817-15.333.817-23.222 3.62-4.288 1.522-5.253 6.868-5.773 12.993-1.132 13.124.91 37.738.91 37.738l-.167 62.797c10.878 7.908 55.557 11.936 78.445-4.771 0 0-2.803-74.769-2.803-86.408 0-11.639-6.46-25.616-24.317-26.656l-23.073.687Z"
      fill="#fff"
    />
    <Path
      d="M247.427 396.823c-3.972 3.211-14.59 4.139-21.959 1.169a191.175 191.175 0 0 0 22.553 21.273 34.17 34.17 0 0 0-.594-22.442Z"
      fill="#455A64"
    />
    <Path
      d="M229.459 343.864a20.952 20.952 0 0 0 25.034 20.575 20.956 20.956 0 1 0-25.034-20.575ZM214.2 358.714s3.397 15.945 4.752 18.562a7.26 7.26 0 0 0 4.177 3.36l-.241-11.23-8.688-10.692Z"
      fill="#000"
    />
    <Path
      d="M218.451 335.901a7.818 7.818 0 0 0-3.512.389 7.85 7.85 0 0 0-2.985 1.894c-2.803 2.858-1.243 11.806 2.246 20.567l6.386.743-2.135-23.593Z"
      fill="#000"
    />
    <Path
      d="M222.516 359.494c-1.28.761-3.118-1.708-4.696-3.36-1.578-1.652-6.738-3.917-9.281 1.522s2.265 12.827 6.33 14.107c7.016 2.191 8.056-2.301 8.056-2.301l.52 25.987a176.035 176.035 0 0 0 24.613 23.76c2.395-6.051 2.395-17.04-1.856-25.189v-7.648c3.123.5 6.291.662 9.449.483 5.141-.78 8.39-4.845 9.949-10.432 2.506-8.948 3.453-16.187 1.337-33.803-2.339-19.305-24.8-19.509-36.921-11.88-12.122 7.629-7.5 28.754-7.5 28.754Z"
      fill="#9E6767"
    />
    <Path
      d="M266.565 334.007c-4.232 3.434-6.998 5.272-15.184 5.569a36.617 36.617 0 0 1-16.242-3.137 10.565 10.565 0 0 1-2.413 5.309 16.407 16.407 0 0 1-5.736 3.712c-.445 3.472-.427 7.927-.65 11.416 0 1.188 0 7.425-2.858 5.569a14.197 14.197 0 0 1-2.451-2.729 38.934 38.934 0 0 0-3.211-3.582c-2.209-2.005.631-20.233.631-20.233a8.835 8.835 0 0 1 1.541-9.838c3.026-3.342 7.258-7.425 20.994-5.792 9.801 1.169 20.419 4.214 29.088 0 1.262 4.919-.483 11.267-3.509 13.736Z"
      fill="#000"
    />
    <Path
      d="m218.47 337.311-7.759-4.158a4.29 4.29 0 0 1 5.884-1.856 4.64 4.64 0 0 1 1.875 6.014Z"
      fill="#000"
    />
    <Path
      d="M246.202 386.372s-11.509-2.302-15.518-4.455a13.215 13.215 0 0 1-5.569-5.569 18.358 18.358 0 0 0 3.156 6.497c2.951 3.713 17.931 6.516 17.931 6.516v-2.989Z"
      fill="#874C4C"
    />
    <Path
      d="M244.049 356.542a2.472 2.472 0 0 1-3.015 2.296 2.47 2.47 0 0 1-1.722-3.376 2.466 2.466 0 0 1 2.268-1.5 2.542 2.542 0 0 1 2.469 2.58ZM241.636 348.189l-5.105 2.803a3.046 3.046 0 0 1 1.207-4.047 2.824 2.824 0 0 1 3.898 1.244Z"
      fill="#000"
    />
    <Path
      d="m242.156 370.928 8.353 3.119a4.389 4.389 0 0 1-3.948 3.029 4.366 4.366 0 0 1-1.732-.264 4.64 4.64 0 0 1-2.673-5.884Z"
      fill="#874C4C"
    />
    <Path
      d="m264.895 350.064-4.697-3.583a2.818 2.818 0 0 1 3.025-1.13c.371.095.719.265 1.022.499a3.1 3.1 0 0 1 .65 4.214ZM263.354 356.282a2.389 2.389 0 0 1-1.541 2.145 2.391 2.391 0 0 1-2.577-.577 2.391 2.391 0 0 1 .403-3.657 2.396 2.396 0 0 1 1.32-.398 2.439 2.439 0 0 1 1.716.745c.222.231.397.504.514.803.116.299.172.618.165.939Z"
      fill="#000"
    />
    <Path
      d="m251.567 352.978.816 15.036 7.852-2.673-8.668-12.363Z"
      fill="#874C4C"
    />
    <Path
      d="M221.57 395.115c-3.044.65-3.88 13.513-3.137 29.087.13 2.562.297 4.604.241 5.569h-1.726c0-.928 0-2.933-.242-5.439-.779-12.325-.872-30.201 4.493-31.018a7.66 7.66 0 0 1 2.171-.204v1.857l-1.8.148Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="M221.57 395.115c-3.044.65-3.88 13.513-3.137 29.087.13 2.562.297 4.604.241 5.569h-1.726c0-.928 0-2.933-.242-5.439-.779-12.325-.872-30.201 4.493-31.018a7.66 7.66 0 0 1 2.171-.204v1.857l-1.8.148Z"
      fill="#000"
    />
    <Path
      d="M214.46 470.572h-.148v-1.207a7.835 7.835 0 0 0 3.991-1.485 14.502 14.502 0 0 1-3.304-3.007c-1.968-2.525-6.256-20.604-4.938-24.818 1.114-3.527 4.585-11.323 7.666-11.453 3.082 0 7.128 7.425 8.521 10.766 1.67 4.084-1.059 22.461-2.785 25.152a15.106 15.106 0 0 1-3.063 3.267 7.533 7.533 0 0 0 4.103 1.132l.148 1.207a9.428 9.428 0 0 1-5.309-1.541 9.689 9.689 0 0 1-4.882 1.987Zm3.397-40.745c-1.856 0-5.067 5.847-6.552 10.599-1.225 3.861 2.895 21.365 4.715 23.704a13.761 13.761 0 0 0 3.341 2.989 14.43 14.43 0 0 0 3.155-3.267c1.597-2.487 4.233-20.27 2.692-24.02-1.819-4.566-5.513-10.005-7.351-10.005Z"
      fill="#455A64"
    />
    <Path
      d="M226.489 469.458a1.853 1.853 0 0 1-1.037 1.817 1.862 1.862 0 0 1-2.07-.303 1.856 1.856 0 1 1 3.107-1.514ZM216.112 469.885a1.853 1.853 0 0 1-1.037 1.817 1.857 1.857 0 1 1-.819-3.525 1.857 1.857 0 0 1 1.856 1.708Z"
      fill="#263238"
    />
    <Path
      d="M226.674 447.331h-1.02a.426.426 0 0 1-.372-.427 19.638 19.638 0 0 0-.315-6.886c-1.857-4.381-5.569-9.894-7.128-9.82-1.56.074-4.864 5.866-6.293 10.395-.294 2.295-.2 4.623.278 6.887a.401.401 0 0 1-.072.3.405.405 0 0 1-.262.164l-1.002.148a.4.4 0 0 1-.3-.072.405.405 0 0 1-.164-.262 20.576 20.576 0 0 1-.204-7.722c.761-2.413 4.343-11.508 7.944-11.657 3.602-.148 7.927 8.594 8.892 10.952.634 2.51.785 5.118.445 7.685a.406.406 0 0 1-.427.315Z"
      fill="#F1A8A8"
    />
    <Path
      d="M226.674 447.331h-1.02a.426.426 0 0 1-.372-.427 19.638 19.638 0 0 0-.315-6.886c-1.857-4.381-5.569-9.894-7.128-9.82-1.56.074-4.864 5.866-6.293 10.395-.294 2.295-.2 4.623.278 6.887a.401.401 0 0 1-.072.3.405.405 0 0 1-.262.164l-1.002.148a.4.4 0 0 1-.3-.072.405.405 0 0 1-.164-.262 20.576 20.576 0 0 1-.204-7.722c.761-2.413 4.343-11.508 7.944-11.657 3.602-.148 7.927 8.594 8.892 10.952.634 2.51.785 5.118.445 7.685a.406.406 0 0 1-.427.315Z"
      fill="#000"
      opacity={0.2}
    />
    <Path
      d="M246.202 391.384c2.747.371 11.138 4.529 15.184 17.077 4.047 12.549 3.713 32.206 3.713 37.701 0 5.494-.501 9.615-.501 9.615h-1.968s.278-6.998.353-10.506c.278-15.444-.687-28.623-3.713-36.568-3.712-9.504-7.889-12.548-12.994-14.962l-.074-2.357Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="M246.202 391.384c2.747.371 11.138 4.529 15.184 17.077 4.047 12.549 3.713 32.206 3.713 37.701 0 5.494-.501 9.615-.501 9.615h-1.968s.278-6.998.353-10.506c.278-15.444-.687-28.623-3.713-36.568-3.712-9.504-7.889-12.548-12.994-14.962l-.074-2.357Z"
      fill="#000"
    />
    <Path
      d="M263.187 463.796a7.09 7.09 0 1 0 0-14.181 7.09 7.09 0 0 0 0 14.181Z"
      fill="#455A64"
    />
    <Path
      d="M263.187 460.733a4.027 4.027 0 1 0 .001-8.055 4.027 4.027 0 0 0-.001 8.055Z"
      fill="#37474F"
    />
    <Path
      d="M223.278 512.411v59.4a1.16 1.16 0 0 1-.483 1.096l-2.673 1.577a1.096 1.096 0 0 1-.427 0 1.597 1.597 0 0 1-.761-.222l-67.326-38.796a3.86 3.86 0 0 1-1.727-3.007v-59.4a1.245 1.245 0 0 1 .483-1.114h.111l2.525-1.466a.89.89 0 0 1 .445-.112c.274.012.542.088.78.223l35.269 20.419 4.176 2.413 6.237 3.583h.167l21.422 12.362c.123.072.241.153.352.241.349.301.644.659.873 1.059.335.522.527 1.124.557 1.744Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.5}
      d="m222.776 510.704-2.598 1.522a3.525 3.525 0 0 0-.26-.39 4.268 4.268 0 0 0-.613-.668l-.353-.26-21.421-12.344-6.348-3.713-2.562-1.466-36.976-21.254a1.298 1.298 0 0 0-1.132-.167l2.524-1.467a.89.89 0 0 1 .446-.111c.275 0 .545.078.779.223l35.269 20.418 4.176 2.413 6.237 3.583h.168l21.421 12.363c.123.072.241.152.352.241.357.305.658.669.891 1.077Z"
      fill="#fff"
    />
    <Path
      d="M149.9 473.133v59.4a3.869 3.869 0 0 0 1.745 3.026l67.512 38.981a1.02 1.02 0 0 0 1.54-.891v-59.715a3.503 3.503 0 0 0-.52-1.708 3.704 3.704 0 0 0-1.225-1.3l-21.421-12.362-6.348-3.713-39.538-22.72a1.298 1.298 0 0 0-1.133-.167 1.247 1.247 0 0 0-.612 1.169Z"
      fill="#F1A8A8"
    />
    <Path
      d="M220.177 512.225a3.6 3.6 0 0 0-.259-.389c.1.12.187.25.259.389Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.2}
      d="m220.029 574.503 2.692-1.559a1.326 1.326 0 0 0 .464-1.114v-59.4a3.503 3.503 0 0 0-.52-1.708l-2.599 1.522c.312.517.485 1.105.501 1.708v59.623a.998.998 0 0 1-.538.928Z"
      fill="#000"
    />
    <Path
      d="M166.885 477.514c14.423-58.955 21.829-77.034 39.352-76.812 5.123 6.107-.278 25.134-3.712 34.378 0 0-15.5 37.868-18.377 51.381-2.525 11.88-5.383 26.804-5.198 37.014a7.43 7.43 0 0 0 2.005 4.863c3.286 3.564 3.304 6.441 5.42 10.376 1.671 3.1 3.509 4.251 2.989 4.938a3.993 3.993 0 0 1-5.42.408 20.962 20.962 0 0 1-4.103-3.935 46.014 46.014 0 0 0 3.212 13.551l3.712 2.116s-1.114 3.805-9.968 1.262c-10.005-2.858-13.588-4.102-14.646-21.068a132.53 132.53 0 0 1-.185-18.767c1.503-16.112 2.598-30.257 4.919-39.705Z"
      fill="#9E6767"
    />
    <Path
      d="M206.664 395.932s-13.458.482-17.69 6.961c-4.9 7.425-9.17 20.808-17.393 50.118-8.223 29.311-10.729 50.583-11.138 67.048 0 0 10.303 4.808 19.807-.668 0 0 4.213-25.449 7.944-38.981s15.704-42.88 15.704-42.88 9.43-21.272 2.766-41.598Z"
      fill="#37474F"
    />
    <Path
      d="M210.507 395.208c-9.968 1.039-18.934.724-23.872 9.968-5.569 10.432-17.987 48.262-17.987 48.262a36.985 36.985 0 0 0 26.285 12.122l11.991-33.58s7.908-22.312 3.583-36.772Z"
      fill="#F1A8A8"
    />
    <Path
      d="M210.507 395.208c-9.968 1.039-18.934.724-23.872 9.968-5.569 10.432-17.987 48.262-17.987 48.262a36.985 36.985 0 0 0 26.285 12.122l11.991-33.58s7.908-22.312 3.583-36.772Z"
      fill="#fff"
      opacity={0.6}
    />
    <Path
      d="M224.521 561.342c.798.501 1.151-1.021 1.337-3.248v-4.121s3.155 2.821 3.916-6.72c.854-10.488-3.842-12.4-3.842-12.4v-5.197c0-3.453-.724-4.307-1.522-4.882l-1.188-.594v36.438s.482.223 1.299.724Z"
      fill="#37474F"
    />
    <Path
      d="M220.66 556.163c0-3.118-.297-4.975-2.302-6.2-2.004-1.225-3.267-1.856-3.267-1.856l-.594-19.323s3.305 2.227 4.437 2.895c1.132.669 1.856-3.285 1.726-4.436l2.71 1.225.316 29.848-3.026-2.153Z"
      fill="#37474F"
    />
    <Path
      d="m214.145 554.288 2.357 1.43c1.318.779 1.856-3.23 1.3-8.966l.556-12.771c-.594-5.736-.686-10.989-2.004-11.769l-2.358-1.429.149 33.505Z"
      fill="#37474F"
    />
    <Path
      d="M560.539 700.06h-63.465l-1.188-37.998h-63.001l-1.188 37.998h-63.354l-1.262 14.46v26.117h63.335l-1.188 11.862v26.136h70.315v-26.136l-1.188-11.862h63.335v-26.136l-1.151-14.441Z"
      fill="#F1A8A8"
    />
    <Path
      d="M560.539 700.06h-63.465l-1.188-37.998h-63.001l-1.188 37.998h-63.354l-1.262 14.46v26.117h63.335l-1.188 11.862v26.136h70.315v-26.136l-1.188-11.862h63.335v-26.136l-1.151-14.441Z"
      fill="#000"
      opacity={0.2}
    />
    <Path
      d="M560.539 673.924h-63.465l-1.188-37.998h-63.001l-1.188 37.998h-63.354l-1.262 40.596h63.335l-1.188 37.979h70.315l-1.188-37.998h63.335l-1.151-40.577Z"
      fill="#F1A8A8"
    />
    <Path
      opacity={0.13}
      d="M498.355 714.501h63.335v26.136h-62.518l-.817-26.136ZM430.416 714.52l-.816 26.117h-62.519V714.52h63.335Z"
      fill="#000"
    />
  </Svg>
);

export default TrustImage;
