import React from "react";
import { Circle, Icon } from "native-base";
import colors from "config/colors";
import { FontAwesome5 } from "assets/icons";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";

const AddUserIcon = ({ circleSize = 50, iconSize = 7 }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <Circle
      bg="gray.200"
      size={circleSize}
      mr={isBigScreen ? webStyle.xNum : 3}
    >
      <Icon
        size={iconSize}
        color={colors.black}
        as={<FontAwesome5 name="user-plus" />}
      />
    </Circle>
  );
};

export default AddUserIcon;
