import React from "react";
import { AlertDialog, Button, Center, HStack, Stack, Text } from "native-base";
import colors from "config/colors";

const AlertBox = ({
  header,
  text,
  isOpen,
  setIsOpen,
  actionText,
  action = "",
  disableClose = false,
}: any) => {
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef(null);

  return (
    <Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content rounded="2xl">
          {!disableClose ? <AlertDialog.CloseButton /> : null}
          <AlertDialog.Header>
            <Text fontSize="lg" fontWeight="medium">
              {header}
            </Text>
          </AlertDialog.Header>
          <AlertDialog.Body>
            <Stack justifyContent="flex-start" w="full" space={5}>
              <Text>{text}</Text>
              <HStack justifyContent="flex-end" w="full">
                {disableClose ? (
                  <Button
                    colorScheme="danger"
                    bg={colors.primary}
                    onPress={action}
                  >
                    {actionText}
                  </Button>
                ) : (
                  <Button.Group space={2}>
                    <Button
                      variant="unstyled"
                      colorScheme="coolGray"
                      onPress={onClose}
                      ref={cancelRef}
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="danger"
                      bg={colors.primary}
                      onPress={action}
                    >
                      {actionText}
                    </Button>
                  </Button.Group>
                )}
              </HStack>
            </Stack>
          </AlertDialog.Body>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
};
export default AlertBox;
