import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import { Box, FlatList } from "native-base";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import { LinkToPage, PackageCard } from "widgets";

const PackagesList = ({
	packages,
	handlePagination,
	listFooterComponent,
	navigation,
	selected = {},
	showDetails = false,
}: any) => {
	const [isBigScreen] = AppStore("isBigScreen");

	return (
		<FlatList
			data={packages}
			showsVerticalScrollIndicator={false}
			numColumns={isBigScreen ? webStyle.numCol : 2}
			onEndReached={handlePagination}
			onEndReachedThreshold={0.5}
			ListFooterComponent={listFooterComponent}
			// contentContainerStyle={{ flex: 1 }}
			renderItem={({ item }) => {
				let duration;
				let category;
				if (showDetails) {
					// duration = "duration" in item ? item.duration : null;
					category = item.package_category_id.name;
				}
				return showDetails ? (
					<LinkToPage
						route={
							item.type == "doctor-appointment"
								? "Doctors"
								: item.type == "blood-donation"
								? "BloodDonors"
								: item.type == "upload-prescription"
								? "UploadPrescription"
								: item.type == "products"
								? "Products"
								: "ServiceDetails"
						}
						params={{ id: item.id, name: item.name, image: item.image }}
					>
						<PackageCard
							image={item.package_id.image}
							name={item.package_id.name}
							// details={duration ? `${duration}  (${category})` : category}
							details={category}
							rating={item.rating}
							price={item.price}
							imageSize={isBigScreen ? 100 : 115}
						/>
					</LinkToPage>
				) : (
					<LinkToPage
						route={
							item.type == "doctor-appointment"
								? "Doctors"
								: item.type == "blood-donation"
								? "BloodDonors"
								: item.type == "upload-prescription"
								? "UploadPrescription"
								: item.type == "products"
								? "Products"
								: "ServiceDetails"
						}
						params={{ id: item.id, name: item.name, image: item.image }}
					>
						<PackageCard
							image={item.image}
							name={item.name}
							rating={item.rating}
							imageSize={isBigScreen ? 100 : 115}
							item={item}
						/>
					</LinkToPage>
				);
			}}
			keyExtractor={(item: any, index: any) => index}
		/>
	);
};

export default PackagesList;
