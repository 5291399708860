import _APP_CONFIG from "config/app";
import React, { useState } from "react";
import { Divider, Box, Circle, HStack, Icon, Image, ScrollView, Text, VStack } from "native-base";
import { Entypo, Ionicons } from "assets/icons";
import { TouchableOpacity } from "react-native";
import colors from "config/colors";
import { shareApp, signOut } from "AppActions";
import AppStore from "AppStore";
import AlertBox from "widgets/alert-box";
import { webStyle } from "config/web-style";
import { LinkToPage } from "widgets";
import { _APP_URL } from "config/url";

const Account = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [user, setUser] = AppStore("user");
  const [session, setSession] = AppStore("session");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSignOut = () => {
    // setIsLoading(true);
    signOut(setUser, setSession, navigation, setIsLoading);
    setIsOpen(false);
  };

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
      <ScrollView
        // flex="1"
        bg={colors.primary}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 10,
        }}
        // mx={isBigScreen ? webStyle.xNum : 0}
        mx={isBigScreen ? webStyle.authX : 0}
      >
        <Box
        // mx={isBigScreen ? webStyle.authX : 0}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            h="72"
            // w="full"
            bg={colors.white}
            roundedBottom="3xl"
            pt="5"
            // overflow="hidden"
          >
            {Object.keys(user).length && user.image ? (
              <Image
                source={{ uri: `${_APP_CONFIG._IMAGE_URL}/${user.image}` }}
                alt="loading"
                size="180"
                rounded="full"
                // resizeMode="contain"
              />
            ) : (
              <Image source={require("assets/images/no-user.png")} alt="loading" size="180" resizeMode="cover" rounded="full" />
            )}

            <Text color={colors.primary} fontSize="3xl" fontWeight="bold" mt="1">
              {user.name}
            </Text>
          </Box>

          <VStack
            py="10"
            justifyContent="center"
            alignItems="flex-start"
            px="10"
            space="5"
            // mx={isBigScreen ? webStyle.xNum : 0}
          >
            {/* <Box w="full">
              <LinkToPage route="HealthCard">
                <HStack w="full" alignItems="center" justifyContent="space-between">
                  <Text color="white" fontSize="3xl" fontWeight="semibold">
                    HAI Subscription
                  </Text>
                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-right" />} />
                  </Circle>
                </HStack>
              </LinkToPage>
            </Box>

            <Divider bg="gray.200" thickness="1" /> */}

            <Box w="full">
              <LinkToPage route="Profile">
                <HStack w="full" alignItems="center" justifyContent="space-between">
                  <Text color="white" fontSize="3xl" fontWeight="semibold">
                    Profile
                  </Text>
                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-right" />} />
                  </Circle>
                </HStack>
              </LinkToPage>
            </Box>

            {/* <Divider bg="gray.200" thickness="1" />

            <Box w="full">
              <LinkToPage route="ViewBookings">
                <HStack w="full" alignItems="center" justifyContent="space-between">
                  <Text color="white" fontSize="3xl" fontWeight="semibold">
                    Bookings
                  </Text>

                  <Circle bgColor={colors.white} size={10}>
                    <Icon size="6" color={colors.primary} as={<Entypo name="chevron-right" />} />
                  </Circle>
                </HStack>
              </LinkToPage>
            </Box> */}

            <Divider bg="gray.200" thickness="1" />
            <TouchableOpacity
              style={{ width: "100%" }}
              onPress={() => setIsOpen(!isOpen)}
              // onPress={() => signOut(setUser, navigation)}
            >
              <HStack w="full" alignItems="center" justifyContent="space-between">
                <Text color="white" fontSize="3xl" fontWeight="semibold">
                  Sign Out
                </Text>
                <Icon size="10" color={colors.white} as={<Ionicons name="power" />} />
              </HStack>
            </TouchableOpacity>
            {!isBigScreen ? (
              <>
                <Divider bg="gray.200" thickness="1" />
                <TouchableOpacity style={{ width: "100%" }} onPress={shareApp}>
                  <HStack w="full" alignItems="center" justifyContent="space-between">
                    <Text color="white" fontSize="3xl" fontWeight="semibold">
                      Share App
                    </Text>
                    <Icon size="10" color={colors.white} as={<Entypo name="share" />} />
                  </HStack>
                </TouchableOpacity>
              </>
            ) : null}
          </VStack>
        </Box>
        <AlertBox header={"Sign out"} text={"Are you sure you want to Sign out?"} isOpen={isOpen} setIsOpen={setIsOpen} actionText={"Sign out"} action={handleSignOut} />
      </ScrollView>
    </Box>
  );
};

export default Account;
