import AppStore from "AppStore";

const { getState, setState } = AppStore;
let user = getState()["user"];
const hasUser = Object.keys(user).length;

export const _BOOKING_PREFIX = "#ZB00";
// export const _ZEMIDI_HELPLINE = "+91" + 7099023777;
export const _ZEMIDI_HELPLINE = "+91" + 9854031130;
export const _PREFILL_TEXT = "Yes, I am interested in availing Zemidi Doctor Consultation! Let me know how to book it.";
export const _UPI_PAY_99 = `upi://pay?pa=BONPHULHEALTH4886@icici&pn=BONPHULHEALTH4886&tr=EZYS9311344886&cu=INR&mc=5411&am=99`;
export const _UPI_PAY_199 = `upi://pay?pa=BONPHULHEALTH4886@icici&pn=BONPHULHEALTH4886&tr=EZYS9311344886&cu=INR&mc=5411&am=199`;
export const _UPI_PAY_499 = `upi://pay?pa=BONPHULHEALTH4886@icici&pn=BONPHULHEALTH4886&tr=EZYS9311344886&cu=INR&mc=5411&am=499`;

// export const _POPULAR_ = [
//   // {
//   //   id: 1,
//   //   name: "Doctors",
//   //   display_name: "Doctors",
//   //   // image: require("assets/images/doctor.png"),
//   // },
//   { id: 2, name: "BloodDonors", display_name: "Blood Donors", image: null },
// ];
