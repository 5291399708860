import AppStore from "AppStore";
import axios from "axios";
import { query } from "config/query";
import { _BASE_URL, _BASE_URL2, _DATABASE } from "config/url";
const { getState, setState } = AppStore;
import { _APP_URL, _PACKAGE_NAME } from "config/url";
import Api from "services/Api";

export const getData = async (
	setIsLoading,
	setAppIsReady,
	setPopularPackages,
	setPopularHospitals,
	setPopularServices,
	setPopularCategories,
	setTopCategories,
	setHospitalsTypeTwo,
	isBigScreen
) => {
	setIsLoading(true);

	const packageRes = await axios.post(
		_BASE_URL2,
		{
			sql: query.getPackages.query,
			sqlParams: [12, 0],
		},
		{
			headers: {
				db: _DATABASE,
				sqlToken: query.getPackages.token,
			},
		}
	);
	if (packageRes?.data?.err == undefined) {
		setPopularPackages(packageRes?.data?.result);
	}

	const hospitalRes = await axios.post(
		_BASE_URL2,
		{
			sql: query.getHospitals.query,
			sqlParams: [12, 0],
		},
		{
			headers: {
				db: _DATABASE,
				sqlToken: query.getHospitals.token,
			},
		}
	);
	if (hospitalRes?.data?.err == undefined) {
		setPopularHospitals(hospitalRes?.data?.result);
	}

	const hospital2Res = await axios.post(
		_BASE_URL2,
		{
			sql: query.getHospitals2.query,
		},
		{
			headers: {
				db: _DATABASE,
				sqlToken: query.getHospitals2.token,
			},
		}
	);
	if (hospital2Res?.data?.err == undefined) {
		let hospitalsType2 = hospital2Res?.data?.result;

		if (hospitalsType2.length) {
			let data = [];
			for (let i in hospitalsType2) {
				data.push({
					id: hospitalsType2[i].id,
					hospital_id: hospitalsType2[i],
				});
			}
			setHospitalsTypeTwo(data);
		}
	}

	const categoriesRes = await axios.post(
		_BASE_URL2,
		{
			sql: query.getHospitalsCategories.query,
			sqlParams: [6, 0],
		},
		{
			headers: {
				db: _DATABASE,
				sqlToken: query.getHospitalsCategories.token,
			},
		}
	);
	// console.log("res :>> ", res);
	if (categoriesRes?.data?.err == undefined) {
		setPopularCategories(categoriesRes?.data?.result);
		setTopCategories(categoriesRes?.data?.result);
	}

	const servicesRes = await axios.post(
		_BASE_URL2,
		{
			sql: query.getServices.query,
			sqlParams: [12, 0],
		},
		{
			headers: {
				db: _DATABASE,
				sqlToken: query.getServices.token,
			},
		}
	);
	// console.log("res :>> ", res);
	if (servicesRes?.data?.err == undefined) {
		setPopularServices(servicesRes?.data?.result);
	}

	let mImages = [];
	let wImages = [];

	if (!isBigScreen) {
		const sliderImgMobileRes = await axios.post(
			_BASE_URL2,
			{
				sql: query.getAllSliderImages.query,
				sqlParams: ["mobile"],
			},
			{
				headers: {
					db: _DATABASE,
					sqlToken: query.getAllSliderImages.token,
				},
			}
		);
		// console.log("res :>> ", res);
		if (sliderImgMobileRes?.data?.err == undefined) {
			let mobileImages = sliderImgMobileRes?.data?.result;
			if (mobileImages?.length) {
				mobileImages.map(e => mImages.push(e.image));
			}
			setState({ mobileSliderImages: mImages });
		}
	} else {
		const sliderImgWebRes = await axios.post(
			_BASE_URL2,
			{
				sql: query.getAllSliderImages.query,
				sqlParams: ["web"],
			},
			{
				headers: {
					db: _DATABASE,
					sqlToken: query.getAllSliderImages.token,
				},
			}
		);
		// console.log("res :>> ", res);
		if (sliderImgWebRes?.data?.err == undefined) {
			let webImages = sliderImgWebRes?.data?.result;
			if (webImages?.length) {
				webImages.map(e => wImages.push(e.image));
			}
			setState({ webSliderImages: wImages });
		}
	}

	// let endpoints = [
	// // `${_BASE_URL}/hospitals_packages?join=packages&join=hospital_categories&filter=package_status,eq,1&order=id&size=7`,
	// `${_BASE_URL}/packages?filter=priority,gt,0&order=priority,desc&size=12`,
	// // `${_BASE_URL}/hospitals_packages?join=hospitals&join=hospital_categories&filter=hospital_status,eq,1&order=id&size=7`,
	// `${_BASE_URL}/hospitals?filter=priority,gt,0&order=priority,desc&size=12`,
	// `${_BASE_URL}/hospitals?filter=type,eq,2&filter=priority,gt,0`,
	// `${_BASE_URL}/services?filter=priority,gt,0&order=priority,desc&size=12`,
	// `${_BASE_URL}/hospitals_categories?filter=priority,gt,0&order=priority,desc&size=6`,
	// `${_BASE_URL}/slider_images?filter=device,eq,mobile&filter=priority,gt,0&order=priority,desc`,
	// `${_BASE_URL}/slider_images?filter=device,eq,web&filter=priority,gt,0&order=priority,desc`,
	// ];
	// Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
	// .then(
	// (
	// [
	// { data: packages },
	// { data: hospitals },
	// { data: hospitalsType2 },
	// { data: services },
	// { data: categories },
	// { data: mobileImages },
	// { data: webImages },
	// ]
	// ) => {
	// console.log({ packages, hospitals, services });

	// let popularPackages = [];
	// let popularHospitals = [];
	// if (packages.records.length && "package_id" in packages.records[0]) {
	//   for (let i in packages.records) {
	//     popularPackages.push(packages.records[i].package_id);
	//   }
	// }
	// if (hospitals.records.length && "hospital_id" in hospitals.records[0]) {
	//   for (let i in hospitals.records) {
	//     popularHospitals.push(hospitals.records[i].hospital_id);
	//   }
	// }
	// console.log("popularHospitals", popularHospitals);
	// console.log("popularPackages", popularPackages);

	// setPopularPackages(packages.records);
	// setPopularHospitals(hospitals.records);
	// setPopularServices(services.records);
	// setPopularCategories(categories.records);
	// setTopCategories(categories.records);

	// if (hospitalsType2.records.length) {
	//   let data = [];
	//   for (let i in hospitalsType2.records) {
	//     data.push({
	//       id: hospitalsType2.records[i].id,
	//       hospital_id: hospitalsType2.records[i],
	//     });
	//   }
	//   setHospitalsTypeTwo(data);
	// }

	// let mImages = [];
	// let wImages = [];

	// if (mobileImages.records.length) {
	//   mobileImages.records.map((e) => mImages.push(e.image));
	// }
	// setState({ mobileSliderImages: mImages });

	// if (webImages.records.length) {
	//   webImages.records.map((e) => wImages.push(e.image));
	// }
	// setState({ webSliderImages: wImages });

	setAppIsReady(true);
	setIsLoading(false);
	// }
	// )
	// .catch((error) => {
	// console.log("error", error);
	// setIsLoading(false);
	// });
};
