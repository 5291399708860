import React, { useCallback, useEffect, useState } from "react";
import { Box, Icon, Input, Stack } from "native-base";
import { AntDesign } from "assets/icons";
import colors from "config/colors";

import { searchProduct } from "./actions";

import { LoadingAnimation, NoData } from "widgets";
import AppStore from "AppStore";
import HeaderBack from "widgets/header-back";
import { webStyle } from "config/web-style";
import debounce from "lodash.debounce";
import ProductsList from "./products-list";

const ProductsSearch = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [isLoading, setIsLoading] = AppStore("isLoading");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [results, setResults] = useState([]);

  const debouncedFetchData = useCallback(
    debounce((nextValue: any) => searchProduct(nextValue, setResults, setIsLoading), 300),
    []
  );

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  return (
    <Box flex={1} bg={colors.white} px={isBigScreen ? webStyle.xNum : 4}>
      <HeaderBack navigation={navigation} />
      <Stack w="full" p="4" alignItems="flex-start">
        <Input
          onChangeText={(e: any) => debouncedFetchData(e)}
          autoFocus
          returnKeyType="search"
          w="full"
          h="10"
          variant="unstyled"
          rounded="full"
          placeholder="Search"
          InputLeftElement={<Icon ml="2" size="6" color="black" as={<AntDesign name="search1" />} />}
          borderWidth={0}
          backgroundColor="gray.100"
        />
      </Stack>
      {isLoading ? (
        <LoadingAnimation loading={isLoading} />
      ) : !isLoading && !results.length ? (
        <NoData />
      ) : (
        <Box flex="1" w="full" alignItems={isBigScreen ? "center" : null} pt={isBigScreen ? "4" : "2"}>
          <ProductsList products={results} />
        </Box>
      )}
    </Box>
  );
};

export default ProductsSearch;
