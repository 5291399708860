import { Text, View } from "native-base";
import React from "react";

const AccordionSections = ({ data }: any) => {
  return (
    <View
      p="3"
      bgColor="coolGray.100"
      rounded="md"
      mt="1"
      w="full"
      flex={1}
      alignItems="flex-start"
      justifyContent="center"
    >
      <Text fontSize="md">{data}</Text>
    </View>
  );
};

export default AccordionSections;
