import StoreService from "services/StoreService";

const state = {
  user: {},
  session: {},
  showWelcome: true,
  loading: true,
  pushNotification: [],
  showNotificationBadge: false,
  broadcastSubscribed: false,
  deviceInfo: {},
  isBigScreen: false,
  selectedWebHeader: "",
  isForwarded: false,
  isOpen: false,
  updateAvailable: false,
  referralCode: null,
  topCategories: [],
  // selectedFooter: "Home",
  selectedFooter: null,
  initialRoute: "Zemidi",
  androidId: null,
  ipAddress: null,
  params: null,
  referralUserSaved: false,
  // usersInstallsSaved: false,
  otaAvailable: false,
  appBackgroundTime: null,
  currentPageInit: null,
  goToHome: false,
  deviceOs: null,
  latLong: {},
  mobileSliderImages: [],
  webSliderImages: [],
  popularPackages: [],
  popularHospitals: [],
  popularServices: [],
  popularCategories: [],
  params: null,
  isLoading: true,
  mitraICard: {},
  hospitalsTypeTwo: [],
  routeParams: {},
};
export default StoreService(state);
