import _APP_CONFIG from "config/app";

import { useNavigation } from "@react-navigation/native";
import AppStore from "AppStore";
import { Box, Image } from "native-base";
import React from "react";
import { Linking, TouchableOpacity } from "react-native";

const HospitalRegistrationImage = () => {
  const [isBigScreen] = AppStore("isBigScreen");
  const navigation = useNavigation();

  return (
    <Box h={isBigScreen ? "64" : "48"} w={isBigScreen ? "70%" : "full"} alignSelf="center" rounded="2xl" borderRadius="2xl">
      <TouchableOpacity onPress={() => Linking.openURL("https://surveyheart.com/form/657c05985cb33709ae1e9921")} style={{ flex: 1 }}>
        <Image
          source={{
            uri: isBigScreen ? `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/hospital-register-web.png` : `${_APP_CONFIG._IMAGE_URL}/zemidi/assets/hospital-register-mobile.png`,
          }}
          size="full"
          flex={1}
          resizeMode="contain"
          alt="loading"
        />
      </TouchableOpacity>
    </Box>
  );
};

export default HospitalRegistrationImage;
