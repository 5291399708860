import Api from "services/Api";
import { hashPassword } from "config/password-hash";
import { getAndroidId, getIpAddress, registerForPushNotificationsAsync, subscribeToTopic } from "AppActions";
import { Platform } from "react-native";
import axios from "axios";
import { query } from "config/query";
import { _BASE_URL2, _DATABASE } from "config/url";

export const saveUser = async (fields, latLong, deviceInfo, broadcastSubscribed, deviceOs, referralCode, callback) => {
  registerForPushNotificationsAsync();
  subscribeToTopic();
  getAndroidId();
  getIpAddress();

  let details = { ...deviceInfo };
  details.subscribed = broadcastSubscribed;

  let pass = "";
  if (fields.password) {
    pass = await hashPassword(fields.password);
  }

  let paramsObj = {
    name: fields.name,
    email: fields.email,
    phone: parseInt(fields.phone),
    password: pass,
    lat_long: JSON.stringify(latLong),
    device_info: Object.keys(details).length ? JSON.stringify(details) : null,
    os: deviceOs,
    referral_code: referralCode ? referralCode.toUpperCase() : null,
  };

  let userParams = [
    fields.name,
    fields.email,
    parseInt(fields.phone),
    pass,
    JSON.stringify(latLong),
    Object.keys(details).length ? JSON.stringify(details) : null,
    deviceOs,
    referralCode ? referralCode.toUpperCase() : null,
  ];

  const checkRes = await axios.post(
    _BASE_URL2,
    {
      sql: query.checkUserByPhone.query,
      sqlParams: [fields.phone],
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.checkUserByPhone.token,
      },
    }
  );

  if (checkRes?.data?.err == undefined) {
    if (checkRes?.data?.result.length && checkRes?.data?.result[0].phone == fields.phone) {
      callback({ taken: true }, false);
    } else {
      let params = userParams;

      const body = {
        sql: query.addUser.query,
        sqlParams: params,
      };
      const headers = {
        db: _DATABASE,
        sqlToken: query.addUser.token,
      };

      const res = await axios.post(_BASE_URL2, body, {
        headers: headers,
      });
      if (res?.data?.err == undefined) {
        if (res?.data?.result?.insertId > 0) {
          let session = res?.data?.result;
          let token = res?.data?.resultToken;
          const userObjRes = await axios.post(
            _BASE_URL2,
            {
              sql: query.getUserByInsertId.query,
              session: session,
            },
            {
              headers: {
                db: _DATABASE,
                sqlToken: query.getUserByInsertId.token,
                sessionToken: token,
              },
            }
          );

          if (userObjRes?.data?.err == undefined) {
            if (userObjRes?.data?.result.length) {
              // callback({ data: paramsObj, id: res?.data?.result?.insertId }, false);
              callback({ data: { user: userObjRes?.data?.result[0], session: userObjRes?.data?.resultToken } }, false);
            }
          }
        } else {
          callback(null, true);
        }
      } else {
        callback(null, true);
      }
    }
  } else {
    callback(null, true);
  }

  // Api.get(`/users?filter=phone,eq,${params.phone}`).send((user) => {
  //   if (user.type !== "error") {
  //     if (user.records.length && user.records[0].phone == params.phone) {
  //       callback({ taken: true }, false);
  //     } else {
  //       Api.post("/users", params).send((res) => {
  //         if (res.type !== "error") {
  //           callback({ data: params, id: res }, false);
  //         } else {
  //           callback(null, true);
  //         }
  //       });
  //     }
  //   } else {
  //     callback(null, true);
  //   }
  // });
};
