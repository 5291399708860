import React, { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";
import { Box, Image, FlatList, ScrollView, VStack } from "native-base";
import { TouchableOpacity } from "react-native";
import colors from "config/colors";

import LoadingAnimation from "widgets/loading-animation";
import { getPackages, getHospitalCategories } from "./actions";
import { MenuBar } from "widgets";
import NoData from "widgets/no-data";
import ListFooter from "widgets/list-footer";
import axios from "axios";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import PackagesList from "./packages-list";
import { Footer } from "widgets";
import { getServices } from "screens/services/actions";

const Packages = ({ route, navigation }: any) => {
	const [isForwarded, setIsForwarded] = AppStore("isForwarded");
	const [isBigScreen] = AppStore("isBigScreen");
	const [_, setCurrentPageInit] = AppStore("currentPageInit");
	const [isLoading, setIsLoading] = useState<any>(true);
	const [packages, setPackages] = useState<any>([]);
	const [totalPackages, setTotalPackages] = useState(0);
	const [totalHospitals, setTotalHospitals] = useState(0);
	const [hospitalCategories, setHospitalCategories] = useState<any>([]);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [packagePage, setPackagePage] = useState(1);
	const [hospitalPage, setHospitalPage] = useState(1);
	const [selected, setSelected] = useState<any>({});
	let params = route.params;

	useEffect(() => {
		if (params) {
			setSelected(params);
			setIsForwarded(true);
			resetStates();
		} else {
			setSelected({
				id: 0,
				name: "All",
			});
		}
	}, [route]);

	const resetStates = () => {
		setIsLoading(true);
		setPackagePage(1);
		setPackages([]);
		setTotalPackages(0);
	};

	const reload = () => {
		setIsLoading(true);
		setPackagePage(1);
		setPackages([]);
		setTotalPackages(0);
		getCategories();
		setSelected({
			id: 0,
			name: "All",
		});
	};

	useEffect(() => {
		setCurrentPageInit(reload);
		const unsubscribe = navigation.addListener("focus", () => {
			setCurrentPageInit(reload);
		});
		return unsubscribe;
	}, []);

	useEffect(() => {
		getCategories();
	}, []);

	const getCategories = () => {
		getHospitalCategories((res: any, err: any) => {
			if (!err) {
				setHospitalCategories(res.data);
			}
		});
	};

	useEffect(() => {
		if (!packages.length || packages.length < totalPackages) {
			getServices(packagePage, (res: any, err: any) => {
				if (!err) {
					setPackages([...packages, ...res.data]);
					setTotalPackages(res.totalData);
					setIsLoading(false);
					setIsLoadingMore(false);
				} else {
					setIsLoading(false);
					setIsLoadingMore(false);
				}
			});

			// 	getPackages(packagePage, selected.id, (res: any, err: any) => {
			// 		if (!err) {
			// 			setPackages([...packages, ...res.data]);
			// 			setTotalPackages(res.totalData);
			// 			setIsLoading(false);
			// 			setIsLoadingMore(false);
			// 		} else {
			// 			setIsLoading(false);
			// 			setIsLoadingMore(false);
			// 		}
			// 	});
		}
	}, []);

	const handleSelected = (value: any) => {
		if (value.id !== selected.id) {
			resetStates();
			setSelected(value);
		}
	};

	const handlePagination = () => {
		if (packages.length < totalPackages && !isLoadingMore) {
			setIsLoadingMore(true);
			setPackagePage(packagePage => packagePage + 1);
		}
	};

	const listFooterComponent = () => {
		return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
	};

	return (
		<Box flex={1} bgColor={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
			{/* {hospitalCategories.length ? (
        <Box
          bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          mx={isBigScreen ? webStyle.xNum : 0}
        >
          <MenuBar
            data={hospitalCategories}
            handleSelected={handleSelected}
            selected={selected}
            field="hospital"
          />
        </Box>
      ) : null} */}
			<Box
				flex={1}
				justifyContent="center"
				alignItems={
					isBigScreen
						? packages.length > 5
							? "center"
							: "flex-start"
						: packages.length > 1
						? "center"
						: "flex-start"
				}
				px={!isBigScreen ? (packages.length == 1 ? "4" : "0") : webStyle.xNum}
				mx={isBigScreen ? webStyle.xNum : 0}
				bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
				pt={isBigScreen ? "4" : "2"}
			>
				{packages.length ? (
					<PackagesList
						packages={packages}
						handlePagination={handlePagination}
						listFooterComponent={listFooterComponent}
						navigation={navigation}
						selected={selected}
					/>
				) : !packages.length && !isLoading ? (
					<NoData />
				) : null}
			</Box>

			<LoadingAnimation loading={isLoading} />
		</Box>
	);
};

export default Packages;
