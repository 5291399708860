import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { RootStackScreens } from "./root-stack";
import { _APP_URL } from "config/url";
import * as Linking from "expo-linking";
import { utils } from "@react-native-firebase/app";
import dynamicLinks from "@react-native-firebase/dynamic-links";

const Navigation = () => {
  const prefix = Linking.createURL("/");
  const linking: any = {
    prefixes: [prefix, "zemidi://", "https://zemidi.com"],

    async getInitialURL() {
      const { isAvailable } = utils().playServicesAvailability;
      if (isAvailable) {
        const initialLink = await dynamicLinks().getInitialLink();
        if (initialLink) {
          return initialLink.url;
        }
      }
      const url = await Linking.getInitialURL();
      return url;
    },

    subscribe(listener: any) {
      const unsubscribeFirebase = dynamicLinks().onLink(({ url }) => {
        listener(url);
      });

      const linkingSubscription = Linking.addEventListener("url", ({ url }) => {
        listener(url);
      });
      return () => {
        unsubscribeFirebase();
        linkingSubscription.remove();
      };
    },
    config: {
      screens: {
        Zemidi: {
          initialRouteName: "Notifications",
          screens: {
            Home: "/",
            Packages: "packages",
            Hospitals: "hospitals",
            Account: `account`,
            SignIn: "sign-in",
          },
        },
        SignUp: `SignUp`,
        SignUp2: `sign-up`,
        SignIn2: "sign_in",
        Profile: "profile",
        ForgotPassword: "forgot-password",
        Booking: `booking`,
        ViewBookings: "view-bookings",
        BookingSuccess: "booking-success",
        PackageDetails: "package-details",
        BookingDetails: "booking-details",
        Services: "services",
        ServiceDetails: "service-details",
        Notifications: "Notifications",
        SearchResults: "search-results",
        Doctors: "doctors",
        DoctorSearch: "doctor-search",
        Products: "products",
        ProductSearch: "product-search",
        BloodDonors: "blood-donors",
        AddDonor: "register-as-donor",
        DonorSearch: "donor-search",
        HealthCareMitra: "healthcare-mitra",
        ViewMitras: "view-mitras",
        MitraLocation: "mitra-location",
        MitraSearch: "mitra-mitras",
        HealthCard: "health-card",
        Discover: "discover",
        Trust: "trust",
        Support: "support",
        Filter: "filter",
        About: "about",
        Consultancy: "consultancy",
        Gallery: "gallery",
        Videos: "videos",
        // Terms: "terms",
        Benefits: "benefits",
        HospitalCenters: "hospital-centers",
        UploadPrescription: "upload-prescription",
        Privacy: "privacy",
        Enquire: "hospital-details",
        Insurance: "insurance",
        HealthServices: "health-services",
      },
    },
  };
  return (
    <NavigationContainer linking={linking}>
      <RootStackScreens />
    </NavigationContainer>
  );
};

export default Navigation;
