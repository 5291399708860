import _APP_CONFIG from "config/app";
import React, { useEffect, useState } from "react";
import { Box, Circle, Icon, ScrollView, Text } from "native-base";
import colors from "config/colors";
import BookingTopInfo from "./partials/booking-top-info";
import BookingBottomAccordion from "./partials/booking-bottom-accordion";
import { HeaderTitle, LoadingAnimation } from "widgets";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import { getHospitalPackageDetails } from "./actions";
import HospitalScroll from "./partials/hospital-scroll";
import BookingTopImage from "./partials/booking-top-image";
import { TouchableOpacity } from "react-native";
import { generateDynamicLink } from "AppActions";
import { Entypo, Ionicons } from "assets/icons";

const Booking = ({ route, navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [routeParams] = AppStore("routeParams");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [reloadScreen, setReloadScreen] = useState<any>(false);

  let packageId: any;
  let hospitalId: any;
  let packageCategoryId: any;
  let packageName: any;
  let selectedId: any;
  let item: any;

  if (route.params) {
    // console.log("route.params.item", route.params.item);
    packageId = route.params.share ? route.params.share : route.params.id;
    hospitalId = route.params.hospitalId;
    packageCategoryId = route.params.packageCategoryId;
    if (Object.keys(routeParams).length) {
      item = routeParams.item;
    } else {
      item = route.params.item;
    }
    packageName = route.params.name;
    selectedId = route.params.selectedId;
  }
  const hasSelected = Object.keys(selected).length;

  useEffect(() => {
    if (route.params.share) {
      navigation.setOptions({
        headerLeft: () => (
          <TouchableOpacity
            onPress={() => {
              navigation.replace("Zemidi", { screen: "Home" });
            }}
          >
            <Icon
              size={12}
              color={colors.primary}
              as={<Ionicons name="ios-caret-back-circle" />}
              ml={isBigScreen ? webStyle.xNum : 3}
            />
          </TouchableOpacity>
        ),
      });
    }
    navigation.setOptions({
      headerTitle: () => <HeaderTitle title={hasSelected ? selected.package_id.name : ""} />,
      headerRight: () =>
        results.length ? (
          <TouchableOpacity
            onPress={() => {
              setIsLoading(true);
              generateDynamicLink(packageId, packageName, selected.id, setIsLoading);
            }}
          >
            <Circle bg="gray.200" size="sm" mr={isBigScreen ? webStyle.xNum : 3}>
              <Icon size="6" color={colors.black} as={<Entypo name="share" />} />
            </Circle>
          </TouchableOpacity>
        ) : null,
    });
  }, [route, selected]);

  useEffect(() => {
    // setIsLoading(true);
    getHospitalPackageDetails(packageId, hospitalId, packageCategoryId, item, setIsLoading, (res: any, err: any) => {
      if (!err && res.data.length) {
        // console.log("res.data", res.data);
        setResults(res.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, [packageId, hospitalId, reloadScreen]);

  const reload = () => {
    setResults([]);
    setSelected({});
    setSelectedIndex(null);
    setReloadScreen(!reloadScreen);
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  useEffect(() => {
    if (results.length && !selectedId) {
      setSelected(results[0]);
    }
    if (results.length && selectedId) {
      for (let i in results) {
        if (results[i].id == selectedId) {
          setSelected(results[i]);
          setSelectedIndex(i);
        }
      }
    }
  }, [results]);

  const handleSelected = (item: any) => {
    // console.log("item selected", item);
    setSelected(item);
  };

  let location: any;
  let url: any;
  let details: any = {};
  let duration = null;
  let los: any;
  let packageCategory: any;

  if (Object.keys(selected).length) {
    // console.log("selected", selected);
    if (selected.hospital_id?.location) {
      // if ("location" in selected.hospital_id) {
      location = selected.hospital_id.location;
      // location = JSON.parse(selected.hospital_id.location);
      url = location.url;
    }
    if (selected.package_id.details) {
      details = selected.package_id.details;
      // details = JSON.parse(selected.package_id.details);
    }
    if (selected.package_id.duration) {
      duration = selected.package_id.duration;
    }
    packageCategory = selected.package_category_id.name;
    if (duration !== 0 && duration !== "0" && duration !== "" && duration !== undefined && duration !== null) {
      //   los = `${duration} (${packageCategory})`;
      los = `${duration}`;
    } else {
      //   los = packageCategory;
      los = "N/A";
    }
  }

  // console.log(packageId, hospitalId, packageCategoryId);
  // console.log("item das", item);
  // console.log(results);
  // console.log("_APP_CONFIG", _APP_CONFIG);
  return (
    <Box flex="1" px={isBigScreen ? webStyle.xNum : 0} bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      <ScrollView
        flex="1"
        bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 10,
        }}
      >
        {Object.keys(selected).length ? (
          <Box alignItems="center">
            <BookingTopImage
              packageImage={selected.package_id.image}
              hospitalImage={selected.hospital_id.image}
              rating={selected.rating}
            />
            {results.length > 1 ? (
              <Box pt="2" w={isBigScreen ? "2/3" : "full"}>
                <Box pb="1" px={isBigScreen ? webStyle.xNum : 0}>
                  <Text fontWeight="medium" fontSize="lg" color={colors.black} pl="5">
                    Also offered by
                  </Text>
                </Box>
                <HospitalScroll
                  data={results}
                  selected={selected}
                  handleSelected={handleSelected}
                  hospitalId={hospitalId}
                  selectedIndex={selectedIndex}
                />
              </Box>
            ) : null}
            <Box px={isBigScreen ? webStyle.xNum : 4} w={isBigScreen ? "2/3" : "full"}>
              <BookingTopInfo
                item={selected}
                url={url}
                los={los}
                packageCategory={packageCategory}
                packageName={selected.package_id.name}
                hospitalName={selected.hospital_id.name}
                price={selected.price}
                price_suffix={selected.price_suffix}
                navigation={navigation}
                setIsLoading={setIsLoading}
              />
              <BookingBottomAccordion details={details} />
            </Box>
          </Box>
        ) : null}
      </ScrollView>
      <LoadingAnimation loading={isLoading} />
    </Box>
  );
};

export default Booking;
