import { getGoogleMapApiKey } from "AppActions";
import AppStore from "AppStore";
import axios from "axios";
import { _BASE_URL, _BASE_URL2, _DATABASE } from "config/url";
const { getState, setState } = AppStore;
import { _APP_URL, _PACKAGE_NAME } from "config/url";
import Api from "services/Api";
import * as Location from "expo-location";
import { query } from "config/query";

export const getCoords = async (location, setLocation) => {
  setLocation({});
  getGoogleMapApiKey(async (res, err) => {
    if (!err) {
      let key = res.data;
      Location.setGoogleApiKey(key);
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        return;
      }
      let address = await Location.getCurrentPositionAsync({});
      const place = await Location.reverseGeocodeAsync({
        latitude: address.coords.latitude,
        longitude: address.coords.longitude,
      });

      location.address = place[0].city + ", " + place[0].region;
      location.latitude = address.coords.latitude;
      location.longitude = address.coords.longitude;
      setLocation({ ...location });
    }
  });
};
export const saveMitra = async (name, phone, location, fields, callback) => {
  let lat_long = JSON.stringify({
    latitude: location.latitude ? location.latitude : null,
    longitude: location.longitude ? location.longitude : null,
  });
  let details = JSON.stringify({
    email: fields.mail ? fields.mail : null,
    whatsapp_no: fields.whatsapp_no ? fields.whatsapp_no : null,
    place: fields.place ? fields.place : null,
    district: fields.dist ? fields.dist : null,
    block: fields.block ? fields.block : null,
    panchayat: fields.panchayat ? fields.panchayat : null,
    pin: fields.pin ? fields.pin : null,
    gender: fields.gender ? fields.gender : null,
    date_of_birth: fields.dob ? fields.dob : null,
    education: fields.edu ? fields.edu : null,
    govt_project: fields.govtProject ? fields.govtProject : null,
    have_smartphone: fields.have_smartPhone ? fields.have_smartPhone : null,
    have_medical_condition: fields.have_MedCon ? fields.have_MedCon : null,
    have_bike: fields.have_bike ? fields.have_bike : null,
    bank_details: fields.bank_details ? fields.bank_details : null,
    referred_by: fields.referredBy ? fields.referredBy : null,
  });
  let params = [
    name,
    phone,
    lat_long,
    location.address ? location.address : null,
    fields.dist ? fields.dist : null,
    fields.block ? fields.block : null,
    fields.referredBy ? fields.referredBy : null,
    details,
  ];

  const checkRes = await axios.post(
    _BASE_URL2,
    {
      sql: query.checkMitraByPhone.query,
      sqlParams: [phone],
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.checkMitraByPhone.token,
      },
    }
  );
  if (checkRes?.data?.err == undefined) {
    if (checkRes?.data?.result.length && checkRes?.data?.result[0].phone_no == phone) {
      callback(false, "Already Applied");
    } else {
      const body = {
        sql: query.addMitra.query,
        sqlParams: params,
      };
      const headers = {
        db: _DATABASE,
        sqlToken: query.addMitra.token,
      };

      const res = await axios.post(_BASE_URL2, body, {
        headers: headers,
      });

      if (res?.data?.err == undefined) {
        if (res?.data?.result?.insertId > 0) {
          callback(false, "Request submitted");
        } else {
          callback(true, "Something went wrong!");
        }
      } else {
        callback(true, "Something went wrong!");
      }
    }
  } else {
    callback(true, "Something went wrong!");
  }

  // Api.get(`/healthcare_mitras?filter=phone_no,eq,${phone}`).send((res) => {
  //   if (res.type != "error") {
  //     if (res.records.length && res.records[0].phone_no == params.phone_no) {
  //       // let mitraId = res.records[0].id;
  //       // Api.put(`/healthcare_mitras/${mitraId}`, params).send((res) => {
  //       //   if (res.type != "error") {
  //       callback(false, "Already Applied");
  //       //   } else {
  //       //     callback(true, "Something went wrong!");
  //       //   }
  //       // });
  //     } else {
  //       Api.post(`/healthcare_mitras`, params).send((res) => {
  //         if (res.type != "error") {
  //           callback(false, "Request submitted");
  //         } else {
  //           callback(true, "Something went wrong!");
  //         }
  //       });
  //     }
  //   } else {
  //     callback(true, "Something went wrong!");
  //   }
  // });
};

export const getMitras = async (page, callback) => {
  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: query.getMitrasCount.query,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.getMitrasCount.token,
      },
    }
  );
  let params = [(page - 1) * 20];
  const body = {
    sql: query.getMitras.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.getMitras.token,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      callback(
        {
          // data: [],
          data: res?.data?.result,
          totalData: resCount?.data?.result[0]?.["count(*)"],
        },
        false
      );
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }

  // let query;
  // query = `/healthcare_mitras?filter=deleted,eq,0&filter=priority,gt,0&order=priority,desc&page=${page},30`;
  // Api.get(query).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: res.records, totalData: res.results }, false);
  //   } else {
  //     callback({ data: [], totalData: 0 }, true);
  //   }
  // });
};

export const mitraCalled = async (user, mitraId, callback) => {
  let params = [user.id, mitraId];
  const body = {
    sql: query.createUsersMitrasCalls.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.createUsersMitrasCalls.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.insertId > 0) {
      callback({ data: true }, false);
    } else {
      callback({ data: false }, true);
    }
  } else {
    callback({ data: false }, true);
  }

  // let params = {
  //   user_id: user.id,
  //   mitra_id: mitraId,
  // };
  // Api.post(`/users_mitras_calls`, params).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: true }, false);
  //   } else {
  //     callback({ data: false }, true);
  //   }
  // });
};

export const searchMitra = async (e, setResults, setIsLoading) => {
  if (!e.length) {
    setResults([]);
  }
  if (e.length > 2) {
    setIsLoading(true);
    let searchTerm = { e, ...searchTerm };
    let params = [`%${searchTerm.e}%`, `%${searchTerm.e}%`];
    const body = {
      sql: query.getAllMitrasByName.query,
      sqlParams: params,
    };
    const headers = {
      db: _DATABASE,
      sqlToken: query.getAllMitrasByName.token,
    };

    const res = await axios.post(_BASE_URL2, body, {
      headers: headers,
    });

    if (res?.data?.err == undefined) {
      setResults(res?.data?.result);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    // let endpoints = [
    //   `${_BASE_URL}/healthcare_mitras?filter1=name,cs,${searchTerm.e}&filter2=address,cs,${searchTerm.e}&filter=deleted,eq,0&filter=priority,gt,0&order=priority,desc`,
    // ];
    // Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
    //   .then(([{ data: mitras }]) => {
    //     if (mitras.records.length) {
    //       setResults(mitras.records);
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch((e) => setIsLoading(false));
  }
};

export const checkMitra = async (hcmNo, phone, callback) => {
  const checkRes = await axios.post(
    _BASE_URL2,
    {
      sql: query.checkMitraByPhoneAndHcmNo.query,
      sqlParams: [phone, hcmNo],
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: query.checkMitraByPhoneAndHcmNo.token,
      },
    }
  );
  if (checkRes?.data?.err == undefined) {
    if (checkRes?.data?.result.length) {
      let data = checkRes?.data?.result[0];
      callback({ data: data, message: "Card Linked" }, false);
    } else {
      callback({ data: {}, message: "Invalid phone no. / card no." }, true);
    }
  } else {
    callback({ data: {}, message: "Something went wrong!" }, true);
  }

  // let query;
  // query = `/healthcare_mitras?filter=deleted,eq,0&filter=priority,gt,0&filter=phone_no,eq,${phone}&filter=hcm_no,eq,${hcmNo}`;
  // Api.get(query).send((res) => {
  //   if (res.type !== "error") {
  //     if (res.records.length) {
  //       callback({ data: res.records[0], message: "Card Linked" }, false);
  //     } else {
  //       callback({ data: {}, message: "Invalid phone no. / card no." }, true);
  //     }
  //   } else {
  //     callback({ data: {}, message: "Something went wrong!" }, true);
  //   }
  // });
};

export const updateMitra = async (mitraId, phone, location, callback) => {
  let lat_long = JSON.stringify({
    latitude: location.latitude,
    longitude: location.longitude,
  });
  let address = location.place;

  let params = [lat_long, address, mitraId];

  const body = {
    sql: query.updateMitra.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.updateMitra.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.affectedRows > 0) {
      callback(false, "Location updated!");
    } else {
      callback(true, "Something went wrong!");
    }
  } else {
    callback(true, "Something went wrong!");
  }

  // Api.put(`/healthcare_mitras/${mitraId}`, params).send((res) => {
  //   if (res.type != "error") {
  //     callback(false, "Location updated!");
  //   } else {
  //     callback(true, "Something went wrong!");
  //   }
  // });
};

export const districts = [
  {
    id: 1,
    label: "Baksa",
    value: "Baksa",
  },
  {
    id: 2,
    label: "Barpeta",
    value: "Barpeta",
  },
  {
    id: 3,
    label: "Biswanath",
    value: "Biswanath",
  },
  {
    id: 4,
    label: "Bongaigaon",
    value: "Bongaigaon",
  },
  {
    id: 5,
    label: "Bajali",
    value: "Bajali",
  },
  {
    id: 6,
    label: "Cachar",
    value: "Cachar",
  },
  {
    id: 7,
    label: "Charaideo",
    value: "Charaideo",
  },
  {
    id: 8,
    label: "Chirang",
    value: "Chirang",
  },
  {
    id: 9,
    label: "Darrang",
    value: "Darrang",
  },
  {
    id: 10,
    label: "Dhemaji",
    value: "Dhemaji",
  },
  {
    id: 11,
    label: "Dhubri",
    value: "Dhubri",
  },
  {
    id: 12,
    label: "Dibrugarh",
    value: "Dibrugarh",
  },
  {
    id: 13,
    label: "Dima Hasao",
    value: "Dima Hasao",
  },
  {
    id: 14,
    label: "Goalpara",
    value: "Goalpara",
  },
  {
    id: 15,
    label: "Golaghat",
    value: "Golaghat",
  },
  {
    id: 16,
    label: "Hailakandi",
    value: "Hailakandi",
  },
  {
    id: 17,
    label: "Hojai",
    value: "Hojai",
  },
  {
    id: 18,
    label: "Jorhat",
    value: "Jorhat",
  },
  {
    id: 19,
    label: "Kamrup (M)",
    value: "Kamrup (M)",
  },
  {
    id: 20,
    label: "Kamrup (R)",
    value: "Kamrup (R)",
  },
  {
    id: 21,
    label: "Karbi Anglong",
    value: "Karbi Anglong",
  },
  {
    id: 22,
    label: "Karimganj",
    value: "Karimganj",
  },
  {
    id: 23,
    label: "Kokrajhar",
    value: "Kokrajhar",
  },
  {
    id: 24,
    label: "Lakhimpur",
    value: "Lakhimpur",
  },
  {
    id: 25,
    label: "Majuli",
    value: "Majuli",
  },
  {
    id: 26,
    label: "Morigaon",
    value: "Morigaon",
  },
  {
    id: 27,
    label: "Nagaon",
    value: "Nagaon",
  },
  {
    id: 28,
    label: "Nalbari",
    value: "Nalbari",
  },
  {
    id: 29,
    label: "Sivasagar",
    value: "Sivasagar",
  },
  {
    id: 30,
    label: "Sonitpur",
    value: "Sonitpur",
  },
  {
    id: 31,
    label: "South Salmara-Mankachar",
    value: "South Salmara-Mankachar",
  },
  {
    id: 32,
    label: "Tinsukia",
    value: "Tinsukia",
  },
  {
    id: 33,
    label: "Tamulpur",
    value: "Tamulpur",
  },
  {
    id: 34,
    label: "Udalguri",
    value: "Udalguri",
  },
  {
    id: 35,
    label: "West Karbi Anglong",
    value: "West Karbi Anglong",
  },
];
