import _APP_CONFIG from "config/app";
import { FontAwesome } from "assets/icons";
import AppStore from "AppStore";
import colors from "config/colors";
import { Box, Center, HStack, Image, Text, VStack } from "native-base";
import React from "react";
import { Rating } from "react-native-ratings";

const PackageCard = ({
  image,
  name,
  details = null,
  rating,
  price,
  // w = { base: 150, sm: "40" },
  w = 150,
  h = 48,
  p = 0,
  m = 1,
  imageContainerSize = 32,
  imageSize = 115,
}: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <Box
      borderWidth="1"
      borderRadius="lg"
      w={w}
      rounded="3xl"
      overflow="hidden"
      // borderColor="coolGray.200"
      borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
      bg={colors.white}
      m={m}
      flex="1"
    >
      <VStack flex="1" alignItems="center" p={p} space="1">
        <Box minH={h} flex="1" alignItems="center">
          <Center size={imageContainerSize} overflow="hidden">
            {image ? (
              <Image
                size={imageSize}
                borderRadius="full"
                // borderWidth="2"
                // borderColor={colors.primary}
                source={{
                  uri: `${_APP_CONFIG._IMAGE_URL}/${image}`,
                }}
                resizeMode="contain"
                alt="loading"
              />
            ) : (
              <Image
                source={require("assets/images/no-image.png")}
                size={imageSize}
                resizeMode="contain"
                alt="loading"
              />
            )}
          </Center>
          <VStack flex="1" minH="16" mx="2" w="32">
            <Text
              fontSize="sm"
              fontWeight="medium"
              color={colors.primary}
              textAlign="center"
              noOfLines={3}
              lineHeight="sm"
            >
              {name}
            </Text>
          </VStack>
          {details ? (
            <Text fontSize="sm" fontWeight="medium" color="gray.600" isTruncated>
              {details}
            </Text>
          ) : null}
          {rating ? (
            <Rating
              type="heart"
              // @ts-ignore
              startingValue={rating}
              imageSize={15}
              ratingCount={5}
              readonly
            />
          ) : null}
        </Box>
        <HStack justifyContent="center">
          <Text fontSize="lg" fontWeight="bold" color="black">
            {price ? (
              <>
                <FontAwesome name="rupee" size={16} color="black" /> {price}
              </>
            ) : null}
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
};

export default PackageCard;
