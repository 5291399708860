import { AntDesign, Entypo, MaterialIcons } from "assets/icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import AppStore from "AppStore";
import colors from "config/colors";
import { _ZEMIDI_HELPLINE } from "config/defaults";
import { _APP_URL } from "config/url";
import { webStyle } from "config/web-style";
import { Box, Circle, HStack, Icon, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { Linking, TouchableOpacity } from "react-native";
import LinkToPage from "widgets/link-to-page";
import ZemidiLogo from "widgets/zemidi-logo";

const Footer = () => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [topCategories] = AppStore("topCategories");
  const [selectedWebHeader, setSelectedWebHeader] = AppStore("selectedWebHeader");
  const [selectedFooter, setSelectedFooter] = AppStore("selectedFooter");
  const navigation: any = useNavigation();
  const route = useRoute();

  //   useEffect(() => {
  //     setSelectedFooter(route.name);
  //   }, [route]);

  return (
    <VStack
      //   mx={isBigScreen ? webStyle.xNum : 0}
      bg={colors.white}
      space="2"
      pb="2"
    >
      <HStack justifyContent="space-around" px={isBigScreen ? webStyle.xNum : 0}>
        <VStack w="64" alignItems="center">
          <LinkToPage route="Home">
            <Box w="64" h="20" px="2">
              <ZemidiLogo ml="2" />
            </Box>
          </LinkToPage>
          <Box w="64" ml="3" p="2">
            <Text fontSize="md" fontWeight="medium">
              Zemidi is an online aggregator of small and medium private hospitals, standardizing medical and surgical interventions into packages.
            </Text>

            <HStack justifyContent="start" alignItems="center" space="2">
              <Box w="10" h="10" justifyContent="center" alignItems="center" mt="4">
                <TouchableOpacity onPress={() => Linking.openURL("https://youtube.com/channel/UCNoRngxQtuv_m4QjQoB4Rew")}>
                  <Icon size="10" color={colors.primary} as={<Entypo name="youtube-with-circle" />} />
                </TouchableOpacity>
              </Box>
              <TouchableOpacity onPress={() => Linking.openURL(_APP_URL)}>
                <Box w="10" h="10" justifyContent="center" alignItems="center" mt="4" rounded="full" backgroundColor={colors.primary}>
                  <Icon size="6" color={colors.white} as={<AntDesign name="android1" />} />
                </Box>
              </TouchableOpacity>
            </HStack>
          </Box>
        </VStack>

        <VStack w="64" alignItems="flex-start">
          <Box h="20" p="2" pt="6">
            <Text fontSize="lg" fontWeight="bold" color={colors.primary}>
              Popular Categories
            </Text>
          </Box>

          <VStack space="2" p="2">
            {topCategories.length
              ? topCategories.map((category: any) => (
                  <TouchableOpacity
                    key={category.id}
                    onPress={() => {
                      navigation.navigate("Packages", {
                        id: category.id,
                        name: category.name,
                      });
                      setSelectedWebHeader("Packages");
                      //   setSelectedFooter(category.name);
                    }}
                  >
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      //   color={
                      //     selectedFooter == category.name
                      //       ? colors.primary
                      //       : colors.black
                      //   }
                    >
                      {category.name}
                    </Text>
                  </TouchableOpacity>
                ))
              : null}
          </VStack>
        </VStack>

        <VStack w="64" alignItems="flex-start">
          <Box h="20" p="2" pt="6">
            <Text fontSize="lg" fontWeight="bold" color={colors.primary}>
              Useful Links
            </Text>
          </Box>
          <VStack space="2" p="2">
            <LinkToPage route="Home">
              <Text
                fontSize="md"
                fontWeight="medium"
                // color={selectedFooter == "Home" ? colors.primary : colors.black}
              >
                Home
              </Text>
            </LinkToPage>
            {/* <LinkToPage route="Packages">
              <Text
                fontSize="md"
                // fontWeight="medium"
                // color={
                //   selectedFooter == "Packages" ? colors.primary : colors.black
                // }
              >
                Packages
              </Text>
            </LinkToPage> */}
            <LinkToPage route="Hospitals">
              <Text
                fontSize="md"
                fontWeight="medium"
                // color={
                //   selectedFooter == "Hospitals" ? colors.primary : colors.black
                // }
              >
                Hospitals
              </Text>
            </LinkToPage>
            <LinkToPage route="Services">
              <Text fontSize="md" fontWeight="medium" color={colors.black}>
                Services
              </Text>
            </LinkToPage>
          </VStack>
        </VStack>
        <VStack w="64" alignItems="flex-start">
          <Box h="20" p="2" pt="6">
            <Text fontSize="lg" fontWeight="bold" color={colors.primary}>
              Contact
            </Text>
          </Box>
          <VStack space="2">
            <Box w="64" p="2">
              <Text fontSize="md" fontWeight="medium">
                Zemidi H/no. 1, 2nd Floor, Balaram Basumatari Path, Kerakuchi Tiniali , Guwahati , Assam - 781020 ,IN
              </Text>
            </Box>
            <HStack alignItems="center" space="2">
              <Circle bg="gray.200" size="7">
                <Icon size="4" as={<MaterialIcons name="call" />} />
              </Circle>
              <Text fontSize="md" fontWeight="medium">
                {_ZEMIDI_HELPLINE}
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </HStack>
      <HStack alignItems="center" justifyContent="center" space="2">
        <Icon size="4" as={<MaterialIcons name="copyright" />} />
        <Text fontSize="md" fontWeight="medium">
          Copyright Bonphul Health Private Limited
        </Text>
      </HStack>
    </VStack>
  );
};

export default Footer;
