import _APP_CONFIG from "config/app";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import { Box, ScrollView, Text, VStack, Image, HStack } from "native-base";
import React from "react";
import { Footer } from "widgets";
import HealthcareImage from "./healthcare-image";
import HealthcareText from "./healthcare-text";

const Consultancy = () => {
  const [isBigScreen] = AppStore("isBigScreen");
  const content = [
    {
      title_1: "Health Care",
      title_2: "Research",
      text: "Our services include health care research designing, conduct surveys, data analysis and report writing. We also offer support for baseline studies, impact evaluations, and development evaluations.",
      image: require("assets/images/health-care.png"),
    },
    {
      title_1: "Health",
      title_2: "Communication",
      text: "Our communication strategy and materials design services help to effectively disseminate information and promote health in the community.",
      image: require("assets/images/communication.png"),
    },
    {
      title_1: "Training and",
      title_2: "Empowerment",
      text: "Additionally, we offer need-based training for frontline community based healthcare workers and technical resources to support for school health, adolescent health and well-being programs. Our experienced team is dedicated to providing high-quality, customized solutions that address the unique needs of our clients.",
      image: require("assets/images/training.png"),
    },
  ];

  const consultants = [
    {
      name: "Dr. Gitartha Roymedhi",
      text: "MD (Russia), IESC (London), PGD in Healthcare Management and Administration",
      // text: "MD (Russia), IESC (London), PGD in Healthcare Management and Administration (Gurgaon), Master Class in Healthcare Management (IIM, Shillong) Healthcare Entrepreneur, Facilitator, Management Expert and Educator.",
      image: `${_APP_CONFIG._IMAGE_URL}/zemidi/consultancy/gitartha.png`,
    },
    {
      name: "Dr. Kuntilraj Borgohain",
      text: "MD ( Respiratory Medicine)",
      image: `${_APP_CONFIG._IMAGE_URL}/zemidi/consultancy/kuntilraj.png`,
    },
    {
      name: "Dr. Nilaxi Khataniar",
      text: "MD (Radiation Oncology)",
      image: `${_APP_CONFIG._IMAGE_URL}/zemidi/consultancy/nilaxi.png`,
    },
    {
      name: "Dr. Deepanjali Brahma",
      text: "Plastic and Reconstructive Surgery",
      image: `${_APP_CONFIG._IMAGE_URL}/zemidi/consultancy/deepanjali.png`,
    },
    {
      name: "Deepankar Bhattacharjya",
      text: "Co Founder and Chief Operating Officer (Zemidi)",
      image: `${_APP_CONFIG._IMAGE_URL}/zemidi/consultancy/deepankar.png`,
    },
    {
      name: "Dhruba Sarma",
      text: "Co Founder & Chief Technology Officer (Zemidi)",
      image: `${_APP_CONFIG._IMAGE_URL}/zemidi/consultancy/dhruba_sir.png`,
    },
    // {
    //   name: "Bedanta Barman",
    //   text: "Experienced Health Project Coordinator Dedicated to Improving Community Health in Assam and North East India",
    //   image: `${_APP_CONFIG._IMAGE_URL}/zemidi/consultancy/bedanta.png`,
    // },
    {
      name: "Rishi Bhagawati",
      text: "Technical Consultant (Zemidi)",
      image: `${_APP_CONFIG._IMAGE_URL}/zemidi/consultancy/rishi_sir.png`,
    },
    {
      name: "",
      text: "",
      image: null,
    },
  ];

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      <ScrollView
        py={isBigScreen ? "0" : "4"}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={isBigScreen ? { paddingBottom: 0 } : { paddingBottom: 50 }}
      >
        <VStack
          space={4}
          mx={isBigScreen ? webStyle.xNum : 0}
          bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          p={isBigScreen ? "2" : "4"}
        >
          <Text fontWeight="medium" fontSize="xl" py={isBigScreen ? "6" : "0"} letterSpacing="xl">
            Zemidi provides a range of consultancy services to support the social health care sectors supported by a
            dedicated team of healthcare professionals.
          </Text>
          {content.length &&
            content.map((e, index) => (
              <Box mt="2" flexDirection={isBigScreen ? "row" : "column"} key={index}>
                {index % 2 == 0 ? (
                  <>
                    <HealthcareText e={e} />
                    <HealthcareImage e={e} />
                  </>
                ) : (
                  <>
                    {isBigScreen ? (
                      <>
                        <HealthcareImage e={e} />
                        <HealthcareText e={e} />
                      </>
                    ) : (
                      <>
                        <HealthcareText e={e} />
                        <HealthcareImage e={e} />
                      </>
                    )}
                  </>
                )}
              </Box>
            ))}
          <Text fontWeight="bold" fontSize={isBigScreen ? "5xl" : "3xl"} mt="4">
            MEET OUR <Text color={colors.primary}>PROJECT CONSULTANTS</Text>
          </Text>
          <Box
            pt={isBigScreen ? "6" : "2"}
            flex={1}
            flexDirection={"row"}
            flexWrap="wrap"
            justifyContent="space-between"
          >
            {consultants.length &&
              consultants.map((c, index) => (
                <Box
                  width={isBigScreen ? 450 : 350}
                  mb={isBigScreen ? "16" : "10"}
                  flexDirection="row"
                  alignItems="center"
                  key={index}
                >
                  <Box w="32" h="32">
                    {c.image ? (
                      <Image
                        size="full"
                        source={{ uri: `${c?.image}` }}
                        resizeMode="cover"
                        alt="loading"
                        rounded="full"
                        overflow="hidden"
                      />
                    ) : null}
                  </Box>
                  <VStack flex="1" alignItems="center" justifyContent="center">
                    <Box w="full" px={isBigScreen ? "3" : "1"}>
                      <HStack>
                        <Text fontWeight="medium" fontSize="xl" color={colors.black}>
                          {c.name}
                        </Text>
                      </HStack>
                      <Box mt="4">
                        <Text>{c.text}</Text>
                      </Box>
                    </Box>
                  </VStack>
                </Box>
              ))}
          </Box>
        </VStack>
        {isBigScreen ? <Box h="6"></Box> : null}
        {isBigScreen ? <Footer /> : null}
      </ScrollView>
    </Box>
  );
};

export default Consultancy;
