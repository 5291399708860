import _APP_CONFIG from "config/app";
import React, { useEffect, useState } from "react";
import { Box, Icon, Image, Text, VStack, FlatList, Circle, Center } from "native-base";
import { MaterialIcons } from "assets/icons";
import { TouchableOpacity } from "react-native";
import colors from "config/colors";
import * as Linking from "expo-linking";

import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import AccfServicesImage from "./accf-services-image";
import { getType2HospitalById } from "./actions";
import { LoadingAnimation, NoData } from "widgets";
import { useNavigation } from "@react-navigation/native";
import { providersCalled } from "AppActions";

const HospitalCenters = ({ route, w = 150, h = 48, p = 1, m = 1, imageContainerSize = 32, imageSize = 115 }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isLoading, setIsLoading] = useState(true);
  const [centers, setCenters] = useState<any>({});
  const navigation = useNavigation();
  let hospitalId = route.params?.id;

  useEffect(() => {
    setIsLoading(true);
    getType2HospitalById(hospitalId, (res: any, err: any) => {
      if (!err && res.data.length) {
        setCenters(res.data[0]);
        setIsLoading(false);
      }
      setIsLoading(false);
    });
  }, [hospitalId]);

  const reload = () => {
    navigation.goBack();
  };

  useEffect(() => {
    setCurrentPageInit(reload);
  }, []);

  const handleCall = (item: any) => {
    setIsLoading(true);
    //@ts-ignore
    providersCalled({ hospitalId: centers.id, center: item.center, contactMode: "phone" }, (res: any, err: any) => {
      if (!err && res) {
        setIsLoading(false);
        Linking.openURL(`tel:"+91" ${item.phone_no}`);
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      {Object.keys(centers).length ? (
        <Box
          flex={1}
          justifyContent="center"
          alignItems={
            isBigScreen
              ? Object.keys(centers).length > 5
                ? "center"
                : "flex-start"
              : Object.keys(centers).length > 1
              ? "center"
              : "flex-start"
          }
          px={!isBigScreen ? (Object.keys(centers).length > 5 ? "4" : "0") : webStyle.xNum}
          mx={isBigScreen ? webStyle.xNum : 0}
          bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          pt={isBigScreen ? "4" : "2"}
        >
          <FlatList
            data={centers.type_details}
            // data={JSON.parse(centers.type_details)}
            showsVerticalScrollIndicator={false}
            numColumns={isBigScreen ? webStyle.numCol : 2}
            ListHeaderComponent={() => <AccfServicesImage />}
            renderItem={({ item }: any) => {
              return (
                <Box
                  borderWidth="1"
                  borderRadius="lg"
                  w={w}
                  rounded="3xl"
                  overflow="hidden"
                  borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
                  p={p}
                  m={m}
                  bg={colors.white}
                >
                  <VStack justifyContent="center" alignItems="center" space="1" p={p}>
                    <Box h={h} alignItems="center">
                      <Center size={imageContainerSize} overflow="hidden">
                        {centers.image ? (
                          <Image
                            size={imageSize}
                            borderRadius="full"
                            source={{
                              uri: `${_APP_CONFIG._IMAGE_URL}/${centers.image}`,
                            }}
                            resizeMode="contain"
                            alt="loading"
                          />
                        ) : (
                          <Image
                            source={require("assets/images/no-image.png")}
                            size={imageSize}
                            resizeMode="contain"
                            alt="loading"
                          />
                        )}
                      </Center>
                      <Box flex="1" mx="2">
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color={colors.primary}
                          textAlign="center"
                          noOfLines={3}
                          lineHeight="sm"
                        >
                          {item.center}
                        </Text>
                      </Box>
                    </Box>
                    <Text textAlign="center" fontSize="sm" color={colors.grayText}>
                      {item.phone_no}
                    </Text>
                    <TouchableOpacity onPress={() => handleCall(item)}>
                      <Circle bg="gray.200" size="sm">
                        <Icon size="6" color="green.700" as={<MaterialIcons name="call" />} />
                      </Circle>
                    </TouchableOpacity>
                  </VStack>
                </Box>
              );
            }}
            keyExtractor={(item: any, index: any) => index}
          />
        </Box>
      ) : !isLoading && !Object.keys(centers).length ? (
        <NoData />
      ) : null}
      <LoadingAnimation loading={isLoading} />
    </>
  );
};

export default HospitalCenters;
