import _APP_CONFIG from "config/app";
import React from "react";
import { Avatar, Box, Image } from "native-base";
import AppStore from "AppStore";

const UserAvatar = () => {
  const [user] = AppStore("user");

  return (
    <Avatar
      size="lg"
      ml="3"
      source={{
        uri:
          Object.keys(user).length && user.image
            ? `${_APP_CONFIG._IMAGE_URL}/${user.image}`
            : `${_APP_CONFIG._IMAGE_URL}/zemidi/no-user.png`,
      }}
    />
    // <Box ml="3" size="16" overflow="hidden">
    //   <Image
    //     source={{
    //       uri:
    //         Object.keys(user).length && user.image
    //           ? user.image
    //           : `${_APP_CONFIG._IMAGE_URL}/zemidi/no-user.png`,
    //     }}
    //     borderRadius="full"
    //     alt="loading"
    //     size="full"
    //     resizeMode="contain"
    //   />
    // </Box>
  );
};

export default UserAvatar;
