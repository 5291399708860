import { BackHandler, Linking } from "react-native";
import React from "react";
import { AlertBox } from "widgets";
import { _APP_URL } from "config/url";
import AppStore from "AppStore";

const AppUpdate = () => {
  const [isOpen, setIsOpen] = AppStore("isOpen");
  const [user, setUser] = AppStore("user");
  const [session, setSession] = AppStore("session");

  return (
    <AlertBox
      header={"App Update Available"}
      text="Please update app to continue"
      isOpen={isOpen}
      setIsOpen={() => BackHandler.exitApp()}
      actionText="Update App"
      action={() => {
        // setTimeout(() => {
        //   setUser({});
        //   setSession({});
        // }, 0);
        Linking.openURL(`${_APP_URL}`);
      }}
      disableClose={true}
    />
  );
};

export default AppUpdate;
