import React, { useEffect, useState } from "react";
import { Box } from "native-base";
import colors from "config/colors";
import { getHospitalsByPackage } from "./actions";

import { ActivityIndicator } from "react-native";
import LoadingAnimation from "widgets/loading-animation";
import { filterMenu } from "config/filter-menu";

import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import HospitalsList from "screens/hospitals/hospitals-list";
import { LinkToPage, NoData } from "widgets";
import { FilterIcon } from "widgets";

const HospitalDetails = ({ route, navigation }: any) => {
	let packageId = route.params.id;
	const [isBigScreen] = AppStore("isBigScreen");
	const [hospitals, setHospitals] = useState<any>([]);
	const [totalHospitals, setTotalHospitals] = useState(0);
	const [selected, setSelected] = useState<any>({});
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		navigation.setOptions({
			headerRight: () => (
				<LinkToPage
					route="Filter"
					params={{
						header: "Sort by Price",
						filterData: filterMenu,
						selected: selected,
						handleFilterData: handleFilterData,
					}}
				>
					<FilterIcon />
				</LinkToPage>
			),
		});
	}, [filterMenu, selected]);

	useEffect(() => {
		if (!hospitals.length || hospitals.length < totalHospitals) {
			setIsLoading(true);
			getHospitalsByPackage(page, packageId, selected, (res: any, err: any) => {
				if (!err) {
					setHospitals([...hospitals, ...res.data]);
					setTotalHospitals(res.totalData);
					setIsLoading(false);
					setIsLoadingMore(false);
				}
				setIsLoading(false);
				setIsLoadingMore(false);
			});
		}
	}, [page, packageId, selected]);

	const resetStates = () => {
		setIsLoading(true);
		setPage(1);
		setHospitals([]);
		setTotalHospitals(0);
	};

	const handleFilterData = (data: any) => {
		resetStates();
		if (selected.id == data.id) {
			setSelected({});
		} else {
			setSelected(() => ({ ...selected, ...data }));
		}
	};

	const handlePagination = () => {
		if (hospitals.length < totalHospitals && !isLoadingMore) {
			setIsLoadingMore(true);
			setPage(page => page + 1);
		}
	};

	const listFooterComponent = () => {
		return isLoadingMore ? <ActivityIndicator color={colors.primary} /> : null;
	};

	return (
		<Box
			flex={1}
			// py="4"
			px={!isBigScreen ? (hospitals.length == 1 ? "4" : "0") : webStyle.xNum}
			justifyContent="center"
			bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}
		>
			{hospitals.length ? (
				<Box
					flex="1"
					bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
					w="full"
					pt={isBigScreen ? "4" : "2"}
					alignItems={
						isBigScreen
							? hospitals.length > 5
								? "center"
								: "flex-start"
							: hospitals.length > 1
							? "center"
							: "flex-start"
					}
				>
					<HospitalsList
						hospitals={hospitals.hospital_id}
						handlePagination={handlePagination}
						listFooterComponent={listFooterComponent}
						navigation={navigation}
						selected={selected}
						showDetails={true}
					/>
				</Box>
			) : !isLoading && !hospitals.length ? (
				<NoData />
			) : (
				<LoadingAnimation loading={isLoading} />
			)}
		</Box>
	);
};

export default HospitalDetails;
