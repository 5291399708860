import React from "react";
import { Box, FlatList } from "native-base";
import HospitalCard from "widgets/hospital-card";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";
import { LinkToPage } from "widgets";

const HospitalsList = ({
	hospitals,
	handlePagination,
	listFooterComponent,
	navigation,
	selected = {},
	showDetails = false,
}: any) => {
	const [hospitalsTypeTwo] = AppStore("hospitalsTypeTwo");
	const [isBigScreen] = AppStore("isBigScreen");

	return (
		<FlatList
			data={hospitals}
			showsVerticalScrollIndicator={false}
			numColumns={isBigScreen ? webStyle.numCol : 2}
			// onEndReached={handlePagination}
			onEndReachedThreshold={0.5}
			// ListFooterComponent={listFooterComponent}
			// contentContainerStyle={{ flex: 1 }}
			style={{ paddingHorizontal: 4 }}
			renderItem={({ item }) => {
				let location: any = "location" in item && item.location;
				// let location: any = "location" in item.hospital_id && JSON.parse(item.hospital_id.location);

				return (
					<>
						{/* {item.hospital_id.type == 1 ? ( */}
						{/* {showDetails ? (
							<LinkToPage
								route="Booking"
								params={{
									name: item.package_id.name,
									item: item,
								}}
							>
								<HospitalCard
									name={item.hospital_id.name}
									image={item.hospital_id.image}
									rating={item.rating}
									price={item.price}
									location={location}
									package_category={item.package_category_id.name}
								/>
							</LinkToPage>
						) : ( */}
						<LinkToPage route="Enquire" params={{ id: item.id }}>
							<HospitalCard
								name={item.name}
								image={item.image}
								rating={item.rating}
								location={location}
							/>
						</LinkToPage>
						{/* )} */}
						{/* ) : (
							<LinkToPage
								route="HospitalCenters"
								params={{
									name: item.hospital_id.name,
									id: item.hospital_id.id,
								}}
							>
								<HospitalCard
									name={item.hospital_id.name}
									image={item.hospital_id.image}
									rating={item.hospital_id.rating}
									location={location}
								/>
							</LinkToPage>
						)} */}
					</>
				);
			}}
			keyExtractor={(item: any) => item.id}
		/>
	);
};

export default HospitalsList;
