import axios from "axios";
import { query } from "config/query";
import { _BASE_URL, _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getProducts = async (page, selectedTypeId, callback) => {
  let paramsCount = [];
  let params = [];
  let countQuery = "";
  let countQueryToken = "";
  let dataQuery = "";
  let dataQueryToken = "";

  if (selectedTypeId > 0) {
    paramsCount = [selectedTypeId];
    params = [selectedTypeId, (page - 1) * 20];
    countQuery = query?.getProductsByTypeCount?.query;
    countQueryToken = query?.getProductsByTypeCount?.token;
    dataQuery = query?.getProductsByType?.query;
    dataQueryToken = query?.getProductsByType?.token;
  } else {
    params = [(page - 1) * 20];
    countQuery = query?.getProductsCount?.query;
    countQueryToken = query?.getProductsCount?.token;
    dataQuery = query?.getProducts?.query;
    dataQueryToken = query?.getProducts?.token;
  }

  const resCount = await axios.post(
    _BASE_URL2,
    {
      sql: countQuery,
      sqlParams: paramsCount,
    },
    {
      headers: {
        db: _DATABASE,
        sqlToken: countQueryToken,
      },
    }
  );
  // console.log("resCount", resCount);
  const body = {
    sql: dataQuery,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: dataQueryToken,
  };
  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });
  // console.log("res", res);
  if (resCount?.data?.err == undefined) {
    if (res?.data?.err == undefined) {
      // console.log(" res?.data?.result", res?.data?.result);
      callback(
        {
          // data: [],
          data: res?.data?.result,
          totalData: resCount?.data?.result[0]?.["count(*)"],
        },
        false
      );
    } else {
      callback({ data: [], totalData: 0 }, true);
    }
  } else {
    callback({ data: [], totalData: 0 }, true);
  }
  // let query;
  // if (selectedTypeId > 0) {
  //   query = `/products?filter=product_type_id,eq,${selectedTypeId}&filter=deleted,eq,0&filter=priority,gt,0&order=priority,desc&page=${page},10`;
  // } else {
  //   query = `/products?filter=deleted,eq,0&filter=priority,gt,0&order=priority,desc&page=${page},10`;
  // }
  // Api.get(query).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: res.records, totalData: res.results }, false);
  //   } else {
  //     callback({ data: [], totalData: 0 }, true);
  //   }
  // });
};
// export const getSpecializations = (callback) => {
//   Api.get(`/doctors_specializations`).send((res) => {
//     if (res.type !== "error") {
//       callback({ data: res.records }, false);
//     }
//     callback({ data: [] }, true);
//   });
// };

export const searchProduct = async (e, setResults, setIsLoading) => {
  if (!e.length) {
    setResults([]);
  }
  if (e.length > 2) {
    setIsLoading(true);
    let searchTerm = { e, ...searchTerm };

    let params = [`%${searchTerm.e}%`, `%${searchTerm.e}%`];
    const body = {
      sql: query.getAllProductsByName.query,
      sqlParams: params,
    };
    const headers = {
      db: _DATABASE,
      sqlToken: query.getAllProductsByName.token,
    };

    const res = await axios.post(_BASE_URL2, body, {
      headers: headers,
    });

    if (res?.data?.err == undefined) {
      setResults(res?.data?.result);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }
};

// export const searchProduct = async (e, setResults, setIsLoading) => {
//   if (!e.length) {
//     setResults([]);
//   }
//   if (e.length > 2) {
//     setIsLoading(true);
//     let searchTerm = { e, ...searchTerm };
//     let endpoints = [
//       `${_BASE_URL}/products?filter1=name,cs,${searchTerm.e}&filter2=keywords,cs,${searchTerm.e}&filter=deleted,eq,0&filter=priority,gt,0&order=priority,desc`,
//     ];
//     Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
//       .then(([{ data: products }]) => {
//         if (products.records.length) {
//           setResults(products.records);
//         }
//         setIsLoading(false);
//       })
//       .catch((e) => setIsLoading(false));
//   }
// };

export const productViewed = async (user, productId, callback) => {
  let params = [user.id, productId];
  const body = {
    sql: query.createProductEnquired.query,
    sqlParams: params,
  };
  const headers = {
    db: _DATABASE,
    sqlToken: query.createProductEnquired.token,
  };

  const res = await axios.post(_BASE_URL2, body, {
    headers: headers,
  });

  if (res?.data?.err == undefined) {
    if (res?.data?.result?.insertId > 0) {
      callback({ data: true }, false);
    } else {
      callback({ data: false }, true);
    }
  } else {
    callback({ data: false }, true);
  }

  // Api.post(`/product_enquired`, params).send((res) => {
  //   if (res.type !== "error") {
  //     callback({ data: true }, false);
  //   } else {
  //     callback({ data: false }, true);
  //   }
  // });
};
