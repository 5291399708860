import React, { useEffect } from "react";

import colors from "config/colors";
import { Circle, HStack, Icon, Text } from "native-base";
import AppStore from "AppStore";
import { useRoute } from "@react-navigation/native";
import { webStyle } from "config/web-style";
import LinkToPage from "widgets/link-to-page";
import { FontAwesome } from "assets/icons";

const WebHeaderRight = ({ navigation }: any) => {
  const [user] = AppStore("user");
  const [selectedWebHeader, setSelectedWebHeader] =
    AppStore("selectedWebHeader");

  const route = useRoute();

  useEffect(() => {
    setSelectedWebHeader(route.name);
  }, [route]);

  return (
    <HStack
      mr={webStyle.xNum}
      alignItems="center"
      justifyContent="center"
      mt="1"
    >
      <LinkToPage route={Object.keys(user).length ? "Account" : "SignIn"}>
        <HStack alignItems="center" justifyContent="center">
          <Text
            color={
              selectedWebHeader == "Account" || selectedWebHeader == "SignIn"
                ? colors.primary
                : colors.black
            }
            fontWeight="medium"
            fontSize="lg"
          >
            Account
          </Text>

          <Circle ml="2" size="7" rounded="full" bg={colors.lightGrayish}>
            <Icon
              as={<FontAwesome name="user" />}
              size="5"
              color={
                selectedWebHeader == "Account" || selectedWebHeader == "SignIn"
                  ? colors.primary
                  : colors.black
              }
              alignSelf="center"
              ml="1.5"
            />
          </Circle>
        </HStack>
      </LinkToPage>
    </HStack>
  );
};

export default WebHeaderRight;
