import { MaterialIcons } from "assets/icons";
import colors from "config/colors";
import { HStack, Icon, Text } from "native-base";
import React from "react";
import { Button } from "widgets";
import { cancelBooking, sendMail } from "../actions";

import AppStore from "AppStore";
import { showToast } from "widgets/nb-toast";

const BookingStatus = ({
  id,
  status,
  hospitalName,
  packageName,
  packageCategory,
  hospitalImage,
  packageImage,
  item,
  setIsLoading,
}: any) => {
  const [user] = AppStore("user");
  const [session] = AppStore("session");

  const handleBookingCancel = () => {
    setIsLoading(true);
    cancelBooking(id, user, session, (res: any, err: any) => {
      if (!err) {
        item.status = "cancelled";
        setIsLoading(false);
        showToast("Booking cancelled!");
        sendMail(id, user, hospitalName, packageName, packageCategory, setIsLoading, "Booking Cancellation");
      } else {
        setIsLoading(false);
      }
    });
  };

  return status == "cancelled" ? (
    <HStack alignItems="center" justifyContent="center" space="2">
      <Icon size="5" color={colors.primary} as={<MaterialIcons name="cancel" />} />
      <Text fontSize="xl" fontWeight="semibold" color="gray.600">
        Cancelled
      </Text>
    </HStack>
  ) : status == "pending" ? (
    <HStack w="full" justifyContent="space-between" alignItems="center">
      <HStack alignItems="center" justifyContent="center" space="2"></HStack>
      <Button
        title="Cancel"
        btnColor={colors.primary}
        pressedColor={colors.accent}
        titleColor={colors.white}
        h="12"
        w="32"
        onPress={() => handleBookingCancel()}
      />
    </HStack>
  ) : // :
  // item.status == "confirmed" ? (
  //   <HStack w="full" justifyContent="space-between" mt={2}>
  //     <VStack>
  //       <HStack alignItems="center" space={2}>
  //         <Icon
  //           size="5"
  //           color="gray.500"
  //           as={<FontAwesome name="calendar-check-o" />}
  //         />
  //         <Text fontSize="lg" fontWeight="semibold" color="gray.500">
  //           {moment(item.appointment_date).format("DD-MM-YYYY")}
  //         </Text>
  //       </HStack>
  //       <HStack alignItems="center" space={2}>
  //         <Icon size="5" color="gray.500" as={<FontAwesome5 name="clock" />} />
  //         <Text fontSize="lg" fontWeight="semibold" color="gray.500">
  //           {item.appointment_date
  //             ? moment(item.appointment_date).format("hh:mm a")
  //             : null}
  //         </Text>
  //       </HStack>
  //     </VStack>
  //     <HStack alignItems="center" justifyContent="flex-start" space="2">
  //       <Circle size="4" bgColor="green.600" />
  //       <Text fontSize="xl" fontWeight="semibold" color="gray.600">
  //         Confirmed
  //       </Text>
  //     </HStack>
  //   </HStack>
  // )
  null;
};

export default BookingStatus;
