import React, { useEffect, useState } from "react";
import {
	Box,
	Circle,
	HStack,
	Icon,
	Input,
	InputLeftAddon,
	ScrollView,
	Stack,
	Text,
	VStack,
} from "native-base";
import { Entypo, MaterialIcons } from "assets/icons";

import colors from "config/colors";

import AppStore from "AppStore";
import { TouchableOpacity } from "react-native";
import { loginUser } from "./actions";
import { AuthHeader, Button, LinkToPage } from "widgets";

import LoadingAnimation from "widgets/loading-animation";
import { showToast } from "widgets/nb-toast";
import { webStyle } from "config/web-style";
import { registerForPushNotificationsAsync } from "AppActions";

const SignIn = ({ navigation, route }: any) => {
	const [isBigScreen] = AppStore("isBigScreen");
	const [deviceOs] = AppStore("deviceOs");
	const [selectedWebHeader, setSelectedWebHeader] = AppStore("selectedWebHeader");
	const [user, setUser] = AppStore("user");
	const [session, setSession] = AppStore("session");
	const [latLong] = AppStore("latLong");
	const [deviceInfo] = AppStore("deviceInfo");
	const [broadcastSubscribed] = AppStore("broadcastSubscribed");
	const [fields, setFields] = useState<any>({});
	const [togglePassword, setTogglePassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	let screen: any;
	let id: any;
	if (route.params) {
		screen = route.params.screen;
		id = route.params.id;
	}

	useEffect(() => {
		registerForPushNotificationsAsync();
	}, []);

	const signInUser = async () => {
		if (Object.keys(fields).length && fields.phone && fields.password) {
			if (fields.phone.match(/^[0-9]*$/) && fields.phone.length == 10) {
				setIsLoading(true);
				loginUser(
					fields,
					latLong,
					deviceInfo,
					broadcastSubscribed,
					deviceOs,
					(res: any, err: any) => {
						if (!err) {
							if (res.invalid) {
								setIsLoading(false);
								showToast("Invalid phone no/password!");
							} else {
								setUser(res.data?.user);
								// delete user?.password;
								// setUser({ ...user });
								let data = { ...res.data?.user };
								let token = res.data?.session;
								setSession({ userSession: data, token: token });
								if (screen && screen == "Enquire") {
									navigation.navigate(`${screen}`, { id });
								} else if (screen && screen !== "Enquire") {
									navigation.navigate(`${screen}`);
								} else {
									navigation.navigate("Home");
									isBigScreen ? setSelectedWebHeader("Home") : null;
								}
								// setIsLoading(false);
								showToast("Sign in successful!");
							}
						} else {
							setIsLoading(false);
							showToast("Please try again after sometime!");
						}
					}
				);
			} else {
				showToast("Please enter valid phone no");
			}
		} else {
			showToast("Please fill required fields");
		}
	};

	return (
		<Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.white}>
			<ScrollView
				flex="1"
				bg={colors.primary}
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{ paddingBottom: 10 }}
				// mx={isBigScreen ? webStyle.xNum : 0}
				mx={isBigScreen ? webStyle.authX : 0}
			>
				<Box
				// mx={isBigScreen ? webStyle.authX : 0}
				>
					<AuthHeader />
					<VStack
						py="10"
						justifyContent="center"
						alignItems="flex-start"
						px="10"
						space="6"
						mx={isBigScreen ? webStyle.xNum : 0}
					>
						<HStack w="full" alignItems="center" justifyContent="space-between">
							<Text color="white" fontSize="4xl" fontWeight="bold">
								Sign In
							</Text>
							{!isBigScreen ? (
								<TouchableOpacity onPress={() => navigation.goBack()}>
									<Circle bgColor={colors.white} size={10}>
										<Icon
											size="6"
											color={colors.primary}
											as={<Entypo name="chevron-left" />}
										/>
									</Circle>
								</TouchableOpacity>
							) : null}
						</HStack>

						<Input
							h="12"
							w="full"
							keyboardType="phone-pad"
							placeholder="Mobile Number"
							maxLength={10}
							variant="unstyled"
							isRequired
							rounded="3xl"
							size="2xl"
							bgColor="white"
							focusOutlineColor="white"
							onChangeText={(e: any) => {
								fields.phone = e;
								setFields({ ...fields });
							}}
							value={fields.phone || ""}
							InputLeftElement={
								<InputLeftAddon w="20%" h="full" bgColor="gray.100">
									<Text
										fontSize="lg"
										fontWeight="semibold"
										color={colors.primary}
									>
										+91
									</Text>
								</InputLeftAddon>
							}
						/>
						<Stack w="full" alignItems="flex-end" space="1">
							<Input
								w="full"
								h="12"
								type={togglePassword ? "text" : "password"}
								variant="unstyled"
								placeholder="Password"
								rounded="3xl"
								size="xl"
								bgColor="white"
								returnKeyType="go"
								onSubmitEditing={signInUser}
								onChangeText={(e: any) => {
									fields.password = e;
									setFields({ ...fields });
								}}
								value={fields.password || ""}
								InputLeftElement={
									<InputLeftAddon
										w="20%"
										h="full"
										fontSize="2xl"
										bgColor="gray.100"
									>
										<Icon
											as={<MaterialIcons name="lock-outline" />}
											size={5}
											color={colors.primary}
										/>
									</InputLeftAddon>
								}
								InputRightElement={
									<InputLeftAddon
										borderColor={colors.white}
										h="full"
										fontSize="2xl"
										bgColor={colors.white}
									>
										<TouchableOpacity
											onPress={() => setTogglePassword(!togglePassword)}
										>
											<Icon
												as={
													<MaterialIcons
														name={
															togglePassword
																? "visibility"
																: "visibility-off"
														}
													/>
												}
												size={5}
												color={colors.black}
											/>
										</TouchableOpacity>
									</InputLeftAddon>
								}
							/>

							<LinkToPage route="ForgotPassword">
								<Text color={colors.white} fontSize="lg" fontWeight="semibold">
									Forgot Password
								</Text>
							</LinkToPage>
						</Stack>
						<Stack w="full" alignItems="flex-end" space="2">
							<Button title="SIGN IN" onPress={signInUser} />

							<HStack w="full" justifyContent="center" alignItems="center" space="1">
								<Text color={colors.white} fontSize="lg" fontWeight="normal">
									Don't have an account?
								</Text>

								<LinkToPage route="SignUp2" params={{ screen: screen, id: id }}>
									<Text color={colors.white} fontSize="lg" fontWeight="semibold">
										Sign Up
									</Text>
								</LinkToPage>
							</HStack>
						</Stack>
					</VStack>
				</Box>
				<LoadingAnimation loading={isLoading} />
			</ScrollView>
		</Box>
	);
};

export default SignIn;
