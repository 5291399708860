import AppStore from "AppStore";
import colors from "config/colors";
import { Box, FlatList, Text } from "native-base";
import React, { useEffect, useRef } from "react";
import { TouchableOpacity } from "react-native";
import { webStyle } from "config/web-style";

const MenuBar = ({ data, handleSelected, selected, field }: any) => {
  const [isForwarded, setIsForwarded] = AppStore("isForwarded");
  const [isBigScreen] = AppStore("isBigScreen");
  const flatListRef: any = useRef();

  useEffect(() => {
    if (isForwarded) {
      let selectedIndex = data.findIndex((e: any) => e.id == selected.id);
      let selectedItem = data.splice(selectedIndex, 1)[0];
      data.unshift(selectedItem);
      setIsForwarded(false);
    }
  }, [selected.id]);

  return (
    <FlatList
      ref={flatListRef}
      data={data}
      horizontal
      showsHorizontalScrollIndicator={false}
      ListHeaderComponent={
        <TouchableOpacity
          onPress={() => handleSelected({ id: 0, name: "All" })}
        >
          <Box
            borderWidth="1"
            borderRadius="lg"
            maxW="80"
            rounded="3xl"
            overflow="hidden"
            borderColor={
              selected.name == "All"
                ? colors.primary
                : isBigScreen
                ? colors.webBorder
                : colors.phoneBorder
            }
            p="2"
            mx="2"
            mt="2"
            px="6"
            bg={
              field == "hospital"
                ? selected.name == "All"
                  ? colors.primary
                  : colors.white
                : selected.name == "All"
                ? colors.primary
                : colors.white
            }
          >
            <Text
              isTruncated
              fontSize="sm"
              fontWeight="black"
              color={selected.name == "All" ? colors.white : "gray.500"}
            >
              All
            </Text>
          </Box>
        </TouchableOpacity>
      }
      style={{
        flexGrow: 0,
        // backgroundColor: "#fcf7f7",
        // backgroundColor: colors.extremeLightGrayish,
        paddingHorizontal: 4,
        // padding: 4,
      }}
      renderItem={({ item }) => (
        <TouchableOpacity
          onPress={() =>
            field == "hospital"
              ? handleSelected({
                  id: item.id,
                  name: item.name,
                })
              : handleSelected({
                  id: item.id,
                  name: item.name,
                })
          }
        >
          <Box
            borderWidth="1"
            borderRadius="lg"
            maxW="80"
            rounded="3xl"
            overflow="hidden"
            borderColor={
              field == "hospital"
                ? selected.id == item.id
                  ? colors.primary
                  : isBigScreen
                  ? colors.webBorder
                  : colors.phoneBorder
                : selected.id == item.id
                ? colors.primary
                : isBigScreen
                ? colors.webBorder
                : colors.phoneBorder
            }
            p="2"
            mx="2"
            mt="2"
            px="6"
            bg={
              field == "hospital"
                ? selected.id == item.id
                  ? colors.primary
                  : colors.white
                : selected.id == item.id
                ? colors.primary
                : colors.white
            }
          >
            <Text
              isTruncated
              fontSize="sm"
              fontWeight="black"
              color={
                field == "hospital"
                  ? selected.id == item.id
                    ? colors.white
                    : "gray.500"
                  : selected.id == item.id
                  ? colors.white
                  : "gray.500"
              }
            >
              {field == "hospital" ? item.name : item.name}
            </Text>
          </Box>
        </TouchableOpacity>
      )}
      // keyExtractor={(item: any) => item.hospital_id.id}
      keyExtractor={(item: any) => item.id}
    />
  );
};

export default MenuBar;
