import { ActivityIndicator, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import { getMitras } from "./actions";
import { Box } from "native-base";
import { LinkToPage, LoadingAnimation, NoData, SearchIcon, Button } from "widgets";
import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import MitrasList from "./mitras-list";
import AppAlert from "widgets/app-alert";
import { linkToPage } from "widgets/link-to-page/link-to-page";
import MitraMapImage from "./mitra-map-image";

const ViewMitras = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isOpen, setIsOpen] = AppStore("isOpen");
  const [isLoading, setIsLoading] = AppStore("isLoading");
  const [mitras, setMitras] = useState<any>([]);
  const [totalMitras, setTotalMitras] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <LinkToPage route="MitraSearch">
          <SearchIcon />
        </LinkToPage>
      ),
    });
  }, []);

  useEffect(() => {
    if (!mitras.length || mitras.length < totalMitras) {
      if (page == 1) {
        setIsLoading(true);
      }
      getMitras(page, (res: any, err: any) => {
        if (!err) {
          setMitras([...mitras, ...res.data]);
          setTotalMitras(res.totalData);
          setIsLoading(false);
          setIsLoadingMore(false);
        } else {
          setIsLoading(false);
          setIsLoadingMore(false);
        }
      });
    }
  }, [page]);

  const reload = () => {
    setIsLoading(true);
    setMitras([]);
    setTotalMitras(0);
    setPage(1);
  };

  useEffect(() => {
    setCurrentPageInit(reload);
    const unsubscribe = navigation.addListener("focus", () => {
      setCurrentPageInit(reload);
    });
    return unsubscribe;
  }, []);

  const handlePagination = () => {
    if (mitras.length < totalMitras && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage((page) => page + 1);
    }
  };

  const listHeaderComponent = () => {
    return (
      <Box w="full" h="64" alignItems="center" justifyContent="center">
        <MitraMapImage data={mitras} />
      </Box>
    );
  };

  const listFooterComponent = () => {
    return isLoadingMore ? (
      <ActivityIndicator color={colors.primary} />
    ) : Platform.OS == "web" && mitras.length < totalMitras ? (
      <Box py="2">
        <Button
          title="Load More"
          btnColor={colors.primary}
          pressedColor={colors.accent}
          titleColor={colors.white}
          h="12"
          w="40"
          rounded="lg"
          fontSize="md"
          fontWeight="medium"
          onPress={handlePagination}
        />
      </Box>
    ) : null;
  };

  return (
    <Box flex="1" px={isBigScreen ? webStyle.xNum : "4"} bgColor={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      {mitras.length ? (
        <Box
          flex="1"
          bgColor={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
          w="full"
          alignItems={isBigScreen ? "center" : null}
          pt={isBigScreen ? "4" : "2"}
        >
          <MitrasList
            mitras={mitras}
            handlePagination={handlePagination}
            listHeaderComponent={listHeaderComponent}
            listFooterComponent={listFooterComponent}
          />
        </Box>
      ) : !isLoading && !mitras.length ? (
        <NoData />
      ) : null}
      {isOpen ? (
        <AppAlert
          header="Healthcare Mitra"
          text="Login to contact Healthcare Mitra"
          actionText="Login"
          action={() => {
            setIsOpen(false);
            linkToPage({
              navigation: navigation,
              route: "SignUp2",
              params: { screen: "ViewMitras" },
            });
          }}
        />
      ) : null}
      <LoadingAnimation loading={isLoading} />
    </Box>
  );
};

export default ViewMitras;
