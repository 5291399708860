import axios from "axios";
import { query } from "config/query";
import { _BASE_URL, _BASE_URL2, _DATABASE } from "config/url";
import Api from "services/Api";

export const getHospitals = async (hospitalsTypeTwo, page, selectedCategoryId, callback) => {
	let params = [];
	let dataQuery = "";
	let dataQueryToken = "";

	if (selectedCategoryId > 0) {
		params = [selectedCategoryId];
		dataQuery = query?.getAllHospitalPackagesByCategoryId?.query;
		dataQueryToken = query?.getAllHospitalPackagesByCategoryId?.token;
	} else {
		dataQuery = query?.getAllHospitalPackagesByHospitalStatus?.query;
		dataQueryToken = query?.getAllHospitalPackagesByHospitalStatus?.token;
	}

	const body = selectedCategoryId
		? {
				sql: dataQuery,
				sqlParams: params,
		  }
		: {
				sql: dataQuery,
		  };

	const headers = {
		db: _DATABASE,
		sqlToken: dataQueryToken,
	};
	const res = await axios.post(_BASE_URL2, body, {
		headers: headers,
	});

	if (res?.data?.err == undefined) {
		if (res?.data?.result.length) {
			let hospitalsTypeOne = [];
			hospitalsTypeOne = res?.data?.result;
			for (let i of hospitalsTypeOne) {
				i.hospital_id.type = 1;
			}
			callback(
				{
					data:
						selectedCategoryId == 0 || selectedCategoryId == 12
							? [...hospitalsTypeTwo, ...hospitalsTypeOne]
							: hospitalsTypeOne,
					totalData: res.data.result.length,
				},
				false
			);
		} else {
			callback({ data: [], totalData: 0 }, true);
		}
	} else {
		callback({ data: [], totalData: 0 }, true);
	}

	// let query;
	// if (selectedCategoryId > 0) {
	//   // query = `/hospitals_packages?join=hospitals&filter=hospital_category_id,eq,${selectedCategoryId}&filter=hospital_status,eq,1&order=id&page=${page},10`;
	//   query = `/hospitals_packages?join=hospitals&filter=hospital_category_id,eq,${selectedCategoryId}&filter=deleted,eq,0&order=hospital_order,desc`;
	// } else {
	//   // query = `/hospitals_packages?join=hospitals&filter=hospital_status,eq,1&page=${page},10&filter=deleted,eq,0&order=hospital_order,desc`;
	//   query = `/hospitals_packages?join=hospitals&filter=hospital_status,eq,1&filter=deleted,eq,0&order=hospital_order,desc`;
	// }
	// Api.get(query).send((res) => {
	//   if (res.type !== "error") {
	//     callback(
	//       {
	//         data:
	//           selectedCategoryId == 0 || selectedCategoryId == 12 ? [...hospitalsTypeTwo, ...res.records] : res.records,
	//         totalData: res.results,
	//       },
	//       false
	//     );
	//   } else {
	//     callback({ data: [], totalData: 0 }, true);
	//   }
	// });
};

export const getHospitalCategories = async callback => {
	const body = {
		sql: query.getAllHospitalsCategories.query,
	};
	const headers = {
		db: _DATABASE,
		sqlToken: query.getAllHospitalsCategories.token,
	};

	const res = await axios.post(_BASE_URL2, body, {
		headers: headers,
	});
	// console.log("res :>> ", res);
	if (res?.data?.err == undefined) {
		callback({ data: res.data.result }, false);
	} else {
		callback({ data: [] }, true);
	}

	// Api.get(`/hospitals_categories`).send((res) => {
	//   if (res.type !== "error") {
	//     callback({ data: res.records }, false);
	//   } else {
	//     callback({ data: [] }, true);
	//   }
	// });
};

export const getType2HospitalById = async (hospitalId, callback) => {
	let params = [hospitalId];
	const body = {
		sql: query.getHospitalById.query,
		sqlParams: params,
	};
	const headers = {
		db: _DATABASE,
		sqlToken: query.getHospitalById.token,
	};

	const res = await axios.post(_BASE_URL2, body, {
		headers: headers,
	});
	if (res?.data?.err == undefined) {
		callback({ data: res.data.result }, false);
	} else {
		callback({ data: [] }, true);
	}

	// Api.get(`/hospitals?filter=id,eq,${hospitalId}`).send((res) => {
	//   if (res.type !== "error") {
	//     callback({ data: res.records }, false);
	//   } else {
	//     callback({ data: [] }, true);
	//   }
	// });
};
export const getAllHospitals = async callback => {
	const body = {
		sql: query.getAllHospitals.query,
	};
	const headers = {
		db: _DATABASE,
		sqlToken: query.getAllHospitals.token,
	};

	const res = await axios.post(_BASE_URL2, body, {
		headers: headers,
	});

	if (res?.data?.err == undefined) {
		callback({ data: res.data.result }, false);
	} else {
		callback({ data: [] }, true);
	}
};
