import _APP_CONFIG from "config/app";
import React from "react";
import { Box, Center, HStack, Image, Spacer, Text, VStack } from "native-base";
import colors from "config/colors";

const NotificationCard = ({ item }: any) => {
  return (
    <Box borderRadius="2xl" shadow="2" backgroundColor="white" paddingY="4" pr="2" margin="3">
      <Box pl="4" pr="5" py="2">
        <HStack alignItems="center" space={3}>
          <Center w="1/4">
            {item && item.image ? (
              <Image
                size={70}
                borderRadius={70}
                source={{
                  uri: `${_APP_CONFIG._IMAGE_URL}/${item.image}`,
                }}
                alt="loading"
              />
            ) : (
              <Image size={70} borderRadius={70} source={require("assets/images/no-image.png")} alt="loading" />
            )}
          </Center>
          <VStack w="3/4" space="1.5">
            <Text fontSize="lg" lineHeight="xs" fontWeight="semibold">
              {item.title}
            </Text>
            <Text lineHeight="xs">{item.body}</Text>
          </VStack>
          <Spacer />
          {/* <Text color={colors.darkGrayish}>{item.timeStamp}</Text> */}
        </HStack>
      </Box>
    </Box>
  );
};
export default NotificationCard;
