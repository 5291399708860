import _APP_CONFIG from "config/app";
import React from "react";
import AppStore from "AppStore";
import { Box, Image } from "native-base";

const AboutImage = ({ e }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  return (
    <Box flex={isBigScreen && "2"} h={isBigScreen ? "96" : 400}>
      <Image size="full" source={e.image} resizeMode="contain" alt="loading" />
    </Box>
  );
};

export default AboutImage;
