import AppStore from "AppStore";
import colors from "config/colors";
import { webStyle } from "config/web-style";
import { Box, ScrollView, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { LoadingAnimation } from "widgets";
import { getGalleryImages } from "./actions";
import ImagesList from "./images-list";

const Gallery = () => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [loading, setLoading] = useState(true);

  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    getGalleryImages((error: any, res: any) => {
      if (!error) {
        setImages(res);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
      <ScrollView
        py={isBigScreen ? "0" : "4"}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={isBigScreen ? { paddingBottom: 0 } : { paddingBottom: 50 }}
      >
        <VStack space={4} mx={isBigScreen ? webStyle.xNum : 0} bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}>
          <ImagesList images={images} />
        </VStack>
      </ScrollView>
      <LoadingAnimation loading={loading} />
    </Box>
  );
};

export default Gallery;
