import React from "react";
import { Pressable, Text } from "native-base";
import colors from "config/colors";

const Button = ({
  title,
  btnColor = "white",
  pressedColor = "gray.200",
  titleColor = colors.primary,
  w = "full",
  h = "16",
  mt = "3",
  fontSize = "2xl",
  fontWeight = "semibold",
  rounded = "3xl",
  onPress,
}: any) => {
  return (
    <Pressable
      onPress={onPress && onPress}
      _pressed={{
        bg: pressedColor,
      }}
      bg={btnColor}
      mt={mt}
      h={h}
      w={w}
      rounded={rounded}
      alignSelf="center"
      justifyContent="center"
    >
      <Text fontSize={fontSize} fontWeight={fontWeight} color={titleColor} alignSelf="center">
        {title}
      </Text>
    </Pressable>
  );
};

export default Button;
