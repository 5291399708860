import _APP_CONFIG from "config/app";
import { TouchableOpacity } from "react-native";
import React, { useEffect, useRef } from "react";
import { Box, Center, FlatList, Image, Text, VStack } from "native-base";
import colors from "config/colors";
import { FontAwesome } from "assets/icons";
import AppStore from "AppStore";
import { webStyle } from "config/web-style";

const HospitalScroll = ({ data, selected, handleSelected, hospitalId, selectedIndex }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const flatListRef: any = useRef();

  useEffect(() => {
    if (selectedIndex && data.length && flatListRef.current) {
      flatListRef.current?.scrollToIndex({
        animated: true,
        index: selectedIndex,
      });
    }
  }, [selectedIndex]);

  return (
    <Box mx={isBigScreen ? webStyle.xNum : 0}>
      <FlatList
        data={data}
        ref={flatListRef}
        onScrollToIndexFailed={(info) => {
          const wait = new Promise((resolve) => setTimeout(resolve, 100));
          wait.then(() => {
            flatListRef.current?.scrollToIndex({
              index: info.index,
              animated: true,
            });
          });
        }}
        horizontal
        showsHorizontalScrollIndicator={false}
        style={{
          // flexGrow: 0,
          paddingHorizontal: 12,
          // paddingLeft: 10,
        }}
        renderItem={({ item, index }) => {
          return (
            <Box pr={index == data.length - 1 ? "5" : "0"}>
              <TouchableOpacity onPress={() => handleSelected(item)}>
                <VStack
                  flex="1"
                  borderWidth="1"
                  borderRadius="lg"
                  w="32"
                  // h="60"
                  rounded="3xl"
                  overflow="hidden"
                  borderColor={
                    Object.keys(selected).length && selected.id == item.id
                      ? colors.primary
                      : isBigScreen
                      ? colors.webBorder
                      : colors.phoneBorder
                  }
                  mx="1"
                  p="1"
                  bg={colors.white}
                  alignItems="center"
                >
                  <Box mb="1" overflow="hidden">
                    {item.hospital_id.image ? (
                      <Image
                        size="16"
                        borderRadius="full"
                        source={{
                          uri: `${_APP_CONFIG._IMAGE_URL}/${item.hospital_id.image}`,
                        }}
                        resizeMode="contain"
                        alt="loading"
                      />
                    ) : (
                      <Image
                        source={require("assets/images/no-image.png")}
                        size="16"
                        resizeMode="contain"
                        alt="loading"
                      />
                    )}
                  </Box>

                  <Box w="32" p="1" h="16">
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      color={colors.primary}
                      textAlign="center"
                      noOfLines={2}
                      lineHeight="sm"
                    >
                      {item.hospital_id.name}
                    </Text>
                  </Box>

                  <Box w="32" p="1" maxH="16">
                    <Text textAlign="center" fontSize="xs" color={colors.grayText} noOfLines={2}>
                      {item.package_category_id.name ? item.package_category_id.name : "N/A"}
                    </Text>
                  </Box>
                  {item.price ? (
                    <Box w="32" p="1" maxH="16">
                      <Text textAlign="center" fontSize="sm" color={colors.grayText}>
                        <FontAwesome name="rupee" size={12} color="black" /> {item.price}
                      </Text>
                    </Box>
                  ) : null}
                </VStack>
              </TouchableOpacity>
            </Box>
          );
        }}
        keyExtractor={(item: any) => item.id}
      />
    </Box>
  );
};

export default HospitalScroll;
