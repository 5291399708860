import _APP_CONFIG from "config/app";
import React from "react";
import { Box, Image, VStack, ScrollView, Text } from "native-base";
import AppStore from "AppStore";
import colors from "config/colors";
import { FontAwesome } from "assets/icons";
import { Linking, TouchableOpacity } from "react-native";

const VideosList = ({ videos }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");

  return (
    <ScrollView
      contentContainerStyle={{
        flexDirection: "row",
        flexWrap: "wrap",
        marginHorizontal: 4,
        justifyContent: isBigScreen ? "flex-start" : "center",
      }}
    >
      {videos.map((item: any) => (
        <TouchableOpacity
          key={item.id}
          onPress={() => {
            Linking.openURL(item.video_url);
          }}
        >
          <Box
            borderWidth="1"
            borderRadius="lg"
            w={["72", "72"]}
            h={["72", "72"]}
            rounded="2xl"
            overflow="hidden"
            borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
            m="2"
            bg={colors.white}
          >
            <VStack justifyContent="center" alignItems="center">
              <Box h={["72", "72"]} alignItems="center" justifyContent="center">
                <Image
                  borderRadius="lg"
                  h={["72", "72"]}
                  w={["72", "72"]}
                  source={{
                    uri: `${_APP_CONFIG._IMAGE_URL}/${item.video_preview_url}`,
                  }}
                  resizeMode="cover"
                  alt="loading"
                />
                <Box
                  position="absolute"
                  bg={colors.transparent}
                  w="full"
                  h="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FontAwesome name="play-circle" size={90} color={colors.white} />
                </Box>
              </Box>
            </VStack>
          </Box>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};

export default VideosList;
