import React, { Suspense, useEffect, useState } from "react";
import { Box, HStack, Icon, ScrollView, Stack, Text, VStack } from "native-base";
import { TouchableOpacity } from "react-native";
import * as SplashScreen from "expo-splash-screen";

import { webStyle } from "config/web-style";
import colors from "config/colors";
import { Ionicons } from "assets/icons";

import { LinkToPage, SearchBar } from "widgets/index";
import { LoadingAnimation, Footer } from "widgets";
import OtaUpdate from "widgets/updates/ota-update";
import LoadingGif from "widgets/loading-gif";

let ImageCarousel = React.lazy(() => import("widgets/image-carousel"));
let FAB = React.lazy(() => import("widgets/fab"));
let TopPackages = React.lazy(() => import("./top-packages"));
let TopHospitals = React.lazy(() => import("./top-hospitals"));
let TopServices = React.lazy(() => import("./top-services"));
let TopCategories = React.lazy(() => import("./top-categories"));
let CallZemidi = React.lazy(() => import("./call-zemidi"));
import MitraCard from "screens/healthcare-mitra/mitra-card";

import { getLocation, handleNotificationOpened, resetToHome } from "AppActions";
import { getData } from "./actions";
import AppStore from "AppStore";
import BuyHealthCard from "./buy-health-card";
import VideoPlayer from "./video-player";
import AyushmanBharat from "./ayushman-bharat";
import HouseAaya from "./house-aaya";
import HospitalRegistrationImage from "screens/hospitals/hospital-registration-image";

const Home = ({ navigation }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [otaAvailable] = AppStore("otaAvailable");
  const [goToHome] = AppStore("goToHome");
  const [_, setCurrentPageInit] = AppStore("currentPageInit");
  const [isLoading, setIsLoading] = AppStore("isLoading");

  const [topCategories, setTopCategories] = AppStore("topCategories");
  const [popularPackages, setPopularPackages] = AppStore("popularPackages");
  const [popularHospitals, setPopularHospitals] = AppStore("popularHospitals");
  const [popularServices, setPopularServices] = AppStore("popularServices");
  const [hospitalsTypeTwo, setHospitalsTypeTwo] = AppStore("hospitalsTypeTwo");
  const [popularCategories, setPopularCategories] = AppStore("popularCategories");

  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    handleNotificationOpened(navigation);
  }, []);

  const prepare = async () => {
    setAppIsReady(false);
    try {
      SplashScreen.preventAutoHideAsync();
      getData(setLoading, setAppIsReady, setPopularPackages, setPopularHospitals, setPopularServices, setPopularCategories, setTopCategories, setHospitalsTypeTwo, isBigScreen);
    } catch (e) {
      setLoading(false);
      SplashScreen.hideAsync();
    } finally {
      SplashScreen.hideAsync();
    }
  };

  useEffect(() => {
    if (goToHome) {
      resetToHome(navigation);
    }
    getLocation(setLocation);
    prepare();
    setCurrentPageInit(prepare);
    const unsubscribe = navigation.addListener("focus", () => {
      setCurrentPageInit(prepare);
    });
    return unsubscribe;
  }, [goToHome]);

  //to reset isLoading global state used in doctor, donor, mitra.
  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      setIsLoading(true);
    });
    return unsubscribe;
  }, []);
  //

  if (!appIsReady) {
    return <LoadingAnimation loading={loading} />;
  }

  return (
    <Suspense fallback={<LoadingAnimation loading={true} />}>
      <Box flex="1" bg={isBigScreen ? colors.webOuterBg : colors.appInnerBg}>
        <ScrollView
          // flex="1"
          // bg={isBigScreen ? colors.webOuterBg : colors.white}
          py={isBigScreen ? "0" : "4"}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={isBigScreen ? { paddingBottom: 0 } : { paddingBottom: 50 }}
          // mx={isBigScreen ? webStyle.xNum : 0}
        >
          <VStack
            space={4}
            mx={isBigScreen ? webStyle.xNum : 0}
            bg={isBigScreen ? colors.webInnerBg : colors.appInnerBg}
            // alignItems={isBigScreen ? "center" : "flex-start"}
          >
            {otaAvailable ? <OtaUpdate /> : null}
            <HStack w="full" flex={1} color="amber.400" justifyContent="space-between" px="3" pt={isBigScreen ? "1" : "0"}>
              <TouchableOpacity onPress={() => getLocation(setLocation)}>
                <HStack
                  // mb="2"
                  justifyContent="flex-start"
                  alignItems="center"
                  space="2"
                  w="48"
                >
                  <Icon size="5" color="black" as={<Ionicons name="ios-location-sharp" />} />

                  <Text color="gray.500" fontWeight="medium" pb="1" noOfLines={1}>
                    {location ? location : "Detecting Location"}
                  </Text>
                </HStack>
              </TouchableOpacity>
            </HStack>

            {/* {!isBigScreen ? (
              <LinkToPage route="SearchResults">
                <Box px="3" h="10" w="full">
                  <SearchBar />
                </Box>
              </LinkToPage>
            ) : null} */}

            <Box pt={isBigScreen ? "2" : "0"}>
              <ImageCarousel />
            </Box>

            {/* <Box mx="3" py="2">
              <VideoPlayer />
            </Box> */}

            {!isBigScreen ? (
              <Box mx="3">
                <CallZemidi />
              </Box>
            ) : null}

            <Stack
              w="full"
              display={isBigScreen ? "flex" : "flex"}
              flexWrap={isBigScreen ? "wrap" : "nowrap"}
              direction={isBigScreen ? "row" : "column"}
              justifyContent="center"
              space={isBigScreen ? 1 : 4}
            >
              <Box m="3">
                <BuyHealthCard h={isBigScreen ? 64 : null} />
              </Box>

              {/* <Box m="3">
								<MitraCard />
							</Box> */}

              {/* <Box m="3">
								<AyushmanBharat h={isBigScreen ? 64 : 64} />
							</Box> */}

              {/* <Box m="3">
                <HouseAaya h={isBigScreen ? 64 : 64} />
              </Box> */}
            </Stack>

            <HStack justifyContent="space-between" alignItems="center" px="3">
              <HStack alignItems="center">
                <Text fontSize="xl" fontWeight="bold">
                  Hospitals
                </Text>
              </HStack>

              <LinkToPage route="Hospitals">
                <Text fontSize="lg" fontWeight="bold" color={colors.primary}>
                  See All
                </Text>
              </LinkToPage>
            </HStack>

            {/* <TopHospitals navigation={navigation} hospitals={popularHospitals} /> */}
            <HospitalRegistrationImage />
            {/* <HStack justifyContent="space-between" alignItems="center" px="3">
							<HStack alignItems="center">
								<Text fontSize="xl" fontWeight="bold">
									Popular Categories
								</Text>
							</HStack>
						</HStack>

						<TopCategories data={popularCategories} navigation={navigation} /> */}

            {/* <HStack justifyContent="space-between" alignItems="center" px="3">
              <Text fontSize="xl" fontWeight="bold">
                Packages
              </Text>

              <LinkToPage route="Packages">
                <Text fontSize="lg" fontWeight="bold" color={colors.primary}>
                  See All
                </Text>
              </LinkToPage>
            </HStack>

            <TopPackages navigation={navigation} packages={popularPackages} /> */}

            <HStack justifyContent="space-between" alignItems="center" px="3">
              <Text fontSize="xl" fontWeight="bold">
                Services
              </Text>

              <LinkToPage route="Services">
                <Text fontSize="lg" fontWeight="bold" color={colors.primary}>
                  See All
                </Text>
              </LinkToPage>
            </HStack>

            <TopServices navigation={navigation} services={popularServices} />

            {/* <Box h={isBigScreen ? "56" : "40"} mt={isBigScreen ? "8" : "0"}>
              <DiscountBanner />
            </Box> */}

            {/* <HStack justifyContent="space-between" alignItems="center" px="3">
              <Text fontSize="xl" fontWeight="bold">
                Popular
              </Text>
            </HStack>
            <Popular /> */}

            {isBigScreen ? <Box h="6"></Box> : null}
          </VStack>
          {isBigScreen ? <Footer /> : null}
          <LoadingAnimation loading={loading} />
        </ScrollView>
        <Box position="absolute" right={isBigScreen ? "24" : "10"} bottom="10">
          <FAB />
        </Box>
      </Box>
    </Suspense>
  );
};

export default React.memo(Home);
