import _APP_CONFIG from "config/app";
import React from "react";
import { Box, Circle, Divider, FlatList, HStack, Icon, Image, Text, VStack } from "native-base";
import AppStore from "AppStore";
import colors from "config/colors";
import { FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from "assets/icons";
import { Linking, TouchableOpacity } from "react-native";
import { productViewed } from "./actions";
import { _ZEMIDI_HELPLINE } from "config/defaults";

const ProductsList = ({ products, handlePagination, listFooterComponent }: any) => {
  const [isBigScreen] = AppStore("isBigScreen");
  const [user] = AppStore("user");
  const [isOpen, setIsOpen] = AppStore("isOpen");
  const [isLoading, setIsLoading] = AppStore("isLoading");

  const handleCall = (productId: any) => {
    setIsLoading(true);
    productViewed(user, productId, (res: any, err: any) => {
      if (!err && res.data) {
        setIsLoading(false);
        Linking.openURL(`tel:${_ZEMIDI_HELPLINE}`);
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <FlatList
      data={products}
      showsVerticalScrollIndicator={false}
      numColumns={isBigScreen ? 2 : 1}
      onEndReached={handlePagination}
      onEndReachedThreshold={0.5}
      ListFooterComponent={listFooterComponent}
      renderItem={({ item }: any) => {
        return (
          <Box
            w={isBigScreen ? 600 : "full"}
            borderRadius="2xl"
            borderWidth="1"
            borderColor={isBigScreen ? colors.webBorder : colors.phoneBorder}
            backgroundColor="white"
            py="4"
            my="2"
            mx={isBigScreen ? 1 : 0}
            flex={!isBigScreen ? "1" : isBigScreen ? (item.length % 2 == 0 ? "1" : "1/2") : null}
            justifyContent="center"
          >
            <HStack alignItems="center">
              <Box flex="1" overflow="hidden" alignItems="center" justifyContent="center" p="1">
                {item.image ? (
                  <Image
                    size="65"
                    rounded="full"
                    source={{
                      uri: `${_APP_CONFIG._IMAGE_URL}/${item.image}`,
                    }}
                    alt="loading"
                    resizeMode="cover"
                  />
                ) : (
                  <Image size={65} source={require("assets/images/no-image.png")} alt="loading" resizeMode="contain" />
                )}
              </Box>
              <VStack space="1" flex="3">
                <Text fontSize="md" fontWeight="medium" noOfLines={2} lineHeight="sm" pl="6">
                  {item.name}
                </Text>
                <HStack alignItems="center" space="1" flexWrap="wrap">
                  <Circle bg="gray.200" size="5">
                    <Icon size="3" color={colors.darkGrayish} as={<Ionicons name="document-text-outline" />} />
                  </Circle>
                  <Text flex={1} fontSize="xs" fontWeight="medium" color="coolGray.500">
                    {item.description ? item.description : "No Description."}
                  </Text>
                </HStack>
                {/* <HStack alignItems="center" space="1" flexWrap="wrap">
                  <Circle bg="gray.200" size="5">
                    <Icon size="3" color={colors.darkGrayish} as={<MaterialCommunityIcons name="medical-bag" />} />
                  </Circle>
                  <Text fontSize="xs" fontWeight="medium" color="coolGray.500">
                    {item.address}
                  </Text>
                </HStack> */}
              </VStack>
              <Divider orientation="vertical" />
              <VStack alignItems="center" space="3" flex="1">
                <TouchableOpacity onPress={() => (Object.keys(user).length ? handleCall(item.id) : setIsOpen(!isOpen))}>
                  <Circle bg="gray.200" size="sm">
                    <Icon size="8" color="green.700" as={<MaterialIcons name="call" />} />
                  </Circle>
                </TouchableOpacity>
              </VStack>
            </HStack>
          </Box>
        );
      }}
      keyExtractor={(item: any) => item.id}
    />
  );
};

export default ProductsList;
